import { useEffect, useState } from "react";
import { deleteLayoutAction, readLayoutAction } from "../../../store/Layout/LayoutAction";
import { useDispatch } from "react-redux";
import Toastiy from "../../../components/universal/Toastiy";

const LayoutHooks = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [allLayouts, setAllLayouts] = useState([]);
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  const handleDelete = (data) => {
    dispatch(deleteLayoutAction({
      apiPayloadRequest: { _id: data?._id },
      callback: (res) => {
        if (res?.type === 1) {
          const requiredData = allLayouts.filter((item) => item._id !== data?._id)
          setAllLayouts(requiredData);
          Toastiy('Layout Deleted Successfully!', 1)
        } else {
          Toastiy('Failed To Delete Layout')
        }
      }
    }))
  }

  useEffect(() => {
    dispatch(readLayoutAction({
      callback: (res) => {
        if (res?.type === 1) {
          setAllLayouts(res?.data)
        } else {
          Toastiy("Failed To Get Layout Data!!")
        }
      }
    }))
  }, []);


  return {
    value,
    onChangeSearchBar,
    allLayouts,
    handleDelete
  };
};

export default LayoutHooks;