import avatar from "../../assets/images/avatar.png";
export const languagesHeaders = [
  {
    headerName: "User Name",
    headerId: "userName",
  },
  {
    headerName: "About",
    headerId: "about",
  },
  {
    headerName: "Last Login",
    headerId: "lastLogin",
  },
];

export const languagesData = [
  {
    userName: "Arvind",
    about: "We become what we think ab...",
    lastLogin: "23-02-2023",
  },
  {
    userName: "John Suresh",
    about: "We become what we think ab...",
    lastLogin: "23-02-2023",
  },
  {
    userName: "George",
    about: "We become what we think ab...",
    lastLogin: "23-02-2023",
  },
  {
    userName: "Stephen Gill",
    about: "We become what we think ab...",
    lastLogin: "23-02-2023",
  },
];
export const allUsersHeaders = [
  {
    headerName: "User Name",
    headerId: "firstName",
    image: true,
  },
  {
    headerName: "User ID",
    headerId: "twgId",
    hyperLink: true,
  },
  {
    headerName: "Last Activity",
    headerId: "lastActivity",
  },
  {
    headerName: "Language",
    headerId: "language_prefference",
  },
  // {
  //   headerName: "User Status",
  //   headerId: "active",
  // },
  {
    headerName: "Actions",
    headerId: "actions",
  },
];
export const allUsersData = [
  {
    userName: {
      imgUrl: avatar,
      name: "Glenn Maxwell",
    },
    userId: "#099129",
    lastActivity: "14/03/2023 09:18PM",
    language: "English",
    status: "Active",
  },
  {
    userName: {
      imgUrl: "",
      name: "Glenn Maxwell",
    },
    userId: "#099129",
    lastActivity: "14/03/2023 09:18PM",
    language: "English",
    status: "Inactive",
  },
  {
    userName: {
      imgUrl: "",
      name: "Glenn Maxwell",
    },
    userId: "#099129",
    lastActivity: "14/03/2023 09:18PM",
    language: "English",
    status: "Active",
  },
];

// add user const

export const langArray = [
  { value: "tamil", label: "Tamil" },
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
];
