import * as actionTypes from "../ActionTypes";

export const translateLanguageAction = (payload) => {
  return {
    type: actionTypes.TRANSLATE_TEXT_REQUEST,
    payload: payload,
  };
};

export const languageDashboardAction = (payload) => {
  return {
    type: actionTypes.LANGUAGE_DASHBOARD_REQUEST,
    payload: payload,
  };
};

export const uploadAssestsAction = (payload) => {
  return {
    type: actionTypes.UPLOAD_ASSEST_REQUEST,
    payload: payload,
  };
};

export const uploadVideosAction = (payload) => {
  return {
    type: actionTypes.UPLOAD_VIDEOS_REQUEST,
    payload: payload,
  };
};

export const saveLanguageAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGES_REQUEST,
    payload: payload,
  };
};

export const updateLanguageAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGES_REQUEST,
    payload: payload,
  };
};
export const readLanguageAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGES_REQUEST,
    payload: payload,
  };
};
export const activeOrInactiveLanguageAction = (payload) => {
  return {
    type: actionTypes.ACTIVE_OR_INACTIVE_LANGUAGES_REQUEST,
    payload: payload,
  };
};

export const saveLanguageHomeDataAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGES_HOME_DATA_REQUEST,
    payload: payload,
  };
};

export const updateLanguageHomeDataAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGES_HOME_DATA_REQUEST,
    payload: payload,
  };
};

export const saveLanguagePromisesDataAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGES_PROMISES_DATA_REQUEST,
    payload: payload,
  };
};

export const updateLanguagePromisesDataAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGES_PROMISES_DATA_REQUEST,
    payload: payload,
  };
};

export const readLanguageHomeDataAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGES_HOME_DATA_REQUEST,
    payload: payload,
  };
};

export const deleteLanguageHomeDataAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGES_HOME_DATA_REQUEST,
    payload: payload,
  };
};

export const saveLanguageLoginAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGE_LOGIN_META,
    payload: payload,
  };
};
export const updateLanguageLoginAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGES_LOGIN_META,
    payload: payload,
  };
};

export const ReadLanguageLoginAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGES_LOGIN_META,
    payload: payload,
  };
};

export const deleteLanguageLoginAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGES_LOGIN_META,
    payload: payload,
  };
};

export const saveLanguageSignUpAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGES_SIGN_UP_REQUEST,
    payload: payload,
  };
};

export const updateLanguageSignUpAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGES_SIGN_UP_REQUEST,
    payload: payload,
  };
};

export const ReadLanguageSignUpAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGES_SIGN_UP_REQUEST,
    payload: payload,
  };
};

export const deleteLanguageSignUpAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGES_SIGN_UP_REQUEST,
    payload: payload,
  };
};

export const saveLanguageTestimoniesAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGE_TESTIMONIES_REQUEST,
    payload: payload,
  };
};
export const updateLanguageTestimoniesAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGE_TESTIMONIES_REQUEST,
    payload: payload,
  };
};

export const readLanguageTestimoniesAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGE_TESTIMONIES_REQUEST,
    payload: payload,
  };
};

export const deleteLanguageTestimoniesAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGE_TESTIMONIES_REQUEST,
    payload: payload,
  };
};

export const saveLanguageCurriculumAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGE_CURRICULUM,
    payload: payload,
  };
};
export const updateLanguageCurriculumAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGE_CURRICULUM,
    payload: payload,
  };
};
export const readLanguageCurriculumAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGE_CURRICULUM,
    payload: payload,
  };
};
export const deleteLanguageCurriculumAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGE_CURRICULUM,
    payload: payload,
  };
};

export const saveLanguageFavouritesAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGE_FAVOURITES,
    payload: payload,
  };
};
export const updateLanguageFavouritesAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGE_FAVOURITES,
    payload: payload,
  };
};
export const readLanguageFavouritesAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGE_FAVOURITES,
    payload: payload,
  };
};
export const deleteLanguageFavouritesAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGE_FAVOURITES,
    payload: payload,
  };
};

export const saveLanguageGivingsAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGES_GIVINGS_REQUEST,
    payload: payload,
  };
};
export const updateLanguageGivingsAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGES_GIVINGS_REQUEST,
    payload: payload,
  };
};
export const readLanguageGivingsAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGES_GIVINGS_REQUEST,
    payload: payload,
  };
};
export const deleteLanguageGivingsAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGES_GIVINGS_REQUEST,
    payload: payload,
  };
};

export const saveLanguageMenuAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGES_MENU_REQUEST,
    payload: payload,
  };
};
export const updateLanguageMenuAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGES_MENU_REQUEST,
    payload: payload,
  };
};
export const readLanguageMenuAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGES_MENU_REQUEST,
    payload: payload,
  };
};
export const deleteLanguageMenuAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGES_MENU_REQUEST,
    payload: payload,
  };
};

export const saveLanguageMyProfileAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGES_MY_PROFILE_REQUEST,
    payload: payload,
  };
};
export const updateLanguageMyProfileAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGES_MY_PROFILE_REQUEST,
    payload: payload,
  };
};
export const readLanguageMyProfileAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGES_MY_PROFILE_REQUEST,
    payload: payload,
  };
};
export const deleteLanguageMyProfileAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGES_MY_PROFILE_REQUEST,
    payload: payload,
  };
};

export const saveLanguageMyDownloadsAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGES_MY_DOWNLOADS_REQUEST,
    payload: payload,
  };
};
export const updateLanguageMyDownloadsAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGES_MY_DOWNLOADS_REQUEST,
    payload: payload,
  };
};
export const readLanguageMyDownloadsAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGES_MY_DOWNLOADS_REQUEST,
    payload: payload,
  };
};
export const deleteLanguageMyDownloadsAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGES_MY_DOWNLOADS_REQUEST,
    payload: payload,
  };
};

export const saveLanguageMyGivingsAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGES_MY_GIVINGS_REQUEST,
    payload: payload,
  };
};
export const updateLanguageMyGivingsAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGES_MY_GIVINGS_REQUEST,
    payload: payload,
  };
};
export const readLanguageMyGivingsAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGES_MY_GIVINGS_REQUEST,
    payload: payload,
  };
};
export const deleteLanguageMyGivingsAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGES_MY_GIVINGS_REQUEST,
    payload: payload,
  };
};

export const saveLanguageHelpAndSupportAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGES_HELP_AND_SUPPORT_REQUEST,
    payload: payload,
  };
};
export const updateLanguageHelpAndSupportAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGES_HELP_AND_SUPPORT_REQUEST,
    payload: payload,
  };
};
export const readLanguageHelpAndSupportAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGES_HELP_AND_SUPPORT_REQUEST,
    payload: payload,
  };
};
export const deleteLanguageHelpAndSupportAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGES_HELP_AND_SUPPORT_REQUEST,
    payload: payload,
  };
};

export const saveLanguageHelpAndSupportAccordianAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST,
    payload: payload,
  };
};
export const updateLanguageHelpAndSupportAccordianAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST,
    payload: payload,
  };
};
export const readLanguageHelpAndSupportAccordianAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST,
    payload: payload,
  };
};
export const deleteLanguageHelpAndSupportAccordianAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST,
    payload: payload,
  };
};

export const saveLanguageAboutTWGAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGES_ABOUT_TWG_REQUEST,
    payload: payload,
  };
};
export const updateLanguageAboutTWGAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGES_ABOUT_TWG_REQUEST,
    payload: payload,
  };
};
export const readLanguageAboutTWGAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGES_ABOUT_TWG_REQUEST,
    payload: payload,
  };
};
export const deleteLanguageAboutTWGAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGES_ABOUT_TWG_REQUEST,
    payload: payload,
  };
};

export const saveLanguageForgotPasswordAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGES_FORGOT_PASSWORD_REQUEST,
    payload: payload,
  };
};
export const updateLanguageForgotPasswordAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGES_FORGOT_PASSWORD_REQUEST,
    payload: payload,
  };
};
export const readLanguageForgotPasswordAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGES_FORGOT_PASSWORD_REQUEST,
    payload: payload,
  };
};
export const deleteLanguageForgotPasswordAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGES_FORGOT_PASSWORD_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_REQUEST,
    payload: payload,
  };
};
export const readCurriculumAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_REQUEST,
    payload: payload,
  };
};

export const saveBGCurriculumAction = (payload) => {
  return {
    type: actionTypes.SAVE_BG_CURRICULUM_REQUEST,
    payload: payload,
  };
};

export const updateBGCurriculumAction = (payload) => {
  return {
    type: actionTypes.UPDATE_BG_CURRICULUM_REQUEST,
    payload: payload,
  };
};
export const readBGCurriculumAction = (payload) => {
  return {
    type: actionTypes.READ_BG_CURRICULUM_REQUEST,
    payload: payload,
  };
};
export const deleteBGCurriculumAction = (payload) => {
  return {
    type: actionTypes.DELETE_BG_CURRICULUM_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLanguageAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLanguageAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LANGUAGE_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLanguageAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LANGUAGE_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLanguageAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const saveBGCurriculumLanguageAction = (payload) => {
  return {
    type: actionTypes.SAVE_BG_CURRICULUM_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const updateBGCurriculumLanguageAction = (payload) => {
  return {
    type: actionTypes.UPDATE_BG_CURRICULUM_LANGUAGE_REQUEST,
    payload: payload,
  };
};
export const readBGCurriculumLanguageAction = (payload) => {
  return {
    type: actionTypes.READ_BG_CURRICULUM_LANGUAGE_REQUEST,
    payload: payload,
  };
};
export const deleteBGCurriculumLanguageAction = (payload) => {
  return {
    type: actionTypes.DELETE_BG_CURRICULUM_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLessonAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LESSON_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLessonAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LESSON_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLessonAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LESSON_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLessonAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LESSON_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLessonLanguageAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLessonLanguageAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLessonLanguageAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLessonLanguageAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLessonThumbNailAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LESSON_THUMBNAIL_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLessonThumbNailAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LESSON_THUMBNAIL_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLessonThumbNailAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LESSON_THUMBNAIL_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLessonThumbNailAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LESSON_THUMBNAIL_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLanguageLessonThumbNailAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLanguageLessonThumbNailAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLanguageLessonThumbNailAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLanguageLessonThumbNailAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLessonSlideAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLessonSlideAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLessonSlideAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LESSON_SLIDE_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLessonSlideAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLanguageLessonSlideAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLanguageLessonSlideAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLanguageLessonSlideAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLanguageLessonSlideAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLessonBGSlideAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LESSON_BG_SLIDE_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLessonBGSlideAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LESSON_BG_SLIDE_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLessonBGSlideAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LESSON_BG_SLIDE_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLessonBGSlideAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LESSON_BG_SLIDE_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLanguageLessonBySlideAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_BG_SLIDE_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLessonSlideTypeAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLessonSlideTypeAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLessonSlideTypeAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLessonSlideTypeAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLanguageLessonSlideTypeAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLanguageLessonSlideTypeAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLanguageLessonSlideTypeAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLanguageLessonSlideTypeAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLessonSlideTypeBGAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLessonSlideTypeBGAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLessonSlideTypeBGAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLessonSlideTypeBGAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLanguageLessonSlideTypeBGAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLanguageLessonSlideTypeBGAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLanguageLessonSlideTypeBGAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLanguageLessonSlideTypeBGAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLessonSlideTypeAssetAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLessonSlideTypeAssetAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLessonSlideTypeAssetAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLessonSlideTypeAssetAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLanguageLessonSlideTypeAssetAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLanguageLessonSlideTypeAssetAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLanguageLessonSlideTypeAssetAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLanguageLessonSlideTypeAssetAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLessonSlideTypeLinkAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLessonSlideTypeLinkAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLessonSlideTypeLinkAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLessonSlideTypeLinkAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLanguageLessonSlideTypeLinkAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLanguageLessonSlideTypeLinkAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLanguageLessonSlideTypeLinkAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLanguageLessonSlideTypeLinkAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLessonSlideTypePollAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLessonSlideTypePollAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLessonSlideTypePollAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLessonSlideTypePollAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLanguageLessonSlideTypePollAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLanguageLessonSlideTypePollAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLanguageLessonSlideTypePollAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLanguageLessonSlideTypePollAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLessonSlideTypePollOptionsAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLessonSlideTypePollOptionsAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLessonSlideTypePollOptionsAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLessonSlideTypePollOptionsAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    payload: payload,
  };
};
export const saveCurriculumLessonSlideTypePollOptionsVoteAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLessonSlideTypePollOptionsVoteAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLessonSlideTypePollOptionsVoteAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLessonSlideTypePollOptionsVoteAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLanguageLessonSlideTypePollOptionsAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLanguageLessonSlideTypePollOptionsAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLanguageLessonSlideTypePollOptionsAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLanguageLessonSlideTypePollOptionsAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    payload: payload,
  };
};
export const saveCurriculumLanguageLessonSlideTypePollOptionsVoteAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLanguageLessonSlideTypePollOptionsVoteAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    payload: payload,
  };
};
export const readCurriculumLanguageLessonSlideTypePollOptionsVoteAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    payload: payload,
  };
};
export const deleteCurriculumLanguageLessonSlideTypePollOptionsVoteAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    payload: payload,
  };
};

export const saveVideosAction = (payload) => {
  return {
    type: actionTypes.SAVE_VIDEOS_REQUEST,
    payload: payload,
  };
};

export const updateVideosAction = (payload) => {
  return {
    type: actionTypes.UPDATE_VIDEOS_REQUEST,
    payload: payload,
  };
};
export const readVideosAction = (payload) => {
  return {
    type: actionTypes.READ_VIDEOS_REQUEST,
    payload: payload,
  };
};
export const deleteVideosAction = (payload) => {
  return {
    type: actionTypes.DELETE_VIDEOS_REQUEST,
    payload: payload,
  };
};

export const saveVideosLanguageAction = (payload) => {
  return {
    type: actionTypes.SAVE_VIDEOS_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const updateVideosLanguageAction = (payload) => {
  return {
    type: actionTypes.UPDATE_VIDEOS_LANGUAGE_REQUEST,
    payload: payload,
  };
};
export const readVideosLanguageAction = (payload) => {
  return {
    type: actionTypes.READ_VIDEOS_LANGUAGE_REQUEST,
    payload: payload,
  };
};
export const deleteVideosLanguageAction = (payload) => {
  return {
    type: actionTypes.DELETE_VIDEOS_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const saveVideosUrlsAction = (payload) => {
  return {
    type: actionTypes.SAVE_VIDEOS_URLS_REQUEST,
    payload: payload,
  };
};

export const saveCommonsUrlsAction = (payload) => {
  return {
    type: actionTypes.SAVE_COMMON_URLS_REQUEST,
    payload: payload,
  };
};

export const updateCommonsUrlsAction = (payload) => {
  return {
    type: actionTypes.UPDATE_COMMON_URLS_REQUEST,
    payload: payload,
  };
};

export const saveVideoLanguageAction = (payload) => {
  return {
    type: actionTypes.SAVE_VIDEO_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const saveCategoriesLanguageAction = (payload) => {
  return {
    type: actionTypes.SAVE_CATEGORIES_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const updateCategoriesLanguageAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CATEGORIES_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const updateVideoLanguageAction = (payload) => {
  return {
    type: actionTypes.UPDATE_VIDEO_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const saveVideoUrlLanguageAction = (payload) => {
  return {
    type: actionTypes.SAVE_VIDEO_URL_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const updateVideoUrlLanguageAction = (payload) => {
  return {
    type: actionTypes.UPDATE_VIDEO_URL_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const saveDownloadVideoUrlLanguageAction = (payload) => {
  return {
    type: actionTypes.SAVE_VIDEO_DOWNLOAD_URL_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const updateDownloadVideoUrlLanguageAction = (payload) => {
  return {
    type: actionTypes.UPDATE_VIDEO_DOWNLOAD_URL_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const updateVideosUrlsAction = (payload) => {
  return {
    type: actionTypes.UPDATE_VIDEOS_URLS_REQUEST,
    payload: payload,
  };
};
export const readVideosUrlsAction = (payload) => {
  return {
    type: actionTypes.READ_VIDEOS_URLS_REQUEST,
    payload: payload,
  };
};
export const deleteVideosUrlsAction = (payload) => {
  return {
    type: actionTypes.DELETE_VIDEOS_URLS_REQUEST,
    payload: payload,
  };
};

export const saveVideosUrlsLanguageAction = (payload) => {
  return {
    type: actionTypes.SAVE_VIDEOS_URLS_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const updateVideosUrlsLanguageAction = (payload) => {
  return {
    type: actionTypes.UPDATE_VIDEOS_URLS_LANGUAGE_REQUEST,
    payload: payload,
  };
};
export const readVideosUrlsLanguageAction = (payload) => {
  return {
    type: actionTypes.READ_VIDEOS_URLS_LANGUAGE_REQUEST,
    payload: payload,
  };
};
export const deleteVideosUrlsLanguageAction = (payload) => {
  return {
    type: actionTypes.DELETE_VIDEOS_URLS_LANGUAGE_REQUEST,
    payload: payload,
  };
};

export const saveVideosDownloadUrlsAction = (payload) => {
  return {
    type: actionTypes.SAVE_VIDEOS_DOWNLOAD_URLS_REQUEST,
    payload: payload,
  };
};

export const updateVideosDownloadUrlsAction = (payload) => {
  return {
    type: actionTypes.UPDATE_VIDEOS_DOWNLOAD_URLS_REQUEST,
    payload: payload,
  };
};
export const readVideosDownloadUrlsAction = (payload) => {
  return {
    type: actionTypes.READ_VIDEOS_DOWNLOAD_URLS_REQUEST,
    payload: payload,
  };
};
export const deleteVideosDownloadUrlsAction = (payload) => {
  return {
    type: actionTypes.DELETE_VIDEOS_DOWNLOAD_URLS_REQUEST,
    payload: payload,
  };
};

export const saveVideosDownloadUrlsLanguageAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST,
    payload: payload,
  };
};

export const updateVideosDownloadUrlsLanguageAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST,
    payload: payload,
  };
};
export const readVideosDownloadUrlsLanguageAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST,
    payload: payload,
  };
};
export const deleteVideosDownloadUrlsLanguageAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST,
    payload: payload,
  };
};

export const saveVideosThumbNailUrlsAction = (payload) => {
  return {
    type: actionTypes.SAVE_VIDEOS_THUMBNAIL_URLS_REQUEST,
    payload: payload,
  };
};

export const updateVideosThumbNailUrlsAction = (payload) => {
  return {
    type: actionTypes.UPDATE_VIDEOS_THUMBNAIL_URLS_REQUEST,
    payload: payload,
  };
};
export const readVideosThumbNailUrlsAction = (payload) => {
  return {
    type: actionTypes.READ_VIDEOS_THUMBNAIL_URLS_REQUEST,
    payload: payload,
  };
};
export const deleteVideosThumbNailUrlsAction = (payload) => {
  return {
    type: actionTypes.DELETE_VIDEOS_THUMBNAIL_URLS_REQUEST,
    payload: payload,
  };
};

export const saveVideosLanguageThumbNailUrlsAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST,
    payload: payload,
  };
};

export const updateVideosLanguageThumbNailUrlsAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST,
    payload: payload,
  };
};
export const readVideosLanguageThumbNailUrlsAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST,
    payload: payload,
  };
};
export const deleteVideosLanguageThumbNailUrlsAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST,
    payload: payload,
  };
};

export const saveVideosrelatedAction = (payload) => {
  return {
    type: actionTypes.SAVE_VIDEOS_RELATED_REQUEST,
    payload: payload,
  };
};

export const readVideosrelatedAction = (payload) => {
  return {
    type: actionTypes.READ_VIDEOS_RELATED_REQUEST,
    payload: payload,
  };
};
export const deleteVideosrelatedAction = (payload) => {
  return {
    type: actionTypes.DELETE_VIDEOS_RELATED_REQUEST,
    payload: payload,
  };
};

export const saveCategoriesAction = (payload) => {
  return {
    type: actionTypes.SAVE_CATEGORIES_REQUEST,
    payload: payload,
  };
};

export const saveCommonThumbnailCategoriesAction = (payload) => {
  return {
    type: actionTypes.SAVE_COMMON_THUMBNAIL_CATEGORIES_REQUEST,
    payload: payload,
  };
};

export const updateCommonThumbnailCategoriesAction = (payload) => {
  return {
    type: actionTypes.UPDATE_COMMON_THUMBNAIL_CATEGORIES_REQUEST,
    payload: payload,
  };
};

export const updateCategoriesAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CATEGORIES_REQUEST,
    payload: payload,
  };
};
export const readCategoriesAction = (payload) => {
  return {
    type: actionTypes.READ_CATEGORIES_REQUEST,
    payload: payload,
  };
};
export const deleteCategoriesAction = (payload) => {
  return {
    type: actionTypes.DELETE_CATEGORIES_REQUEST,
    payload: payload,
  };
};

export const saveCategoriesIconAction = (payload) => {
  return {
    type: actionTypes.SAVE_CATEGORIES_ICON_REQUEST,
    payload: payload,
  };
};

export const updateCategoriesIconAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CATEGORIES_ICON_REQUEST,
    payload: payload,
  };
};
export const readCategoriesIconAction = (payload) => {
  return {
    type: actionTypes.READ_CATEGORIES_ICON_REQUEST,
    payload: payload,
  };
};
export const deleteCategoriesIconAction = (payload) => {
  return {
    type: actionTypes.DELETE_CATEGORIES_ICON_REQUEST,
    payload: payload,
  };
};
export const saveCategoriesRelatedAction = (payload) => {
  return {
    type: actionTypes.SAVE_CATEGORIES_RELATED_REQUEST,
    payload: payload,
  };
};

export const updateCategoriesRelatedAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CATEGORIES_RELATED_REQUEST,
    payload: payload,
  };
};
export const readCategoriesRelatedAction = (payload) => {
  return {
    type: actionTypes.READ_CATEGORIES_RELATED_REQUEST,
    payload: payload,
  };
};
export const deleteCategoriesRelatedAction = (payload) => {
  return {
    type: actionTypes.DELETE_CATEGORIES_RELATED_REQUEST,
    payload: payload,
  };
};

export const saveLanguageCategoriesAction = (payload) => {
  return {
    type: actionTypes.SAVE_LANGUAGE_CATEGORIES_REQUEST,
    payload: payload,
  };
};

export const updateLanguageCategoriesAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LANGUAGE_CATEGORIES_REQUEST,
    payload: payload,
  };
};
export const readLanguageCategoriesAction = (payload) => {
  return {
    type: actionTypes.READ_LANGUAGE_CATEGORIES_REQUEST,
    payload: payload,
  };
};
export const deleteLanguageCategoriesAction = (payload) => {
  return {
    type: actionTypes.DELETE_LANGUAGE_CATEGORIES_REQUEST,
    payload: payload,
  };
};
