import * as actionTypes from "../ActionTypes";

export const getGivingsListAction = (payload) => {
    return {
        type: actionTypes.GET_GIVINGS_LIST_REQUEST,
        payload: payload,
    };
};

export const getUserProfileAction = (payload) => {
    return {
        type: actionTypes.GET_USER_PROFILE_REQUEST,
        payload: payload,
    };
};

export const getUserTransactionsAction = (payload) => {
    return {
        type: actionTypes.GET_USER_TRANSACTIONS_REQEST,
        payload: payload,
    };
};

export const postRefundAction = (payload) => {
    return {
        type: actionTypes.POST_REFUND_REQUEST,
        payload: payload,
    };
};
