import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { acceptAudio, acceptImage, acceptVideo } from "../../constants/ValidationConstants";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";

const UploadControls = ({
  title,
  subtitle,
  className,
  tooltipTitle = "",
  handleFileUpload,
  handleMobileFileUpload,
  handleTabletFileUpload,
  handleWebFileUpload,
  handleChangeUrl,
  handleMobileChangeUrl,
  handleTabletChangeUrl,
  handleWebChangeUrl,
  mobileUploadUrl,
  mobileUploadName = "mobileUploadUrl",
  tabletUploadUrl,
  tabletUploadName = "tabletUploadUrl",
  webUploadUrl,
  webUploadName = "webUploadUrl",
  showMobileUpload = true,
  handleMobilePreview,
  showTabletUpload = true,
  handleTabletPreview,
  showWebUpload = true,
  handleWebPreview,
  showPreview = false,
  accept = "image",
  isFieldRequired = false,
  isMobileFieldRequired = false,
  isTabletFieldRequired = false,
  isWebFieldRequired = false,
  requiredMessage = "Required Field",
}) => {
  const [collapse, setCollapse] = useState(true);
  const fileAccept =
    accept === "video" ? acceptVideo : accept === "audio" ? acceptAudio : acceptImage;

  return (
    <>
      <div
        className={`bg-blackishV2 rounded-lg border border-opacity-10 border-white p-2 ${
          className ?? ""
        }`}
      >
        <div
          className="flex justify-between items-center text-greyish cursor-pointer "
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          <label htmlFor="videoURLs" className="text-[12px] font-medium leading-4 mb-2">
            <span className="flex items-center gap-2">
              {title}{" "}
              <FaInfoCircle
                {...(tooltipTitle
                  ? { "data-tooltip-id": "upload_controls", "data-tooltip-content": tooltipTitle }
                  : {})}
              />
            </span>
          </label>
          {collapse ? <IoIosArrowDown /> : <IoIosArrowUp />}
        </div>
        <div
          className={`p-4 w-full flex flex-col  justify-between pb-6 rounded-lg bg-blackish md:col-span-2 col-span-1 ${
            collapse ? "hidden" : ""
          }`}
        >
          {showMobileUpload && (
            <div className="flex flex-row gap-3 items-center ">
              <div className="w-1/2">
                <label
                  htmlFor={mobileUploadName}
                  className="text-[12px] text-greyish font-medium leading-4 "
                >
                  {subtitle} <span className="text-[10px]">( For Mobile )</span>
                </label>
                <input
                  name={mobileUploadName}
                  className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                  type="file"
                  accept={fileAccept}
                  onChange={handleMobileFileUpload ?? handleFileUpload}
                />
              </div>
              <p className="pt-10 md:col-span-2 col-span-1 text-center text-white">Or</p>
              <div className="w-1/2 md:col-span-2 col-span-1">
                <div className="flex justify-between mt-2">
                  <label
                    htmlFor={mobileUploadName}
                    className="text-[12px] text-greyish font-medium leading-4 "
                    data-tooltip-id="upload_controls"
                    data-tooltip-content={`Enter the ${accept === "image" ? "Image" : "Video"} URL`}
                  >
                    Upload URL
                  </label>
                  {showPreview && (
                    <button
                      className="text-[8px] text-green-500 font-medium leading-4 cursor-pointer z-50"
                      onClick={handleMobilePreview}
                    >
                      View Preview
                    </button>
                  )}
                </div>
                <input
                  name={mobileUploadName}
                  placeholder="Enter URL"
                  type="text"
                  className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                  value={mobileUploadUrl}
                  onChange={handleMobileChangeUrl ?? handleChangeUrl}
                />
              </div>
            </div>
          )}

          {isMobileFieldRequired && (
            <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2 mb-2">
              {requiredMessage}
            </p>
          )}

          {showTabletUpload && (
            <div className="flex flex-row gap-3 items-center ">
              <div className="w-1/2">
                <label
                  htmlFor={tabletUploadUrl}
                  className="text-[12px] text-greyish font-medium leading-4 "
                >
                  {subtitle} <span className="text-[10px]">( For Tablet )</span>
                </label>
                <input
                  name={tabletUploadName}
                  className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                  type="file"
                  accept={fileAccept}
                  onChange={handleTabletFileUpload ?? handleFileUpload}
                />
              </div>
              <p className="pt-10 md:col-span-2 col-span-1 text-center text-white">Or</p>
              <div className="w-1/2 md:col-span-2 col-span-1">
                <div className="flex justify-between mt-2">
                  <label
                    htmlFor={tabletUploadName}
                    className="text-[12px] text-greyish font-medium leading-4 "
                    data-tooltip-id="upload_controls"
                    data-tooltip-content={`Enter the ${accept === "image" ? "Image" : "Video"} URL`}
                  >
                    Upload URL
                  </label>
                  {showPreview && (
                    <button
                      className="text-[8px] text-green-500 font-medium leading-4 cursor-pointer z-50"
                      onClick={handleTabletPreview}
                    >
                      View Preview
                    </button>
                  )}
                </div>
                <input
                  name={tabletUploadName}
                  placeholder="Enter URL"
                  type="text"
                  className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                  value={tabletUploadUrl}
                  onChange={handleTabletChangeUrl ?? handleChangeUrl}
                />
              </div>
            </div>
          )}

          {isTabletFieldRequired && (
            <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2 mb-2">
              {requiredMessage}
            </p>
          )}

          {showWebUpload && (
            <div className="flex flex-row gap-3 items-center ">
              <div className="w-1/2">
                <label
                  htmlFor={webUploadName}
                  className="text-[12px] text-greyish font-medium leading-4 "
                >
                  {subtitle} <span className="text-[10px]">( For Web )</span>
                </label>
                <input
                  name={webUploadName}
                  className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                  type="file"
                  accept={fileAccept}
                  onChange={handleWebFileUpload ?? handleFileUpload}
                />
              </div>
              <p className="pt-10 md:col-span-2 col-span-1 text-center text-white">Or</p>
              <div className="w-1/2 md:col-span-2 col-span-1">
                <div className="flex justify-between mt-2">
                  <label
                    htmlFor={webUploadName}
                    className="text-[12px] text-greyish font-medium leading-4 "
                    data-tooltip-id="upload_controls"
                    data-tooltip-content={`Enter the ${accept === "image" ? "Image" : "Video"} URL`}
                  >
                    Upload URL
                  </label>
                  {showPreview && (
                    <button
                      className="text-[8px] text-green-500 font-medium leading-4 cursor-pointer z-50"
                      onClick={handleWebPreview}
                    >
                      View Preview
                    </button>
                  )}
                </div>
                <input
                  name={webUploadName}
                  placeholder="Enter URL"
                  type="text"
                  className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                  value={webUploadUrl}
                  onChange={handleWebChangeUrl ?? handleChangeUrl}
                />
              </div>
            </div>
          )}
          {isWebFieldRequired && (
            <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2 mb-2">
              {requiredMessage}
            </p>
          )}
        </div>
      </div>
      <Tooltip id="upload_controls" className="border border-greyish border-opacity-20" />
      {isFieldRequired && (
        <p className="text-red-500 relative bottom-[-10px] text-[10px] left-2 mb-1">
          {requiredMessage}
        </p>
      )}
    </>
  );
};

export default UploadControls;
