import React, { useState } from "react";
import Select from "react-select";
import infoCircle from "../../assets/icons/infocircle.svg";
import searchIcon from "../../assets/icons/search.svg";
import reload from "../../assets/icons/reload.svg";
import CustomTabsV2 from "../universal/CustomTabsV2";
import {
  AddCategoriesHooks,
  GetRelatedVideosHooks,
} from "../../screens/Categories/hooks/CategoriesHooks";
import categoryPreview from "../../assets/images/categoryPreview.png";
import { AddVideosHooks } from "../../screens/Videos/hooks/VideosHooks";
import GroupImage from "../../assets/images/GroupImage.png";
import DropdownWithTags from "../universal/DropdownWithTags";
import LayoutDropdown from "../universal/LayoutDropdown";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import edit from "../../assets/icons/edit.svg";
import close from "../../assets/icons/close.svg";
import story from "../../assets/icons/story.svg";
import moment from "moment/moment";
import ReactModal from "react-modal";
import { ImCross } from "react-icons/im";
import { acceptImage, acceptVideo } from "../../constants/ValidationConstants";
import LanguageDrawer from "../universal/LanguageDrawer";
import UploadControls from "../TWG/UploadControls";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";

const AddVideosFields = ({ setLoading, data }) => {
  // const { options } = GetRelatedVideosHooks();

  const {
    activeTab,
    activeLanguageDetails,
    setActiveLanguageDetails,
    setActiveTab,
    handleInputChange,
    handleFileInputChange,
    handleSave,
    readLanguageData,
    videoInputs,
    videoErrors,
    videoUrlsInputs,
    setVideoUrlsInputs,
    setDownloadUrlsInputs,
    setTumbNailUrlsInputs,
    tumbNailUrlsInputs,
    UdownloadrlsInputs,
    handleChange,
    selectedOptions,
    handleDropdownChange,
    setLayoutValues,
    handleVideoLayoutChange,
    layoutValues,
    initialLayoutValues,
    setLayoutInfo,
    layoutInfo,
    handleDateChange,
    handleAddLayoutToArray,
    layoutArray,
    setAddedLayout,
    addedLayout,
    handleVideoDateChange,
    options,
    handleDeleteLayoutFromArray,
    setIsLayoutEdit,
    isLayoutEdit,
    setEditLayoutIndex,
    setEditTargetLayout,
    editTargetLayout,
    handleLayoutUpdate,
    updateStateWithTranslation,
    handleCommonTumbNailInputChange,
    setCommonUrlsInputs,
    commonVideoUrlsInputs,
    languagemeta,
    setIsLanguageSave,
    handleSaveLanguage,
  } = AddVideosHooks(setLoading, data);

  const [showModal, setShowModal] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  console.log("readLanguageData1", readLanguageData);

  return (
    <div className="flex flex-col md:flex-row h-full w-full flex-1 rounded-2xl overflow-hidden">
      <div className="flex h-full flex-1">
        <LanguageDrawer
          languageData={readLanguageData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setActiveLanguageDetails={setActiveLanguageDetails}
          languagemeta={languagemeta}
          setIsLanguageSave={setIsLanguageSave}
        />
        <div className="flex h-full flex-1">
          <div className="flex flex-col h-full w-full bg-greyishV2 bg-opacity-50 md:border-l md:border-r border-greyish border-opacity-30 overflow-auto">
            <div className="flex gap-4 items-center justify-between h-[64px] px-4 border-b border-[#28292a]">
              <p className="text-white font-semibold text-lg">
                {activeTab === "en"
                  ? "Add Videos"
                  : `Translate Video to ${activeLanguageDetails?.language_name}`}
              </p>
              <div className="flex items-center gap-5">
                {activeTab !== "en" && data?._id && (
                  <p
                    className="flex items-center gap-1 text-greyish text-[11px] font-medium cursor-pointer whitespace-nowrap"
                    onClick={updateStateWithTranslation}
                  >
                    <img src={reload} alt="" /> Auto fill data with google translation
                  </p>
                )}
                {activeTab === "en" ? (
                  <div className="w-full flex items-end justify-end gap-4">
                    <button
                      className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                      onClick={handleSave}
                    >
                      {videoInputs?._id ? "Update Video" : "Add Video"}
                    </button>
                  </div>
                ) : data?._id ? (
                  <div className="w-full flex items-end justify-end gap-4">
                    <button
                      className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                      onClick={handleSaveLanguage}
                    >
                      Save Language
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            {/* TAB CONTENT  */}
            {activeTab !== "en" && !data?._id ? (
              <div className="flex items-center justify-center text-white gap-1 py-28">
                Video translation in {" "} 
                <span className="italic">{activeLanguageDetails?.language_name}</span> is available
                for saved videos
              </div>
            ) : (
              <div className="px-4 my-4 h-full">
                <div className=" overflow-y-auto overflow-x-hidden">
                  <div className="grid md:grid-cols-2 grid-cols-1 grid-rows-1 gap-x-4 gap-y-4 mx-1 w-full">
                    <div className="md:col-span-2 col-span-1">
                      <label
                        htmlFor="video_title"
                        className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                      >
                        Video Title *
                        <FaInfoCircle
                          data-tooltip-id="add_videos"
                          data-tooltip-content="Enter the Video Title"
                        />
                      </label>
                      <input
                        name="video_title"
                        placeholder="Enter Video Title"
                        type="text"
                        className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                        // value={inputs[activeTab]?.categoryCode}
                        value={videoInputs?.video_title}
                        onChange={handleChange}
                      />
                      {videoErrors?.video_title && (
                        <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2">
                          Video Title Is Required Field
                        </p>
                      )}
                    </div>

                    <div className="md:col-span-2 col-span-1">
                      <label
                        htmlFor="video_description"
                        className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                      >
                        Video Description *
                        <FaInfoCircle
                          data-tooltip-id="add_videos"
                          data-tooltip-content="Enter the Video Description"
                        />
                      </label>
                      <textarea
                        rows={6}
                        className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md`}
                        name="video_description"
                        placeholder="Enter Video Description"
                        // value={inputs[activeTab]?.videoDescription}
                        // onChange={handleVideoDescriptionChange}
                        value={videoInputs?.video_description}
                        onChange={handleChange}
                      />
                      {videoErrors?.video_description && (
                        <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2">
                          Video Description Is Required Field
                        </p>
                      )}
                    </div>
                    <UploadControls
                      tooltipTitle="Upload Video URLs"
                      className="my-2 col-span-2 mr-1"
                      title="Video URLs *"
                      subtitle="Upload Video"
                      accept="video"
                      handleChangeUrl={(event) => handleInputChange(event, setVideoUrlsInputs)}
                      handleMobileFileUpload={(e) =>
                        handleFileInputChange(
                          setVideoUrlsInputs,
                          "mobileUploadUrl",
                          e.target.files[0],
                          "videos"
                        )
                      }
                      mobileUploadUrl={videoUrlsInputs?.mobileUploadUrl}
                      handleMobilePreview={() => {
                        setIsImage(false);
                        setShowModal(true);
                        setCurrentUrl(videoUrlsInputs?.mobileUploadUrl);
                      }}
                      handleTabletFileUpload={(e) =>
                        handleFileInputChange(
                          setVideoUrlsInputs,
                          "tabletUploadUrl",
                          e.target.files[0],
                          "videos"
                        )
                      }
                      tabletUploadUrl={videoUrlsInputs?.tabletUploadUrl}
                      handleTabletPreview={() => {
                        setIsImage(true);
                        setShowModal(true);
                        setCurrentUrl(videoUrlsInputs?.tabletUploadUrl);
                      }}
                      handleWebFileUpload={(e) =>
                        handleFileInputChange(
                          setVideoUrlsInputs,
                          "webUploadUrl",
                          e.target.files[0],
                          "videos"
                        )
                      }
                      webUploadUrl={videoUrlsInputs?.webUploadUrl}
                      handleWebPreview={() => {
                        setIsImage(true);
                        setShowModal(true);
                        setCurrentUrl(videoUrlsInputs?.webUploadUrl);
                      }}
                      isFieldRequired={videoErrors?.video_urls}
                      requiredMessage="Video URL's Is Required"
                    />
                    <UploadControls
                      tooltipTitle="Upload Video Download URLs"
                      className="my-2 col-span-2 mr-1"
                      title="Video Download URLs *"
                      subtitle="Upload Video"
                      handleChangeUrl={(event) => handleInputChange(event, setDownloadUrlsInputs)}
                      handleMobileFileUpload={(e) =>
                        handleFileInputChange(
                          setDownloadUrlsInputs,
                          "mobileUploadUrl",
                          e.target.files[0],
                          "videos"
                        )
                      }
                      mobileUploadUrl={UdownloadrlsInputs?.mobileUploadUrl}
                      handleMobilePreview={() => {
                        setIsImage(false);
                        setShowModal(true);
                        setCurrentUrl(UdownloadrlsInputs?.mobileUploadUrl);
                      }}
                      handleTabletFileUpload={(e) =>
                        handleFileInputChange(
                          setDownloadUrlsInputs,
                          "tabletUploadUrl",
                          e.target.files[0],
                          "videos"
                        )
                      }
                      tabletUploadUrl={UdownloadrlsInputs?.tabletUploadUrl}
                      handleTabletPreview={() => {
                        setIsImage(true);
                        setShowModal(true);
                        setCurrentUrl(UdownloadrlsInputs?.tabletUploadUrl);
                      }}
                      handleWebFileUpload={(e) =>
                        handleFileInputChange(
                          setDownloadUrlsInputs,
                          "webUploadUrl",
                          e.target.files[0],
                          "videos"
                        )
                      }
                      webUploadUrl={UdownloadrlsInputs?.webUploadUrl}
                      handleWebPreview={() => {
                        setIsImage(true);
                        setShowModal(true);
                        setCurrentUrl(UdownloadrlsInputs?.webUploadUrl);
                      }}
                      isFieldRequired={videoErrors?.video_download_urls}
                      requiredMessage="Video Download URL's Is Required"
                      accept="video"
                    />

                    {activeTab === "en" && (
                      <>
                        <UploadControls
                          tooltipTitle="Upload Video Thumbnail"
                          className="my-2 col-span-2 mr-1"
                          title="Video Thumbnail *"
                          subtitle="Upload Image"
                          handleChangeUrl={(event) =>
                            handleInputChange(event, setTumbNailUrlsInputs)
                          }
                          handleMobileFileUpload={(e) =>
                            handleFileInputChange(
                              setTumbNailUrlsInputs,
                              "mobileUploadUrl",
                              e.target.files[0]
                            )
                          }
                          mobileUploadUrl={tumbNailUrlsInputs?.mobileUploadUrl}
                          handleMobilePreview={() => {
                            setIsImage(false);
                            setShowModal(true);
                            setCurrentUrl(tumbNailUrlsInputs?.mobileUploadUrl);
                          }}
                          handleTabletFileUpload={(e) =>
                            handleFileInputChange(
                              setTumbNailUrlsInputs,
                              "tabletUploadUrl",
                              e.target.files[0]
                            )
                          }
                          tabletUploadUrl={tumbNailUrlsInputs?.tabletUploadUrl}
                          handleTabletPreview={() => {
                            setIsImage(true);
                            setShowModal(true);
                            setCurrentUrl(tumbNailUrlsInputs?.tabletUploadUrl);
                          }}
                          handleWebFileUpload={(e) =>
                            handleFileInputChange(
                              setTumbNailUrlsInputs,
                              "webUploadUrl",
                              e.target.files[0]
                            )
                          }
                          webUploadUrl={tumbNailUrlsInputs?.webUploadUrl}
                          handleWebPreview={() => {
                            setIsImage(true);
                            setShowModal(true);
                            setCurrentUrl(tumbNailUrlsInputs?.webUploadUrl);
                          }}
                          isFieldRequired={videoErrors?.video_thumbnails_urls}
                          requiredMessage="Video Thumbnail Is Required Field"
                        />
                        <UploadControls
                          tooltipTitle="Upload Common Thumbnails"
                          className="my-2 col-span-2 mr-1"
                          title="Common Thumbnail *"
                          subtitle="Upload Image"
                          handleChangeUrl={(event) => handleInputChange(event, setCommonUrlsInputs)}
                          handleMobileFileUpload={(e) =>
                            handleFileInputChange(
                              setCommonUrlsInputs,
                              "mobileUploadUrl",
                              e.target.files[0]
                            )
                          }
                          mobileUploadUrl={commonVideoUrlsInputs?.mobileUploadUrl}
                          handleMobilePreview={() => {
                            setIsImage(false);
                            setShowModal(true);
                            setCurrentUrl(commonVideoUrlsInputs?.mobileUploadUrl);
                          }}
                          handleTabletFileUpload={(e) =>
                            handleFileInputChange(
                              setCommonUrlsInputs,
                              "tabletUploadUrl",
                              e.target.files[0]
                            )
                          }
                          tabletUploadUrl={commonVideoUrlsInputs?.tabletUploadUrl}
                          handleTabletPreview={() => {
                            setIsImage(true);
                            setShowModal(true);
                            setCurrentUrl(commonVideoUrlsInputs?.tabletUploadUrl);
                          }}
                          handleWebFileUpload={(e) =>
                            handleFileInputChange(
                              setCommonUrlsInputs,
                              "webUploadUrl",
                              e.target.files[0]
                            )
                          }
                          webUploadUrl={commonVideoUrlsInputs?.webUploadUrl}
                          handleWebPreview={() => {
                            setIsImage(true);
                            setShowModal(true);
                            setCurrentUrl(commonVideoUrlsInputs?.webUploadUrl);
                          }}
                          isFieldRequired={videoErrors?.video_thumbnails_urls}
                          requiredMessage="Video Thumbnail Is Required Field"
                        />
                      </>
                    )}
                  </div>
                </div>
                {activeTab === "en" && (
                  <>
                    {" "}
                    <div className="md:col-span-2 col-span-1 mt-2">
                      <label
                        htmlFor="relatedVideos"
                        className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                      >
                        Related Videos *
                        <FaInfoCircle
                          data-tooltip-id="add_videos"
                          data-tooltip-content="Select Related Videos"
                        />
                      </label>
                      <DropdownWithTags
                        selectedOptions={selectedOptions}
                        options={options}
                        handleChange={handleDropdownChange}
                      />
                      {videoErrors?.selectedOptions && (
                        <p className="text-red-500 relative bottom-[-10px] text-[10px] left-2">
                          Related Videos Is Required Field
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
            <Tooltip id="add_videos" className="border border-greyish border-opacity-20" />
          </div>
        </div>
      </div>
      <div className="md:w-[33%] bg-[#1b1b1b]">
        <CustomTabsV2
          tabHeight={"h-[64px]"}
          tabsTitleArray={["Preview", "Stats", "Layouts"]}
          tabsContentObject={{
            Preview: (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                No Data Found...
              </div>
            ),
            Stats: (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                {videoInputs?.totallikes ? (
                  <div className="w-full px-16">
                    <p className="text-white">
                      Total Likes:{" "}
                      <span className="text-yellowish font-lg">{videoInputs?.totallikes}</span>
                    </p>
                  </div>
                ) : (
                  <>No stats available for video</>
                )}
              </div>
            ),
            Layouts: !data?._id ? (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                Save this video to assign the layout
              </div>
            ) : (
              <>
                <div className="mt-4 p-1">
                  <label htmlFor="relatedVideos" className="text-[12px] text-greyish font-medium">
                    Layouts
                  </label>
                  <div className="bg-blackish py-4 px-3 mt-3 rounded-lg">
                    <LayoutDropdown
                      setMyState={setLayoutValues}
                      setLayoutInfo={setLayoutInfo}
                      setAddedLayout={setAddedLayout}
                      addedLayout={addedLayout}
                      newTarget={editTargetLayout}
                      isLayoutEdit={isLayoutEdit}
                      outerTargetLayout={layoutValues?.layout_name}
                    />

                    {layoutInfo && (
                      <>
                        {/* <div className="flex gap-3">
                          <div className="w-full">
                            <label
                              htmlFor="title"
                              className="text-[12px] text-greyish font-medium leading-4 "
                            >
                              Layout Title
                            </label>
                            <input
                              type="text"
                              className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                              name="title"
                              placeholder="Enter title"
                              value={layoutValues?.title}
                              onChange={handleVideoLayoutChange}
                            />
                          </div>
                          <div className="w-full">
                            <label
                              htmlFor="subtitle"
                              className="text-[12px] text-greyish font-medium leading-4 "
                            >
                              Layout Subtitle
                            </label>
                            <input
                              type="text"
                              className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                              name="subtitle"
                              placeholder="Enter subtitle "
                              value={layoutValues?.subtitle}
                              onChange={handleVideoLayoutChange}
                            />
                          </div>
                        </div> */}
                        <div className="flex gap-3">
                          {/* <div className="w-full flex flex-col mt-2">
                            <label
                              htmlFor="expiry"
                              className="text-[12px] text-greyish font-medium leading-4"
                            >
                              Expiry
                            </label>
                            <DateTimePicker
                              name="expiry"
                              className={
                                "bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10"
                              }
                              value={layoutValues?.expiry}
                              onChange={handleVideoDateChange}
                            />
                          </div> */}
                          <div className="w-full">
                            <label
                              htmlFor="order"
                              className="text-[12px] text-greyish font-medium leading-4 "
                            >
                              Display Order
                            </label>
                            <input
                              type="text"
                              className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                              name="order"
                              placeholder="Enter order"
                              value={layoutValues?.order}
                              onChange={handleVideoLayoutChange}
                            />
                          </div>
                        </div>
                        <div className="flex justify-end mt-4">
                          <div className="flex gap-3">
                            {!isLayoutEdit && (
                              <button
                                className="text-greyish bg-blackishV2 font-medium text-[12px] rounded-xl py-2 px-6"
                                onClick={() => {
                                  setLayoutValues(initialLayoutValues);
                                  setLayoutInfo(false);
                                }}
                              >
                                Cancel
                              </button>
                            )}
                            {isLayoutEdit ? (
                              <button
                                className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                                onClick={handleLayoutUpdate}
                              >
                                Confirm
                              </button>
                            ) : (
                              <button
                                className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                                onClick={handleAddLayoutToArray}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {layoutArray?.length > 0 &&
                  activeTab === "en" &&
                  layoutArray?.map((item, index) => (
                    <div className="w-full px-4 mt-4">
                      <div className="w-full p-2 px-4 bg-black bg-opacity-10 rounded-lg border border-opacity-25 border-white">
                        <div className="flex flex-col items-center justify-center w-full text-white">
                          <div className="flex justify-between w-full">
                            <p className="text-sm font-white font-medium text-white">
                              {item?.layout_name}
                            </p>
                            <div className="flex gap-2 justify-end items-center cursor-pointer">
                              <img
                                className="h-4"
                                src={edit}
                                alt=""
                                onClick={() => {
                                  setIsLayoutEdit(true);
                                  setEditLayoutIndex(index);
                                  setLayoutValues(item);
                                  setEditTargetLayout(item?.layout_name);
                                }}
                              />
                              <img
                                className="h-4"
                                src={close}
                                alt=""
                                onClick={() => {
                                  handleDeleteLayoutFromArray(item?._id);
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex justify-between w-full mt-5 bg-blackishV2 p-1 px-2">
                            <p className="text-[10px] font-white text-white">Order</p>
                            {/* <p className="text-[10px] font-white text-white">Title</p>
                            <p className="text-[10px] font-white  text-white">Subtitle</p>
                            <p className="text-[10px] font-white text-white">Expiry</p> */}
                          </div>
                          <div className="flex w-full mt-2 p-1 px-2">
                            <p className="flex w-full text-xs font-white font-bold text-white">
                              {item?.order}
                            </p>
                            {/* <p className="flex w-full  text-xs font-white font-bold text-white">
                              {item?.title}
                            </p>
                            <p className="flex w-full justify-center text-xs font-white font-bold text-white">
                              {item?.subtitle}
                            </p>
                            <p className="flex w-full justify-end text-end  text-xs font-white font-bold text-white">
                              {moment(item?.expiry).format("DD-MM-YYYY")}
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            ),
          }}
        />
      </div>
      <ReactModal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <div className="flex justify-center h-full w-full relative">
          <div
            className="absolute top-0 right-0 cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            <ImCross />
          </div>
          {isImage ? (
            <img className="h-full" src={currentUrl} />
          ) : (
            <video className="h-full" controls autoPlay>
              <source src={currentUrl} />
            </video>
          )}
        </div>
      </ReactModal>
    </div>
  );
};

export default AddVideosFields;
