import React from "react";

const SearchDropdown = ({
  id,
  handleChange,
  placeholder,
  data,
  width,
  value,
  bgColor = "blackish",
}) => {
  return (
    <div className={`${width}`}>
      <input
        className={`bg-${bgColor} p-2 w-full focus:outline-none text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 placeholder:text-greyish`}
        list={id}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
      />
      <datalist className="" id={id}>
        {id === "language"
          ? data?.map((item, index) => (
              <option key={index}>
                {item?.name} - {item?.language}
              </option>
            ))
          : id === "layout"
          ? data?.map((item, index) => (
              <option key={index} className="w-full">
                {item?.layout_name}
              </option>
            ))
          : data?.map((item, index) => <option key={index}>{item}</option>)}
      </datalist>
      <p className="text-white text-xs pl-2 opacity-60 pt-1">Type and Search..</p>
    </div>
  );
};

export default SearchDropdown;
