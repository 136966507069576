import { useState } from "react";

const UsersHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

export default UsersHooks;

export const AllUserHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};
