import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileAction,
  saveUserProfileAction,
  updateUserProfileAction,
} from "../../../store/Profile/ProfileAction";
import Toastiy from "../../universal/Toastiy";

export const UserProfileHooks = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const [editProfile, setEditProfile] = useState(true);
  const [imageUrl, setImageUrl] = useState();
  const [profileValues, setProfileValues] = useState({
    _id: "",
    profilePicUrl: "",
    dob: "",
    gender: "male",
    firstName: "",
    lastName: "",
    aboutMe: "",
    mobile: "",
    email: "",
    active: 1,
    language_prefference: "",
    country: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setProfileValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateImageUrl = (imgUrl) => {
    setImageUrl(imgUrl);
    handleChange({ target: { name: "profilePicUrl", value: imgUrl } });
  };

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = () => {
    dispatch(
      getProfileAction({
        callback: getProfileSuccess,
      })
    );
  };

  const getProfileSuccess = (res) => {
    if (res?.type === 1 && res?.data?.length !== 0) {
      setProfileValues((prevState) => ({
        ...prevState,
        firstName: res?.data[0]?.firstName || "",
        lastName: res?.data[0]?.lastName || "",
        aboutMe: res?.data[0]?.aboutMe || "",
        profilePicUrl: res?.data[0]?.profilePicUrl || "",
        email: res?.data[0]?.email,
        mobile: res?.data[0]?.mobile || "",
        country: res?.data[0]?.country || "",
        dob: res?.data[0]?.dob || "",
        _id: res?.data[0]?._id || "",
      }));
      setImageUrl(res?.data[0]?.profilePicUrl || "");
    }
    // else {
    //   navigate("/login");
    // }
  };

  const handleUpdateProfile = () => {
    if (profileValues?._id !== "") {
      dispatch(
        updateUserProfileAction({
          apiPayloadRequest: profileValues,
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Profile Saved Successfully!", res?.type);
              setEditProfile((prevState) => !prevState);
            } else {
              Toastiy("Something Went Wrong!", res?.type);
              setEditProfile((prevState) => !prevState);
            }
          },
        })
      );
    } else {
      dispatch(
        saveUserProfileAction({
          apiPayloadRequest: profileValues,
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Proile Updated Successfully!", res?.type);
            } else {
              Toastiy("Something Went Wrong!", res?.type);
            }
          },
        })
      );
    }
  };

  return {
    value,
    editProfile,
    setEditProfile,
    profileValues,
    handleChange,
    onChangeSearchBar,
    imageUrl,
    setImageUrl,
    handleUpdateProfile,
    setProfileValues,
    updateImageUrl,
  };
};
