import * as actionTypes from "../ActionTypes";

const INITIAL = {
    loading: false,
};

const GlobalLoaderReducer = (state = INITIAL, action) => {
    switch (action.type) {
        case actionTypes.APP_LOADING:
            return { ...state, loading: action.payload };
        default:
            return state;
    }
};

export default GlobalLoaderReducer;
