import React from "react";
import { AllLanguagesHooks } from "./hooks/LanguagesHooks";
import SearchBar from "../../components/universal/SearchBar";
import { allLanguagesData, allLanguagesHeaders } from "./Constants";

import { AiOutlinePlus } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineFilter } from "react-icons/hi";
import Navbar from "../../components/universal/Navbar";
import CustomTable from "../../components/universal/CustomTable";

function AllLanguages() {
  const navigate = useNavigate();

  const {
    value,
    onChangeSearchBar,
    allLanguageData,
    handleActiveInActiveToggle,
  } = AllLanguagesHooks();

  console.log(allLanguageData, "allLanguageData");

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar
            placeholder={"Search"}
            value={value}
            onChangeSearchBar={onChangeSearchBar}
          />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium">Languages</p>
              <div className="flex gap-4">
                {/* <Link to={"/allLanguages"}> */}
                <button className="flex items-center gap-16 text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                  All
                  <HiOutlineFilter />
                </button>
                {/* </Link> */}
                <Link to={"/languages/add"}>
                  {" "}
                  <button className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl">
                    <AiOutlinePlus /> Add New Language
                  </button>
                </Link>
              </div>
            </div>
            <div className="">
              <CustomTable
                searchFor={"Languages"}
                heightDesktop={"h-[72vh]"}
                width={"w-24"}
                // giving extra width to 2nd column
                nthChildWidth={
                  "[&>*:nth-child(2)]:w-40 [&>*:nth-child(4)]:w-40"
                }
                data={allLanguageData}
                headers={allLanguagesHeaders}
                reverseTable={true}
                handleToggleClick={(row, isActive) => {
                  handleActiveInActiveToggle(row?._id, isActive ? 1 : 0);
                }}
                handleEyeClick={(row) => {
                  console.log(row, "Calling");
                  navigate("/languages/add", {
                    state: {
                      targetLanguage:
                        row?.language_name + " - " + row?.language_code,
                    },
                  });
                }}
                handleEditClick={(row) => {
                  console.log(row);
                  navigate("/languages/add", {
                    state: {
                      targetLanguage:
                        row?.language_name + " - " + row?.language_code,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllLanguages;
