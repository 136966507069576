import HorizontalTile from "../../components/universal/HorizontalTile";
import avatar from "../../assets/icons/squareCard/story1.png";
import avatar2 from "../../assets/icons/StoryCircles/story1.png";
import SingleTab from "../../components/universal/SingleTab";

export const tabsTitleArray = ["Users", "Testimonies", "Givings"];
export const tabsContentObject = {
  Users: (
    <>
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
    </>
  ),
  Testimonies: (
    <>
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
    </>
  ),
};
export const languagesHeaders = [
  {
    headerName: "Language Name",
    headerId: "languageName",
  },
  {
    headerName: "Google Translation Code",
    headerId: "GTC",
  },
  {
    headerName: "Status",
    headerId: "status",
  },
];
export const languagesData = [
  {
    languageName: "English",
    GTC: "en",
    status: "Active",
  },
  {
    languageName: "Tamil",
    GTC: "en",
    status: "Inactive",
  },
  {
    languageName: "Malayalam",
    GTC: "en",
    status: "Inactive",
  },
  {
    languageName: "Urdu",
    GTC: "en",
    status: "Active",
  },
];
export const allLanguagesHeaders = [
  {
    headerName: "Language Name",
    headerId: "languageName",
  },
  {
    headerName: "Google Translation Code",
    headerId: "GTC",
  },
  {
    headerName: "Status",
    headerId: "status",
  },
  {
    headerName: "Default Language",
    headerId: "defaultLanguage",
  },
  {
    headerName: "Actions",
    headerId: "actions",
  },
];
export const allLanguagesData = [
  {
    languageName: "English",
    GTC: "en",
    status: "Active",
    defaultLanguage: "True",
  },
  {
    languageName: "Tamil",
    GTC: "en",
    status: "Inactive",
    defaultLanguage: "False",
  },
  {
    languageName: "Malayalam",
    GTC: "en",
    status: "Inactive",
    defaultLanguage: "True",
  },
  {
    languageName: "Urdu",
    GTC: "en",
    status: "Active",
    defaultLanguage: "True",
  },
];

// add languages const

export const langArray = [
  { value: "tamil", label: "Tamil" },
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
];
export const LoginInFields = [
  {
    label: "Login",
    placeholder: "Login Id",
  },
  {
    label: "Email or Phone",
    placeholder: "Email or Phone",
  },
  {
    label: "Password",
    placeholder: "Password",
  },
];
export const SignUpFields = [
  {
    label: "Sign Up",
    placeholder: "Sign Up",
  },
  {
    label: "Email or Phone",
    placeholder: "Email or Phone",
  },
];
export const TestimonialsHeaders = [
  {
    headerName: "Title",
    headerId: "testimonial_name",
  },
  {
    headerName: "Description",
    headerId: "testimonial_description",
  },
  {
    headerName: "Total Likes",
    headerId: "totallikes",
  },
  {
    headerName: "Actions",
    headerId: "actions",
  },
];

export const TestimonialsData = {
  All: [
    {
      categoryName: "All",
      description: "We become what we think ab...",
      views: "124",
      selected: false,
      user: "#909011",
    },
    {
      categoryName: "Forgiveness",
      description:
        "It is a long established fact that a render will be distracte...",
      views: "1212",
      selected: false,
      user: "#909011",
    },
    {
      categoryName: "Early Prayer",
      description: "It is a long established fact that a reader wi...",
      views: "122",
      selected: true,
      user: "#909011",
    },
    {
      categoryName: "Prayer",
      description: "We become what we think ab...",
      views: "123",
      selected: true,
      user: "#909011",
    },
    {
      categoryName: "Some Times",
      description: "It is a long established fact tha...",
      views: "123",
      selected: false,
      user: "#909011",
    },
    {
      categoryName: "Good God",
      description: "It is a long established fact that...",
      views: "123",
      selected: false,
      user: "#909011",
    },
  ],
  New: [
    {
      categoryName: "New",
      description: "We become what we think ab...",
      views: "124",
      selected: false,
    },
    {
      categoryName: "Forgiveness",
      description:
        "It is a long established fact that a render will be distracte...",
      views: "1212",
      selected: false,
    },
    {
      categoryName: "Early Prayer",
      description: "It is a long established fact that a reader wi...",
      views: "122",
      selected: true,
    },
    {
      categoryName: "Prayer",
      description: "We become what we think ab...",
      views: "123",
      selected: true,
    },
    {
      categoryName: "Some Times",
      description: "It is a long established fact tha...",
      views: "123",
      selected: false,
    },
    {
      categoryName: "Good God",
      description: "It is a long established fact that...",
      views: "123",
      selected: false,
    },
  ],
  Rejected: [
    {
      categoryName: "Rejected",
      description: "We become what we think ab...",
      views: "124",
      selected: false,
    },
    {
      categoryName: "Forgiveness",
      description:
        "It is a long established fact that a render will be distracte...",
      views: "1212",
      selected: false,
    },
    {
      categoryName: "Early Prayer",
      description: "It is a long established fact that a reader wi...",
      views: "122",
      selected: true,
    },
    {
      categoryName: "Prayer",
      description: "We become what we think ab...",
      views: "123",
      selected: true,
    },
    {
      categoryName: "Some Times",
      description: "It is a long established fact tha...",
      views: "123",
      selected: false,
    },
    {
      categoryName: "Good God",
      description: "It is a long established fact that...",
      views: "123",
      selected: false,
    },
  ],
  Trending: [
    {
      categoryName: "Trending",
      description: "We become what we think ab...",
      views: "124",
      selected: false,
    },
    {
      categoryName: "Forgiveness",
      description:
        "It is a long established fact that a render will be distracte...",
      views: "1212",
      selected: false,
    },
    {
      categoryName: "Early Prayer",
      description: "It is a long established fact that a reader wi...",
      views: "122",
      selected: true,
    },
    {
      categoryName: "Prayer",
      description: "We become what we think ab...",
      views: "123",
      selected: true,
    },
    {
      categoryName: "Some Times",
      description: "It is a long established fact tha...",
      views: "123",
      selected: false,
    },
    {
      categoryName: "Good God",
      description: "It is a long established fact that...",
      views: "123",
      selected: false,
    },
  ],
};
