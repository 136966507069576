const ConfirmToast = ({ onClose, onSuccess, title, message }) => {
  return (
    <div className="bg-blackishV2 w-[30dvw] gap-3 text-white flex flex-col items-center px-20 py-12">
      <h1 className="font-bold text-2xl">{title ?? "Are you sure?"}</h1>
      <p className="text-sm mb-5">{message ?? "You want to delete this file?"}</p>
      <div className="w-full gap-4 grid grid-cols-2">
        <button
          onClick={onClose}
          className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish py-2 px-6"
        >
          No
        </button>
        <button
          className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish py-2 px-6"
          onClick={onSuccess}
        >
          Yes, Delete it!
        </button>
      </div>
    </div>
  );
};

export default ConfirmToast;
