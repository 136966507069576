import React, { useState } from "react";
import SearchBar from "../../components/universal/SearchBar";
import SquareCard from "../../components/universal/SquareCard";
import squareIcon1 from "../../assets/icons/squareCard/icon1.svg";
import userIcon from "../../assets/icons/userIcon.svg";
import CustomCarousel from "../../components/universal/CustomCarousel";
import SingleTab from "../../components/universal/SingleTab";
import CustomTable from "../../components/universal/CustomTable";
import { AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";
import { twgData, twgHeaders } from "./Constants";
import TwgHooks from "./hooks/TwgHooks";
import CustomLineChart from "../../components/charts/lineChart/CustomLineChart";
import Navbar from "../../components/universal/Navbar";

function Twg() {
  // search bar component state with handler
  const { value, onChangeSearchBar } = TwgHooks();
  // tab clicking state for language table
  const [activeTab, setActiveTab] = useState("Trending");
  const [userTab, setUserTab] = useState("Month");
  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar
            placeholder={"Search"}
            value={value}
            onChangeSearchBar={onChangeSearchBar}
          />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium">Today with God</p>
              <div className="flex gap-4">
                <Link to={"/twg/all"}>
                  <button className=" text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                    Today with God List
                  </button>
                </Link>
                <Link to={"/twg/add"}>
                  <button className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl">
                    <AiOutlinePlus /> Add Promise
                  </button>
                </Link>
              </div>
            </div>

            <div className="flex gap-4 flex-wrap">
              <SquareCard
                heading={"Total No. of Videos"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={"0"}
                trail={"Views"}
                percentage={"0%"}
                handleOnClick={() => {
                  console.log("clicked");
                }}
              />
              <SquareCard
                heading={"Total Views Today"}
                icon={userIcon}
                iconStyle={"h-11 w-11"}
                info={"0"}
                trail={""}
                percentage={"0%"}
                handleOnClick={() => {
                  console.log("clicked");
                }}
              />
              <SquareCard
                heading={"Total Views This Week"}
                icon={userIcon}
                iconStyle={"h-11 w-11"}
                info={"0"}
                trail={""}
                percentage={"0%"}
                handleOnClick={() => {
                  console.log("clicked");
                }}
              />
              <SquareCard
                heading={"Total Views This Month"}
                icon={userIcon}
                iconStyle={"h-11 w-11"}
                info={"0"}
                trail={""}
                percentage={"0%"}
                handleOnClick={() => {
                  console.log("clicked");
                }}
              />
              <SquareCard
                heading={"Total No. of Views"}
                icon={userIcon}
                iconStyle={"h-11 w-11"}
                info={"0"}
                trail={""}
                percentage={"0%"}
                handleOnClick={() => {
                  console.log("clicked");
                }}
              />
            </div>
            <div className="grid grid-rows-1 md:grid-cols-6 grid-cols-1 flex-1 h-full gap-x-4 gap-y-4 my-4">
              <div className="md:col-span-3 row-span-1 bg-blackishV2 px-6 py-4 rounded-2xl flex justify-between">
                <div className="text-white w-full">
                  <div className="flex justify-between items-center w-full">
                    <div className="flex items-center gap-8">
                      <p className="text-lg font-semibold text-white">
                        Reports
                      </p>
                      <p className="text-gray-400">Hindi</p>
                    </div>
                    <div className="md:w-[15vw] w-full">
                      <div className="md:block hidden">
                        <CustomCarousel
                          rows={1}
                          cols={3}
                          gapX={0}
                          gapY={0}
                          // easier to pass this array like this rather than separating it
                          completeSliders={[
                            <SingleTab activeTab={userTab} tab={"Week"} />,
                            <SingleTab activeTab={userTab} tab={"Month"} />,
                            <SingleTab activeTab={userTab} tab={"Year"} />,
                          ]}
                          numberOfCardsShownInSingleSlide={3}
                          handleCardOnClick={(card) => {
                            setUserTab(card.props.tab);
                            switch (card.props.tab) {
                              case "Month":
                                // change data for trending tab
                                break;
                              // add other tabs here
                              default:
                                break;
                            }
                          }}
                          topNext={"hidden"}
                          topPrev={"hidden"}
                        />
                      </div>
                      <div className="md:hidden block">
                        <CustomCarousel
                          rows={1}
                          cols={2}
                          gapX={0}
                          gapY={0}
                          // easier to pass this array like this rather than separating it
                          completeSliders={[
                            <SingleTab activeTab={userTab} tab={"Week"} />,
                            <SingleTab activeTab={userTab} tab={"Month"} />,
                            <SingleTab activeTab={userTab} tab={"Year"} />,
                          ]}
                          numberOfCardsShownInSingleSlide={2}
                          handleCardOnClick={(card) => {
                            setUserTab(card.props.tab);
                            switch (card.props.tab) {
                              case "Month":
                                // change data for trending tab
                                break;
                              // add other tabs here
                              default:
                                break;
                            }
                          }}
                          topNext={"hidden"}
                          topPrev={"hidden"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="block">
                    <CustomLineChart
                      customHeight="300"
                      customWidth="750"
                      data={[
                        100, 150, 180, 220, 300, 280, 320, 400, 380, 450, 500,
                        520,
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="md:col-span-3 row-span-1 bg-blackishV2 px-6 py-4 rounded-2xl flex flex-col gap-4">
                <div className="flex justify-between md:items-center md:flex-row flex-col gap-4">
                  <p className="text-lg font-semibold text-white">
                    Today With God
                  </p>
                  <div className="md:w-[22vw] w-full">
                    <CustomCarousel
                      rows={1}
                      cols={2}
                      gapX={24}
                      gapY={0}
                      // easier to pass this array like this rather than separating it
                      completeSliders={[
                        <SingleTab activeTab={activeTab} tab={"Trending"} />,
                        <SingleTab activeTab={activeTab} tab={"Recent"} />,
                        <SingleTab
                          activeTab={activeTab}
                          tab={"No. of Films"}
                        />,
                        <SingleTab
                          activeTab={activeTab}
                          tab={"No. of Reviews"}
                        />,
                        <SingleTab
                          activeTab={activeTab}
                          tab={"No. of Ratings"}
                        />,
                      ]}
                      numberOfCardsShownInSingleSlide={2}
                      handleCardOnClick={(card) => {
                        setActiveTab(card.props.tab);
                        switch (card.props.tab) {
                          case "Trending":
                            // change data for trending tab
                            break;
                          // add other tabs here
                          default:
                            break;
                        }
                      }}
                      topNext={"top-3"}
                      topPrev={"top-9"}
                    />
                  </div>
                </div>
                <div>
                  {/* custom table with different data as per the selected carousel  */}
                  <CustomTable
                    searchFor="Promises"
                    data={[]}
                    headers={twgHeaders}
                    width={"w-24"}
                    heightDesktop={"h-[32vh]"}
                    // giving extra width to 2nd column
                    nthChildWidth={"md:[&>*:nth-child(2)]:w-48"}
                    nthChildColor={"text-white text-opacity-50"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Twg;
