import React from "react";

export default function SignInButton({ Label, style, labelstyle }) {

    return (
        <div className={`${style}  rounded-xl w-full py-1 flex justify-center items-center`}>
            <div className='flex items-center'>
                <p className={`${labelstyle} font-bold w-full text-xs py-0.5`}>{Label}</p>
            </div>
        </div>
    )
}