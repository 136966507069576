import Axios from "../../utils/axios";

//Get Country data
export const getRoles = (payload) => {
    const URL = "/getCMSRoles";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const saveCMSUser = (payload) => {
    const URL = "/saveCMSUser";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const getCMSUser = (payload) => {
    const URL = "/user/read";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const updateCMSUser = (payload) => {
    const URL = "/user/update";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};
