import React from "react";

export default function InputsOfText({ value, onChange, inputStyle, onClick }) {

    return (
        <div>
            <p className={`${inputStyle}  outline-none border-none cursor-pointer  bg-inherit `}
                onClick={onClick}
                onChange={onChange}
            >
                {value}
            </p>
        </div>
    )
}