import * as actionTypes from "../ActionTypes";

export const readTestimonyCategoriesAction = (payload) => {
    return {
        type: actionTypes.READ_TESTIMONY_CATEGORY_REQUEST,
        payload: payload,
    };
};

export const readTestimonyAction = (payload) => {
    return {
        type: actionTypes.READ_TESTIMONY_REQUEST,
        payload: payload,
    };
};

export const accetTestimonyAction = (payload) => {
    return {
        type: actionTypes.POST_ACCEPT_TESTIMONY_REQUEST,
        payload: payload,
    };
};

export const rejectTestimonyAction = (payload) => {
    return {
        type: actionTypes.POST_REJECT_TESTIMONY_REQUEST,
        payload: payload,
    };
};

export const testimonyOfTheDayAction = (payload) => {
    return {
        type: actionTypes.POST_TESTIMONY_OF_THE_DAY_REQUEST,
        payload: payload,
    };
};