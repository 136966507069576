import * as actionTypes from "../ActionTypes";

export const getProfileAction = (payload) => {
  return {
    type: actionTypes.GET_PROFILE_REQUEST,
    payload: payload,
  };
};

export const saveUserProfileAction = (payload) => {
  return {
    type: actionTypes.SAVE_USER_PROFILE_REQUEST,
    payload: payload,
  };
};

export const updateUserProfileAction = (payload) => {
  return {
    type: actionTypes.UPDATE_USER_PROFILE_REQUEST,
    payload: payload,
  };
};

export const getPickWhereLeftAction = (payload) => ({
  type: actionTypes.GET_PICK_WHERE_LEFT_REQUEST,
  payload,
});

export const readUserFavAction = (payload) => ({
  type: actionTypes.READ_FAV_REQUEST,
  payload,
});

export const getAchievementsAction = (payload) => ({
  type: actionTypes.GET_ACHIEVEMENTS,
  payload,
});
