import React, { useEffect, useState } from "react";
import SearchBar from "../../components/universal/SearchBar";
import { Link, useNavigate } from "react-router-dom";
import { AllTestimonialsHooks } from "./hooks/TestimonialsHooks";
import CustomCarousel from "../../components/universal/CustomCarousel";
import SingleTab from "../../components/universal/SingleTab";
import CustomTable from "../../components/universal/CustomTable";
import { TestimonialsData, TestimonialsHeaders } from "./Constants";
import CustomTabsV2 from "../../components/universal/CustomTabsV2";
import avatar from "../../assets/icons/avatar.svg";
import playcircle from "../../assets/icons/playcircle.svg";
import TestVideo from "../../assets/images/TestVideo.png";
import close from "../../assets/icons/close.svg";
import avatar1 from "../../assets/images/avatar1.png";
import Navbar from "../../components/universal/Navbar";
import { useDispatch } from "react-redux";
import {
  accetTestimonyAction,
  readTestimonyAction,
  testimonyOfTheDayAction,
} from "../../store/Testimony/TestimonyAction";
import ReactPlayer from "react-player";
import Toastiy from "../../components/universal/Toastiy";
import ReactModal from "react-modal";
import DateTimePicker from "react-datetime-picker";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import ConfirmToast from "../../components/universal/ConfirmToast";

function AllTestimonials() {
  const initialState = {
    New: [],
    Approved: [],
    Rejected: [],
  };
  // search bar component state with handler
  const { value, onChangeSearchBar } = AllTestimonialsHooks();
  const [activeTab, setActiveTab] = useState("New");

  const [testimonyData, setTestimonyData] = useState(initialState);
  const [selectedView, setSelectedView] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [isUpdated, setIsUpdated] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [scheduleDate, setScheduleDate] = useState("");
  const [currentId, setCurrentId] = useState("");

  console.log(selectedView, "selectedView");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleAccept = (id, status) => {
    dispatch(
      accetTestimonyAction({
        apiPayloadRequest: {
          _id: id,
          approval_status: status,
        },
        callback: (res) => {
          if (res?.type === 1) {
            if (status === 1) {
              Toastiy("Testimony Approved!", 1);
              setIsUpdated(true);
              setTestimonyData(initialState);
            } else {
              Toastiy("Testimony Rejected!", 1);
              setIsUpdated(true);
              setTestimonyData(initialState);
            }
          }
        },
      })
    );
  };

  const handleActiveInActiveToggle = (id, status) => {};

  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const makeTestimonyOfTheDay = () => {
    dispatch(
      testimonyOfTheDayAction({
        apiPayloadRequest: {
          _id: currentId,
          testimony_date: scheduleDate,
        },
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("Testimony Scheduled Successfully!", 1);
            handleToggleModal();
            setScheduleDate("");
          } else {
            Toastiy("Failed To Schedule Testimony!");
          }
        },
      })
    );
  };

  useEffect(() => {
    if (isUpdated) {
      dispatch(
        readTestimonyAction({
          callback: (res) => {
            if (res?.type === 1) {
              // setTestimonyData(initialState);
              // setTimeout(() => {
              res?.data?.map((item) => {
                if (item?.approval_status === 0) {
                  setTestimonyData((prev) => ({
                    ...prev,
                    ["New"]: [...prev["New"], item],
                  }));
                } else if (item?.approval_status === 1) {
                  setTestimonyData((prev) => ({
                    ...prev,
                    ["Approved"]: [...prev["Approved"], item],
                  }));
                } else {
                  setTestimonyData((prev) => ({
                    ...prev,
                    ["Rejected"]: [...prev["Rejected"], item],
                  }));
                }
              });
              setIsUpdated(false);
              // }, 500);
            }
          },
        })
      );
    }
  }, [isUpdated]);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium">Testimonials</p>
            </div>

            <div className="w-full flex-1 grid grid-cols-6 grid-rows-1 rounded-2xl overflow-hidden">
              <div className=" col-span-4 bg-[#1F1F1F] p-4 flex flex-col gap-4">
                <div className="HEADING flex items-center justify-between">
                  <p className="text-lg font-semibold text-white">Testimonials List</p>
                  <div>
                    <CustomCarousel
                      freeFlow={true}
                      gapX={12}
                      gapY={12}
                      topNext={"hidden"}
                      topPrev={"hidden"}
                      numberOfCardsShownInSingleSlide={4}
                      completeSliders={[
                        <SingleTab activeTab={activeTab} tab={"New"} />,
                        <SingleTab activeTab={activeTab} tab={"Approved"} />,
                        <SingleTab activeTab={activeTab} tab={"Rejected"} />,
                      ]}
                      handleCardOnClick={(card) => {
                        setActiveTab(card.props.tab);
                        switch (card.props.tab) {
                          case "All":
                            // change data for trending tab
                            break;
                          // add other tabs here
                          default:
                            break;
                        }
                      }}
                    />
                  </div>
                </div>

                <CustomTable
                  searchFor={"Notifications"}
                  data={testimonyData[activeTab]}
                  activeTab={activeTab}
                  headers={TestimonialsHeaders}
                  testimonialTable={true}
                  width={"w-24"}
                  heightDesktop={"h-[60vh]"}
                  // giving extra width to 2nd column
                  nthChildWidth={"[&>*:nth-child(2)]:w-48"}
                  nthChildStyle={"[&>*:nth-child(2)]:h-5 [&>*:nth-child(2)]:overflow-hidden"}
                  handleToggleClick={(row, isActive) => {
                    handleActiveInActiveToggle(row?._id, isActive ? 1 : 0);
                  }}
                  handleEyeClick={(row) => {
                    console.log(row);
                    setSelectedView(row);
                    setShowPreview(true);
                  }}
                  handleEditClick={(row) => {
                    console.log(row);
                  }}
                  handleGreenTick={(row) => {
                    handleAccept(row?._id, 1);
                  }}
                  handleRedCross={(row) => {
                    const options = {
                      customUI: ({ onClose }) => {
                        return (
                          <ConfirmToast
                            title="Are you sure?"
                            message="You want to reject this testimony?"
                            onClose={() => onClose()}
                            onSuccess={() => {
                              handleAccept(row?._id, 2);
                              onClose();
                            }}
                          />
                        );
                      },
                      closeOnEscape: true,
                      closeOnClickOutside: true,
                      keyCodeForClose: [8, 32],
                      overlayClassName: "!bg-gray-500 !bg-opacity-75",
                    };

                    confirmAlert(options);
                  }}
                />
              </div>
              <div className=" md:col-span-2 col-span-1">
                <CustomTabsV2
                  tabHeight={"h-[64px]"}
                  tabsTitleArray={["Preview", "Stats"]}
                  tabsContentObject={{
                    Preview: (
                      <>
                        {showPreview && (
                          <div className="flex items-center justify-center w-full h-full flex-col gap-4">
                            <div className="h-[60vh] overflow-auto w-full flex flex-col gap-6 py-4">
                              <div className="flex justify-between items-center w-full">
                                <div className="flex gap-2 items-center px-[2%]">
                                  <img
                                    src={
                                      selectedView?.user[0]?.profilePicUrl
                                        ? selectedView?.user[0]?.profilePicUrl
                                        : avatar
                                    }
                                    className="h-8 w-8 rounded-full object-contain"
                                    alt=""
                                  />
                                  <p className="text-sm text-white font-medium">
                                    User: {selectedView?.user[0]?.firstName}
                                  </p>
                                </div>
                                {/* <p className="text-greyish text-[12px] font-medium">
                                  View User Details
                                </p> */}
                              </div>
                              <div className="px-[2%]">
                                <p className="text-white text-lg">
                                  {selectedView?.testimonial_name}
                                </p>
                                <p className="text-white opacity-60 mt-2">
                                  {" "}
                                  {selectedView?.testimonial_description}
                                </p>
                              </div>
                              <div className="relative flex justify-center items-center p-3">
                                <ReactPlayer
                                  url={selectedView?.testomonial_rec_videoUrl}
                                  height={"500px"}
                                  width={"100%"}
                                  controls={true}
                                />
                              </div>
                            </div>
                            <div className="flex justify-between items-center gap-2 w-full mb-[10%] p-2">
                              {selectedView?.approval_status === 1 && (
                                <button
                                  onClick={() => {
                                    handleToggleModal();
                                    setCurrentId(selectedView?._id);
                                  }}
                                  className="text-blackish bg-yellow-500 px-4 py-2 rounded-md text-[10px] font-semibold w-2/4"
                                >
                                  Make Testimony Of The Day
                                </button>
                              )}
                              {selectedView?.approval_status === 1 ? (
                                <div className="flex justify-end items-center gap-2 w-full">
                                  <button
                                    onClick={() => handleAccept(selectedView?._id, 2)}
                                    className="text-white bg-[#EF6D51] px-4 py-2 rounded-md text-[13px] font-semibold"
                                  >
                                    Reject
                                  </button>
                                </div>
                              ) : selectedView?.approval_status === 2 ? (
                                <div className="flex justify-end items-center gap-2 w-full">
                                  <button
                                    onClick={() => handleAccept(selectedView?._id, 1)}
                                    className="text-blackish bg-[#7FBA7A] px-4 py-2 rounded-md text-[13px] font-semibold"
                                  >
                                    Accept
                                  </button>
                                </div>
                              ) : (
                                <div className="flex justify-end items-center gap-2 w-full">
                                  <button
                                    onClick={() => handleAccept(selectedView?._id, 1)}
                                    className="text-blackish bg-[#7FBA7A] px-4 py-2 rounded-md text-[13px] font-semibold"
                                  >
                                    Accept
                                  </button>
                                  <button
                                    onClick={() => handleAccept(selectedView?._id, 2)}
                                    className="text-white bg-[#EF6D51] px-4 py-2 rounded-md text-[13px] font-semibold"
                                  >
                                    Reject
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ),
                    Stats: (
                      <div className="mt-8 flex items-center justify-center w-full text-white">
                        No Data Found...
                      </div>
                    ),
                  }}
                />
                <ReactModal
                  isOpen={showModal}
                  onRequestClose={handleToggleModal}
                  className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
                >
                  <div className="relative bg-black text-white flex flex-col justify-between h-[25%] w-[25%] rounded-xl p-6">
                    <img
                      src={close}
                      className="absolute top-3 right-3 cursor-pointer"
                      onClick={handleToggleModal}
                    />
                    <div className="w-full flex flex-col mt-2">
                      <label className="text-[12px] text-greyish font-medium leading-4">
                        Schedule Date
                      </label>
                      <DateTimePicker
                        className={
                          "bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10"
                        }
                        value={scheduleDate}
                        onChange={setScheduleDate}
                      />
                    </div>
                    <div className="flex w-full justify-end">
                      <button
                        onClick={makeTestimonyOfTheDay}
                        className="text-blackish bg-yellow-500 px-6 py-2 rounded-md text-[13px] font-semibold"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </ReactModal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllTestimonials;
