import React from "react";
import SignUp from "./PreviewComponents/SignUp";
import Login from "./PreviewComponents/Login";
import ForgotPswdPreview from "./PreviewComponents/ForgotPswdPreview";
import Homepreview from "./PreviewComponents/Homepreview";
import Testimonypreview from "./PreviewComponents/Testimonyprewiew";
import CurriculumPreview from "./PreviewComponents/CurriculumPreview";
import FavouritePreview from "./PreviewComponents/FavouritePreview";
import GivingsPreview from "./PreviewComponents/GivingsPreview";
import MenuPreview from "./PreviewComponents/MenuPreview";
import MyProfilePreview from "./PreviewComponents/MyProfilePreview";
import MyDownloadsPreview from "./PreviewComponents/MyDownloadsPreview";
import MyGivingsPreview from "./PreviewComponents/MyGivingsPreview";
import HelpAndSupportPreview from "./PreviewComponents/HelpAndSupportPreview";
import AboutTWGPreview from "./PreviewComponents/AvoutTWGPreview";

export const LanguagePreview = ({ activeTab, fieldValues }) => {
  console.log(fieldValues, "fieldValues");

  return (
    <div className="flex  h-[73vh] justify-center p-[2%] items-center bg-zinc-900">
      <div className="flex h-[85%] aspect-[9/16] flex-col justify-center gap-2 bg-black rounded-md">
        {activeTab === "Log In" && <Login fieldValues={fieldValues} />}
        {activeTab === "Sign Up" && <SignUp fieldValues={fieldValues} />}
        {activeTab === "Forgot Password" && <ForgotPswdPreview fieldValues={fieldValues} />}
        {activeTab === "Home page" && <Homepreview fieldValues={fieldValues} />}
        {activeTab === "Testimonies" && <Testimonypreview fieldValues={fieldValues} />}
        {activeTab === "Curriculum" && <CurriculumPreview fieldValues={fieldValues} />}
        {activeTab === "Favourites" && <FavouritePreview fieldValues={fieldValues} />}
        {activeTab === "Givings" && <GivingsPreview fieldValues={fieldValues} />}
        {activeTab === "Menu" && <MenuPreview fieldValues={fieldValues} />}
        {activeTab === "My Profile" && <MyProfilePreview fieldValues={fieldValues} />}
        {activeTab === "My Downloads" && <MyDownloadsPreview fieldValues={fieldValues} />}
        {activeTab === "My Givings" && <MyGivingsPreview fieldValues={fieldValues} />}
        {activeTab === "Help and Support" && <HelpAndSupportPreview fieldValues={fieldValues} />}
        {activeTab === "About TWG" && <AboutTWGPreview fieldValues={fieldValues} />}
      </div>
    </div>
  );
};
