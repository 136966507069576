// CustomLineChart.js
import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const CustomLineChart = ({ data, customHeight, customWidth, titles = [] }) => {
  const svgRef = useRef();

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    const margin = { top: 20, right: 0, bottom: 20, left: 0 };
    const width = customWidth - margin.left - margin.right;
    const height = customHeight - margin.top - margin.bottom;

    const svg = d3
      .select(svgRef.current)
      .attr("width", "100%") // Set width to be responsive
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const xScale = d3
      .scaleBand()
      .domain(data.map((d, i) => i))
      .range([0, width])
      .padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data)])
      .range([height, 0]);

    const line = d3
      .line()
      .x((d, i) => xScale(i) + xScale.bandwidth() / 2)
      .y((d) => yScale(d))
      .curve(d3.curveCardinal);

    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat((i) => titles[i])
      .tickSize(0);

    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(xAxis)
      .selectAll("text")
      .style("fill", "#979592"); // Set x-axis label color

    svg.select(".domain").remove(); // Remove x-axis line

    svg
      .append("defs") // Add a definition for the shadow filter
      .append("filter")
      .attr("id", "line-shadow")
      .append("feDropShadow")
      .attr("dx", 0)
      .attr("dy", 2) // Adjust vertical offset for shadow
      .attr("stdDeviation", 4) // Adjust blur amount for shadow
      .attr("flood-color", "#EFBC51"); // Set the shadow color

    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#EFBC51") // Set the line color
      .attr("stroke-width", 4) // Increase the line thickness
      .attr("d", line)
      .style("filter", "url(#line-shadow)"); // Apply the shadow filter
  }, [data]);

  return (
    <div className="w-full">
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default CustomLineChart;
