import logo from "../../../../assets/icons/logo.png";
import userImg from "../../../../assets/images/profilepic.png";
import Screenshotallmenu from "../../../../assets/images/Screenshotallmenu.png";
import Screenshotcur1 from "../../../../assets/images/Screenshotcur1.png";
import Screenshotcur2 from "../../../../assets/images/Screenshotcur2.png";
import Screenshotcur3 from "../../../../assets/images/Screenshotcur3.png";
import Screenshotcur4 from "../../../../assets/images/Screenshotcur4.png";
import InputsOfText from "../../../InputFields/InputsOfText";
import { AddLanguagesHooks } from "../../../../screens/Languages/hooks/LanguagesHooks";

const FavouritePreview = (fieldValues) => {
  console.log(fieldValues, "teti");
  const { setShowData, showData } = AddLanguagesHooks();

  return (
    <div className="flex flex-col h-full relative bg-zinc-900">
      <div className="bg-zinc-800 absolute  bottom-0 left-0 right-0   rounded-t-xl  z-10 ">
        <img src={Screenshotallmenu} />
      </div>

      <div className="flex flex-col small-bar  overflow-auto pb-14">
        <div className="flex flex-row gap-2 pt-8 px-3 items-center">
          <img className="w-10 " src={logo} />
          <div className="w-24 h-7 bg-slate-50 opacity-40 rounded-3xl"></div>
          <img className="w-8 " src={logo} />
          <img className="w-8 " src={userImg} />
        </div>

        <div className="px-2">
          <div className="flex flex-col pt-3">
            <div className="flex fex-row justify-between px-5 pt-4">
              <InputsOfText
                value={fieldValues?.fieldValues?.curriculum_lbl}
                inputStyle={"text-slate-200 w-10 font-bold text-[12px]"}
              />
              <InputsOfText
                value={fieldValues?.fieldValues?.see_all_btn}
                inputStyle={"text-yellowish w-12 text-[10px]"}
              />
            </div>

            <div className="flex justify-center  py-3 ">
              <img src={Screenshotcur1} />
            </div>
          </div>

          <div className="flex flex-col">
            <div className="flex fex-row justify-between px-3">
              <InputsOfText
                value={fieldValues?.fieldValues?.videos_lbl}
                inputStyle={"text-slate-200 w-full font-bold text-[12px]"}
              />
              <InputsOfText
                value={fieldValues?.fieldValues?.see_all_btn}
                inputStyle={"text-yellowish w-12 text-[10px]"}
              />
            </div>

            <div className="flex justify-center  py-3 ">
              <img src={Screenshotcur2} />
            </div>
          </div>

          <div className="bg-zinc-900 mr-1 mb-1 rounded-tr-3xl flex flex-col justify-center gap-4 ">
            <div className="flex flex-row justify-between pt-6 px-2">
              <InputsOfText
                value={fieldValues?.fieldValues?.testimonies_lbl}
                inputStyle={"text-slate-200 w-24 font-semibold text-[14px]"}
              />
              <InputsOfText
                value={fieldValues?.fieldValues?.see_all_btn}
                inputStyle={"text-yellowish w-12 text-[10px]"}
              />
            </div>
            <div className="flex justify-center">
              <img className=" pt-2" src={Screenshotcur3} />
            </div>
          </div>

          <div className="bg-zinc-900 mr-1 mb-1 rounded-tr-3xl flex flex-col justify-center gap-4 ">
            <div className="flex flex-row justify-between pt-6 px-2">
              <InputsOfText
                value={fieldValues?.fieldValues?.promise_lbl}
                inputStyle={"text-slate-200 w-24 font-semibold text-[14px]"}
              />
              <InputsOfText
                value={fieldValues?.fieldValues?.see_all_btn}
                inputStyle={"text-yellowish w-12 text-[10px]"}
              />
            </div>
            <div className="flex justify-center">
              <img className=" pt-2" src={Screenshotcur4} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavouritePreview;
