import axios from "axios";
import Axios from "../../utils/axios";

//Get Country data
export const getCountriesData = () => {
  const URL = "https://countriesnow.space/api/v0.1/countries/states";
  return axios.get(URL).then((res) => {
    return res.data;
  });
};

export const categoriesDashboard = (payload) => {
  const URL = "/categories/minidash";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const curriculumDashboard = (payload) => {
  const URL = "/curriculum/minidash";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
