import React, { useEffect, useState } from "react";
import SearchBar from "../../components/universal/SearchBar";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import squareIcon1 from "../../assets/icons/squareCard/icon1.svg";
import Navbar from "../../components/universal/Navbar";
import LayoutHooks from "./Hooks/LayoutHooks";
import { useDispatch } from "react-redux";
import { layoutDashboardAction } from "../../store/Layout/LayoutAction";
import SquareCard from "../../components/universal/SquareCard";
import CustomCarousel from "../../components/universal/CustomCarousel";
import SingleTab from "../../components/universal/SingleTab";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import ReactECharts from "echarts-for-react";
import DateFilter from "../../components/universal/DateFilter";
import { subDays } from "date-fns";
import { FaDownload } from "react-icons/fa6";
import { downloadExcel } from "../../utils/DownloadExcel";

function Layout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // search bar component state with handler
  const { value, allLayouts, onChangeSearchBar } = LayoutHooks();
  // tab clicking state for language table
  const [activeTab, setActiveTab] = useState("Most Used Videos");
  const [activeLayoutCount, setActiveLayoutCount] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [mostUsedVideos, setMostUsedVideos] = useState({ xAxis: [], yAxis: [] });
  const [mostUsedCategories, setMostUsedCategories] = useState({ xAxis: [], yAxis: [] });
  const [mostUsedCurriculums, setMostUsedCurriculums] = useState({ xAxis: [], yAxis: [] });
  const [languageTabData, setLanguageTabData] = useState({ xAxis: [], yAxis: [] });

  const [filterRange, setFilterRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const fetchReportsData = ({ startDate, endDate }) => {
    dispatch(
      layoutDashboardAction({
        apiPayloadRequest: {
          startDate: startDate ?? null,
          endDate: endDate ?? null,
        },
        callback: (res) => {
          const data = res?.data;
          setActiveLayoutCount(
            data?.filter((item) => item.dash === "Active Layouts count")[0].data[0].activeLayouts
          );
          const tempMostUsedVideos = {
            xAxis: data
              ?.filter((item) => item?.dash === "Most used videos")[0]
              ?.data?.map((d) => ({ ...d, value: d?.count })),
            yAxis: data
              ?.filter((item) => item?.dash === "Most used videos")[0]
              ?.data?.map((d) => ({ ...d, value: d?.title })),
          };

          setLanguageTabData(tempMostUsedVideos);

          setMostUsedVideos(tempMostUsedVideos);
          setMostUsedCategories({
            xAxis: data
              ?.filter((item) => item?.dash === "Most used categories")[0]
              ?.data?.map((d) => ({ ...d, value: d?.count })),
            yAxis: data
              ?.filter((item) => item?.dash === "Most used categories")[0]
              ?.data?.map((d) => ({ ...d, value: d?.title })),
          });
          setMostUsedCurriculums({
            xAxis: data
              ?.filter((item) => item?.dash === "Most used curriculums")[0]
              ?.data?.map((d) => ({ ...d, value: d?.count })),
            yAxis: data
              ?.filter((item) => item?.dash === "Most used curriculums")[0]
              ?.data?.map((d) => ({ ...d, value: d?.title })),
          });
        },
      })
    );
  };

  useEffect(() => {
    fetchReportsData({
      startDate: filterRange?.[0]?.startDate,
      endDate: filterRange?.[0]?.endDate,
    });
  }, [filterRange]);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div
            className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4 relative"
            id="reportContainer"
          >
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <div className="flex justify-between items-center flex-1">
                <p className=" text-white text-3xl font-medium">Layouts</p>
                <p className="flex gap-3 items-center">
                  <DateFilter range={filterRange} setRange={setFilterRange} />
                </p>
              </div>
              <div className="flex gap-4">
                <Link to={"/layout/all"}>
                  <button className=" text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                    See All Layouts
                  </button>
                </Link>
                <Link to={"/layout/add"}>
                  <button className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl">
                    <AiOutlinePlus /> Add New Layout
                  </button>
                </Link>
              </div>
            </div>
            <div className="flex gap-4 flex-wrap">
              <SquareCard
                heading={"Total No. Of Layouts"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={allLayouts?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([{ "Total No. Of Layouts": allLayouts?.length }]);
                }}
                trail={""}
                handleOnClick={() => {
                  navigate("/layout/all");
                }}
              />
              <SquareCard
                heading={"Active Layout"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={activeLayoutCount}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([{ "Active Layout": activeLayoutCount }]);
                }}
                trail={""}
                handleOnClick={() => {
                  navigate("/layout/all");
                }}
              />
              <SquareCard
                heading={"No. of Most Used Videos"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={mostUsedVideos?.xAxis?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([{ "No. of Most Used Videos": mostUsedVideos?.xAxis?.length }]);
                }}
                trail={""}
                handleOnClick={() => {
                  setAnimate((prev) => !prev);
                  setActiveTab("Most Used Videos");
                  setLanguageTabData(mostUsedVideos);
                }}
              />
              <SquareCard
                heading={"No. of Most Used Categories"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={mostUsedCategories?.xAxis?.length || 0}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    { "No. of Most Used Categories": mostUsedCategories?.xAxis?.length || 0 },
                  ]);
                }}
                trail={""}
                handleOnClick={() => {
                  setAnimate((prev) => !prev);
                  setActiveTab("Most Used Categories");
                  setLanguageTabData(mostUsedCategories);
                }}
              />
              <SquareCard
                heading={"No. of Most Used Curriculums"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={mostUsedCurriculums?.xAxis?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    { "No. of Most Used Curriculums": mostUsedCurriculums?.xAxis?.length },
                  ]);
                }}
                trail={""}
                handleOnClick={() => {
                  setAnimate((prev) => !prev);
                  setActiveTab("Most Used Curriculum");
                  setLanguageTabData(mostUsedCurriculums);
                }}
              />
            </div>
            <div className="grid grid-rows-1 md:grid-cols-6 grid-cols-1 flex-1 h-full gap-x-4 gap-y-4 my-4">
              <div className="md:col-span-6 row-span-1 bg-blackishV2 px-6 py-4 rounded-2xl flex flex-col gap-4">
                <div className="flex justify-between md:items-center md:flex-row flex-col gap-6 ">
                  <p className="text-lg font-semibold text-white">Reports</p>
                  <div className="md:w-[28vw] w-full">
                    <div className="md:block hidden">
                      <CustomCarousel
                        rows={1}
                        cols={2}
                        gapX={0}
                        gapY={0}
                        // easier to pass this array like this rather than separating it
                        completeSliders={[
                          <SingleTab activeTab={activeTab} tab={"Most Used Videos"} />,
                          <SingleTab activeTab={activeTab} tab={"Most Used Categories"} />,
                          <SingleTab activeTab={activeTab} tab={"Most Used Curriculum"} />,
                        ]}
                        numberOfCardsShownInSingleSlide={2}
                        handleCardOnClick={(card) => {
                          setActiveTab(card.props.tab);
                          switch (card.props.tab) {
                            case "Most Used Videos":
                              setLanguageTabData(mostUsedVideos);
                              break;

                            case "Most Used Categories":
                              setLanguageTabData(mostUsedCategories);
                              break;

                            case "Most Used Curriculum":
                              setLanguageTabData(mostUsedCurriculums);
                              break;
                            // add other tabs here
                            default:
                              break;
                          }
                        }}
                        topNext={"top-3"}
                        topPrev={"top-9"}
                      />
                    </div>
                    <div className="md:hidden block">
                      <CustomCarousel
                        rows={1}
                        cols={2}
                        gapX={0}
                        gapY={0}
                        // easier to pass this array like this rather than separating it
                        completeSliders={[
                          <SingleTab activeTab={activeTab} tab={"Most Used Videos"} />,
                          <SingleTab activeTab={activeTab} tab={"Most Used Categories"} />,
                          <SingleTab activeTab={activeTab} tab={"Most Used Curriculum"} />,
                        ]}
                        numberOfCardsShownInSingleSlide={2}
                        handleCardOnClick={(card) => {
                          setActiveTab(card.props.tab);
                          switch (card.props.tab) {
                            case "Languages":
                              // change data for trending tab
                              break;
                            // add other tabs here
                            default:
                              break;
                          }
                        }}
                        topNext={"top-3"}
                        topPrev={"top-9"}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    animate
                      ? "absolute bg-blackishV2 w-full h-full left-0  top-0 p-5 rounded-lg z-50 transition-all duration-500 ease-out overflow-y-auto"
                      : "transition-all duration-500 ease-in-out"
                  }`}
                >
                  {animate && (
                    <button
                      className="flex gap-2 font-bold text-xs px-4 py-2 bg-yellowish rounded-md"
                      onClick={() => setAnimate((prev) => !prev)}
                    >
                      <FaRegArrowAltCircleLeft size={16} /> <p>Back</p>
                    </button>
                  )}
                  <div className="w-full h-full relative">
                    <button
                      className="text-yellowish absolute top-2 right-2 z-50"
                      onClick={() => {
                        downloadExcel(languageTabData?.yAxis || []);
                      }}
                    >
                      <FaDownload />
                    </button>
                    <ReactECharts
                      key={languageTabData?.yAxis?.length}
                      className="py-4 bg-blackish mt-5"
                      onEvents={{
                        click: (e) => {
                          if (e?.componentType === "series") {
                            if (activeTab === "Most Used Categories") {
                              navigate("/categories/add", {
                                state: {
                                  _id: e?.data?._id || "",
                                },
                              });
                            } else if (activeTab === "Most Used Videos") {
                              navigate("/videos/add", {
                                state: {
                                  _id: e?.data?._id || "",
                                },
                              });
                            } else if (activeTab === "Most Used Curriculum") {
                              navigate("/curriculum/add", {
                                state: {
                                  _id: e?.data?._id || "",
                                },
                              });
                            }
                          }
                        },
                      }}
                      option={{
                        resposive: true,
                        // maintainAspectRatio: true,
                        tooltip: {
                          trigger: "axis",
                          axisPointer: {
                            type: "shadow",
                          },
                        },
                        grid: {
                          left: "3%",
                          right: "4%",
                          bottom: "3%",
                          containLabel: true,
                        },
                        yAxis: [
                          {
                            type: "category",
                            data: [...languageTabData?.yAxis],
                            axisLabel: { width: 100, overflow: "truncate" },
                            axisTick: { show: false },
                            // triggerEvent: true,
                          },
                        ],
                        xAxis: [
                          {
                            // triggerEvent: true,
                            type: "value",
                            splitLine: {
                              show: false,
                              lineStyle: {
                                type: "dashed",
                              },
                            },
                          },
                        ],
                        series: [
                          {
                            name: "Most Liked",
                            type: "bar",
                            barWidth: "65%",
                            data: [...languageTabData?.xAxis],
                            color: ["#EFBC51"],
                            itemStyle: {
                              normal: {
                                shadowColor: "rgb(239,188,81, 0.3)",
                                shadowBlur: 15,
                              },
                            },
                          },
                        ],
                        graph: {},
                      }}
                      notMerge={true}
                      lazyUpdate={true}
                      theme={"halloween"}
                      onChartReady={() => console.log("chart loaded")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
