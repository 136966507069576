import Axios from "../../utils/axios";

export const translateLanguage = (payload) => {
  const URL = "/translate";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const languageDashboard = (payload) => {
  const URL = "/language/minidash";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const uploadAssests = (payload, config = null) => {
  const URL = "/uploadAsset";
  return Axios.post(URL, payload, config).then((res) => {
    return res.data;
  });
};

export const uploadVideos = (payload, config = null) => {
  const URL = "/uploadVideos";
  return Axios.post(URL, payload, config).then((res) => {
    return res.data;
  });
};

export const saveLanguage = (payload) => {
  const URL = "/language/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const readLanguage = (payload) => {
  const URL = "/language/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateLanguage = (payload) => {
  const URL = "/language/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const activeorinactiveLanguage = (payload) => {
  const URL = "/language/activeorinactive";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageLoginMeta = (payload) => {
  const URL = "/language/login/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageLogin = (payload) => {
  const URL = "/language/login/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguageLogin = (payload) => {
  const URL = "/language/login/meta/read";
  return Axios.post(URL).then((res) => {
    return res.data;
  });
};
export const deleteLanguageLogin = (payload) => {
  const URL = "/language/login/meta/delete";
  return Axios.post(URL).then((res) => {
    return res.data;
  });
};

export const saveLanguageHomeData = (payload) => {
  const URL = "/language/home/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateLanguageHomeData = (payload) => {
  const URL = "/language/home/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguagePromisesData = (payload) => {
  const URL = "/language/promise/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateLanguagePromisesData = (payload) => {
  const URL = "/language/promise/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const readLanguageHomeData = (payload) => {
  const URL = "/language/home/meta/read";
  return Axios.post(URL).then((res) => {
    return res.data;
  });
};

export const deleteLanguageHomeData = (payload) => {
  const URL = "/language/home/meta/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const deleteLanguageSignUp = (payload) => {
  const URL = "/language/signup/meta/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageSignUp = (payload) => {
  const URL = "/language/signup/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageSignUp = (payload) => {
  const URL = "/language/signup/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const readLanguageSignUp = (payload) => {
  const URL = "/language/signup/meta/read";
  return Axios.post(URL).then((res) => {
    return res.data;
  });
};

export const saveLanguageCurriculumMeta = (payload) => {
  const URL = "/language/curriculum/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateLanguageCurriculumMeta = (payload) => {
  const URL = "/language/curriculum/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const deleteLanguageCurriculumMeta = (payload) => {
  const URL = "/language/curriculum/meta/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageTestimoniesData = (payload) => {
  const URL = "/language/testimonies/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateLanguageTestimoniesData = (payload) => {
  const URL = "/language/testimonies/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const readLanguageTestimoniesData = (payload) => {
  const URL = "/language/testimonies/meta/read";
  return Axios.post(URL).then((res) => {
    return res.data;
  });
};

export const deleteLanguageTestimoniesData = (payload) => {
  const URL = "/language/testimonies/meta/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const readLanguageCurriculumMeta = (payload) => {
  const URL = "/language/curriculum/meta/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageFavouritesMeta = (payload) => {
  const URL = "/language/myfav/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateLanguageFavouritesMeta = (payload) => {
  const URL = "/language/myfav/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const readLanguageFavouritesMeta = (payload) => {
  const URL = "/language/myfav/meta/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const deleteLanguageFavouritesMeta = (payload) => {
  const URL = "/language/myfav/meta/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageGivingsMeta = (payload) => {
  const URL = "/language/givings/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageGivingsMeta = (payload) => {
  const URL = "/language/givings/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguageGivingsMeta = (payload) => {
  const URL = "/language/givings/meta/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguageGivingsMeta = (payload) => {
  const URL = "/language/givings/meta/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageMenuMeta = (payload) => {
  const URL = "/language/menus/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageMenuMeta = (payload) => {
  const URL = "/language/givings/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguageMenuMeta = (payload) => {
  const URL = "/language/givings/meta/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguageMenuMeta = (payload) => {
  const URL = "/language/givings/meta/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageMyProfileMeta = (payload) => {
  const URL = "/language/myprofile/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageMyProfileMeta = (payload) => {
  const URL = "/language/myprofile/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguageMyProfileMeta = (payload) => {
  const URL = "/language/myprofile/meta/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguageMyProfileMeta = (payload) => {
  const URL = "/language/myprofile/meta/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageMyDownloadsMeta = (payload) => {
  const URL = "/language/mydownload/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageMyDownloadsMeta = (payload) => {
  const URL = "/language/mydownload/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguageMyDownloadsMeta = (payload) => {
  const URL = "/language/mydownload/meta/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguageMyDownloadsMeta = (payload) => {
  const URL = "/language/mydownload/meta/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageMyGivingsMeta = (payload) => {
  const URL = "/language/mygivings/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageMyGivingsMeta = (payload) => {
  const URL = "/language/mygivings/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguageMyGivingsMeta = (payload) => {
  const URL = "/language/mygivings/meta/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguageMyGivingsMeta = (payload) => {
  const URL = "/language/mygivings/meta/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageHelpAndSupportMeta = (payload) => {
  const URL = "/language/helpnsupport/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageHelpAndSupportMeta = (payload) => {
  const URL = "/language/helpnsupport/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguageHelpAndSupportMeta = (payload) => {
  const URL = "/language/helpnsupport/meta/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguageHelpAndSupportMeta = (payload) => {
  const URL = "/language/helpnsupport/meta/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageHelpAndSupportAccordianMeta = (payload) => {
  const URL = "/language/helpnsupport/acrd/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageHelpAndSupportAccordianMeta = (payload) => {
  const URL = "/language/helpnsupport/acrd/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguageHelpAndSupportAccordianMeta = (payload) => {
  const URL = "/language/helpnsupport/acrd/meta/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguageHelpAndSupportAccordianMeta = (payload) => {
  const URL = "/language/helpnsupport/acrd/meta/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageAboutTWGMeta = (payload) => {
  const URL = "/language/abouttwg/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageAboutTWGMeta = (payload) => {
  const URL = "/language/abouttwg/meta/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguageAboutTWGMeta = (payload) => {
  const URL = "/language/abouttwg/meta/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguageAboutTWGMeta = (payload) => {
  const URL = "/language/abouttwg/meta/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageForgotPasswordMeta = (payload) => {
  const URL = "/language/forgetpwd/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageForgotPasswordMeta = (payload) => {
  const URL = "/language/forgetpwd/meta/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguageForgotPasswordMeta = (payload) => {
  const URL = "/language/forgetpwd/meta/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguageForgotPasswordMeta = (payload) => {
  const URL = "/language/forgetpwd/meta/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculum = (payload) => {
  const URL = "/curriculum/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculum = (payload) => {
  const URL = "/curriculum/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculum = (payload) => {
  const URL = "/curriculum/readcms";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculum = (payload) => {
  const URL = "/curriculum/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveBGCurriculum = (payload) => {
  const URL = "/curriculum/bg/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateBGCurriculum = (payload) => {
  const URL = "/curriculum/bg/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readBGCurriculum = (payload) => {
  const URL = "/curriculum/bg/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteBGCurriculum = (payload) => {
  const URL = "/curriculum/bg/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguagesCurriculum = (payload) => {
  const URL = "/curriculum/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguagesCurriculum = (payload) => {
  const URL = "/curriculum/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguagesCurriculum = (payload) => {
  const URL = "/curriculum/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguagesCurriculum = (payload) => {
  const URL = "/curriculum/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveBGLanguagesCurriculum = (payload) => {
  const URL = "/curriculum/bg/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateBGLanguagesCurriculum = (payload) => {
  const URL = "/curriculum/bg/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readBGLanguagesCurriculum = (payload) => {
  const URL = "/curriculum/bg/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteBGLanguagesCurriculum = (payload) => {
  const URL = "/curriculum/bg/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLesson = (payload) => {
  const URL = "/curriculum/lesson/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLesson = (payload) => {
  const URL = "/curriculum/lesson/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLesson = (payload) => {
  const URL = "/curriculum/lesson/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLesson = (payload) => {
  const URL = "/curriculum/lesson/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLessonLanguage = (payload) => {
  const URL = "/curriculum/lesson/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLessonLanguage = (payload) => {
  const URL = "/curriculum/lesson/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLessonLanguage = (payload) => {
  const URL = "/curriculum/lesson/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLessonLanguage = (payload) => {
  const URL = "/curriculum/lesson/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLessonThumbNail = (payload) => {
  const URL = "/lesson/tumbnail/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLessonThumbNail = (payload) => {
  const URL = "/lesson/tumbnail/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLessonThumbNail = (payload) => {
  const URL = "/lesson/tumbnail/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLessonThumbNail = (payload) => {
  const URL = "/lesson/tumbnail/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLanguageLessonThumbNail = (payload) => {
  const URL = "/lesson/tumbnail/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLanguageLessonThumbNail = (payload) => {
  const URL = "/lesson/tumbnail/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLanguageLessonThumbNail = (payload) => {
  const URL = "/lesson/tumbnail/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLanguageLessonThumbNail = (payload) => {
  const URL = "/lesson/tumbnail/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLessonSlide = (payload) => {
  const URL = "/lesson/slide/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLessonSlide = (payload) => {
  const URL = "/lesson/slide/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLessonSlide = (payload) => {
  const URL = "/lesson/slide/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLessonSlide = (payload) => {
  const URL = "/lesson/slide/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLanguageLessonSlide = (payload) => {
  const URL = "/lesson/slide/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLanguageLessonSlide = (payload) => {
  const URL = "/lesson/slide/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLanguageLessonSlide = (payload) => {
  const URL = "/lesson/slide/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLanguageLessonSlide = (payload) => {
  const URL = "/lesson/slide/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLessonSlideBG = (payload) => {
  const URL = "/lesson/slide/bg/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLessonSlideBG = (payload) => {
  const URL = "/lesson/slide/bg/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLessonSlideBG = (payload) => {
  const URL = "/lesson/slide/bg/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLessonSlideBG = (payload) => {
  const URL = "/lesson/slide/bg/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLanguageLessonSlideBG = (payload) => {
  const URL = "/lesson/slide/bg/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLanguageLessonSlideBG = (payload) => {
  const URL = "/lesson/slide/bg/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLanguageLessonSlideBG = (payload) => {
  const URL = "/lesson/slide/bg/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLanguageLessonSlideBG = (payload) => {
  const URL = "/lesson/slide/bg/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLessonSlideType = (payload) => {
  const URL = "/lesson/slidetype/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLessonSlideType = (payload) => {
  const URL = "/lesson/slidetype/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLessonSlideType = (payload) => {
  const URL = "/lesson/slidetype/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLessonSlideType = (payload) => {
  const URL = "/lesson/slidetype/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLanguageLessonSlideType = (payload) => {
  const URL = "/lesson/slidetype/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLanguageLessonSlideType = (payload) => {
  const URL = "/lesson/slidetype/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLanguageLessonSlideType = (payload) => {
  const URL = "/lesson/slidetype/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLanguageLessonSlideType = (payload) => {
  const URL = "/lesson/slidetype/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLessonSlideTypeBG = (payload) => {
  const URL = "/lesson/slidetype/bg/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLessonSlideTypeBG = (payload) => {
  const URL = "/lesson/slidetype/bg/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLessonSlideTypeBG = (payload) => {
  const URL = "/lesson/slidetype/bg/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLessonSlideTypeBG = (payload) => {
  const URL = "/lesson/slidetype/bg/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLanguageLessonSlideTypeBG = (payload) => {
  const URL = "/lesson/slidetype/bg/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLanguageLessonSlideTypeBG = (payload) => {
  const URL = "/lesson/slidetype/bg/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLanguageLessonSlideTypeBG = (payload) => {
  const URL = "/lesson/slidetype/bg/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLanguageLessonSlideTypeBG = (payload) => {
  const URL = "/lesson/slidetype/bg/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLessonSlideTypeAsset = (payload) => {
  const URL = "/lesson/slidetype/asset/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLessonSlideTypeAsset = (payload) => {
  const URL = "/lesson/slidetype/asset/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLessonSlideTypeAsset = (payload) => {
  const URL = "/lesson/slidetype/asset/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLessonSlideTypeAsset = (payload) => {
  const URL = "/lesson/slidetype/asset/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLanguageLessonSlideTypeAsset = (payload) => {
  const URL = "/lesson/slidetype/asset/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLanguageLessonSlideTypeAsset = (payload) => {
  const URL = "/lesson/slidetype/asset/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLanguageLessonSlideTypeAsset = (payload) => {
  const URL = "/lesson/slidetype/asset/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLanguageLessonSlideTypeAsset = (payload) => {
  const URL = "/lesson/slidetype/asset/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLessonSlideTypeLink = (payload) => {
  const URL = "/lesson/slidetype/link/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLessonSlideTypeLink = (payload) => {
  const URL = "/lesson/slidetype/link/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLessonSlideTypeLink = (payload) => {
  const URL = "/lesson/slidetype/link/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLessonSlideTypeLink = (payload) => {
  const URL = "/lesson/slidetype/link/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLanguageLessonSlideTypeLink = (payload) => {
  const URL = "/lesson/slidetype/link/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLanguageLessonSlideTypeLink = (payload) => {
  const URL = "/lesson/slidetype/link/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLanguageLessonSlideTypeLink = (payload) => {
  const URL = "/lesson/slidetype/link/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLanguageLessonSlideTypeLink = (payload) => {
  const URL = "/lesson/slidetype/link/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLessonSlideTypePoll = (payload) => {
  const URL = "/lesson/slidetype/poll/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLessonSlideTypePoll = (payload) => {
  const URL = "/lesson/slidetype/poll/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLessonSlideTypePoll = (payload) => {
  const URL = "/lesson/slidetype/poll/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLessonSlideTypePoll = (payload) => {
  const URL = "/lesson/slidetype/poll/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLanguageLessonSlideTypePoll = (payload) => {
  const URL = "/lesson/slidetype/poll/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLanguageLessonSlideTypePoll = (payload) => {
  const URL = "/lesson/slidetype/poll/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLanguageLessonSlideTypePoll = (payload) => {
  const URL = "/lesson/slidetype/poll/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLanguageLessonSlideTypePoll = (payload) => {
  const URL = "/lesson/slidetype/poll/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLessonSlideTypePollOptions = (payload) => {
  const URL = "/lesson/slidetype/poll/option/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLessonSlideTypePollOptions = (payload) => {
  const URL = "/lesson/slidetype/poll/option/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLessonSlideTypePollOptions = (payload) => {
  const URL = "/lesson/slidetype/poll/option/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLessonSlideTypePollOptions = (payload) => {
  const URL = "/lesson/slidetype/poll/option/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const saveCurriculumLessonSlideTypePollOptionsVote = (payload) => {
  const URL = "/lesson/slidetype/poll/option/vote/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLessonSlideTypePollOptionsVote = (payload) => {
  const URL = "/lesson/slidetype/poll/option/vote/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLessonSlideTypePollOptionsVote = (payload) => {
  const URL = "/lesson/slidetype/poll/option/vote/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLessonSlideTypePollOptionsVote = (payload) => {
  const URL = "/lesson/slidetype/poll/option/vote/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCurriculumLanguageLessonSlideTypePollOptions = (payload) => {
  const URL = "/lesson/slidetype/poll/option/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLanguageLessonSlideTypePollOptions = (payload) => {
  const URL = "/lesson/slidetype/poll/option/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLanguageLessonSlideTypePollOptions = (payload) => {
  const URL = "/lesson/slidetype/poll/option/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLanguageLessonSlideTypePollOptions = (payload) => {
  const URL = "/lesson/slidetype/poll/option/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const saveCurriculumLanguageLessonSlideTypePollOptionsVote = (payload) => {
  const URL = "/lesson/slidetype/poll/option/vote/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCurriculumLanguageLessonSlideTypePollOptionsVote = (payload) => {
  const URL = "/lesson/slidetype/poll/option/vote/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCurriculumLanguageLessonSlideTypePollOptionsVote = (payload) => {
  const URL = "/lesson/slidetype/poll/option/vote/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCurriculumLanguageLessonSlideTypePollOptionsVote = (payload) => {
  const URL = "/lesson/slidetype/poll/option/vote/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveVideos = (payload) => {
  const URL = "/videos/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateVideos = (payload) => {
  const URL = "/videos/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readVideos = (payload) => {
  const URL = "/videos/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteVideos = (payload) => {
  const URL = "/videos/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageVideos = (payload) => {
  const URL = "/videos/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageVideos = (payload) => {
  const URL = "/videos/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguageVideos = (payload) => {
  const URL = "/videos/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguageVideos = (payload) => {
  const URL = "/videos/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveVideosUrls = (payload) => {
  const URL = "/videos/urls/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCommonsUrls = (payload) => {
  const URL = "/videos/generictumbnailurls/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveVideoLanguag = (payload) => {
  const URL = "/videos/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCategoriesLanguage = (payload) => {
  const URL = "/categories/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateCategoriesLanguage = (payload) => {
  const URL = "/categories/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateVideoLanguage = (payload) => {
  const URL = "/videos/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveVideoUrlLanguage = (payload) => {
  const URL = "/videos/urls/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateVideoUrlLanguage = (payload) => {
  const URL = "/videos/urls/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveDownloadVideoUrlLanguage = (payload) => {
  const URL = "/videos/downloadurls/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateDownloadVideoUrlLanguage = (payload) => {
  const URL = "/videos/downloadurls/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateCommonsUrls = (payload) => {
  const URL = "/videos/generictumbnailurls/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateVideosUrls = (payload) => {
  const URL = "/videos/urls/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readVideosUrls = (payload) => {
  const URL = "/videos/urls/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteVideosUrls = (payload) => {
  const URL = "/videos/urls/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageVideosUrls = (payload) => {
  const URL = "/videos/urls/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageVideosUrls = (payload) => {
  const URL = "/videos/urls/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguageVideosUrls = (payload) => {
  const URL = "/videos/urls/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguageVideosUrls = (payload) => {
  const URL = "/videos/urls/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveVideosDownloadUrls = (payload) => {
  const URL = "/videos/downloadurls/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateVideosDownloadUrls = (payload) => {
  const URL = "/videos/downloadurls/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readVideosDownloadUrls = (payload) => {
  const URL = "/videos/downloadurls/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteVideosDownloadUrls = (payload) => {
  const URL = "/videos/downloadurls/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageVideosDownloadUrls = (payload) => {
  const URL = "/videos/downloadurls/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageVideosDownloadUrls = (payload) => {
  const URL = "/videos/downloadurls/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguageVideosDownloadUrls = (payload) => {
  const URL = "/videos/downloadurls/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguageVideosDownloadUrls = (payload) => {
  const URL = "/videos/downloadurls/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveVideosThumbNailUrls = (payload) => {
  const URL = "/videos/tumbnailurls/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateVideosThumbNailUrls = (payload) => {
  const URL = "/videos/tumbnailurls/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readVideosThumbNailUrls = (payload) => {
  const URL = "/videos/tumbnailurls/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteVideosThumbNailUrls = (payload) => {
  const URL = "/videos/tumbnailurls/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguageVideosThumbNailUrls = (payload) => {
  const URL = "/videos/tumbnailurls/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguageVideosThumbNailUrls = (payload) => {
  const URL = "/videos/tumbnailurls/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguageVideosThumbNailUrls = (payload) => {
  const URL = "/videos/tumbnailurls/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguageVideosThumbNailUrls = (payload) => {
  const URL = "/videos/tumbnailurls/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveRelatedVideos = (payload) => {
  const URL = "/videos/related/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const readRelatedVideos = (payload) => {
  const URL = "/videos/related/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteRelatedVideos = (payload) => {
  const URL = "/videos/related/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCategories = (payload) => {
  const URL = "/categories/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCommonThumbnailCategories = (payload) => {
  const URL = "/categories/generictumbnailurls/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateCommonThumbnailCategories = (payload) => {
  const URL = "/categories/generictumbnailurls/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCategories = (payload) => {
  const URL = "/categories/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCategories = (payload) => {
  const URL = "/categories/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCategories = (payload) => {
  const URL = "/categories/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCategoriesIcon = (payload) => {
  const URL = "/categories/icon/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCategoriesIcon = (payload) => {
  const URL = "/categories/icon/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCategoriesIcon = (payload) => {
  const URL = "/categories/icon/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCategoriesIcon = (payload) => {
  const URL = "/categories/icon/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveCategoriesRelated = (payload) => {
  const URL = "/categories/related/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateCategoriesRelated = (payload) => {
  const URL = "/categories/related/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readCategoriesRelated = (payload) => {
  const URL = "/categories/related/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteCategoriesRelated = (payload) => {
  const URL = "/categories/related/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveLanguagesCategories = (payload) => {
  const URL = "/categories/lang/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const updateLanguagesCategories = (payload) => {
  const URL = "/categories/lang/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const readLanguagesCategories = (payload) => {
  const URL = "/categories/lang/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
export const deleteLanguagesCategories = (payload) => {
  const URL = "/categories/lang/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
