import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import CustomTable from "../../components/universal/CustomTable";
import { feedListHeaders, notificationsListHeaders } from "../NotificationList/Constants";
import Navbar from "../../components/universal/Navbar";
import { useDispatch } from "react-redux";
import { getFeedbackAction } from "../../store/Notifications/NotificationAction";
import Toastiy from "../../components/universal/Toastiy";
import NotificationsListHooks from "../NotificationList/hooks/NotificationsListHooks";
import SearchBar from "../../components/universal/SearchBar";
import RatingStars from "../../components/universal/RatingStars";
import close from "../../assets/icons/close.svg";

const Feedback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { value, onChangeSearchBar } = NotificationsListHooks();
  const [feedbackData, setFeedbackData] = useState([]);
  const [isUpdated, setIsUpdated] = useState(true);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [currentFeedbackDetails, setCurrentFeedbackDetails] = useState({});

  useEffect(() => {
    if (isUpdated) {
      dispatch(
        getFeedbackAction({
          callback: (res) => {
            if (res?.type === 1) {
              setFeedbackData(res?.data);
              setIsUpdated(false);
            }
          },
        })
      );
    }
  }, [isUpdated]);

  const data = [
    {
      user: "Testing 1",
      ratingNumber: 5,
      mostLiked: "Videos",
    },
    {
      user: "Testing 2",
      ratingNumber: 3,
      mostLiked: "Curriculums",
    },
    {
      user: "Testing 3",
      ratingNumber: 2,
      mostLiked: "Testimonies",
    },
    {
      user: "Testing 4",
      ratingNumber: 1,
      mostLiked: "Videos",
    },
    {
      user: "Testing 5",
      ratingNumber: 4,
      mostLiked: "Videos",
    },
  ];

  console.log("currentFeedbackDetails", currentFeedbackDetails);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium">Feedback List</p>
              {/* <div className="flex gap-4">
                <Link to={"/notificationsList/add"}>
                  <button className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl">
                    <AiOutlinePlus /> Feedback List
                  </button>
                </Link>
              </div> */}
            </div>

            {/* <div className="flex gap-4 flex-wrap">
              <SquareCard
                heading={"Total Notifications"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={"0"}
                trail={"Views"}
                percentage={"0%"}
                handleOnClick={() => {
                  console.log("clicked");
                }}
              />
              <SquareCard
                heading={"Notifications Sent Successfully"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={"0"}
                trail={"Views"}
                percentage={"0%"}
                handleOnClick={() => {
                  console.log("clicked");
                }}
              />
              <SquareCard
                heading={"Notifications Sent Failed"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={"0"}
                trail={"Views"}
                percentage={"0%"}
                handleOnClick={() => {
                  console.log("clicked");
                }}
              />
              <SquareCard
                heading={"Nofication noticed Successfully"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={"0"}
                trail={"Views"}
                percentage={"0%"}
                handleOnClick={() => {
                  console.log("clicked");
                }}
              />
              <SquareCard
                heading={"Notification Sent Unread"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={"0"}
                trail={"Views"}
                percentage={"0%"}
                handleOnClick={() => {
                  console.log("clicked");
                }}
              />
            </div> */}
            <div>
              {/* custom table with different data as per the selected carousel  */}
              <CustomTable
                width={"w-24"}
                // giving extra width to 2nd column
                nthChildWidth={
                  "[&>*:nth-child(1)]:w-40 [&>*:nth-child(2)]:w-40 [&>*:nth-child(3)]:w-48"
                }
                nthChildColor={
                  "[&>*:nth-child(2)]:text-greyish [&>*:nth-child(3)]:text-greyish [&>*:nth-child(4)]:text-greyish [&>*:nth-child(5)]:text-greyish"
                }
                nthChildStyle={"[&>*:nth-child(3)]:h-5 [&>*:nth-child(3)]:overflow-hidden"}
                data={feedbackData}
                headers={feedListHeaders}
                notificationTable={true}
                reverseTable={true}
                handleToggleClick={(row, isActive) => {
                  console.log(isActive, row);
                }}
                handleEyeClick={(row) => {
                  setShowFeedbackModal(true);
                  setCurrentFeedbackDetails(row);
                }}
                // handleEditClick={(row) => {}}
                heightDesktop={"h-[72vh]"}
                // handleDeleteClick={(row) => {}}
              />

              <Modal
                isOpen={showFeedbackModal}
                onRequestClose={() => {
                  setShowFeedbackModal((prev) => !prev);
                }}
                contentLabel="Custom Modal"
                className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
                ariaHideApp={false}
              >
                <div className="MODAL-BODY h-[95%] md:w-[40%] w-[90%] md:absolute rounded-2xl p-4 bg-blackishV2">
                  <div className="w-full h-full flex flex-col">
                    <div className="flex items-center justify-between mb-4">
                      <p className="text-white text-lg font-semibold">Feedback Details</p>
                      <div className="flex">
                        <div className="flex space-x-2">
                          <img
                            className="cursor-pointer"
                            onClick={() => {
                              setShowFeedbackModal((prev) => !prev);
                            }}
                            src={close}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="rounded-xl bg-blackish flex-1 overflow-y-auto p-4 flex flex-col justify-between">
                      <div>
                        <div className="">
                          <label
                            htmlFor="topicTitle"
                            className="text-[12px] text-greyish font-medium leading-4 "
                          >
                            Feedback
                          </label>
                          <p className="w-full text-white text-xl font-medium mt-2 leading-4">
                            {currentFeedbackDetails?.feedback}
                          </p>
                        </div>

                        <div className="mt-5">
                          <label
                            htmlFor="lesson_description"
                            className="text-[12px] text-greyish font-medium leading-4 "
                          >
                            Most Liked
                          </label>
                          <p className={`w-full text-white text-[14px] font-medium mt-2 leading-4`}>
                            {currentFeedbackDetails?.likedmost}
                          </p>
                        </div>
                        <div className="mt-5">
                          <label
                            htmlFor="lesson_description"
                            className="text-[12px] text-greyish font-medium leading-4 "
                          >
                            Rating
                          </label>
                          <p className={`w-full text-white text-[14px] font-medium mt-2 leading-4`}>
                            <RatingStars rating={parseFloat(4)} />
                          </p>
                        </div>
                        <div className="flex flex-col gap-3 mt-5">
                          <label
                            htmlFor="lesson_description"
                            className="text-[12px] text-greyish font-medium leading-4 "
                          >
                            User Details
                          </label>
                          <div className="flex items-center gap-3">
                            <div
                              style={{
                                backgroundImage: `url(${currentFeedbackDetails?.user?.[0]?.profilePicUrl})`,
                              }}
                              className="h-10 w-10 bg-black rounded-full bg-cover bg-no-repeat"
                            ></div>
                            <p className="text-white font-bold">
                              {currentFeedbackDetails?.user?.[0]?.firstName}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="py-1 w-full mt-14">
                        <div className="flex justify-end gap-5">
                          <button
                            className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                            onClick={() => {
                              setShowFeedbackModal((prev) => !prev);
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
