export const validateText = (value) => {
  return value?.trim().length > 0;
};
export const validateMobileNumber = (value) => {
  const phoneRegex = /^\d{10}$/; // Assuming a 10-digit phone number
  return phoneRegex.test(value);
};
export const validateCheckbox = (value) => {
  if (value) {
    return true;
  } else {
    return false;
  }
};
export const validateEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
};
export const validateDropdown = (selectedOption) => {
  if (!selectedOption) {
    return false;
  }
  // Add more validation conditions if needed
  return true; // Empty string means no validation error
};
