import React, { useEffect, useState } from "react";
import SearchBar from "../../components/universal/SearchBar";
import { allLanguagesData, allLanguagesHeaders, allUsersData, allUsersHeaders } from "./Constants";
import CustomTable from "../../components/universal/CustomTable";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineFilter } from "react-icons/hi";
import { AllUserHooks } from "./hooks/UsersHooks";
import Navbar from "../../components/universal/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getCMSUserAction, updateCMSUserAction } from "../../store/Users/UsersAction";
import Toastiy from "../../components/universal/Toastiy";
import CustomCarousel from "../../components/universal/CustomCarousel";
import SingleTab from "../../components/universal/SingleTab";

function AllUsers() {
  // search bar component state with handler
  const { value, onChangeSearchBar } = AllUserHooks();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isUpdated, setIsUpdated] = useState(true);
  const [userData, setUserData] = useState([]);
  const [allUserData, setAllUserData] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const { userInfo } = useSelector((state) => state.user);

  const handleToggleStatus = (row) => {
    dispatch(
      updateCMSUserAction({
        apiPayloadRequest: {
          _id: row?._id,
          firstName: row?.firstName || "",
          lastName: row?.lastName || "",
          mobile: row?.mobile || "",
          email: row?.email || "",
          language_prefference: row?.language_prefference || "",
          profilePicUrl: row?.profilePicUrl || "",
          active: !row?.active ? 1 : 0,
        },
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("User Status Updated!", 1);
            setIsUpdated(true);
          } else {
            Toastiy("User Status Failed To Updated!");
          }
        },
      })
    );
  };

  useEffect(() => {
    if (isUpdated) {
      dispatch(
        getCMSUserAction({
          callback: (res) => {
            if (res?.type === 1) {
              setUserData(res?.data);
              setAllUserData(res?.data);
              setIsUpdated(false);
            }
          },
        })
      );
    }
  }, [isUpdated]);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium">Users</p>
              <div className="flex gap-4">
                {/* <Link to={"/allLanguages"}> */}
                <button className="flex items-center gap-16 text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                  All
                  <HiOutlineFilter />
                </button>
                {/* </Link> */}
                <Link to={"/users/add"}>
                  {" "}
                  <button className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl">
                    <AiOutlinePlus /> Add CMS User
                  </button>
                </Link>
              </div>
            </div>
            <div className="">
              <CustomTable
                searchFor="Users"
                width={"w-24"}
                // giving extra width to 2nd column
                nthChildWidth={"[&>*:nth-child(1)]:w-40 [&>*:nth-child(3)]:w-40"}
                nthChildColor={
                  "[&>*:nth-child(2)]:text-greyish [&>*:nth-child(3)]:text-greyish [&>*:nth-child(4)]:text-greyish"
                }
                data={userData}
                headers={allUsersHeaders}
                reverseTable={true}
                handleToggleClick={(row, isActive) => {
                  console.log(isActive, row);
                  handleToggleStatus(row);
                }}
                handleEyeClick={(row) => {
                  console.log(row);
                  navigate("/users/" + row?._id, { state: { _id: row?._id } });
                }}
                {...(userInfo?.roleCode === "39stc8"
                  ? {
                      handleEditClick: (row) => {
                        console.log(row);
                        navigate("/users/add", { state: { _id: row?._id } });
                      },
                    }
                  : {})}
                heightDesktop={"h-[72vh]"}
                customTabs={
                  <CustomCarousel
                    className="ml-10"
                    rows={1}
                    cols={5}
                    gapX={12}
                    gapY={0}
                    // easier to pass this array like this rather than separating it
                    completeSliders={[
                      <SingleTab activeTab={activeTab} tab={"All"} />,
                      <SingleTab activeTab={activeTab} tab={"Super Admin"} />,
                      <SingleTab activeTab={activeTab} tab={"CMS Admin"} />,
                      <SingleTab activeTab={activeTab} tab={"Testimonial Admin"} />,
                      <SingleTab activeTab={activeTab} tab={"Curriculum Admin"} />,
                      <SingleTab activeTab={activeTab} tab={"App Users"} />,
                    ]}
                    numberOfCardsShownInSingleSlide={5}
                    handleCardOnClick={(card) => {
                      setActiveTab(card.props.tab);
                      switch (card.props.tab) {
                        case "All":
                          setUserData(allUserData);
                          break;
                        case "Super Admin":
                          setUserData(allUserData?.filter((d) => d?.roleCode === "39stc8"));
                          break;
                        case "CMS Admin":
                          setUserData(allUserData?.filter((d) => d?.roleCode === "67hbi5"));
                          break;
                        case "Testimonial Admin":
                          setUserData(allUserData?.filter((d) => d?.roleCode === "02wqsz1"));
                          break;
                        case "Curriculum Admin":
                          setUserData(allUserData?.filter((d) => d?.roleCode === "23yutr7"));
                          break;
                        case "App Users":
                          setUserData(
                            allUserData?.filter((d) => d?.roleCode === "49yrj0" || !d?.roleCode)
                          );
                          break;
                        // add other tabs here
                        default:
                          break;
                      }
                    }}
                    topNext={"top-3"}
                    topPrev={"top-9"}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllUsers;
