import Axios from "../../utils/axios";

//Get Country data
export const readTestimonyCategories = (payload) => {
    const URL = "/testimony/read/cat";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const readTestimony = (payload) => {
    const URL = "/testimony/read";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const acceptTestimony = (payload) => {
    const URL = "/testimony/activateordeactivate";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const rejectTestimony = (payload) => {
    const URL = "/testimony/activateordeactivate";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const testimonyOfTheDay = (payload) => {
    const URL = "/testimony/makeTestimonyOfTheDay";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};
