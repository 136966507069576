import * as actionTypes from "../ActionTypes";
import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import * as api from "./TWGAPI";
import { actionUpdateGlobalLoaderSagaAction } from "../GlobalLoader/GlobalLoaderAction";


function* saveTWGRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.saveTWG, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* getListTWGRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.getListTWG, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* updateTWGRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.updateTWG, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* savePromiseRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.savePromise, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* savePromiseLanguageRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.savePromiseLanguage, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* updatePromiseRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.updatePromise, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* updatePromiseLanguageRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.updatePromiseLanguage, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* deletePromiseRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.deletePromise, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* readPromiseRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.readPromise, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* savePromiseBgRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.savePromiseBg, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* updatePromiseBgRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.updatePromiseBg, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* deletePromiseBgRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.deletePromiseBg, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* savePromiseAudioRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.savePromiseAudio, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* savePromiseAudioLanguageRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.savePromiseAudioLanguage, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* updatePromiseAudioRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.updatePromiseAudio, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* updatePromiseAudioLanguageRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.updatePromiseAudioLanguage, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* deletePromiseAudioRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.deletePromiseAudio, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* savePromiseVideoRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.savePromiseVideo, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* savePromiseVideoLanguageRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.savePromiseVideoLanguage, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* updatePromiseVideoRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.updatePromiseVideo, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* updatePromiseVideoLanguageRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.updatePromiseVideoLanguage, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* deletePromiseVideoRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.deletePromiseVideo, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}


export default function* TWGWatcherSaga() {
    yield takeLatest(
        actionTypes.SAVE_TWG_REQUEST,
        saveTWGRequestSaga
    );

    yield takeLatest(
        actionTypes.GET_LIST_TWG_REQUEST,
        getListTWGRequestSaga
    );

    yield takeLatest(
        actionTypes.UPDATE_TWG_REQUEST,
        updateTWGRequestSaga
    );

    yield takeLatest(
        actionTypes.SAVE_PROMISE_REQUEST,
        savePromiseRequestSaga
    );

    yield takeLatest(
        actionTypes.SAVE_PROMISE_LANGUAGE_REQUEST,
        savePromiseLanguageRequestSaga
    );
    
    yield takeLatest(
        actionTypes.UPDATE_PROMISE_REQUEST,
        updatePromiseRequestSaga
    );

    yield takeLatest(
        actionTypes.UPDATE_PROMISE_LANGUAGE_REQUEST,
        updatePromiseLanguageRequestSaga
    );
    
    yield takeLatest(
        actionTypes.DELETE_PROMISE_REQUEST,
        deletePromiseRequestSaga
    );
    
    yield takeLatest(
        actionTypes.READ_PROMISE_REQUEST,
        readPromiseRequestSaga
    );
    
    yield takeEvery(
        actionTypes.SAVE_PROMISE_BG_REQUEST,
        savePromiseBgRequestSaga
    );
    
    yield takeEvery(
        actionTypes.UPDATE_PROMISE_BG_REQUEST,
        updatePromiseBgRequestSaga
    );
    
    yield takeEvery(
        actionTypes.DELETE_PROMISE_BG_REQUEST,
        deletePromiseBgRequestSaga
    );
    
    yield takeEvery(
        actionTypes.SAVE_PROMISE_AUDIO_REQUEST,
        savePromiseAudioRequestSaga
    );

    yield takeEvery(
        actionTypes.SAVE_PROMISE_AUDIO_LANGUAGE_REQUEST,
        savePromiseAudioLanguageRequestSaga
    );
    
    yield takeEvery(
        actionTypes.UPDATE_PROMISE_AUDIO_REQUEST,
        updatePromiseAudioRequestSaga
    );

    yield takeEvery(
        actionTypes.UPDATE_PROMISE_AUDIO_LANGUAGE_REQUEST,
        updatePromiseAudioLanguageRequestSaga
    );
    
    yield takeEvery(
        actionTypes.DELETE_PROMISE_AUDIO_REQUEST,
        deletePromiseAudioRequestSaga
    );
    
    yield takeEvery(
        actionTypes.SAVE_PROMISE_VIDEO_REQUEST,
        savePromiseVideoRequestSaga
    );

    yield takeEvery(
        actionTypes.SAVE_PROMISE_VIDEO_LANGUAGE_REQUEST,
        savePromiseVideoLanguageRequestSaga
    );
    
    yield takeEvery(
        actionTypes.UPDATE_PROMISE_VIDEO_REQUEST,
        updatePromiseVideoRequestSaga
    );

    yield takeEvery(
        actionTypes.UPDATE_PROMISE_VIDEO_LANGUAGE_REQUEST,
        updatePromiseVideoLanguageRequestSaga
    );
    
    yield takeEvery(
        actionTypes.DELETE_PROMISE_VIDEO_REQUEST,
        deletePromiseVideoRequestSaga
    );

}