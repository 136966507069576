import React from "react";
import LoginBg from "../../../../assets/images/langPreview4.png";
import Input from "../../../InputFields/Input";
import SignInButton from "../../../Buttons/SignInButton";

const Login = ({ fieldValues }) => {
  return (
    <>
      {/* Sign in */}
      <div
        className=" h-full overflow-y-auto no-scrollbar flex flex-col justify-end bg-cover bg-center"
        style={{ background: `url(${LoginBg})` }}
      >
        <div className="flex flex-col gap-6  items-center bg-black/70 px-2 py-3 m-1.5 rounded-md">
          <div className="flex flex-col items-center justify-between">
            {/* <img className="opacity-40" src={logo} alt="" /> */}
            <p className="text-2xl font-semibold text-yellowish">{fieldValues?.loginId_lbl}</p>
          </div>

          <div className="flex flex-col gap-1 w-full items-center">
            <Input
              placeholder={"Email or phone"}
              value={fieldValues.email_phone_lbl}
              inputClass="bg-transparent"
            />
            <Input
              placeholder={"Password"}
              value={fieldValues.password_lbl}
              inputClass="bg-transparent"
            />
            {/* <p className='text-yellowish text-[10px] opacity-60 font-semibold'>{fieldValues?.forgot_Password_lbl}</p> */}
          </div>

          <div className="w-full px-5 flex flex-col gap-1 items-center">
            <SignInButton Label={fieldValues.continue_btn} style={"bg-yellow-300"} />

            <SignInButton style="bg-yellow-300" Label={fieldValues?.continue_with_google_btn} />
            <SignInButton style="bg-yellow-300" Label={fieldValues?.continue_with_facebook_btn} />
            <SignInButton style="bg-yellow-300" Label={fieldValues?.continue_with_apple_btn} />
            <p className="text-white text-[10px] py-3 font-semibold">
              Don't have an account?
              <span className="text-yellowish"> Sign Up</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
