import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import bell from "../../assets/icons/bell.svg";
import avatar from "../../assets/icons/avatar.svg";
import { useNavigate } from "react-router-dom";
import Cross from "../../assets/icons/Cross.svg";
import { useAuth0 } from "@auth0/auth0-react";
function SearchBar({ placeholder, value, onChangeSearchBar }) {

  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const { isAuthenticated, logout } = useAuth0();

  const onLogout = () => {
    console.log("Logged out");
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  }

  return (
    <div className="w-full flex  items-center justify-between mb-4">
      <div className="relative">
        {/* <BsSearch className="text-white fill-[#909090] absolute top-3 left-3" />
        <input
          className="pl-8 text-[#e3e3e3] text-sm placeholder:text-sm placeholder:text-[#909090] md:w-80 bg-[#131313] p-2 border border-greyishV2 border-opacity-50 rounded-lg"
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={(e) => { if(onChangeSearchBar) onChangeSearchBar(e) }}
        /> */}
      </div>
      <div className="flex gap-4">
        <img src={bell} alt="" />
        <div className="relative z-50">
          <img className="cursor-pointer" onClick={() => setShowMenu(preState => !preState)} src={avatar} alt="" />
          {
            showMenu &&
            <div className='absolute w-[380px] rounded-2xl bg-[#1F1F1F] right-0 top-16 shadow-xl p-6 z-[101]'>
              <div className='flex justify-between'>
                <p className='text-xs text-yellow-400 font-medium'>Menu</p>
                <img className='cursor-pointer' src={Cross} onClick={() => setShowMenu(preState => !preState)} />
              </div>
              <ul className='pt-6 flex flex-col gap-4'>
                <li
                  className='text font-normal text-white text-opacity-50 hover:text-opacity-100 cursor-pointer'
                  onClick={() => {
                    if(isAuthenticated){
                      navigate("/profile");
                      setShowMenu(preState => !preState);
                    }else{
                      navigate("/login");
                    }
                  }}
                >
                  My Profile
                </li>
                <li className='text font-normal text-white text-opacity-50 hover:text-opacity-100 cursor-pointer' onClick={() => onLogout()}>Logout</li>
              </ul>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
