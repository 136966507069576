import { useEffect, useState } from "react";
import {
  validateDropdown,
  validateText,
} from "../../../components/InputFields/ValidationFunctions";
import {
  readLanguageAction,
  translateLanguageAction,
  uploadAssestsAction,
  uploadVideosAction,
} from "../../../store/Languages/LanguageAction";
import { useDispatch } from "react-redux";
import {
  getListOfNotificationsAction,
  sendNotificationAction,
  updatedNotificationsAction,
} from "../../../store/Notifications/NotificationAction";
import Toastiy from "../../../components/universal/Toastiy";

const NotificationsListHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

export default NotificationsListHooks;

export const AddNotificationsListHooks = (id) => {
  // search logic here
  const [value, setValue] = useState("");
  const [selected, setSelected] = useState("en");
  const dispatch = useDispatch();

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };
  // add category logic here

  const languages = ["english", "hindi", "tamil"]; // List of languages
  const [activeTab, setActiveTab] = useState(languages[0]); // Set the first language as active
  const [inputs, setInputs] = useState(
    languages.reduce((acc, lang) => {
      acc[lang] = {
        categoryName: "",
        categoryCode: "",
        categoryIcon: null,
        mobileLayout: "horizontal",
        categoryDescription: "",
        sendTo: "vertical",
      };
      return acc;
    }, {})
  );

  const initialNotificationValues = {
    notification_title: "",
    notification_description: "",
    notification_icon_url: "",
    notification_schedule_time: new Date(),
  };

  const [notificationInputs, setNotificationInputs] = useState(
    initialNotificationValues
  );

  const handleChange = (event) => {
    const { name, value } = event?.target;

    setNotificationInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTime = (value) => {
    if (id !== undefined) {
      dispatch(
        updatedNotificationsAction({
          apiPayloadRequest: {
            _id: id,
            notification_schedule_time: value,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setNotificationInputs((prev) => ({
                ...prev,
                notification_schedule_time: value,
              }));

              Toastiy("Updated the schedule!", 1);
            } else {
              Toastiy("Failed to update the schedule!");
            }
          },
        })
      );
    } else {
      setNotificationInputs((prev) => ({
        ...prev,
        notification_schedule_time: value,
      }));
    }
  };

  const handleFileUpload = (event, type) => {
    const { name, files } = event.target;
    const formData = new FormData();
    formData.append("file", files[0]);
    if (type === "Image") {
      dispatch(
        uploadAssestsAction({
          apiPayloadRequest: formData,
          callback: (res) => {
            setNotificationInputs((prevInputs) => ({
              ...prevInputs,
              notification_icon_url: res?.url,
            }));
          },
        })
      );
    } else {
      dispatch(
        uploadVideosAction({
          apiPayloadRequest: formData,
          callback: (res) => {
            setNotificationInputs((prevInputs) => ({
              ...prevInputs,
              notification_icon_url: res?.url,
            }));
          },
        })
      );
    }
  };

  const handleInputChange = (language, field, value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [language]: {
        ...prevInputs[language],
        [field]: value,
      },
    }));
  };

  const handleFileInputChange = (language, field, file) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [language]: {
        ...prevInputs[language],
        [field]: file,
      },
    }));
  };

  const handleCategoryDescriptionChange = (e) => {
    handleInputChange(activeTab, "categoryDescription", e.target.value);
  };

  const handleSave = () => {
    // Implement your save logic here
    dispatch(
      sendNotificationAction({
        apiPayloadRequest: notificationInputs,
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("Notication Saved Successfully", 1);
            setNotificationInputs(initialNotificationValues);
          } else {
            Toastiy("Notication Save Failed");
          }
        },
      })
    );
  };

  const combineStateKeys = () => {
    const combinedString = `${notificationInputs?.notification_title}" @@@@ "${notificationInputs?.notification_description}`;
    return combinedString;
  };

  const updateStateWithTranslation = () => {
    const combinedText = combineStateKeys();

    dispatch(
      translateLanguageAction({
        apiPayloadRequest: {
          targetcode: selected,
          translatetext: combinedText,
        },
        callback: (res) => onTranslateSuccess(res?.data),
      })
    );
  };

  const onTranslateSuccess = (data) => {
    const arrayOfValues = data.split(" @@@@ ");

    setNotificationInputs((preState) => ({
      ...preState,
      notification_title: arrayOfValues[0],
      notification_description: arrayOfValues[1],
    }));
  };

  useEffect(() => {
    if (id !== undefined) {
      dispatch(
        getListOfNotificationsAction({
          apiPayloadRequest: {
            _id: id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setNotificationInputs({
                notification_title: res?.data[0]?.notification_title,
                notification_description:
                  res?.data[0]?.notification_description,
                notification_icon_url: res?.data[0]?.notification_icon_url,
                notification_schedule_time:
                  res?.data[0]?.notification_schedule_time,
              });
            }
          },
        })
      );
    }
  }, []);

  return {
    value,
    onChangeSearchBar,
    languages,
    activeTab,
    setActiveTab,
    inputs,
    handleInputChange,
    handleFileInputChange,
    handleCategoryDescriptionChange,
    handleSave,
    notificationInputs,
    handleFileUpload,
    handleTime,
    handleChange,
    selected,
    setSelected,
    updateStateWithTranslation,
  };
};
