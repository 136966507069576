import { useEffect, useState } from "react";
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";
import searchIcon from "../../assets/icons/search.svg";

const LanguageDrawer = ({
  className = "",
  languageData,
  activeTab,
  setActiveTab,
  setActiveLanguageDetails,
  languagemeta,
  setIsLanguageSave,
}) => {
  const [languageDrawerCollapse, setLanguageDrawerCollapse] = useState(true);
  const [languageList, setLanguageList] = useState([]);

  const toggleLanguageDrawer = () => {
    setLanguageDrawerCollapse(!languageDrawerCollapse);
  };

  const searchLanguage = (e) => {
    const { value } = e.target;
    if (value) {
      const result = languageData?.filter((item) =>
        item?.language_name?.toLowerCase().includes(value?.toLowerCase())
      );
      setLanguageList(result);
      return;
    }
    setLanguageList(languageData);
  };

  useEffect(() => {
    if (languageData) {
      setLanguageList(languageData);
    }
  }, [languageData]);

  return (
    <div
      className={`${
        languageDrawerCollapse ? "w-[13%] md:w-[7%]" : "w-[25%] md:w-[25%]"
      } relative bg-greyishV2 bg-opacity-50 ${className}`}
    >
      {languageDrawerCollapse ? (
        <IoIosArrowDroprightCircle
          className="text-yellowish cursor-pointer text-2xl absolute right-0 top-6 translate-x-1/2"
          onClick={toggleLanguageDrawer}
        />
      ) : (
        <IoIosArrowDropleftCircle
          className="text-yellowish cursor-pointer text-2xl absolute right-0 top-6 translate-x-1/2"
          onClick={toggleLanguageDrawer}
        />
      )}

      <div className="h-[64px] flex items-center justify-center relative">
        {!languageDrawerCollapse && (
          <input
            placeholder={languageDrawerCollapse ? "Search" : "Search languages"}
            className="outline-none text-sm font-medium bg-transparent  text-greyish w-full pl-10 p-4"
            type="text"
            onChange={searchLanguage}
          />
        )}
        <img
          className={`${!languageDrawerCollapse ? "absolute" : ""} top-6 left-4`}
          src={searchIcon}
          alt=""
          onClick={() => {
            if (languageDrawerCollapse) {
              toggleLanguageDrawer();
            }
          }}
        />
      </div>
      <div className="TAB-LIST">
        {languageList?.map((lang, index) => (
          <div
            className={`${
              activeTab === lang?.language_code
                ? "text-black bg-yellowish font-semibold"
                : "font-medium text-greyish"
            } text-[13px] cursor-pointer p-4 flex items-center justify-center relative`}
            key={index}
            onClick={() => {
              if (setActiveLanguageDetails) setActiveLanguageDetails(lang);
              setActiveTab(lang?.language_code);
              if (
                languagemeta?.some((language) => language?.language_code === lang?.language_code)
              ) {
                setIsLanguageSave(false);
              } else {
                setIsLanguageSave(true);
              }
            }}
          >
            {lang?.status !== 1 && lang?.language_code !== "en" && (
              <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[10px] font-semibold text-red-500">
                Inactive
              </div>
            )}

            <span title={lang?.language_name}>
              {languageDrawerCollapse ? lang?.language_code : lang?.language_name}
            </span>

            {languagemeta?.some((language) => language?.language_code === lang?.language_code) ||
            lang?.language_code === "en" ? (
              <div className="absolute right-2 -translate-y-1/2 top-1/2 h-2 md:h-2.5 w-2 md:w-2.5 bg-green-500 rounded-full"></div>
            ) : (
              <div className="absolute right-2 -translate-y-1/2 top-1/2 h-2 md:h-2.5 w-2 md:w-2.5 bg-red-500 rounded-full"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguageDrawer;
