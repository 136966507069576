import React, { useState } from 'react';
import Select from 'react-select';

const DropdownWithTags = ({ selectedOptions, options, handleChange }) => {


    return (
        <div className="mt-2">
            <Select
                options={options}
                isMulti
                onChange={handleChange}
                value={selectedOptions}
                components={{
                    Option: CustomOption,
                }}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        width: "100%", // Set the width as needed
                        background: "#191919",
                        border: "none",
                        minHeight: "40px",
                    }),
                    menu: (provided) => ({
                        ...provided,
                        background: "powerblue",
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        color: 'white',
                        backgroundColor: state.isFocused ? "#6a6a6a" : "black", // Change background color on hover
                        "&:hover": {
                            backgroundColor: "#6a6a6a",
                            // Change background color on hover
                        },
                    }),
                    input: (provided) => ({
                        ...provided,
                        color: "white", // Change the text color of the input
                    }),
                }}
            />
        </div>
    );
};

export default DropdownWithTags;

const CustomOption = ({ innerProps, label, data }) => (

    <div {...innerProps} className='p-2 flex justify-between items-center hover:bg-slate-200'>
        <p className='font-bold'>{label}</p>
        <img src={data.image} alt={label} className="h-20 rounded-md" />
    </div>
);
