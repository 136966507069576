import React from "react";
import TextInputField from "../InputFields/TextInputField";
import DropdownField from "../InputFields/DropDown";
import UploadVideo from "../videos/UploadVideo";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import DateTimePicker from "react-datetime-picker";
import { FaInfoCircle } from "react-icons/fa";

function CustomUserFields({ data, onSave, onCancel }) {
  return (
    <div className="h-full w-full mt-4 flex flex-col">
      <div className="h-[64vh] overflow-auto">
        <div className="grid md:grid-cols-2 grid-cols-1 grid-rows-1 gap-x-4 gap-y-4 mx-1 mb-5 w-full">
          {data.map((field, i) => {
            switch (field.type) {
              case "text":
                return (
                  <div key={field.name}>
                    <TextInputField
                      tooltipId="add_user"
                      tooltipText={"Enter " + field?.label}
                      labelText={field?.label}
                      placeholder={field?.placeholder}
                      value={field?.value}
                      htmlFor={field.htmlFor}
                      name={field?.name}
                      handleInputChange={field?.handleInputChange}
                      validateFunction={field?.validateFunction}
                    />
                  </div>
                );
              case "phone":
                return (
                  <div key={field.name}>
                    <TextInputField
                      tooltipId="add_user"
                      tooltipText={"Enter Your Phone Number"}
                      labelText={field?.label}
                      placeholder={field?.placeholder}
                      value={field?.value}
                      htmlFor={field.htmlFor}
                      name={field?.name}
                      handleInputChange={field?.handleInputChange}
                      validateFunction={field?.validateFunction}
                      isPhoneInput={true}
                    />
                  </div>
                );
              case "image":
                return (
                  <div className="col-span-2" key={field.name}>
                    <UploadVideo
                      tooltipId="add_user"
                      tooltipText={"Select your profile picture to upload"}
                      name={"mobileUploadUrl"}
                      forScreen={field?.type}
                      state={field?.value}
                      setState={field?.handleInputChange}
                    />
                  </div>
                );
              case "dropdown":
                return (
                  <div className="" key={field.name}>
                    <DropdownField
                      tooltipId="add_user"
                      tooltipText={"Select " + field?.label}
                      value={field?.value}
                      labelText={field?.label}
                      placeholder={field?.placeholder}
                      options={field?.options}
                      htmlFor={field.htmlFor}
                      name={field?.name}
                      defaultValue={field?.defaultValue}
                      handleInputChange={field?.handleInputChange}
                      validateFunction={field?.validateFunction}
                    />
                  </div>
                );
              case "date":
                return (
                  <div className="" key={field.name}>
                    <p className="text-[12px] font-semibold text-greyish mb-1.5 pt-1 flex items-center gap-2">
                      {field?.label}
                      <FaInfoCircle
                        data-tooltip-id="add_user"
                        data-tooltip-content={"Select " + field?.label}
                      />
                    </p>
                    <div>
                      <DateTimePicker
                        className={"w-full"}
                        value={field?.value}
                        onChange={field?.handleInputChange}
                        defaultValue={field?.defaultValue}
                      />
                    </div>
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
      </div>
      <Tooltip id="add_user" className="border border-greyish border-opacity-20" />
    </div>
  );
}

export default CustomUserFields;
