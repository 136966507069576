import Axios from "../../utils/axios";

export const saveLayout = (payload) => {
    const URL = "/layout/save";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const layoutDashboard = (payload) => {
    const URL = "/layouts/minidash";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const videosDashboard = (payload) => {
    const URL = "/videos/minidash";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const deleteLayout = (payload) => {
    const URL = "/layout/delete";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const updateLayout = (payload) => {
    const URL = "/layout/update";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const readLayout = (payload) => {
    const URL = "/layout/read";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const saveCategoryLayout = (payload) => {
    const URL = "/layout/categories/save";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const deleteCategoryLayout = (payload) => {
    const URL = "/layout/categories/delete";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const readCategoryLayout = (payload) => {
    const URL = "/layout/categories/read";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const saveCurriculumLayout = (payload) => {
    const URL = "/layout/curriculum/save";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const updateCurriculumLayout = (payload) => {
    const URL = "/layout/curriculum/update";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const deleteCurriculumLayout = (payload) => {
    const URL = "/layout/curriculum/delete";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};


export const readCurriculumLayout = (payload) => {
    const URL = "/layout/curriculum/read";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const saveVideoLayout = (payload) => {
    const URL = "/layout/videos/save";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const updateVideoLayout = (payload) => {
    const URL = "/layout/videos/update";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const readVideoLayout = (payload) => {
    const URL = "/layout/videos/read";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const deleteVideoLayout = (payload) => {
    const URL = "/layout/videos/delete";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

