import dashboardIcon from "../assets/icons/navIcons/dashboardIcon.svg";
import languageIcon from "../assets/icons/navIcons/languageIcon.svg";
import layoutIcon from "../assets/icons/navIcons/layoutIcon.svg";
import categoriesIcon from "../assets/icons/navIcons/categoriesIcon.svg";
import userIcon from "../assets/icons/navIcons/userIcon.svg";
import videosIcon from "../assets/icons/navIcons/videosIcon.svg";
import curriculumIcon from "../assets/icons/navIcons/curriculumIcon.svg";
import testimonialsIcon from "../assets/icons/navIcons/testimonialsIcon.svg";
import givingsIcon from "../assets/icons/navIcons/givingsIcon.svg";
import notificationsListIcon from "../assets/icons/navIcons/notificationsListIcon.svg";
import prayersIcon from "../assets/icons/navIcons/prayersIcon.svg";
import feedbackIcon from "../assets/icons/navIcons/feedbackIcon.svg";
import feedbackHoverIcon from "../assets/icons/navIcons/feedbackHoverIcon.svg";
import twgIcon from "../assets/icons/navIcons/twgIcon.svg";
import twgHoverIcon from "../assets/icons/navIcons/twgHoverIcon.svg";

export const iconArray = [
  {
    icon: dashboardIcon,
    route: "/",
    title: "Dashboard",
  },
  {
    icon: languageIcon,
    route: "/languages",
    title: "Languages",
  },
  {
    icon: layoutIcon,
    route: "/layout",
    title: "Layouts",
  },
  {
    icon: categoriesIcon,
    route: "/categories",
    title: "Categories",
  },
  {
    icon: userIcon,
    route: "/users",
    title: "Users",
  },
  {
    icon: videosIcon,
    route: "/videos",
    title: "Videos",
  },
  {
    icon: curriculumIcon,
    route: "/curriculum",
    title: "Curriculum",
  },
  {
    icon: testimonialsIcon,
    route: "/testimonials",
    title: "Testimonials",
  },
  {
    icon: givingsIcon,
    route: "/givings",
    title: "Givings",
  },
  {
    icon: notificationsListIcon,
    route: "/notificationsList",
    title: "Notifications List",
  },
  {
    icon: twgIcon,
    hover: twgHoverIcon,
    route: "/twg",
    title: "Today With God",
  },
  {
    icon: prayersIcon,
    route: "/prayers",
    title: "Prayers",
  },
  {
    icon: feedbackIcon,
    hover: feedbackHoverIcon,
    route: "/feedback",
    title: "Feedback",
  },
  {
    icon: dashboardIcon,
    route: "/analytics",
    title: "Analytics",
  },
];

export const superAdminIconArray = [
  {
    icon: dashboardIcon,
    route: "/",
    title: "Dashboard",
  },
  {
    icon: languageIcon,
    route: "/languages",
    title: "Languages",
  },
  {
    icon: layoutIcon,
    route: "/layout",
    title: "Layouts",
  },
  {
    icon: categoriesIcon,
    route: "/categories",
    title: "Categories",
  },
  {
    icon: userIcon,
    route: "/users",
    title: "Users",
  },
  {
    icon: videosIcon,
    route: "/videos",
    title: "Videos",
  },
  {
    icon: curriculumIcon,
    route: "/curriculum",
    title: "Curriculum",
  },
  {
    icon: testimonialsIcon,
    route: "/testimonials",
    title: "Testimonials",
  },
  {
    icon: givingsIcon,
    route: "/givings",
    title: "Givings",
  },
  {
    icon: notificationsListIcon,
    route: "/notificationsList",
    title: "Notifications List",
  },
  {
    icon: twgIcon,
    hover: twgHoverIcon,
    route: "/twg",
    title: "Today With God",
  },
  {
    icon: prayersIcon,
    route: "/prayers",
    title: "Prayers",
  },
  {
    icon: feedbackIcon,
    hover: feedbackHoverIcon,
    route: "/feedback",
    title: "Feedback",
  },
  {
    icon: dashboardIcon,
    route: "/analytics",
    title: "Analytics",
  },
];

export const cmsAdminIconArray = [
  {
    icon: dashboardIcon,
    route: "/",
    title: "Dashboard",
  },
  {
    icon: languageIcon,
    route: "/languages",
    title: "Languages",
  },
  {
    icon: layoutIcon,
    route: "/layout",
    title: "Layouts",
  },
  {
    icon: categoriesIcon,
    route: "/categories",
    title: "Categories",
  },
  {
    icon: userIcon,
    route: "/users",
    title: "Users",
  },
  {
    icon: videosIcon,
    route: "/videos",
    title: "Videos",
  },
  {
    icon: curriculumIcon,
    route: "/curriculum",
    title: "Curriculum",
  },
  {
    icon: testimonialsIcon,
    route: "/testimonials",
    title: "Testimonials",
  },
  {
    icon: givingsIcon,
    route: "/givings",
    title: "Givings",
  },
  {
    icon: notificationsListIcon,
    route: "/notificationsList",
    title: "Notifications List",
  },
  {
    icon: twgIcon,
    hover: twgHoverIcon,
    route: "/twg",
    title: "Today With God",
  },
  {
    icon: prayersIcon,
    route: "/prayers",
    title: "Prayers",
  },
  {
    icon: feedbackIcon,
    hover: feedbackHoverIcon,
    route: "/feedback",
    title: "Feedback",
  },
];

export const testimonialAdminIconArray = [
  {
    icon: testimonialsIcon,
    route: "/testimonials",
    title: "Testimonials",
  },
  {
    icon: userIcon,
    route: "/users",
    title: "Users",
  },
];

export const curriculumAdminIconArray = [
  {
    icon: curriculumIcon,
    route: "/curriculum",
    title: "Curriculum",
  },
  {
    icon: userIcon,
    route: "/users",
    title: "Users",
  },
];

export const navActiveCss = "bg-yellowish rounded-lg custom-stroke-black";
export const navHoverCss =
  "hover:bg-yellowish rounded-lg  custom-hover-stroke-black custom-stroke-grey";
