import React, { useState } from "react";
import infoCircle from "../../assets/icons/infocircle.svg";
import reload from "../../assets/icons/reload.svg";
import CustomTabsV2 from "../universal/CustomTabsV2";
import jesus from "../../assets/images/jesus.png";
import edit from "../../assets/icons/edit.svg";
import editV2 from "../../assets/icons/editV2.svg";
import close from "../../assets/icons/close.svg";
import story from "../../assets/icons/story.svg";
import { AddCurriculumHooks } from "../../screens/Curriculum/hooks/CurriculumHooks";
import Modal from "react-modal";
import StoryButtons from "./StoryButtons";
import StoryPreview from "./StoryPreview";
import { useLocation } from "react-router-dom";
import MyStatefulEditor from "../universal/MyStatefulEditor";
import LayoutDropdown from "../universal/LayoutDropdown";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { SketchPicker } from "react-color";
import moment from "moment";
import UploadVideo from "../videos/UploadVideo";
import { acceptImage, acceptVideo } from "../../constants/ValidationConstants";
import ReactModal from "react-modal";
import { ImCross } from "react-icons/im";
import { CurriculumHooksNew } from "../../screens/Curriculum/hooks/CurriculumHookNew";
import { AiOutlineEdit } from "react-icons/ai";
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";
import LanguageDrawer from "../universal/LanguageDrawer";
import { BsPlus } from "react-icons/bs";
import LessonPreviewCard from "./LessonPreviewCard";
import UploadControls from "../TWG/UploadControls";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";

const AddCurriculumFields = ({ setLoading }) => {
  const location = useLocation();
  const [curriculumId, setCurriculumId] = useState(location?.state?._id);

  const {
    curriculumValues,
    handleChange,
    addNewLesson,
    currLessonIndex,
    addNewSlide,
    currSlideIndex,
    setCurrLesson,
    currLesson,
    cancelLesson,
    saveLesson,
    setShowLessonBox,
    showLessonBox,
    setCurrLessonIndex,
    setIsLessonEdit,
    initialLessonState,
    showSlideBox,
    initialSlideState,
    cancelSlideModal,
    cancelCurriculum,
    showPreview,
    storyData,
    handleClosePreview,
    handleOpenPreview,
    saveSlide,
    setIsSlideEdit,
    setCurrSlide,
    setCurrSlideIndex,
    setShowSlideBox,
    handleSlideBgChange,
    saveCurriculum,
    curriculumErrors,
    lessonErrors,
    slideErrors,
    showCurriculumErrors,
    showLessonErrors,
    showSlideErrors,
    setShowCurriculumErrors,
    setShowLessonErrors,
    setShowSlideErrors,
    setLayoutValues,
    setLayoutInfo,
    setAddedLayout,
    addedLayout,
    editTargetLayout,
    isLayoutEdit,
    layoutInfo,
    layoutValues,
    initialLayoutValues,
    handleVideoLayoutChange,
    handleVideoDateChange,
    handleLayoutUpdate,
    handleAddLayoutToArray,
    layoutArray,
    setIsLayoutEdit,
    setEditLayoutIndex,
    setEditTargetLayout,
    readLanguageData,
    setActiveTab,
    activeTab,
    setIsLanguageSave,
    languagemeta,
    updateStateWithTranslation,
    setShowCurriculumBox,
    showCurriculumBox,
    isLessonEdit,
    isSlideEdit,
    validateSlideComplete,
    validateLessonComplete,
    validateCurriculumComplete,
    handlePublishCurriculum,
    handleDeleteSlide,
    handleDeleteLesson,
    handleSaveLanguage,
    activeLanguageDetails,
    setActiveLanguageDetails,
  } = CurriculumHooksNew(setLoading, curriculumId, setCurriculumId);

  const [showModal, setShowModal] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  return (
    <div className="flex h-full w-full flex-1 rounded-2xl overflow-hidden">
      <div className="flex h-full flex-1">
        <LanguageDrawer
          languageData={readLanguageData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          languagemeta={languagemeta}
          setIsLanguageSave={setIsLanguageSave}
          setActiveLanguageDetails={setActiveLanguageDetails}
        />
        <div className="flex h-full flex-1">
          <div className="flex flex-col h-full w-full row-span-1 bg-greyishV2 bg-opacity-50 md:border-l md:border-r border-greyish border-opacity-30">
            <div className="flex items-center justify-end py-3">
              {activeTab === "en" &&
                !(
                  curriculumValues?.curriculum_name || curriculumValues?.curriculum_description
                ) && (
                  <button
                    className="inline-flex items-center text-yellowish bg-blackishV2 font-medium text-[12px] border border-yellowish rounded-xl py-3 px-6 mx-1.5"
                    onClick={() => {
                      setShowCurriculumBox(true);
                    }}
                  >
                    <BsPlus className="text-lg" /> Add Curriculum Details
                  </button>
                )}
              {!showLessonBox && activeTab === "en" && (
                <button
                  className="inline-flex items-center text-yellowish bg-blackishV2 font-medium text-[12px] border border-yellowish rounded-xl py-3 px-6 mx-1.5"
                  onClick={() => {
                    setCurrLessonIndex(curriculumValues?.lessons.length - 1);
                    setShowLessonBox((prev) => !prev);
                    addNewLesson();
                    setCurrLesson({ ...initialLessonState });
                    setIsLessonEdit(false);
                  }}
                >
                  <BsPlus className="text-lg" /> Add Lesson
                </button>
              )}

              {activeTab === "en" ? (
                <button
                  className={`w-fit text-black font-medium text-[12px] border mx-1.5  py-3 px-12 rounded-xl ${
                    validateCurriculumComplete(curriculumValues) &&
                    curriculumValues?.publish !== "true" &&
                    curriculumId
                      ? "bg-yellowish border-yellowish hover:border-yellowish hover:bg-yellowish"
                      : "bg-yellowish/40 border-yellowish/40"
                  }`}
                  {...(validateCurriculumComplete(curriculumValues) &&
                  curriculumValues?.publish !== "true" &&
                  curriculumId
                    ? {}
                    : {
                        "data-tooltip-id": "publish_curriculum",
                        "data-tooltip-content": `${
                          curriculumValues?.publish === "true" ? "Update" : "Complete"
                        } Curriculum to publish`,
                        disabled: true,
                      })}
                  onClick={() => {
                    console.log("curriculumErrors", curriculumErrors);
                    if (
                      !curriculumErrors.curriculum_name &&
                      !curriculumErrors.curriculum_description &&
                      !curriculumErrors.bg_Images[0].url_link &&
                      !curriculumErrors.bg_Images[1].url_link &&
                      !curriculumErrors.bg_Images[2].url_link
                    ) {
                      handlePublishCurriculum();
                    } else {
                      setShowCurriculumErrors(true);
                    }
                  }}
                >
                  {!curriculumValues?.publish ? "Publish Curriculum" : "Update Publish"}
                </button>
              ) : (
                <button
                  className={`w-fit text-black font-medium text-[12px] border mx-1.5  py-3 px-12 rounded-xl ${
                    validateCurriculumComplete(curriculumValues) &&
                    curriculumValues?.publish !== "true" &&
                    curriculumId
                      ? "bg-yellowish border-yellowish hover:border-yellowish hover:bg-yellowish"
                      : "bg-yellowish/40 border-yellowish/40"
                  }`}
                  {...(validateCurriculumComplete(curriculumValues) &&
                  curriculumValues?.publish !== "true" &&
                  curriculumId
                    ? {}
                    : {
                        "data-tooltip-id": "publish_curriculum",
                        "data-tooltip-content": `${
                          curriculumValues?.publish === "true" ? "Update" : "Complete"
                        } Curriculum to Translate`,
                        disabled: true,
                      })}
                  onClick={handleSaveLanguage}
                >
                  Save Language
                </button>
              )}
              <Tooltip
                id="publish_curriculum"
                className="border border-greyish border-opacity-20"
              />
            </div>
            {activeTab !== "en" && !curriculumId ? (
              <div className="flex items-center justify-center text-white gap-1 py-28">
                Curriculum translation in{" "}
                <span className="italic">{activeLanguageDetails?.language_name}</span> is available
                for saved curriculums
              </div>
            ) : (
              <div className="pl-12 pr-4 pt-8 flex flex-col overflow-auto">
                <div className="flex-1 flex flex-col gap-6 relative">
                  {(curriculumValues?.curriculum_name ||
                    curriculumValues?.curriculum_description) && (
                    <div className="flex flex-col gap-2 relative">
                      <p className="text-2xl font-white font-medium text-white flex items-center">
                        {curriculumValues?.curriculum_name}{" "}
                        <button
                          className="text-yellow-500 text-[10px] h-fit ml-4 px-3 border border-yellow-500 rounded-md"
                          onClick={() => {
                            setShowCurriculumBox(true);
                          }}
                        >
                          Edit
                        </button>
                      </p>
                      <div className="text-greyish font-medium ">
                        {curriculumValues?.curriculum_description}
                        <div className="bg-yellowish h-5 w-5 absolute -left-8 top-0 rounded-full"></div>
                      </div>

                      <div className="grid grid-cols-3 gap-2 justify-between">
                        {curriculumValues?.bg_Images?.[0]?.url_link && (
                          <img
                            className="aspect-[16/9] object-cover object-top rounded-md"
                            src={curriculumValues?.bg_Images?.[0]?.url_link}
                            alt=""
                          />
                        )}
                        {curriculumValues?.bg_Images?.[1]?.url_link && (
                          <img
                            className="aspect-[16/9] object-cover object-top rounded-md"
                            src={curriculumValues?.bg_Images?.[1]?.url_link}
                            alt=""
                          />
                        )}
                        {curriculumValues?.bg_Images?.[2]?.url_link && (
                          <img
                            className="aspect-[16/9] object-cover object-top rounded-md"
                            src={curriculumValues?.bg_Images?.[2]?.url_link}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {curriculumValues?.lessons?.map(
                    (lesson, index) =>
                      index !== curriculumValues?.lessons?.length - 1 && (
                        <LessonPreviewCard
                          lesson={lesson}
                          key={lesson?.lessonObjId}
                          activeTab={activeTab}
                          addNewSlide={addNewSlide}
                          setCurrSlideIndex={setCurrSlideIndex}
                          curriculumValues={curriculumValues}
                          index={index}
                          setCurrLessonIndex={setCurrLessonIndex}
                          setIsSlideEdit={setIsSlideEdit}
                          setCurrLesson={setCurrLesson}
                          setShowLessonBox={setShowLessonBox}
                          setIsLessonEdit={setIsLessonEdit}
                          setCurrSlide={setCurrSlide}
                          setShowSlideBox={setShowSlideBox}
                          validateSlideComplete={validateSlideComplete}
                          validateLessonComplete={validateLessonComplete}
                          handleDeleteSlide={handleDeleteSlide}
                          handleDeleteLesson={handleDeleteLesson}
                        />
                      )
                  )}

                  {(curriculumValues?.curriculum_name ||
                    curriculumValues?.curriculum_description) && (
                    <div className="h-full w-[1px] border border-dotted border-yellowish border-opacity-40 absolute top-0 -left-6"></div>
                  )}
                </div>
                <Modal
                  isOpen={showSlideBox}
                  onRequestClose={cancelSlideModal}
                  contentLabel="Custom Modal"
                  className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
                  ariaHideApp={false}
                >
                  <div className="MODAL-BODY h-[95%] md:w-[40%] w-[90%] md:absolute bg-blackishV2 text-white rounded-2xl p-4">
                    <div className="w-full h-full flex flex-col">
                      <div className="flex items-center justify-between">
                        <p className="text-white text-lg font-semibold">Create Slide</p>
                        <div className="flex space-x-2">
                          <img
                            className="cursor-pointer"
                            onClick={cancelSlideModal}
                            src={close}
                            alt=""
                          />
                        </div>
                      </div>
                      {showPreview ? (
                        <div className="w-full h-full flex justify-center items-center">
                          <StoryPreview storyData={storyData} onClose={handleClosePreview} />
                        </div>
                      ) : (
                        <>
                          <div className="flex flex-col gap-4 flex-1 overflow-auto py-4">
                            {/* <div className="flex justify-between">
                                <div className="flex gap-2 w-full overflow-y-auto">
                                  {curriculumValues?.lessons[currLessonIndex]
                                    ?.slides?.length > 0 &&
                                    curriculumValues?.lessons[
                                      currLessonIndex
                                    ]?.slides?.map(
                                      (_, index) =>
                                        index !==
                                          curriculumValues?.lessons[
                                            currLessonIndex
                                          ]?.slides.length -
                                            1 && (
                                          <button
                                            onClick={() => {
                                              // setIndexEditSlide(index);
                                              // setSlideData(slideInfo);
                                              // setCurrentSlideData(slideInfo);
                                              // setIsSlideEdit(true);
                                            }}
                                            className="font-semibold min-w-[80px] text-[13px] bg-yellowish text-blackish border border-yellowish rounded-lg p-2"
                                          >
                                            Slide {index + 1}
                                          </button>
                                        )
                                    )}
                                </div>
                                {false ? (
                                  <button
                                    onClick={() => {}}
                                    className="font-semibold min-w-[90px] text-[13px] bg-white text-blackish border border-blackish rounded-lg p-2"
                                  >
                                    Confirm
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => {}}
                                    className="font-semibold min-w-[90px] text-[13px] bg-blackish text-yellowish border border-blackish rounded-lg p-2"
                                  >
                                    Add Slide
                                  </button>
                                )}
                              </div> */}

                            <div className="flex flex-col gap-4">
                              <div>
                                <label
                                  htmlFor="addStory"
                                  className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2 mb-2"
                                >
                                  Add Slide Type
                                  <FaInfoCircle
                                    data-tooltip-id="add_curriculum_slide"
                                    data-tooltip-content="Select Slide Type"
                                  />
                                </label>
                                <div className="flex flex-col px-1 gap-3 text-sm font-bold text-gray-500">
                                  <div className="flex gap-3 items-center">
                                    <input
                                      type="radio"
                                      name="type_of_slide"
                                      value="0"
                                      checked={
                                        curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                          currSlideIndex
                                        ]?.slide_type?.[0]?.type_of_slide === "0"
                                      }
                                      onChange={(e) => {
                                        handleChange(
                                          e,
                                          "slide",
                                          "",
                                          currLessonIndex,
                                          currSlideIndex
                                        );
                                      }}
                                    />
                                    <label>Graphical Image</label>
                                  </div>
                                  <div className="flex gap-3 items-center">
                                    <input
                                      type="radio"
                                      name="type_of_slide"
                                      value="1"
                                      checked={
                                        curriculumValues?.lessons?.[currLessonIndex]?.slides[
                                          currSlideIndex
                                        ]?.slide_type?.[0]?.type_of_slide === "1"
                                      }
                                      onChange={(e) => {
                                        handleChange(
                                          e,
                                          "slide",
                                          "",
                                          currLessonIndex,
                                          currSlideIndex
                                        );
                                      }}
                                    />
                                    <label>Video</label>
                                  </div>
                                  <div className="flex gap-3 items-center">
                                    <input
                                      type="radio"
                                      name="type_of_slide"
                                      value="2"
                                      checked={
                                        curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                          currSlideIndex
                                        ]?.slide_type?.[0]?.type_of_slide === "2"
                                      }
                                      onChange={(e) => {
                                        handleChange(
                                          e,
                                          "slide",
                                          "",
                                          currLessonIndex,
                                          currSlideIndex
                                        );
                                      }}
                                    />
                                    <label>Background Color</label>
                                  </div>
                                  <div className="flex gap-3 items-center">
                                    <input
                                      type="radio"
                                      name="type_of_slide"
                                      value="3"
                                      checked={
                                        curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                          currSlideIndex
                                        ]?.slide_type?.[0]?.type_of_slide === "3"
                                      }
                                      onChange={(e) => {
                                        handleChange(
                                          e,
                                          "slide",
                                          "",
                                          currLessonIndex,
                                          currSlideIndex
                                        );
                                      }}
                                    />
                                    <label>Image Background</label>
                                  </div>
                                </div>

                                {["0", "1", "3"].includes(
                                  curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                    currSlideIndex
                                  ]?.slide_type?.[0]?.type_of_slide
                                ) && (
                                  <UploadControls
                                    tooltipTitle={`Upload ${
                                      curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                        currSlideIndex
                                      ]?.slide_type?.[0]?.type_of_slide === "0"
                                        ? "Image"
                                        : curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                            currSlideIndex
                                          ]?.slide_type?.[0]?.type_of_slide === "1"
                                        ? "Video"
                                        : "Image Background"
                                    }`}
                                    className="my-2 col-span-2"
                                    title={`Upload ${
                                      curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                        currSlideIndex
                                      ]?.slide_type?.[0]?.type_of_slide === "0"
                                        ? "Image"
                                        : curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                            currSlideIndex
                                          ]?.slide_type?.[0]?.type_of_slide === "1"
                                        ? "Video"
                                        : "Image Background"
                                    }`}
                                    subtitle={`Upload ${
                                      curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                        currSlideIndex
                                      ]?.slide_type?.[0]?.type_of_slide === "0"
                                        ? "Image"
                                        : curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                            currSlideIndex
                                          ]?.slide_type?.[0]?.type_of_slide === "1"
                                        ? "Video"
                                        : "Image Background"
                                    }`}
                                    handleMobileChangeUrl={(e) =>
                                      handleChange(e, "slide", 0, currLessonIndex, currSlideIndex)
                                    }
                                    handleMobileFileUpload={(e) =>
                                      handleChange(
                                        e,
                                        curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                          currSlideIndex
                                        ]?.slide_type?.[0]?.type_of_slide === "1"
                                          ? "slideVideo"
                                          : "slideImage",
                                        0,
                                        currLessonIndex,
                                        currSlideIndex
                                      )
                                    }
                                    mobileUploadName="bg_asset_url"
                                    mobileUploadUrl={
                                      curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                        currSlideIndex
                                      ]?.slide_type?.[0]?.bg_asset_url?.[0]?.url_link
                                    }
                                    handleMobilePreview={() => {
                                      setIsImage(true);
                                      setShowModal(true);
                                      setCurrentUrl(
                                        curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                          currSlideIndex
                                        ]?.slide_type?.[0]?.bg_asset_url?.[0]?.url_link
                                      );
                                    }}
                                    isMobileFieldRequired={
                                      showSlideErrors &&
                                      slideErrors?.slide_type?.[0]?.bg_asset_url?.[0].url_link
                                    }
                                    handleTabletChangeUrl={(e) =>
                                      handleChange(e, "slide", 2, currLessonIndex, currSlideIndex)
                                    }
                                    handleTabletFileUpload={(e) =>
                                      handleChange(
                                        e,
                                        curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                          currSlideIndex
                                        ]?.slide_type?.[0]?.type_of_slide === "1"
                                          ? "slideVideo"
                                          : "slideImage",
                                        2,
                                        currLessonIndex,
                                        currSlideIndex
                                      )
                                    }
                                    tabletUploadName="bg_asset_url"
                                    tabletUploadUrl={
                                      curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                        currSlideIndex
                                      ]?.slide_type?.[0]?.bg_asset_url?.[2]?.url_link
                                    }
                                    handleTabletPreview={() => {
                                      setIsImage(true);
                                      setShowModal(true);
                                      setCurrentUrl(
                                        curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                          currSlideIndex
                                        ]?.slide_type?.[0]?.bg_asset_url?.[2]?.url_link
                                      );
                                    }}
                                    isTabletFieldRequired={
                                      showSlideErrors &&
                                      slideErrors?.slide_type?.[0]?.bg_asset_url?.[2].url_link
                                    }
                                    handleWebChangeUrl={(e) =>
                                      handleChange(e, "slide", 1, currLessonIndex, currSlideIndex)
                                    }
                                    handleWebFileUpload={(e) =>
                                      handleChange(
                                        e,
                                        curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                          currSlideIndex
                                        ]?.slide_type?.[0]?.type_of_slide === "1"
                                          ? "slideVideo"
                                          : "slideImage",
                                        1,
                                        currLessonIndex,
                                        currSlideIndex
                                      )
                                    }
                                    webUploadName="bg_asset_url"
                                    webUploadUrl={
                                      curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                        currSlideIndex
                                      ]?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link
                                    }
                                    handleWebPreview={() => {
                                      setIsImage(true);
                                      setShowModal(true);
                                      setCurrentUrl(
                                        curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                          currSlideIndex
                                        ]?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link
                                      );
                                    }}
                                    isWebFieldRequired={
                                      showSlideErrors &&
                                      slideErrors?.slide_type?.[0]?.bg_asset_url?.[1].url_link
                                    }
                                    requiredMessage="Please upload the Image URL"
                                    accept={
                                      curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                        currSlideIndex
                                      ]?.slide_type?.[0]?.type_of_slide === "1"
                                        ? "video"
                                        : "image"
                                    }
                                  />
                                )}
                                {curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                  currSlideIndex
                                ]?.slide_type?.[0]?.type_of_slide === "2" && (
                                  <div className="flex flex-col gap-3 mt-3">
                                    <label
                                      htmlFor="textOnTemplate"
                                      className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                                    >
                                      Select Color
                                      <FaInfoCircle
                                        data-tooltip-id="add_curriculum_slide"
                                        data-tooltip-content="Select Slide Background Color"
                                      />
                                    </label>
                                    <div className="flex w-full justify-center">
                                      <SketchPicker
                                        color={
                                          curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                            currSlideIndex
                                          ]?.slide_type?.[0]?.slide_bg_color
                                        }
                                        onChange={(value) =>
                                          handleSlideBgChange(
                                            value,
                                            currLessonIndex,
                                            currSlideIndex
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div>
                                <label
                                  htmlFor="textOnTemplate"
                                  className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                                >
                                  Add Slide Title
                                  <FaInfoCircle
                                    data-tooltip-id="add_curriculum_slide"
                                    data-tooltip-content="Enter Slide Title"
                                  />
                                </label>
                                <input
                                  name="slide_title"
                                  type="text"
                                  className={`bg-blackish p-2 w-full text-greyish text-[14px] font-medium mt-2 leading-4 rounded-lg h-10 placeholder:text-greyish border border-greyish border-opacity-50 `}
                                  value={
                                    curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                      currSlideIndex
                                    ]?.slide_title
                                  }
                                  placeholder="Enter Slide Title"
                                  onChange={(e) =>
                                    handleChange(e, "slide", "", currLessonIndex, currSlideIndex)
                                  }
                                />
                                {showSlideErrors && slideErrors.slide_title && (
                                  <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2 mb-2">
                                    Slide Title Is Required Field
                                  </p>
                                )}
                              </div>
                              <div className="">
                                <label
                                  htmlFor="addStory"
                                  className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                                >
                                  Add Slide Description
                                  <FaInfoCircle
                                    data-tooltip-id="add_curriculum_slide"
                                    data-tooltip-content="Enter Slide Description"
                                  />
                                </label>
                                <MyStatefulEditor
                                  labelColor={"white"}
                                  labelText={""}
                                  placeholder={"Enter Curriculum Description"}
                                  htmlFor="slide_description"
                                  name="slide_description"
                                  value={
                                    curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                      currSlideIndex
                                    ]?.slide_description
                                  }
                                  handleInputChange={(e) => {
                                    handleChange(e, "slide", "", currLessonIndex, currSlideIndex);
                                  }}
                                />

                                {/* <textarea
                                    rows={6}
                                    className={`bg-white p-2 w-full text-blackish text-[14px] font-medium mt-2 leading-4 rounded-lg placeholder:text-greyish border border-greyish border-opacity-50`}
                                    name="slide_description"
                                    placeholder="Add Story here"
                                    value={
                                      curriculumValues?.lessons?.[
                                        currLessonIndex
                                      ]?.slides?.[currSlideIndex]
                                        ?.slide_description
                                    }
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        "slide",
                                        "",
                                        currLessonIndex,
                                        currSlideIndex
                                      )
                                    }
                                  /> */}
                                {showSlideErrors && slideErrors.slide_description && (
                                  <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2 mb-2">
                                    Slide Description Is Required Field
                                  </p>
                                )}
                              </div>
                            </div>

                            {/* <div>
                            <label
                              htmlFor="textOnTemplate"
                              className="text-[12px] text-black font-medium leading-4 "
                            >
                              Upload Link ( Optional )
                            </label>
                            <input
                              name="slide_link_url"
                              type="text"
                              className={`bg-white p-2 w-full text-blackish text-[14px] font-medium mt-2 leading-4 rounded-lg h-10 placeholder:text-greyish border border-greyish border-opacity-50 `}
                              value={
                                curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                  currSlideIndex
                                ]?.slide_type?.[0]?.slide_link_url?.[0]?.url_link
                              }
                              placeholder="Enter Template Text"
                              onChange={(e) =>
                                handleChange(e, "slide", 0, currLessonIndex, currSlideIndex)
                              }
                            />
                          </div> */}
                          </div>

                          <div className=" space-y-2">
                            <button
                              onClick={() => {
                                handleOpenPreview();
                              }}
                              className="font-semibold text-[12px] bg-blackish text-greyish border border-yellowish rounded-xl py-3 w-full"
                            >
                              Preview
                            </button>
                            <button
                              onClick={() => {
                                if (!slideErrors.slide_title && !slideErrors.slide_description) {
                                  if (
                                    curriculumValues?.lessons?.[currLessonIndex]?.slides?.[
                                      currSlideIndex
                                    ]?.slide_type?.[0]?.type_of_slide !== "2"
                                  ) {
                                    if (
                                      !slideErrors?.slide_type?.[0]?.bg_asset_url?.[0].url_link &&
                                      !slideErrors?.slide_type?.[0]?.bg_asset_url?.[1].url_link &&
                                      !slideErrors?.slide_type?.[0]?.bg_asset_url?.[2].url_link
                                    ) {
                                      saveSlide();
                                    } else {
                                      setShowSlideErrors(true);
                                    }
                                  } else {
                                    saveSlide();
                                  }
                                } else {
                                  setShowSlideErrors(true);
                                }
                              }}
                              className="font-semibold text-[12px] bg-yellowish text-blackish border border-yellowish rounded-xl py-3 w-full"
                            >
                              Finish & {isSlideEdit ? "Update" : "Save"} Slide Draft
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                    <Tooltip
                      id="add_curriculum_slide"
                      className="border border-greyish border-opacity-20"
                    />
                  </div>
                </Modal>
                <Modal
                  isOpen={showCurriculumBox}
                  onRequestClose={cancelCurriculum}
                  contentLabel="Custom Modal"
                  className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
                  ariaHideApp={false}
                >
                  <div className="MODAL-BODY h-[95%] md:w-[40%] w-[90%] md:absolute rounded-2xl p-4 bg-blackishV2">
                    <div className="w-full h-full flex flex-col">
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-white text-lg font-semibold">Edit Curriculum</p>
                        <div className="flex">
                          {activeTab !== "en" && (
                            <button
                              className="flex items-center gap-1 text-greyish text-[11px] font-medium mr-3"
                              onClick={updateStateWithTranslation}
                            >
                              <span>
                                {" "}
                                <img src={reload} alt="" />{" "}
                              </span>
                              Auto fill data with google translation
                            </button>
                          )}
                          <div className="flex space-x-2">
                            <img
                              className="cursor-pointer"
                              onClick={cancelCurriculum}
                              src={close}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col flex-1 w-full row-span-1 bg-greyishV2 bg-opacity-80 md:border-l md:border-r border-greyish border-opacity-30 overflow-y-auto">
                        {/* TAB CONTENT  */}

                        <div className="overflow-y-auto overflow-x-hidden p-4">
                          <div className="grid md:grid-cols-2 grid-cols-1 grid-rows-1 gap-x-4 gap-y-4 mx-1 w-full">
                            <div className="md:col-span-2 col-span-1">
                              <label
                                htmlFor="categoryName"
                                className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                              >
                                Curriculum Name *
                                <FaInfoCircle
                                  data-tooltip-id="add_curriculum"
                                  data-tooltip-content="Enter the Curriculum Name"
                                />
                              </label>
                              <input
                                name="curriculum_name"
                                type="text"
                                className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 placeholder:text-greyish `}
                                value={curriculumValues?.curriculum_name}
                                placeholder="Enter Curriculum Name"
                                onChange={(e) => handleChange(e, "curriculum")}
                              />
                              {showCurriculumErrors && curriculumErrors?.curriculum_name && (
                                <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2">
                                  Curriculum Name Is Required Field
                                </p>
                              )}
                            </div>
                            <div className="md:col-span-2 col-span-1">
                              <label
                                htmlFor="categoryDescription"
                                className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                              >
                                Curriculum Description *
                                <FaInfoCircle
                                  data-tooltip-id="add_curriculum"
                                  data-tooltip-content="Enter the Curriculum Description"
                                />
                              </label>
                              <textarea
                                rows={6}
                                className={`placeholder:text-greyish  bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md`}
                                name="curriculum_description"
                                placeholder="Enter Curriculum Description"
                                value={curriculumValues?.curriculum_description}
                                onChange={(e) => handleChange(e, "curriculum")}
                              />
                              {showCurriculumErrors && curriculumErrors?.curriculum_description && (
                                <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2">
                                  Curriculum Description Is Required Field
                                </p>
                              )}
                            </div>
                          </div>
                          {activeTab === "en" && (
                            <>
                              <UploadControls
                                tooltipTitle="Upload Background Image"
                                className="my-2 col-span-2"
                                title="Background *"
                                subtitle="Upload Image"
                                mobileUploadName="bg_Images"
                                handleMobileChangeUrl={(e) => handleChange(e, "curriculum", 0)}
                                handleMobileFileUpload={(e) => handleChange(e, "curriculumFile", 0)}
                                mobileUploadUrl={curriculumValues?.bg_Images[0]?.url_link}
                                handleMobilePreview={() => {
                                  setIsImage(true);
                                  setShowModal(true);
                                  setCurrentUrl(curriculumValues?.bg_Images[0]?.url_link);
                                }}
                                isMobileFieldRequired={
                                  showCurriculumErrors && curriculumErrors?.bg_Images?.[0]?.url_link
                                }
                                tabletUploadName="bg_Images"
                                handleTabletChangeUrl={(e) => handleChange(e, "curriculum", 2)}
                                handleTabletFileUpload={(e) => handleChange(e, "curriculumFile", 2)}
                                tabletUploadUrl={curriculumValues?.bg_Images?.[2]?.url_link}
                                handleTabletPreview={() => {
                                  setIsImage(true);
                                  setShowModal(true);
                                  setCurrentUrl(curriculumValues?.bg_Images?.[2]?.url_link);
                                }}
                                isTabletFieldRequired={
                                  showCurriculumErrors && curriculumErrors?.bg_Images?.[2]?.url_link
                                }
                                webUploadName="bg_Images"
                                handleWebChangeUrl={(e) => handleChange(e, "curriculum", 1)}
                                handleWebFileUpload={(e) => handleChange(e, "curriculumFile", 1)}
                                webUploadUrl={curriculumValues?.bg_Images?.[1]?.url_link}
                                handleWebPreview={() => {
                                  setIsImage(true);
                                  setShowModal(true);
                                  setCurrentUrl(curriculumValues?.bg_Images?.[1]?.url_link);
                                }}
                                isWebFieldRequired={
                                  showCurriculumErrors && curriculumErrors?.bg_Images?.[1]?.url_link
                                }
                                requiredMessage="Please Upload the Image URL"
                              />
                              <UploadControls
                                tooltipTitle="Upload Common Background Image"
                                className="my-2 col-span-2"
                                title="Common Background *"
                                subtitle="Upload Image"
                                mobileUploadName="common_Bg_Images"
                                handleMobileChangeUrl={(e) =>
                                  handleChange(e, "commonCurriculum", 0)
                                }
                                handleMobileFileUpload={(e) =>
                                  handleChange(e, "commonCurriculumFile", 0)
                                }
                                mobileUploadUrl={curriculumValues?.common_Bg_Images?.[0]?.url_link}
                                handleMobilePreview={() => {
                                  setIsImage(true);
                                  setShowModal(true);
                                  setCurrentUrl(curriculumValues?.common_Bg_Images?.[0]?.url_link);
                                }}
                                isMobileFieldRequired={
                                  showCurriculumErrors &&
                                  curriculumErrors?.common_Bg_Images?.[0]?.url_link
                                }
                                tabletUploadName="common_Bg_Images"
                                handleTabletChangeUrl={(e) =>
                                  handleChange(e, "commonCurriculum", 2)
                                }
                                handleTabletFileUpload={(e) =>
                                  handleChange(e, "commonCurriculumFile", 2)
                                }
                                tabletUploadUrl={curriculumValues?.common_Bg_Images?.[2]?.url_link}
                                handleTabletPreview={() => {
                                  setIsImage(true);
                                  setShowModal(true);
                                  setCurrentUrl(curriculumValues?.common_Bg_Images?.[2]?.url_link);
                                }}
                                isTabletFieldRequired={
                                  showCurriculumErrors &&
                                  curriculumErrors?.common_Bg_Images?.[2]?.url_link
                                }
                                webUploadName="common_Bg_Images"
                                handleWebChangeUrl={(e) => handleChange(e, "commonCurriculum", 1)}
                                handleWebFileUpload={(e) =>
                                  handleChange(e, "commonCurriculumFile", 1)
                                }
                                webUploadUrl={curriculumValues?.common_Bg_Images?.[1]?.url_link}
                                handleWebPreview={() => {
                                  setIsImage(true);
                                  setShowModal(true);
                                  setCurrentUrl(curriculumValues?.common_Bg_Images?.[1]?.url_link);
                                }}
                                isWebFieldRequired={
                                  showCurriculumErrors &&
                                  curriculumErrors?.common_Bg_Images?.[1]?.url_link
                                }
                                requiredMessage="Please Upload the Image URL"
                              />
                            </>
                          )}
                          <div className="flex justify-end gap-5 py-2 w-full">
                            <button
                              className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                              onClick={() => {
                                setShowCurriculumBox(false);
                              }}
                            >
                              Close
                            </button>
                            <button
                              className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                              onClick={() => {
                                if (
                                  !curriculumErrors.curriculum_name &&
                                  !curriculumErrors.curriculum_description &&
                                  !curriculumErrors.bg_Images[0].url_link &&
                                  !curriculumErrors.bg_Images[1].url_link &&
                                  !curriculumErrors.bg_Images[2].url_link
                                ) {
                                  saveCurriculum();
                                } else {
                                  setShowCurriculumErrors(true);
                                }
                              }}
                            >
                              {isLessonEdit ? "Update" : "Save"} Curriculum Draft
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Tooltip
                      id="add_curriculum"
                      className="border border-greyish border-opacity-20"
                    />
                  </div>
                </Modal>
                <Modal
                  isOpen={showLessonBox}
                  onRequestClose={() => {
                    setShowLessonBox((prev) => !prev);
                    cancelLesson(curriculumValues.lessons?.[currLessonIndex], currLessonIndex);
                  }}
                  contentLabel="Custom Modal"
                  className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
                  ariaHideApp={false}
                >
                  <div className="MODAL-BODY h-[95%] md:w-[40%] w-[90%] md:absolute rounded-2xl p-4 bg-blackishV2">
                    <div className="w-full h-full flex flex-col">
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-white text-lg font-semibold">Add Lesson</p>
                        <div className="flex">
                          <div className="flex space-x-2">
                            <img
                              className="cursor-pointer"
                              onClick={() => {
                                setShowLessonBox((prev) => !prev);
                                cancelLesson(
                                  curriculumValues.lessons?.[currLessonIndex],
                                  currLessonIndex
                                );
                              }}
                              src={close}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="rounded-xl bg-blackish flex-1 overflow-y-auto p-4 ">
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4 gap-y-4">
                          {" "}
                          <div className="col-span-2">
                            <label
                              htmlFor="topicTitle"
                              className="text-[12px] text-greyish font-medium leading-4 flex -items-center gap-2"
                            >
                              Lesson Title *
                              <FaInfoCircle
                                data-tooltip-id="add_curriculum_lesson"
                                data-tooltip-content="Enter the Lesson Name"
                              />
                            </label>
                            <input
                              name="lesson_title"
                              type="text"
                              className={` placeholder:text-greyish bg-blackishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                              placeholder="Enter Title Name"
                              onChange={(e) => handleChange(e, "lesson", "", currLessonIndex)}
                              value={curriculumValues?.lessons?.[currLessonIndex]?.lesson_title}
                            />
                            {showLessonErrors && lessonErrors?.lesson_title && (
                              <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2 mb-2">
                                Lesson title Is Required Field
                              </p>
                            )}
                          </div>
                          {/* {activeTab === "en" ? (
                          <div className="col-span-1">
                            <label
                              htmlFor="topicDuration"
                              className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                            >
                              Lesson Duration *
                              <FaInfoCircle
                                data-tooltip-id="add_curriculum_lesson"
                                data-tooltip-content="Enter the Lesson Duration"
                              />
                            </label>
                            <select
                              className={`bg-blackishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 placeholder:text-greyish `}
                              name="lesson_duration"
                              onChange={(e) => handleChange(e, "lesson", "", currLessonIndex)}
                              value={curriculumValues?.lessons?.[currLessonIndex]?.lesson_duration}
                            >
                              <option value="1">1 minute</option>
                              <option value="2">2 minute</option>
                            </select>
                          </div>
                        ) : (
                          <></>
                        )} */}
                          <div className="md:col-span-2 col-span-1">
                            <label
                              htmlFor="lesson_description"
                              className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                            >
                              Lesson Description *
                              <FaInfoCircle
                                data-tooltip-id="add_curriculum_lesson"
                                data-tooltip-content="Enter the Lesson Description"
                              />
                            </label>
                            <textarea
                              rows={6}
                              className={`placeholder:text-greyish  bg-blackishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md`}
                              name="lesson_description"
                              placeholder="Enter Lesson Description"
                              onChange={(e) => handleChange(e, "lesson", "", currLessonIndex)}
                              value={curriculumValues?.lessons[currLessonIndex]?.lesson_description}
                            />
                            {showLessonErrors && lessonErrors.lesson_description && (
                              <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2 mb-2">
                                Lesson Description Is Required Field
                              </p>
                            )}
                          </div>
                          {activeTab === "en" && (
                            <>
                              <UploadControls
                                tooltipTitle="Upload Thumbnail Image"
                                className="my-2 col-span-2"
                                title="Thumbnail *"
                                subtitle="Upload Image"
                                handleMobileChangeUrl={(e) =>
                                  handleChange(e, "lesson", 0, currLessonIndex)
                                }
                                handleMobileFileUpload={(e) =>
                                  handleChange(e, "lessonFile", 0, currLessonIndex)
                                }
                                mobileUploadName="lesson_tumbnail_urls"
                                mobileUploadUrl={
                                  curriculumValues?.lessons?.[currLessonIndex]
                                    ?.lesson_tumbnail_urls[0]?.url_link
                                }
                                handleMobilePreview={() => {
                                  setIsImage(true);
                                  setShowModal(true);
                                  setCurrentUrl(
                                    curriculumValues?.lessons?.[currLessonIndex]
                                      ?.lesson_tumbnail_urls[0]?.url_link
                                  );
                                }}
                                isMobileFieldRequired={
                                  showLessonErrors && lessonErrors.lesson_tumbnail_urls[0].url_link
                                }
                                handleTabletChangeUrl={(e) =>
                                  handleChange(e, "lesson", 2, currLessonIndex)
                                }
                                handleTabletFileUpload={(e) =>
                                  handleChange(e, "lessonFile", 2, currLessonIndex)
                                }
                                tabletUploadName="lesson_tumbnail_urls"
                                tabletUploadUrl={
                                  curriculumValues?.lessons?.[currLessonIndex]
                                    ?.lesson_tumbnail_urls[2]?.url_link
                                }
                                handleTabletPreview={() => {
                                  setIsImage(true);
                                  setShowModal(true);
                                  setCurrentUrl(
                                    curriculumValues?.lessons?.[currLessonIndex]
                                      ?.lesson_tumbnail_urls[2]?.url_link
                                  );
                                }}
                                isTabletFieldRequired={
                                  showLessonErrors && lessonErrors.lesson_tumbnail_urls[2].url_link
                                }
                                handleWebChangeUrl={(e) =>
                                  handleChange(e, "lesson", 1, currLessonIndex)
                                }
                                handleWebFileUpload={(e) =>
                                  handleChange(e, "lessonFile", 1, currLessonIndex)
                                }
                                webUploadName="lesson_tumbnail_urls"
                                webUploadUrl={
                                  curriculumValues?.lessons?.[currLessonIndex]
                                    ?.lesson_tumbnail_urls[1]?.url_link
                                }
                                handleWebPreview={() => {
                                  setIsImage(true);
                                  setShowModal(true);
                                  setCurrentUrl(
                                    curriculumValues?.lessons?.[currLessonIndex]
                                      ?.lesson_tumbnail_urls[1]?.url_link
                                  );
                                }}
                                isWebFieldRequired={
                                  showLessonErrors && lessonErrors.lesson_tumbnail_urls[1].url_link
                                }
                                requiredMessage="Please Update the Thumbnail URL"
                              />
                              <UploadControls
                                tooltipTitle="Upload Common Thumbnail Image"
                                className="my-2 col-span-2"
                                title="Common Thumbnail *"
                                subtitle="Upload Image"
                                handleMobileChangeUrl={(e) =>
                                  handleChange(e, "commonLesson", 0, currLessonIndex)
                                }
                                handleMobileFileUpload={(e) =>
                                  handleChange(e, "commonLessonFile", 0, currLessonIndex)
                                }
                                mobileUploadName="lesson_common_tumbnail_urls"
                                mobileUploadUrl={
                                  curriculumValues?.lessons?.[currLessonIndex]
                                    ?.lesson_common_tumbnail_urls?.[0]?.url_link
                                }
                                handleMobilePreview={() => {
                                  setIsImage(true);
                                  setShowModal(true);
                                  setCurrentUrl(
                                    curriculumValues?.lessons?.[currLessonIndex]
                                      ?.lesson_common_tumbnail_urls?.[0]?.url_link
                                  );
                                }}
                                isMobileFieldRequired={
                                  showLessonErrors && lessonErrors.lesson_tumbnail_urls[0].url_link
                                }
                                handleTabletChangeUrl={(e) =>
                                  handleChange(e, "commonLesson", 2, currLessonIndex)
                                }
                                handleTabletFileUpload={(e) =>
                                  handleChange(e, "commonLessonFile", 2, currLessonIndex)
                                }
                                tabletUploadName="lesson_common_tumbnail_urls"
                                tabletUploadUrl={
                                  curriculumValues?.lessons?.[currLessonIndex]
                                    ?.lesson_common_tumbnail_urls?.[2]?.url_link
                                }
                                handleTabletPreview={() => {
                                  setIsImage(true);
                                  setShowModal(true);
                                  setCurrentUrl(
                                    curriculumValues?.lessons?.[currLessonIndex]
                                      ?.lesson_common_tumbnail_urls?.[2]?.url_link
                                  );
                                }}
                                isTabletFieldRequired={
                                  showLessonErrors && lessonErrors.lesson_tumbnail_urls[2].url_link
                                }
                                handleWebChangeUrl={(e) =>
                                  handleChange(e, "commonLesson", 1, currLessonIndex)
                                }
                                handleWebFileUpload={(e) =>
                                  handleChange(e, "commonLessonFile", 1, currLessonIndex)
                                }
                                webUploadName="lesson_common_tumbnail_urls"
                                webUploadUrl={
                                  curriculumValues?.lessons?.[currLessonIndex]
                                    ?.lesson_common_tumbnail_urls?.[1]?.url_link
                                }
                                handleWebPreview={() => {
                                  setIsImage(true);
                                  setShowModal(true);
                                  setCurrentUrl(
                                    curriculumValues?.lessons?.[currLessonIndex]
                                      ?.lesson_common_tumbnail_urls?.[1]?.url_link
                                  );
                                }}
                                isWebFieldRequired={
                                  showLessonErrors && lessonErrors.lesson_tumbnail_urls[1].url_link
                                }
                                requiredMessage="Please Update the Thumbnail URL"
                              />
                            </>
                          )}
                        </div>
                        <div className="py-1 w-full"></div>
                        <div className=" py-1 w-full">
                          <div className="flex justify-end gap-5">
                            <button
                              className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                              onClick={() => {
                                setShowLessonBox((prev) => !prev);
                                cancelLesson(
                                  curriculumValues.lessons?.[currLessonIndex],
                                  currLessonIndex
                                );
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                              onClick={() => {
                                if (
                                  !lessonErrors?.lesson_title &&
                                  !lessonErrors?.lesson_description &&
                                  !lessonErrors?.lesson_tumbnail_urls[0].url_link &&
                                  !lessonErrors?.lesson_tumbnail_urls[1].url_link &&
                                  !lessonErrors?.lesson_tumbnail_urls[2].url_link
                                ) {
                                  saveLesson();
                                } else {
                                  setShowLessonErrors(true);
                                }
                              }}
                            >
                              {isLessonEdit ? "Update" : "Save"} Lesson Draft
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Tooltip
                      id="add_curriculum_lesson"
                      className="border border-greyish border-opacity-20"
                    />
                  </div>
                </Modal>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-[33%] bg-[#1b1b1b]">
        <CustomTabsV2
          tabHeight={"h-[64px]"}
          tabsTitleArray={["Preview", "Stats", "Layouts"]}
          tabsContentObject={{
            Preview: (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                No Data Found...
              </div>
            ),
            Stats: (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                {curriculumId ? "No Data Found..." : "No stats available for curriculum"}
              </div>
            ),
            Layouts: !curriculumId ? (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                Save this curriculum to assign the layout
              </div>
            ) : (
              <>
                <div className="mt-4 p-1">
                  <label htmlFor="relatedVideos" className="text-[12px] text-greyish font-medium">
                    Layouts
                  </label>
                  <div className="bg-blackish py-4 px-3 mt-3 rounded-lg">
                    <LayoutDropdown
                      setMyState={setLayoutValues}
                      setLayoutInfo={setLayoutInfo}
                      setAddedLayout={setAddedLayout}
                      addedLayout={addedLayout}
                      newTarget={editTargetLayout}
                      isLayoutEdit={isLayoutEdit}
                      outerTargetLayout={layoutValues?.layout_name}
                    />

                    {layoutInfo && (
                      <>
                        {/* <div className="flex gap-3">
                          <div className="w-full">
                            <label
                              htmlFor="title"
                              className="text-[12px] text-greyish font-medium leading-4 "
                            >
                              Layout Title
                            </label>
                            <input
                              type="text"
                              className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                              name="title"
                              placeholder="Enter title"
                              value={layoutValues?.title}
                              onChange={handleVideoLayoutChange}
                            />
                          </div>
                          <div className="w-full">
                            <label
                              htmlFor="subtitle"
                              className="text-[12px] text-greyish font-medium leading-4 "
                            >
                              Layout Subtitle
                            </label>
                            <input
                              type="text"
                              className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                              name="subtitle"
                              placeholder="Enter subtitle "
                              value={layoutValues?.subtitle}
                              onChange={handleVideoLayoutChange}
                            />
                          </div>
                        </div> */}
                        <div className="flex gap-3">
                          {/* <div className="w-full flex flex-col mt-2">
                            <label
                              htmlFor="expiry"
                              className="text-[12px] text-greyish font-medium leading-4"
                            >
                              Expiry
                            </label>
                            <DateTimePicker
                              name="expiry"
                              className={
                                "bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10"
                              }
                              value={layoutValues?.expiry}
                              onChange={handleVideoDateChange}
                            />
                          </div> */}
                          <div className="w-full">
                            <label
                              htmlFor="order"
                              className="text-[12px] text-greyish font-medium leading-4 "
                            >
                              Display Order
                            </label>
                            <input
                              type="text"
                              className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                              name="order"
                              placeholder="Enter order"
                              value={layoutValues?.order}
                              onChange={handleVideoLayoutChange}
                            />
                          </div>
                        </div>
                        <div className="flex justify-end mt-4">
                          <div className="flex gap-3">
                            {!isLayoutEdit && (
                              <button
                                className="text-greyish bg-blackishV2 font-medium text-[12px] rounded-xl py-2 px-6"
                                onClick={() => {
                                  setLayoutInfo(false);
                                  setLayoutValues(initialLayoutValues);
                                }}
                              >
                                Cancel
                              </button>
                            )}
                            {isLayoutEdit ? (
                              <button
                                className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                                onClick={handleLayoutUpdate}
                              >
                                Confirm
                              </button>
                            ) : (
                              <button
                                className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                                onClick={handleAddLayoutToArray}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="w-full">
                  {layoutArray?.length > 0 &&
                    layoutArray?.map((item, index) => (
                      <div className="w-full px-4 mt-4">
                        <div className="w-full p-2 px-4 bg-black bg-opacity-10 rounded-lg border border-opacity-25 border-white">
                          <div className="flex flex-col items-center justify-center w-full text-white">
                            <div className="flex justify-between w-full">
                              <p className="text-sm font-white font-medium text-white">
                                {item?.layout_name}
                              </p>
                              <div className="flex gap-2 justify-end items-center cursor-pointer">
                                <img
                                  className="h-4"
                                  src={edit}
                                  alt=""
                                  onClick={() => {
                                    setIsLayoutEdit(true);
                                    setEditLayoutIndex(index);
                                    setLayoutValues(item);
                                    setEditTargetLayout(item?.layout_name);
                                  }}
                                />
                                <img
                                  className="h-4"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    // handleDeleteLayoutFromArray(item?._id);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="flex justify-between w-full mt-5 bg-blackishV2 p-1 px-2">
                              <p className="text-[10px] font-white text-white">Order</p>
                              {/* <p className="text-[10px] font-white text-white">Title</p>
                              <p className="text-[10px] font-white  text-white">Subtitle</p>
                              <p className="text-[10px] font-white text-white">Expiry</p> */}
                            </div>
                            <div className="flex w-full mt-2 p-1 px-2">
                              <p className="flex w-full text-xs font-white font-bold text-white">
                                {item?.order}
                              </p>
                              {/* <p className="flex w-full  text-xs font-white font-bold text-white">
                                {item?.title}
                              </p>
                              <p className="flex w-full justify-center text-xs font-white font-bold text-white">
                                {item?.subtitle}
                              </p>
                              <p className="flex w-full justify-end text-end  text-xs font-white font-bold text-white">
                                {moment(item?.expiry).format("DD-MM-YYYY")}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            ),
          }}
        />
      </div>
      <ReactModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        className="relative"
        bodyOpenClassName="z-50"
      >
        <div className="flex justify-center h-full w-full relative">
          <div
            className="absolute top-0 right-0 cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            <ImCross />
          </div>
          {isImage ? (
            <img className="h-full" src={currentUrl} />
          ) : (
            <video className="h-full" controls autoPlay>
              <source src={currentUrl} />
            </video>
          )}
        </div>
      </ReactModal>
    </div>
  );
};

export default AddCurriculumFields;
