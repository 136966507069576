import avatar from "../../assets/icons/squareCard/story1.png";
import avatar1 from "../../assets/images/avatar1.png";
import avatar22 from "../../assets/images/avatar2.png";
import avatar3 from "../../assets/images/avatar3.png";
import avatar4 from "../../assets/images/avatar4.png";

export const notificationsListHeaders = [
  {
    headerName: "Notification Title",
    headerId: "notification_title",
  },
  {
    headerName: "Notification Category",
    headerId: "notificationCategory",
  },
  {
    headerName: "Notification Description",
    headerId: "notification_description",
  },
  {
    headerName: "Scheduled Time",
    headerId: "notification_schedule_time",
  },
  {
    headerName: "Date",
    headerId: "lastUpdatedDate",
  },
  {
    headerName: "Actions",
    headerId: "actions",
  },
];

export const feedListHeaders = [
  {
    headerName: "User",
    headerId: "userDetails",
  },
  {
    headerName: "Feedback",
    headerId: "feedback",
  },
  {
    headerName: "Most Liked",
    headerId: "likedmost",
  },
  {
    headerName: "Rating",
    headerId: "rating",
  },
  {
    headerName: "Actions",
    headerId: "actions",
  },
];

export const prayerListHeaders = [
  {
    headerName: "User",
    headerId: "userDetails",
  },
  {
    headerName: "Title",
    headerId: "prayer_request",
  },
  {
    headerName: "Category",
    headerId: "category",
  },
  {
    headerName: "Description",
    headerId: "subject",
  },
  {
    headerName: "Actions",
    headerId: "actions",
  },
];
export const notificationsListData = [
  {
    notificationTitle: {
      imgUrl: avatar,
      title: "Gods Prayer",
    },
    notificationCategory: "Devotional",
    notificationDescription: "It is a long established fact that a...",
    scheduledTime: "00:00 AM",
    date: "23/06/2023",
  },
  {
    notificationTitle: {
      imgUrl: avatar1,
      title: "Momentun Awards",
    },
    notificationCategory: "Events",
    notificationDescription: "It is a long established fact that a...",
    scheduledTime: "10:00 AM",
    date: "23/06/2023",
  },
  {
    notificationTitle: {
      imgUrl: avatar22,
      title: "Prayer of the week",
    },
    notificationCategory: "Prayer",
    notificationDescription: "It is a long established fact that a...",
    scheduledTime: "08:00 AM",
    date: "23/06/2023",
  },
  {
    notificationTitle: {
      imgUrl: avatar3,
      title: "Monthly Givings",
    },
    notificationCategory: "Givings",
    notificationDescription: "It is a long established fact that a...",
    scheduledTime: "00:00 AM",
    date: "23/06/2023",
  },
  {
    notificationTitle: {
      imgUrl: avatar4,
      title: "Prayer of the month",
    },
    notificationCategory: "Prayer",
    notificationDescription: "It is a long established fact that a...",
    scheduledTime: "00:00 AM",
    date: "23/06/2023",
  },
  {
    notificationTitle: {
      imgUrl: avatar1,
      title: "Monthly Prayers",
    },
    notificationCategory: "Prayer",
    notificationDescription: "It is a long established fact that a...",
    scheduledTime: "00:00 AM",
    date: "23/06/2023",
  },
  {
    notificationTitle: {
      imgUrl: avatar22,
      title: "Gods Prayer",
    },
    notificationCategory: "Prayer",
    notificationDescription: "It is a long established fact that a...",
    scheduledTime: "00:00 AM",
    date: "23/06/2023",
  },
];
