import React, { useState } from "react";
import { uploadAssestsAction, uploadVideosAction } from "../../store/Languages/LanguageAction";
import { useDispatch } from "react-redux";
import ReactModal from "react-modal";
import { ImCross } from "react-icons/im";
import { acceptImage, acceptVideo } from "../../constants/ValidationConstants";
import { FaInfoCircle } from "react-icons/fa";

const UploadVideo = ({
  tooltipId,
  tooltipText,
  forScreen,
  setState,
  state,
  name,
  color = "black",
  label = "Upload Profile Picture",
  setIsDisabled = () => {},
  isDisabled = false,
  accept = "image",
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  const handleChange = (e) => {
    setLoading(true);
    setIsDisabled(true);
    const value = e.target.files[0];
    const formData = new FormData();
    formData.append("file", value);

    if (accept === "image") {
      dispatch(
        uploadAssestsAction({
          apiPayloadRequest: formData,
          callback: (res) => {
            console.log(res, "res");
            setState(res?.url, name);
            setLoading(false);
            setIsDisabled(false);
          },
        })
      );
    } else {
      dispatch(
        uploadVideosAction({
          apiPayloadRequest: formData,
          callback: (res) => {
            console.log(res, "res");
            setState(res?.url, name);
            setLoading(false);
            setIsDisabled(false);
          },
        })
      );
    }
  };

  const handleInputChange = (event) => {
    const { value, name } = event.target;
    setState(value, name);
  };
  return (
    <div className="flex gap-3 transition-multiple ease-in-out duration-500">
      <div className="w-full transition-multiple ease-in-out duration-500">
        {forScreen === "image" ? (
          <label
            htmlFor="mobileUploadUrl"
            className={`flex items-center gap-2 
              ${
                color === "white"
                  ? "text-[12px] text-black font-medium leading-4 "
                  : "text-[12px] text-greyish font-medium leading-4 "
              }`}
          >
            {label}{" "}
            {tooltipId && tooltipText && (
              <FaInfoCircle data-tooltip-id={tooltipId} data-tooltip-content={tooltipText} />
            )}
          </label>
        ) : (
          <label
            htmlFor="mobileUploadUrl"
            className={`flex items-center gap-2 
              ${
                color === "white"
                  ? "text-[12px] text-black font-medium leading-4 "
                  : "text-[12px] text-greyish font-medium leading-4 "
              }`}
          >
            {label}{" "}
            {tooltipId && tooltipText && (
              <FaInfoCircle data-tooltip-id={tooltipId} data-tooltip-content={tooltipText} />
            )}
            <span className="text-[10px]">( For {forScreen} )</span>
          </label>
        )}
        <input
          className={
            color === "white"
              ? `bg-white p-2 w-full text-black text-[14px] font-medium mt-2 leading-4 rounded-md h-10 border border-blackish border-opacity-30`
              : `bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `
          }
          type="file"
          accept={accept === "image" ? acceptImage : acceptVideo}
          onChange={handleChange}
          disabled={isDisabled}
        />
      </div>
      <div className="w-full md:col-span-2 col-span-1 transition-multiple ease-in-out duration-500">
        <div className="flex justify-between">
          <label
            htmlFor="mobileUploadUrl"
            className="text-[12px] text-greyish font-medium leading-4 "
          >
            Upload URL
          </label>
          {state && (
            <button
              className="text-[8px] text-green-500 font-medium leading-4 cursor-pointer z-50"
              onClick={() => {
                setIsImage(true);
                setShowModal(true);
                setCurrentUrl(state);
              }}
            >
              View Preview
            </button>
          )}
        </div>
        <div className="relative">
          <input
            name={name}
            id="Url"
            placeholder="Enter URL"
            type="text"
            className={
              color === "white"
                ? `bg-white p-2 w-full text-black text-[14px] font-medium mt-2 leading-4 rounded-md h-10 border border-blackish border-opacity-30`
                : `bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `
            }
            value={state}
            onChange={handleInputChange}
          />
        </div>
      </div>
      {loading && (
        <div
          className={` mt-7 p-2 flex justify-center items-center-center transition-[width] ease-in-out duration-1000 ${
            loading ? "w-[10%]" : "w-0"
          }`}
        >
          <svg
            aria-hidden="true"
            className="inline aspect-square w-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      )}
      <ReactModal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <div className="flex justify-center h-full w-full relative">
          <div
            className="absolute top-0 right-0 cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            <ImCross />
          </div>
          {isImage ? (
            <img className="h-full" src={currentUrl} />
          ) : (
            <video className="h-full" controls autoPlay>
              <source src={currentUrl} />
            </video>
          )}
        </div>
      </ReactModal>
    </div>
  );
};

export default UploadVideo;
