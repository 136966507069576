import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteCurriculumLessonAction,
  deleteCurriculumLessonSlideAction,
  readCurriculumAction,
  readLanguageAction,
  saveBGCurriculumAction,
  saveCurriculumAction,
  saveCurriculumLanguageAction,
  saveCurriculumLanguageLessonSlideAction,
  saveCurriculumLessonAction,
  saveCurriculumLessonLanguageAction,
  saveCurriculumLessonSlideAction,
  saveCurriculumLessonSlideTypeAction,
  saveCurriculumLessonSlideTypeBGAction,
  saveCurriculumLessonSlideTypeLinkAction,
  saveCurriculumLessonThumbNailAction,
  translateLanguageAction,
  updateBGCurriculumAction,
  updateCurriculumAction,
  updateCurriculumLessonAction,
  updateCurriculumLessonSlideAction,
  updateCurriculumLessonSlideTypeAction,
  updateCurriculumLessonSlideTypeBGAction,
  updateCurriculumLessonThumbNailAction,
  uploadAssestsAction,
  uploadVideosAction,
} from "../../../store/Languages/LanguageAction";
import Toastiy from "../../../components/universal/Toastiy";
import { useNavigate } from "react-router-dom";
import {
  deleteCurriculumLayoutAction,
  saveCurriculumLayoutAction,
  updateCurriculumLayoutAction,
} from "../../../store/Layout/LayoutAction";
import { FaTruckMonster } from "react-icons/fa";

export const CurriculumHooksNew = (setLoading, curriculum_id, setCurriculumId) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //layout States
  const initialLayoutValues = {
    _id: "",
    mobile_layout: "1",
    layout_name: "",
    order: "",
    // title: "",
    // subtitle: "",
    // expiry: "",
  };
  const [layoutInfo, setLayoutInfo] = useState(false);
  const [layoutValues, setLayoutValues] = useState(initialLayoutValues);
  const [addedLayout, setAddedLayout] = useState(false);
  const [editTargetLayout, setEditTargetLayout] = useState("");
  const [isLayoutEdit, setIsLayoutEdit] = useState(false);
  const [layoutArray, setLayoutArray] = useState([]);
  const [editLayoutIndex, setEditLayoutIndex] = useState(null);

  //save curriculum language
  const handleSaveLanguage = () => {
    dispatch(
      saveCurriculumLanguageAction({
        apiPayloadRequest: {
          _id: curriculum_id,
          language_code: activeTab,
          curriculum_name: curriculumValues.curriculum_name
            ? curriculumValues.curriculum_name
            : "No Data",
          curriculum_description: curriculumValues.curriculum_description,
        },
      })
    );
    currCurriculumValues?.lessons.map((lesson) => {
      dispatch(
        saveCurriculumLessonLanguageAction({
          apiPayloadRequest: {
            _id: curriculum_id,
            language_code: activeTab,
            lessonObjId: lesson.lessonObjId,
            lesson_title: lesson.lesson_title,
            lesson_description: lesson.lesson_description,
          },
          callback: (res) => saveSlideLanguageResponce(res, lesson),
        })
      );
    });
  };

  const saveSlideLanguageResponce = (res, lesson) => {
    if (res?.type === 1) {
      lesson?.slides?.map((slide) => {
        dispatch(
          saveCurriculumLanguageLessonSlideAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              language_code: activeTab,
              lessonObjId: lesson.lessonObjId,
              sldieObjId: slide.sldieObjId,
              slide_title: slide?.slide_title,
              slide_description: slide?.slide_description,
            },
            callback: (res) => console.log(res),
          })
        );
      });
    }
  };

  //slide delete
  const handleDeleteSlide = ({ slideObjId, lessonObjId, lessonIndex, slideIndex }) => {
    dispatch(
      deleteCurriculumLessonSlideAction({
        apiPayloadRequest: {
          _id: curriculum_id,
          lessonObjId: lessonObjId,
          slideObjId: slideObjId,
        },
        callback: (res) => {
          if (res?.type === 1) {
            const updatedCurriculumValues = structuredClone(curriculumValues);
            updatedCurriculumValues?.lessons?.[lessonIndex]?.slides?.splice(slideIndex, 1);
            setCurriculumValues(updatedCurriculumValues);
            Toastiy("Slide Deleted Successfully!", 1);
          } else {
            Toastiy("Something went wrong, please try again");
          }
        },
      })
    );
  };

  //lesson delete
  const handleDeleteLesson = ({ lessonObjId, lessonIndex }) => {
    dispatch(
      deleteCurriculumLessonAction({
        apiPayloadRequest: {
          _id: curriculum_id,
          lessonObjId: lessonObjId,
        },
        callback: (res) => {
          if (res?.type === 1) {
            const updatedCurriculumValues = structuredClone(curriculumValues);
            updatedCurriculumValues?.lessons?.splice(lessonIndex, 1);
            setCurriculumValues(updatedCurriculumValues);
            Toastiy("Lesson Deleted Successfully!", 1);
          } else {
            Toastiy("Something went wrong, please try again");
          }
        },
      })
    );
  };

  //layout handles
  const handleVideoLayoutChange = (event) => {
    if (isLayoutEdit) {
      const { name, value } = event.target;
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));

      setLayoutArray((prev) => {
        const updatedArray = [...prev];

        updatedArray[editLayoutIndex] = {
          ...updatedArray[editLayoutIndex],
          [name]: value,
        };
      });
    } else {
      const { name, value } = event.target;
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleVideoDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));
  };

  const handleLayoutUpdate = () => {
    if (curriculum_id !== undefined && curriculum_id !== "") {
      dispatch(
        updateCurriculumLayoutAction({
          apiPayloadRequest: {
            _id: layoutArray._id,
            curriculumObjId: curriculum_id,
            order: layoutArray._id,
            // title: layoutArray._id,
            // subtitle: layoutArray._id,
            // expiry: layoutArray._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setLayoutValues(initialLayoutValues);
              setIsLayoutEdit(false);
              Toastiy("Layout Updated Successfully!", 1);
            }
          },
        })
      );
    } else {
      setLayoutValues(initialLayoutValues);
      setIsLayoutEdit(false);
    }
  };

  const handleAddLayoutToArray = () => {
    if (curriculum_id !== undefined && curriculum_id !== "") {
      dispatch(
        saveCurriculumLayoutAction({
          apiPayloadRequest: {
            _id: layoutValues?._id,
            curriculumObjId: curriculum_id,
            order: layoutValues?.order,
            // title: layoutValues?.title,
            // subtitle: layoutValues?.subtitle,
            // expiry: layoutValues?.expiry,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Layout Added Successfully!", 1);
              setLayoutArray((prev) => [...prev, layoutValues]);
              setLayoutValues(initialLayoutValues);
              setAddedLayout(true);
            }
          },
        })
      );
    } else {
      setLayoutArray((prev) => [...prev, layoutValues]);
      setLayoutValues(initialLayoutValues);
      setAddedLayout(true);
    }
  };

  const handleDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));
  };

  const handleDeleteLayoutFromArray = (id) => {
    if (curriculum_id !== undefined && curriculum_id !== "") {
      dispatch(
        deleteCurriculumLayoutAction({
          apiPayloadRequest: {
            _id: id,
            curriculumObjId: curriculum_id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const updatedArray = layoutArray.filter((item) => item._id !== id);
              setLayoutArray(updatedArray);
            }
          },
        })
      );
    } else {
      const updatedArray = layoutArray.filter((item) => item._id !== id);
      setLayoutArray(updatedArray);
    }
  };

  const handleCurriculumLayoutChange = (event) => {
    const { name, value } = event.target;
    setLayoutValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const inititialUrlState = [
    {
      type_of_url: "0",
      url_link: "",
    },
    {
      type_of_url: "1",
      url_link: "",
    },
    {
      type_of_url: "2",
      url_link: "",
    },
  ];

  const initialSlideState = {
    slide_title: "",
    slide_description: "",
    slide_type: [
      {
        bg_asset_url: [
          {
            type_of_url: "0",
            url_link: "",
          },
          {
            type_of_url: "1",
            url_link: "",
          },
          {
            type_of_url: "2",
            url_link: "",
          },
        ],
        slide_bg_color: "#ffffff",
        type_of_slide: "0",
        slide_link_url: [
          {
            type_of_url: "0",
            url_link: "",
          },
          {
            type_of_url: "1",
            url_link: "",
          },
          {
            type_of_url: "2",
            url_link: "",
          },
        ],
      },
    ],
  };

  const initialLessonState = {
    lesson_title: "",
    lesson_duration: "1",
    lesson_description: "",
    lesson_tumbnail_urls: [
      {
        type_of_url: "0",
        url_link: "",
      },
      {
        type_of_url: "1",
        url_link: "",
      },
      {
        type_of_url: "2",
        url_link: "",
      },
    ],
    slides: [initialSlideState],
  };

  const initialCurriculumState = {
    curriculum_name: "",
    curriculum_description: "",
    bg_Images: [
      {
        type_of_url: "0",
        url_link: "",
      },
      {
        type_of_url: "1",
        url_link: "",
      },
      {
        type_of_url: "2",
        url_link: "",
      },
    ],
    lessons: [{ ...initialLessonState }],
  };

  const initialCurriculumErrors = {
    curriculum_name: true,
    curriculum_description: true,
    bg_Images: [
      {
        type_of_url: "0",
        url_link: true,
      },
      {
        type_of_url: "1",
        url_link: true,
      },
      {
        type_of_url: "2",
        url_link: true,
      },
    ],
  };

  const intialLessonErrors = {
    lesson_title: true,
    lesson_duration: true,
    lesson_description: true,
    lesson_tumbnail_urls: [
      {
        type_of_url: "0",
        url_link: true,
      },
      {
        type_of_url: "1",
        url_link: true,
      },
      {
        type_of_url: "2",
        url_link: true,
      },
    ],
  };

  const intialSlideErrors = {
    slide_title: true,
    slide_description: true,
    slide_type: [
      {
        bg_asset_url: [
          {
            type_of_url: "0",
            url_link: true,
          },
          {
            type_of_url: "1",
            url_link: true,
          },
          {
            type_of_url: "2",
            url_link: true,
          },
        ],
        slide_bg_color: true,
        type_of_slide: true,
        slide_link_url: [
          {
            type_of_url: "0",
            url_link: true,
          },
          {
            type_of_url: "1",
            url_link: true,
          },
          {
            type_of_url: "2",
            url_link: true,
          },
        ],
      },
    ],
  };
  const [languagemeta, setLanguagemeta] = useState();
  const [isLanguageSave, setIsLanguageSave] = useState(true);
  const [readLanguageData, setReadLanguageData] = useState([]);
  const [activeTab, setActiveTab] = useState("en");
  const [activeLanguageDetails, setActiveLanguageDetails] = useState({
    language_code: "en",
    language_name: "English",
  });
  const [curriculumValues, setCurriculumValues] = useState(initialCurriculumState);

  const [currCurriculumValues, setCurrCurriculumValues] = useState(initialCurriculumState);

  const [enCurriculumValues, setEnCurriculumValues] = useState();

  const [curriculumErrors, setCurriculumErrors] = useState(initialCurriculumErrors);

  const [showCurriculumErrors, setShowCurriculumErrors] = useState(false);

  const [lessonErrors, setLessonErrors] = useState(intialLessonErrors);
  const [showLessonErrors, setShowLessonErrors] = useState(false);

  const [slideErrors, setSlideErrors] = useState(intialSlideErrors);
  const [showSlideErrors, setShowSlideErrors] = useState(false);

  const [showLessonBox, setShowLessonBox] = useState(false);
  const [showSlideBox, setShowSlideBox] = useState(false);
  const [showCurriculumBox, setShowCurriculumBox] = useState(false);

  const [currLesson, setCurrLesson] = useState(initialLessonState);
  const [currSlide, setCurrSlide] = useState(initialSlideState);

  const [currLessonIndex, setCurrLessonIndex] = useState(0);
  const [currSlideIndex, setCurrSlideIndex] = useState(0);

  const [isLessonEdit, setIsLessonEdit] = useState(false);
  const [isSlideEdit, setIsSlideEdit] = useState(false);

  const [showPreview, setShowPreview] = useState(false);

  const keysArray = Object.keys(inititialUrlState);

  const addNewLesson = () => {
    setShowLessonBox(true);
  };

  const addNewSlide = () => {
    setShowSlideBox(true);
  };

  const cancelLesson = () => {
    setShowLessonErrors(false);
    if (!isLessonEdit) {
      const updatedLesson = [
        ...curriculumValues.lessons.filter((_, idx) => idx !== currLessonIndex),
      ];
      setCurriculumValues((prev) => ({
        ...prev,
        lessons: [...updatedLesson, initialLessonState],
      }));
    } else {
      const prevLessons = [...curriculumValues.lessons];

      prevLessons[currLessonIndex] = currLesson;

      setCurriculumValues((prev) => ({
        ...prev,
        lessons: prevLessons,
      }));
    }

    setLessonErrors(intialLessonErrors);
  };

  const cancelCurriculum = () => {
    setShowCurriculumBox(false);
  };

  const cancelSlide = () => {
    setShowSlideErrors(false);
    setSlideErrors(intialSlideErrors);
    setShowSlideBox(false);
    handleClosePreview();
  };

  const cancelSlideModal = () => {
    setShowSlideErrors(false);
    setCurriculumValues((prev) => ({
      ...prev,
      lessons: prev.lessons.map((lesson, lessonIndex) => {
        if (lessonIndex !== currLessonIndex) {
          return lesson;
        }
        if (!isSlideEdit) {
          const updatedSlides = [...lesson?.slides?.filter((_, idx) => idx !== currSlideIndex)];
          return {
            ...lesson,
            slides: [...updatedSlides, initialSlideState],
          };
        } else {
          return {
            ...lesson,
            slides: lesson?.slides?.map((slide, slideIndex) =>
              slideIndex === currSlideIndex ? currSlide : slide
            ),
          };
        }
      }),
    }));
    setSlideErrors(intialSlideErrors);
    setShowSlideBox(false);
    handleClosePreview();
  };

  const saveLesson = () => {
    setShowLessonErrors(false);
    if (!isLessonEdit) {
      if (curriculum_id !== undefined) {
        dispatch(
          saveCurriculumLessonAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              lesson_title: curriculumValues?.lessons[currLessonIndex]?.lesson_title,
              lesson_description: curriculumValues?.lessons[currLessonIndex]?.lesson_description,
              lesson_duration: curriculumValues?.lessons[currLessonIndex]?.lesson_duration,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Lesson Draft Saved Successfully!", 1);

                keysArray.map((item) => {
                  dispatch(
                    saveCurriculumLessonThumbNailAction({
                      apiPayloadRequest: {
                        _id: curriculum_id,
                        lesson_title: curriculumValues?.lessons[currLessonIndex]?.lesson_title,
                        lessonObjId: res?.data?.lessonObjId,
                        type_of_url: parseInt(
                          curriculumValues?.lessons[currLessonIndex]?.lesson_tumbnail_urls[item]
                            ?.type_of_url
                        ),

                        url_link:
                          curriculumValues?.lessons[currLessonIndex]?.lesson_tumbnail_urls[item]
                            .url_link,
                      },
                      callback: (res) => {
                        if (res?.type == 1) {
                          console.log("Lesson Thumbnail Saved!");
                        } else {
                          Toastiy("Failed to Save Lesson Thumbnails");
                        }
                      },
                    })
                  );
                });

                setCurriculumValues((prev) => ({
                  ...prev,
                  lessons: [
                    ...prev.lessons?.map((lesson, index) =>
                      index === currLessonIndex
                        ? { ...lesson, lessonObjId: res?.data?.lessonObjId }
                        : lesson
                    ),
                    { ...initialLessonState },
                  ],
                }));

                setShowLessonBox(false);
                setCurrLessonIndex(curriculumValues.lessons.length);
                handlePublishCurriculum(false);
              } else {
                Toastiy("Failed to Save Lesson");
              }
            },
          })
        );
      } else {
        setCurriculumValues((prev) => ({
          ...prev,
          lessons: [...prev.lessons, { ...initialLessonState }],
        }));
        setShowLessonBox(false);
        setCurrLessonIndex(curriculumValues.lessons.length);
      }
    } else {
      if (curriculum_id !== undefined) {
        if (
          currLesson.lesson_title !== curriculumValues.lessons[currLessonIndex].lesson_title ||
          currLesson.lesson_description !==
            curriculumValues.lessons[currLessonIndex].lesson_description ||
          currLesson.lesson_duration !== curriculumValues.lessons[currLessonIndex].lesson_duration
        ) {
          dispatch(
            updateCurriculumLessonAction({
              apiPayloadRequest: {
                _id: curriculum_id,
                lesson_title: curriculumValues?.lessons[currLessonIndex]?.lesson_title,
                lessonObjId: curriculumValues?.lessons[currLessonIndex]?.lessonObjId,
                lesson_description: curriculumValues?.lessons[currLessonIndex]?.lesson_description,
                lesson_duration: curriculumValues?.lessons[currLessonIndex]?.lesson_duration,
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Lesson Draft Updated Successfully!", 1);
                  setShowLessonBox(false);
                  setCurrLessonIndex(curriculumValues.lessons.length);
                }
              },
            })
          );
        }

        keysArray.map((item) => {
          if (
            !currLesson.lesson_tumbnail_urls?.[item]?.url_link ||
            currLesson.lesson_tumbnail_urls?.length === 0
          ) {
            dispatch(
              saveCurriculumLessonThumbNailAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  lessonObjId: curriculumValues?.lessons?.[currLessonIndex]?.lessonObjId,
                  type_of_url: parseInt(
                    curriculumValues?.lessons[currLessonIndex]?.lesson_tumbnail_urls?.[item]
                      ?.type_of_url
                  ),

                  url_link:
                    curriculumValues?.lessons?.[currLessonIndex]?.lesson_tumbnail_urls[item]
                      .url_link,
                },
                callback: (res) => {
                  if (res?.type == 1) {
                    Toastiy("Lesson Thumbnail Saved!", 1);
                  } else {
                    Toastiy("Failed to Save Lesson Thumbnails");
                  }
                },
              })
            );
          } else if (
            currLesson.lesson_tumbnail_urls?.[item]?.url_link !==
            curriculumValues?.lessons?.[currLessonIndex]?.lesson_tumbnail_urls?.[item]?.url_link
          ) {
            dispatch(
              updateCurriculumLessonThumbNailAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  lessonObjId: curriculumValues?.lessons[currLessonIndex]?.lessonObjId,
                  type_of_url: parseInt(
                    curriculumValues?.lessons[currLessonIndex]?.lesson_tumbnail_urls[item]
                      ?.type_of_url
                  ),

                  url_link:
                    curriculumValues?.lessons[currLessonIndex]?.lesson_tumbnail_urls[item].url_link,
                },
                callback: (res) => {
                  if (res?.type == 1) {
                    Toastiy("Lesson Thumbnail Updated!", 1);
                  } else {
                    Toastiy("Failed to Update Lesson Thumbnails");
                  }
                },
              })
            );
          }
        });
        setShowLessonBox(false);
        handlePublishCurriculum(false);
      } else {
        setShowLessonBox(false);
        setCurrLessonIndex(curriculumValues?.lessons?.length);
      }
    }
  };

  const saveSlide = () => {
    setShowSlideErrors(false);
    if (!isSlideEdit) {
      if (curriculum_id !== undefined) {
        dispatch(
          saveCurriculumLessonSlideAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
              slide_title:
                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_title,
              slide_description:
                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                  ?.slide_description,
            },
            callback: (res) => {
              if (res?.type === 1) {
                saveLessonSlideBgColor(
                  curriculum_id,
                  {
                    ...curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex],
                    slideObjId: res?.data?.slideObjId,
                  },
                  curriculumValues?.lessons[currLessonIndex].lesson_title
                );
                cancelSlide();
                handlePublishCurriculum(false);
                Toastiy("Slide Draft Saved Successfully!", 1);
              } else {
                Toastiy("Failed to Save Lesson Slide");
              }
            },
          })
        );
      } else {
        setCurriculumValues((prev) => ({
          ...prev,
          lessons: prev.lessons.map((lesson, index) => {
            if (index === currLessonIndex) {
              return {
                ...lesson,
                slides: [...lesson.slides, { ...initialSlideState }],
              };
            } else {
              return lesson;
            }
          }),
        }));
      }
    } else {
      if (curriculum_id !== undefined) {
        // if (
        //   currSlide?.slide_title !==
        //     curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_title ||
        //   currSlide?.slide_description !==
        //     curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_description
        // ) {
        dispatch(
          updateCurriculumLessonSlideAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
              lesson_title: curriculumValues?.lessons[currLessonIndex].lesson_title,
              slideObjId:
                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slideObjId,
              slide_title:
                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_title,
              slide_description:
                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                  ?.slide_description,
            },
            callback: (response) => {
              if (response?.type === 1) {
                if (
                  currSlide?.slide_type[0]?.type_of_slide !==
                    curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                      ?.slide_type[0]?.type_of_slide ||
                  currSlide?.slide_type[0]?.slide_bg_color !==
                    curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                      ?.slide_type[0]?.slide_bg_color ||
                  !currSlide?.slide_type ||
                  currSlide?.slide_type?.length === 0 ||
                  (curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
                    ?.type_of_slide !== "2" &&
                    (currSlide?.slide_type[0]?.type_of_slide !==
                      curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                        ?.slide_type[0]?.type_of_slide ||
                      currSlide?.slide_type[0]?.bg_asset_url[0]?.url_link !==
                        curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                          ?.slide_type[0]?.bg_asset_url[0]?.url_link ||
                      currSlide?.slide_type[0]?.bg_asset_url[1]?.url_link !==
                        curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                          ?.slide_type[0]?.bg_asset_url[1]?.url_link ||
                      currSlide?.slide_type[0]?.bg_asset_url[2]?.url_link !==
                        curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                          ?.slide_type[0]?.bg_asset_url[2]?.url_link))
                ) {
                  dispatch(
                    saveCurriculumLessonSlideTypeAction({
                      apiPayloadRequest: {
                        _id: curriculum_id,
                        lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
                        slideObjId:
                          curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                            ?.slideObjId,
                        type_of_slide:
                          curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                            ?.slide_type[0]?.type_of_slide,
                        slide_bg_color:
                          curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                            ?.slide_type[0]?.slide_bg_color,
                      },
                      callback: (res) => {
                        if (res?.type === 1) {
                          if (
                            curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                              ?.slide_type[0]?.type_of_slide !== "2" &&
                            (currSlide?.slide_type[0]?.type_of_slide !==
                              curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                                ?.slide_type[0]?.type_of_slide ||
                              currSlide?.slide_type[0]?.bg_asset_url[0]?.url_link !==
                                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                                  ?.slide_type[0]?.bg_asset_url[0]?.url_link ||
                              currSlide?.slide_type[0]?.bg_asset_url[1]?.url_link !==
                                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                                  ?.slide_type[0]?.bg_asset_url[1]?.url_link ||
                              currSlide?.slide_type[0]?.bg_asset_url[2]?.url_link !==
                                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                                  ?.slide_type[0]?.bg_asset_url[2]?.url_link)
                          ) {
                            saveLessonSlideBgImgVid(
                              curriculum_id,
                              res?.data?.slidetypeObjId,
                              curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex],
                              curriculumValues?.lessons[currLessonIndex].lesson_title
                            );
                          }
                          handlePublishCurriculum(false);
                          Toastiy("Slide Type Updated Successfully!", 1);
                        } else {
                          Toastiy("Failed to Save Slide Type");
                        }
                      },
                    })
                  );
                }
                handlePublishCurriculum(false);
                Toastiy("Slide Draft Updated Successfully!", 1);
              } else {
                Toastiy("Failed to Save Lesson Slide");
              }
            },
          })
        );
        // }

        // if (
        //   !enCurriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type ||
        //   enCurriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type
        //     ?.length === 0
        // ) {
        //   saveLessonSlideBgColor(
        //     curriculum_id,
        //     curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex],
        //     curriculumValues?.lessons[currLessonIndex].lesson_title
        //   );
        // } else if (
        //   currSlide?.slide_title !==
        //     curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_title ||
        //   currSlide?.slide_type[0]?.type_of_slide !==
        //     curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
        //       ?.type_of_slide ||
        //   currSlide?.slide_type[0]?.slide_bg_color !==
        //     curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
        //       ?.slide_bg_color
        // ) {
        //   dispatch(
        //     updateCurriculumLessonSlideTypeAction({
        //       apiPayloadRequest: {
        //         _id: curriculum_id,
        //         lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
        //         lesson_title: curriculumValues?.lessons[currLessonIndex]?.lesson_title,
        //         slideObjId:
        //           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slideObjId,
        //         slide_title:
        //           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_title,
        //         type_of_slide:
        //           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
        //             ?.type_of_slide,
        //         slide_bg_color:
        //           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
        //             ?.slide_bg_color,
        //         slidetypeObjId:
        //           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
        //             ?.slidetypeObjId,
        //       },
        //       callback: (res) => {
        //         if (res?.type === 1) {
        //           if (
        //             curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //               ?.slide_type[0]?.type_of_slide !== "2"
        //           ) {
        //             if (
        //               currSlide?.slide_type[0]?.type_of_slide !==
        //                 curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                   ?.slide_type[0]?.type_of_slide ||
        //               currSlide?.slide_type[0]?.bg_asset_url[0]?.url_link !==
        //                 curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                   ?.slide_type[0]?.bg_asset_url[0]?.url_link ||
        //               currSlide?.slide_type[0]?.bg_asset_url[1]?.url_link !==
        //                 curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                   ?.slide_type[0]?.bg_asset_url[1]?.url_link ||
        //               currSlide?.slide_type[0]?.bg_asset_url[2]?.url_link !==
        //                 curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                   ?.slide_type[0]?.bg_asset_url[2]?.url_link
        //             ) {
        //               keysArray.map((item) => {
        //                 if (
        //                   currSlide?.slide_type?.[0]?.bg_asset_url?.[item]?.url_link !==
        //                   curriculumValues?.lessons[currLessonIndex]?.slides?.[currSlideIndex]
        //                     ?.slide_type?.[0]?.bg_asset_url?.[item]?.url_link
        //                 ) {
        //                   dispatch(
        //                     updateCurriculumLessonSlideTypeBGAction({
        //                       apiPayloadRequest: {
        //                         _id: curriculum_id,
        //                         lesson_title:
        //                           curriculumValues?.lessons[currLessonIndex]?.lesson_title,
        //                         lessonObjId:
        //                           curriculumValues?.lessons[currLessonIndex]?.lessonObjId,
        //                         slide_title:
        //                           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                             ?.slide_title,
        //                         slideObjId:
        //                           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                             ?.slideObjId,
        //                         slidetypeObjId:
        //                           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                             ?.slide_type[0]?.slidetypeObjId,
        //                         type_of_slide:
        //                           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                             ?.slide_type[0]?.type_of_slide,
        //                         type_of_url: parseInt(
        //                           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                             ?.slide_type[0]?.bg_asset_url[item]?.type_of_url
        //                         ),
        //                         url_link:
        //                           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                             ?.slide_type[0]?.bg_asset_url[item]?.url_link,
        //                       },
        //                       callback: (res) => {
        //                         if (res?.type === 1) {
        //                           Toastiy("Slide BG of Image or Video Updated Successfully!");
        //                         } else {
        //                           Toastiy("Failed to Save slide BG of Image or Video");
        //                         }
        //                       },
        //                     })
        //                   );
        //                 }
        //               });
        //             }
        //           }
        //         } else {
        //           Toastiy("Failed to Save Slide Type");
        //         }
        //       },
        //     })
        //   );
        // }
      }
    }

    setShowSlideBox(false);
    handleClosePreview();
    setCurrSlideIndex(curriculumValues?.lessons[currLessonIndex]?.slides?.length);
  };

  const storyData = [
    {
      content: (props) => (
        <div
          className="flex flex-col justify-center relative bg-red-100"
          style={{
            height: "100%",
            width: "100%",
            marginTop: "40px",
            borderRadius: "10px",
          }}
        >
          <div className="px-[5%] h-full flex flex-col justify-center" style={{ zIndex: 100 }}>
            <p
              style={{
                marginTop: 5,
                fontSize: "10px",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              {curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_title}
            </p>
            <p
              style={{
                marginTop: 5,
                fontSize: "25px",
                textTransform: "capitalize",
                fontFamily: "serif",
                fontWeight: "bold",
                maxHeight: "90%",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                    ?.slide_description,
              }}
            ></p>
          </div>
          {curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
            ?.type_of_slide === "0" && (
            <div className="absolute w-full h-full">
              <img
                className="h-full w-full"
                src={
                  curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                    ?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link
                }
              />
            </div>
          )}
          {curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]?.slide_type?.[0]
            ?.type_of_slide === "1" && (
            <div className="absolute w-full h-full">
              <video className="h-full w-full">
                <source
                  src={
                    curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                      ?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link
                  }
                />
              </video>
            </div>
          )}
          {curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
            ?.type_of_slide === "3" && (
            <div
              className={`absolute w-full h-full`}
              style={{
                backgroundColor: `${curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]?.slide_type?.[0]?.slide_bg_color}`,
              }}
            ></div>
          )}
        </div>
      ),
    },
  ];

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleOpenPreview = () => {
    setShowPreview(true);
  };

  //HandleChange
  const handleChange = (e, category, urlIndex, lessonIndex, slideIndex) => {
    const { name, value, files } = e.target;

    const updatedCurriculum = structuredClone(curriculumValues);
    const updatedCurriculumErrors = { ...curriculumErrors };
    const updatedLessonErrors = { ...lessonErrors };
    const updatedSlideErrors = { ...slideErrors };

    switch (category) {
      case "curriculum":
        if (name === "bg_Images") {
          updatedCurriculum[name][urlIndex].url_link = value;
          updatedCurriculumErrors[name][urlIndex].url_link = value.length > 0 ? false : true;
        } else {
          updatedCurriculum[name] = value;
          updatedCurriculumErrors[name] = value.length > 0 ? false : true;
        }
        break;
      case "curriculumFile":
        if (name === "bg_Images" && files.length > 0) {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);

          dispatch(
            uploadAssestsAction({
              apiPayloadRequest: formData,
              callback: (res) => {
                if (res && res.url) {
                  updatedCurriculum[name][urlIndex].url_link = res.url;
                  updatedCurriculumErrors[name][urlIndex].url_link =
                    res.url.length > 0 ? false : true;

                  setCurriculumValues(updatedCurriculum);
                  setCurriculumErrors(updatedCurriculumErrors);
                }
                setLoading(false);
              },
            })
          );
        }
        break;
      case "lesson":
        if (name === "lesson_tumbnail_urls") {
          if (
            !updatedCurriculum?.lessons?.[lessonIndex]?.lesson_tumbnail_urls ||
            updatedCurriculum?.lessons?.[lessonIndex]?.lesson_tumbnail_urls?.length === 0
          ) {
            updatedCurriculum.lessons[lessonIndex].lesson_tumbnail_urls =
              initialLessonState?.lesson_tumbnail_urls;
          }
          updatedCurriculum.lessons[lessonIndex][name][urlIndex].url_link = value;
          updatedLessonErrors[name][urlIndex].url_link = value.length > 0 ? false : true;
        } else {
          updatedCurriculum.lessons[lessonIndex][name] = value;
          updatedLessonErrors[name] = value.length > 0 ? false : true;
        }
        break;
      case "lessonFile":
        if (name === "lesson_tumbnail_urls") {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);
          dispatch(
            uploadAssestsAction({
              apiPayloadRequest: formData,
              callback: (res) => {
                if (res && res.url) {
                  if (updatedCurriculum.lessons[lessonIndex][name]?.length === 0) {
                    updatedCurriculum.lessons[lessonIndex][name] =
                      initialLessonState.lesson_tumbnail_urls;
                  }
                  updatedCurriculum.lessons[lessonIndex][name][urlIndex].url_link = res.url;
                  updatedLessonErrors[name][urlIndex].url_link = res.url.length > 0 ? false : true;

                  setCurriculumValues(updatedCurriculum);
                  setLessonErrors(updatedLessonErrors);
                }
                setLoading(false);
              },
            })
          );
        }
        break;
      case "slide":
        if (name === "bg_asset_url" || name === "slide_link_url") {
          if (updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type?.length === 0) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type =
              initialSlideState?.slide_type;
          } else if (
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type?.length > 0 &&
            Object.keys(updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0])
              ?.length === 1
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0] = {
              ...initialSlideState?.slide_type[0],
              ...updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0],
            };
          }

          if (
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0]?.bg_asset_url
              ?.length === 0
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0].bg_asset_url =
              initialSlideState?.slide_type[0]?.bg_asset_url;
          }

          if (
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0]?.slide_link_url
              ?.length === 0
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0].slide_link_url =
              initialSlideState?.slide_type[0]?.slide_link_url;
          }

          updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0][name][
            urlIndex
          ].url_link = value;

          updatedSlideErrors.slide_type[0][name][urlIndex].url_link =
            value.length > 0 ? false : true;
        } else if (name === "type_of_slide") {
          if (updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type?.length > 0) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0][name] = value;
          } else {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type = [
              { [name]: value },
            ];
          }

          updatedSlideErrors.slide_type[0][name] = value.length > 0 ? false : true;
        } else {
          updatedCurriculum.lessons[lessonIndex].slides[slideIndex][name] = value;

          updatedSlideErrors[name] = value.length > 0 ? false : true;
        }
        break;
      case "slideImage":
        if (name === "bg_asset_url") {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);

          dispatch(
            uploadAssestsAction({
              apiPayloadRequest: formData,
              callback: (res) => {
                if (res.url) {
                  updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0][name][
                    urlIndex
                  ].url_link = res?.url;
                  updatedSlideErrors.slide_type[0][name][urlIndex].url_link =
                    res.url.length > 0 ? false : true;

                  setCurriculumValues(updatedCurriculum);
                  setSlideErrors(updatedSlideErrors);
                }
                setLoading(false);
              },
            })
          );

          if (updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type?.length === 0) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type =
              initialSlideState?.slide_type;
          } else if (
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type?.length > 0 &&
            Object.keys(updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0])
              ?.length === 1
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0] = {
              ...initialSlideState?.slide_type[0],
              ...updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0],
            };
          }

          if (
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0]?.bg_asset_url
              ?.length === 0
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0].bg_asset_url =
              initialSlideState?.slide_type[0]?.bg_asset_url;
          }

          if (
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0]?.slide_link_url
              ?.length === 0
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0].slide_link_url =
              initialSlideState?.slide_type[0]?.slide_link_url;
          }

          updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0][name][
            urlIndex
          ].url_link = value;

          updatedSlideErrors.slide_type[0][name][urlIndex].url_link =
            value.length > 0 ? false : true;
        }
        break;
      case "slideVideo":
        if (name === "bg_asset_url") {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);

          dispatch(
            uploadVideosAction({
              apiPayloadRequest: formData,
              callback: (res) => {
                if (res.url) {
                  updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0][name][
                    urlIndex
                  ].url_link = res?.url;
                  updatedSlideErrors.slide_type[0][name][urlIndex].url_link =
                    res.url.length > 0 ? false : true;

                  setCurriculumValues(updatedCurriculum);
                  setSlideErrors(updatedSlideErrors);
                }
                setLoading(false);
              },
            })
          );

          if (updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type?.length === 0) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type =
              initialSlideState?.slide_type;
          } else if (
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type?.length > 0 &&
            Object.keys(updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0])
              ?.length === 1
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0] = {
              ...initialSlideState?.slide_type[0],
              ...updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0],
            };
          }

          updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0][name][
            urlIndex
          ].url_link = value;

          updatedSlideErrors.slide_type[0][name][urlIndex].url_link =
            value.length > 0 ? false : true;
        }
        break;
      default:
        break;
    }

    setCurriculumErrors(updatedCurriculumErrors);
    setLessonErrors(updatedLessonErrors);
    setSlideErrors(updatedSlideErrors);
    setCurriculumValues(updatedCurriculum);
  };

  const handleSlideBgChange = (value, lessonIndex, slideIndex) => {
    const updatedCurriculum = { ...curriculumValues };

    updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0]["slide_bg_color"] =
      value.hex;

    setCurriculumValues(updatedCurriculum);
  };

  //*********************************************//
  const saveCurriculum = () => {
    setShowCurriculumErrors(false);
    if (curriculum_id !== undefined) {
      if (
        curriculumValues?.curriculum_name !== currCurriculumValues?.curriculum_name ||
        curriculumValues?.curriculum_description !== currCurriculumValues?.curriculum_description
      ) {
        setLoading(true);
        dispatch(
          updateCurriculumAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              publish: false,
              curriculum_name: curriculumValues?.curriculum_name,
              curriculum_description: curriculumValues?.curriculum_description,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Curriculum Draft Updated Successfully!", 1);
                setCurrCurriculumValues((prev) => ({
                  ...prev,
                  _id: curriculum_id,
                  curriculum_name: curriculumValues?.curriculum_name,
                  curriculum_description: curriculumValues?.curriculum_description,
                }));
                setLoading(false);
                setShowCurriculumBox(false);
              }
            },
          })
        );
      }

      curriculumValues?.bg_Images?.map((item, index) => {
        if (
          curriculumValues?.bg_Images?.[index]?.url_link !==
          currCurriculumValues?.bg_Images?.[index]?.url_link
        ) {
          setTimeout(() => {
            dispatch(
              updateBGCurriculumAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  type_of_url: parseInt(curriculumValues.bg_Images?.[index]?.type_of_url),
                  url_link: curriculumValues.bg_Images?.[index]?.url_link,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    Toastiy("Curriculum BG Updated Successfully!", 1);
                    setCurrCurriculumValues((prev) => ({
                      ...prev,
                      bg_Images: prev.bg_Images.map((item, i) => ({
                        type_of_url: curriculumValues.bg_Images?.[i]?.type_of_url,
                        url_link: curriculumValues.bg_Images?.[i]?.url_link,
                      })),
                    }));
                  } else {
                    Toastiy("Failed to Save Curriculum BG");
                  }
                },
              })
            );
          }, index * 500);
        }
      });
    } else {
      setLoading(true);
      dispatch(
        saveCurriculumAction({
          apiPayloadRequest: {
            publish: false,
            curriculum_name: curriculumValues?.curriculum_name,
            curriculum_description: curriculumValues?.curriculum_description,
          },
          callback: async (response) => {
            await saveCurriculumBg(response);
            setCurrCurriculumValues((prev) => ({
              ...prev,
              _id: response?.data[0]?._id,
              curriculum_name: curriculumValues?.curriculum_name,
              curriculum_description: curriculumValues?.curriculum_description,
            }));
            setCurriculumId(response?.data[0]?._id);
            Toastiy("Curriculum Draft Updated Successfully!", 1);
            setLoading(false);
            setShowCurriculumBox(false);
          },
        })
      );
    }
  };
  const handlePublishCurriculum = (publishType = true) => {
    setShowCurriculumErrors(false);
    if (curriculum_id !== undefined) {
      dispatch(
        updateCurriculumAction({
          apiPayloadRequest: {
            _id: curriculum_id,
            publish: publishType,
          },
          callback: (res) => {
            if (res?.type === 1) {
              if (publishType) {
                Toastiy("Curriculum Published Successfully!", 1);
                navigate("/curriculum/all");
              } else {
                setCurriculumValues((prev) => ({ ...prev, publish: publishType + "" }));
              }
            }
          },
        })
      );
    }
  };

  // const handlePublishCurriculum = () => {
  //   setShowCurriculumErrors(false);
  //   if (curriculum_id !== undefined) {
  //     if (
  //       curriculumValues?.curriculum_name !== currCurriculumValues?.curriculum_name ||
  //       curriculumValues?.curriculum_description !== currCurriculumValues?.curriculum_description
  //     ) {
  //       dispatch(
  //         updateCurriculumAction({
  //           apiPayloadRequest: {
  //             _id: curriculum_id,
  //             curriculum_name: curriculumValues?.curriculum_name,
  //             curriculum_description: curriculumValues?.curriculum_description,
  //           },
  //           callback: (res) => {
  //             if (res?.type === 1) {
  //               Toastiy("Curriculum Updated Successfully!", 1);
  //               setCurrCurriculumValues((prev) => ({
  //                 ...prev,
  //                 _id: curriculum_id,
  //                 curriculum_name: curriculumValues?.curriculum_name,
  //                 curriculum_description: curriculumValues?.curriculum_description,
  //               }));
  //               navigate("/curriculum/all");
  //             }
  //           },
  //         })
  //       );
  //     }

  //     const keysArray = Object.keys(inititialUrlState);

  //     curriculumValues?.bg_Images?.map((item, index) => {
  //       if (
  //         curriculumValues?.bg_Images?.[index]?.url_link !==
  //         currCurriculumValues?.bg_Images?.[index]?.url_link
  //       ) {
  //         setTimeout(() => {
  //           dispatch(
  //             updateBGCurriculumAction({
  //               apiPayloadRequest: {
  //                 _id: curriculum_id,
  //                 type_of_url: parseInt(curriculumValues.bg_Images?.[index]?.type_of_url),
  //                 url_link: curriculumValues.bg_Images?.[index]?.url_link,
  //               },
  //               callback: (res) => {
  //                 if (res?.type === 1) {
  //                   Toastiy("Curriculum BG Updated Successfully!", 1);
  //                   setCurrCurriculumValues((prev) => ({
  //                     ...prev,
  //                     bg_Images: prev.bg_Images.map((item, i) => ({
  //                       type_of_url: curriculumValues.bg_Images?.[i]?.type_of_url,
  //                       url_link: curriculumValues.bg_Images?.[i]?.url_link,
  //                     })),
  //                   }));
  //                 } else {
  //                   Toastiy("Failed to Save Curriculum BG");
  //                 }
  //               },
  //             })
  //           );
  //         }, index * 500);
  //       }
  //     });
  //   } else {
  //     setLoading(true);
  //     dispatch(
  //       saveCurriculumAction({
  //         apiPayloadRequest: {
  //           curriculum_name: curriculumValues?.curriculum_name,
  //           curriculum_description: curriculumValues?.curriculum_description,
  //         },
  //         callback: async (response) => {
  //           await saveCurriculumBg(response);
  //           await saveLessons(response);

  //           layoutArray.map((layout, index) => {
  //             setTimeout(() => {
  //               dispatch(
  //                 saveCurriculumLayoutAction({
  //                   apiPayloadRequest: {
  //                     _id: layout?._id,
  //                     curriculumObjId: response?.data[0]?._id,
  //                     order: layout?.order,
  //                     // title: layout?.title || "",
  //                     // subtitle: layout?.subtitle || "",
  //                     // expiry: layout?.expiry,
  //                   },
  //                 })
  //               );
  //             }, index * 500);
  //           });
  //           Toastiy("Curriculum Updated Successfully!", 1);
  //           navigate("/curriculum/all");
  //         },
  //       })
  //     );
  //   }
  // };

  const saveCurriculumBg = async (curriculumResponse) => {
    if (curriculumResponse?.type === 1) {
      const id = curriculumResponse?.data[0]?._id;

      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveBGCurriculumAction({
              apiPayloadRequest: {
                _id: id,
                type_of_url: parseInt(curriculumValues.bg_Images[item].type_of_url),
                url_link: curriculumValues.bg_Images[item].url_link,
              },
              callback: (res) => {
                if (res?.type !== 1) {
                  Toastiy("Failed to Save Curriculum BG");
                }
              },
            })
          );
        }, 500 * (index + 1));
      });
    }
  };

  const saveLessons = async (curriculumResponse) => {
    if (curriculumResponse?.type === 1) {
      const id = curriculumResponse?.data[0]?._id;
      curriculumValues.lessons.map((lessonItem, index) => {
        if (index !== curriculumValues.lessons.length - 1) {
          setTimeout(() => {
            dispatch(
              saveCurriculumLessonAction({
                apiPayloadRequest: {
                  _id: id,
                  lesson_title: lessonItem?.lesson_title,
                  lesson_description: lessonItem?.lesson_description,
                  lesson_duration: lessonItem?.lesson_duration,
                },
                callback: (response) => {
                  if (response?.type === 1) {
                    saveLessonThumbnails(id, lessonItem);
                    saveLessonSlides(id, lessonItem);
                    handlePublishCurriculum(false);
                  } else {
                    Toastiy("Failed to Save Curriculum BG");
                  }
                },
              })
            );
          }, 5000 * (index + 1));
        }
      });
    }
  };

  const saveLessonThumbnails = (id, lessonItem) => {
    keysArray.map((item, index) => {
      setTimeout(() => {
        dispatch(
          saveCurriculumLessonThumbNailAction({
            apiPayloadRequest: {
              _id: id,
              lesson_title: lessonItem?.lesson_title,
              lessonObjId: lessonItem?.lessonObjId,
              type_of_url: parseInt(lessonItem?.lesson_tumbnail_urls[item].type_of_url),

              url_link: lessonItem?.lesson_tumbnail_urls[item].url_link,
            },
            callback: (res) => {
              if (res?.type !== 1) {
                Toastiy("Failed to Save Lesson Thumbnails");
              }
            },
          })
        );
      }, 500 * (index + 1));
    });
  };

  const saveLessonSlides = (id, lessonsData) => {
    lessonsData?.slides.map((slideItem, index) => {
      if (index !== lessonsData?.slides?.length - 1) {
        setTimeout(() => {
          dispatch(
            saveCurriculumLessonSlideAction({
              apiPayloadRequest: {
                _id: id,
                lesson_title: lessonsData?.lesson_title,
                lessonObjId: lessonsData?.lessonObjId,
                slide_title: slideItem?.slide_title,
                slide_description: slideItem?.slide_description,
              },
              callback: (response) => {
                if (response?.type === 1) {
                  saveLessonSlideBgColor(
                    id,
                    {
                      ...slideItem,
                      slideObjId: response?.data?.slideObjId,
                    },
                    lessonsData?.lesson_title
                  );
                  cancelSlide();
                  handlePublishCurriculum(false);
                  Toastiy("Slide Draft Saved Successfully!", 1);
                } else {
                  Toastiy("Failed to Save Lesson Slide");
                }
              },
            })
          );
        }, 3000 * (index + 1));
      }
    });
  };

  const saveLessonSlideBgColor = (id, slideData, lesson_title) => {
    dispatch(
      saveCurriculumLessonSlideTypeAction({
        apiPayloadRequest: {
          _id: id,
          lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
          slideObjId: slideData?.slideObjId,
          type_of_slide: slideData?.slide_type[0]?.type_of_slide,
          slide_bg_color: slideData?.slide_type[0]?.slide_bg_color,
        },
        callback: (res) => {
          if (res?.type === 1) {
            if (slideData?.slide_type[0]?.type_of_slide !== "2") {
              saveLessonSlideBgImgVid(id, res?.data?.slidetypeObjId, slideData, lesson_title);
              // saveLessonSlideLink(id, slideData, lesson_title);
            } else {
              getCurriculumDetails();
              // saveLessonSlideLink(id, slideData, lesson_title);
            }
            handlePublishCurriculum(false);
            Toastiy("Slide Type Saved Successfully!", 1);
          } else {
            Toastiy("Failed to Save Slide Type");
          }
        },
      })
    );
  };

  const saveLessonSlideBgImgVid = (id, slidetypeObjId, slideData, lesson_title) => {
    keysArray.map((item, index) => {
      setTimeout(() => {
        dispatch(
          saveCurriculumLessonSlideTypeBGAction({
            apiPayloadRequest: {
              _id: id,
              lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
              slideObjId: slideData?.slideObjId,
              slidetypeObjId: slidetypeObjId,
              type_of_url: parseInt(slideData?.slide_type[0]?.bg_asset_url[item].type_of_url),
              url_link: slideData?.slide_type[0]?.bg_asset_url[item].url_link,
            },
            callback: (res) => {
              if (res?.type !== 1) {
                Toastiy("Failed to Save slide BG of Image or Video");
              } else {
                getCurriculumDetails();
              }
            },
          })
        );
      }, 500 * (index + 1));
    });
  };

  const saveLessonSlideLink = (id, slideData, lesson_title) => {
    keysArray.map((item, index) => {
      setTimeout(() => {
        dispatch(
          saveCurriculumLessonSlideTypeLinkAction({
            apiPayloadRequest: {
              _id: id,
              lesson_title: lesson_title,
              lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
              slideObjId: slideData?.slideObjId,
              slide_title: slideData?.slide_title,
              slidetypeObjId: slideData?.slide_type[0]?.slidetypeObjId,
              type_of_slide: slideData?.slide_type[0]?.type_of_slide,
              type_of_url: parseInt(slideData?.slide_type[0]?.slide_link_url[item].type_of_url),
              url_link: slideData?.slide_type[0]?.slide_link_url[0].url_link,
            },
            callback: (res) => {
              if (res?.type !== 1) {
                Toastiy("Failed to Save Slide Link");
              }
            },
          })
        );
      }, 500 * (index + 1));
    });
  };

  const validateSlideComplete = (slide) => {
    if (!slide?.slide_description) {
      return false;
    } else if (!slide?.slide_title) {
      return false;
    } else if (!slide?.slide_type || slide?.slide_type?.length === 0) {
      return false;
    }
    if (slide?.slide_type?.[0]?.type_of_slide === "2") {
      if (!slide?.slide_type?.[0]?.slide_bg_color) return false;
    } else {
      if (slide?.slide_type?.[0]?.bg_asset_url?.length === 0) {
        return false;
      }
      for (let i = 0; i < slide?.slide_type?.[0]?.bg_asset_url?.length; i++) {
        if (
          !slide?.slide_type?.[0]?.bg_asset_url?.[i]?.type_of_url ||
          !slide?.slide_type?.[0]?.bg_asset_url?.[i]?.url_link
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const validateLessonComplete = (lesson) => {
    if (!lesson?.lesson_description) {
      return false;
    } else if (!lesson?.lesson_title) {
      return false;
    } else if (lesson?.slides?.length - 1 <= 0) {
      return false;
    }
    for (let i = 0; i < lesson?.lesson_tumbnail_urls?.length; i++) {
      if (
        !lesson?.lesson_tumbnail_urls?.[i]?.type_of_url ||
        !lesson?.lesson_tumbnail_urls?.[i]?.url_link
      ) {
        return false;
      }
    }
    for (let i = 0; i < lesson?.slides?.length - 1; i++) {
      const slideValid = validateSlideComplete(lesson?.slides?.[i]);
      if (!slideValid) {
        return false;
      }
    }
    return true;
  };

  const validateCurriculumComplete = (curriculum) => {
    if (!curriculum?.curriculum_name) {
      return false;
    } else if (!curriculum?.curriculum_description) {
      return false;
    } else if (curriculum?.lessons?.length - 1 <= 0) {
      return false;
    }
    for (let i = 0; i < curriculum?.bg_Images?.length; i++) {
      if (!curriculum?.bg_Images?.[i]?.type_of_url || !curriculum?.bg_Images?.[i]?.url_link) {
        return false;
      }
    }
    for (let i = 0; i < curriculum?.lessons?.length - 1; i++) {
      const lessonValid = validateLessonComplete(curriculum?.lessons?.[i]);
      if (!lessonValid) {
        return false;
      }
    }
    return true;
  };

  const getAllLanguageResponse = (response) => {
    if (response?.type === 1) {
      setReadLanguageData(response?.data);
    }
  };

  const convertCurriculumToString = (curriculumObject) => {
    let curriculumString = `${curriculumObject?.curriculum_name}$#${curriculumObject?.curriculum_description}$#`;

    for (const lesson of curriculumObject?.lessons) {
      curriculumString += `${lesson?.lesson_title}@#${lesson?.lesson_description}@#`;

      for (const slide of lesson?.slides) {
        curriculumString += `${slide?.slide_title}!@${slide?.slide_description}!@`;
        curriculumString += "***";
      }

      curriculumString += "+++";
    }
    return curriculumString;
  };

  const updateStateWithTranslation = () => {
    const combinedText = convertCurriculumToString(curriculumValues);

    if (activeTab.length > 0) {
      dispatch(
        translateLanguageAction({
          apiPayloadRequest: {
            targetcode: activeTab,
            translatetext: combinedText,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const restructedData = reconstructCurriculumFromString(res?.data);
              if (restructedData) {
                setCurriculumValues(restructedData);
              }
            }
          },
        })
      );
    }
  };

  const reconstructCurriculumFromString = (curriculumString) => {
    if (!curriculumString) return null;

    const [curriculum_name, curriculum_description, lessonsString] = curriculumString.split("$#");
    const lessonsArray = lessonsString?.split("+++") || [];

    const curriculumObject = {
      curriculum_name,
      curriculum_description,
      lessons: [],
    };

    lessonsArray.forEach((lessonString) => {
      if (!lessonString) return;

      const [lesson_title, lesson_description, slidesString] = lessonString.split("@#");
      const slideArray = slidesString?.split("***") || [];

      const lessonObject = {
        lesson_title,
        lesson_description,
        slides: [],
      };

      slideArray.forEach((slideString) => {
        if (!slideString) return;

        const [slide_title, slide_description] = slideString.split("!@");
        lessonObject.slides.push({
          slide_title,
          slide_description,
        });
      });

      curriculumObject.lessons.push(lessonObject);
    });

    const updatedCurriculum = { ...curriculumValues };

    updatedCurriculum.curriculum_name = curriculumObject?.curriculum_name;
    updatedCurriculum.curriculum_description = curriculumObject?.curriculum_description || "";

    curriculumObject?.lessons?.map((lesson, lessonIndex) => {
      updatedCurriculum.lessons[lessonIndex] = {
        ...(updatedCurriculum.lessons[lessonIndex] || {}),
        lesson_title: lesson?.lesson_title || "",
        lesson_description: lesson?.lesson_description || "",
        slides: lesson?.slides?.map((slide, i) => ({
          ...updatedCurriculum?.lessons[lessonIndex]?.slides[i],
          slide_title: slide?.slide_title || "",
          slide_description: slide?.slide_description || "",
        })),
      };
    });

    return curriculumObject;
  };

  useEffect(() => {
    if (activeTab === "en") {
      const updatedCurriculumErrors = {
        curriculum_name: curriculumValues?.curriculum_name?.length > 0 ? false : true,
        curriculum_description: curriculumValues?.curriculum_description?.length > 0 ? false : true,
        bg_Images: [
          {
            type_of_url: "0",
            url_link: curriculumValues?.bg_Images?.[0]?.url_link?.length > 0 ? false : true,
          },
          {
            type_of_url: "1",
            url_link: curriculumValues?.bg_Images?.[1]?.url_link?.length > 0 ? false : true,
          },
          {
            type_of_url: "2",
            url_link: curriculumValues?.bg_Images?.[2]?.url_link?.length > 0 ? false : true,
          },
        ],
      };

      setCurriculumErrors(updatedCurriculumErrors);
    }
  }, [curriculumValues, activeTab]);

  useEffect(() => {
    if (activeTab === "en") {
      const updatedLessonErrors = {
        lesson_title:
          curriculumValues?.lessons?.[currLessonIndex]?.lesson_title?.length > 0 ? false : true,
        lesson_duration:
          curriculumValues?.lessons?.[currLessonIndex]?.lesson_duration.length > 0 ? false : true,
        lesson_description:
          curriculumValues?.lessons?.[currLessonIndex]?.lesson_description?.length > 0
            ? false
            : true,
        lesson_tumbnail_urls: [
          {
            type_of_url: "0",
            url_link:
              curriculumValues?.lessons?.[currLessonIndex]?.lesson_tumbnail_urls[0]?.url_link
                .length > 0
                ? false
                : true,
          },
          {
            type_of_url: "1",
            url_link:
              curriculumValues?.lessons?.[currLessonIndex]?.lesson_tumbnail_urls[1]?.url_link
                .length > 0
                ? false
                : true,
          },
          {
            type_of_url: "2",
            url_link:
              curriculumValues?.lessons?.[currLessonIndex]?.lesson_tumbnail_urls[2]?.url_link
                .length > 0
                ? false
                : true,
          },
        ],
      };

      setLessonErrors(updatedLessonErrors);
    }
  }, [currLessonIndex, curriculumValues, activeTab]);

  useEffect(() => {
    if (activeTab === "en") {
      const updateSlideErrors = {
        slide_title:
          curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]?.slide_title
            .length > 0
            ? false
            : true,
        slide_description:
          curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]?.slide_description
            .length > 0
            ? false
            : true,
        slide_type: [
          {
            bg_asset_url: [
              {
                type_of_url: "0",
                url_link:
                  curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                    ?.slide_type?.[0]?.bg_asset_url?.[0]?.url_link?.length > 0
                    ? false
                    : true,
              },
              {
                type_of_url: "1",
                url_link:
                  curriculumValues?.lessons?.[currLessonIndex]?.slides[currSlideIndex]
                    ?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link?.length > 0
                    ? false
                    : true,
              },
              {
                type_of_url: "2",
                url_link:
                  curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                    ?.slide_type?.[0]?.bg_asset_url?.[2]?.url_link?.length > 0
                    ? false
                    : true,
              },
            ],
            slide_bg_color:
              curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                ?.slide_type?.[0]?.slide_bg_color?.length > 0
                ? false
                : true,
            type_of_slide:
              curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                ?.slide_type?.[0]?.type_of_slide?.length > 0
                ? false
                : true,
            slide_link_url: [
              {
                type_of_url: "0",
                url_link:
                  curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                    ?.slide_type?.[0]?.slide_link_url?.[0]?.url_link?.length > 0
                    ? false
                    : true,
              },
              {
                type_of_url: "1",
                url_link:
                  curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                    ?.slide_type?.[0]?.slide_link_url?.[1]?.url_link?.length > 0
                    ? false
                    : true,
              },
              {
                type_of_url: "2",
                url_link:
                  curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                    ?.slide_type?.[0]?.slide_link_url?.[2]?.url_link?.length > 0
                    ? false
                    : true,
              },
            ],
          },
        ],
      };

      setSlideErrors(updateSlideErrors);
    }
  }, [currLessonIndex, currSlideIndex, curriculumValues, activeTab]);

  const getCurriculumDetails = () => {
    dispatch(
      readCurriculumAction({
        apiPayloadRequest: { _id: curriculum_id },
        callback: (res) => {
          if (res.type === 1) {
            const data = res?.data[0];

            const updatedData = {
              curriculum_name: data?.curriculum_name,
              publish: data?.publish,
              curriculum_description: data?.curriculum_description,
              bg_Images: (data.bg_Images || []).map((image, index) => ({
                type_of_url: index.toString(),
                url_link: image?.url_link,
              })),
              lessons: (data?.lessons || []).map((lesson) => ({
                ...lesson,
                lesson_tumbnail_urls: (lesson.lesson_tumbnail_urls || []).map(
                  (thumbnail, index) => ({
                    type_of_url: index.toString(),
                    url_link: thumbnail?.url_link,
                  })
                ),
                slides: [
                  ...(lesson?.slides || []).map((slide) => {
                    const slide_type =
                      slide?.slide_type && slide?.slide_type?.length > 0 ? slide?.slide_type : [];
                    return {
                      ...slide,
                      slide_type: slide_type.map((type) => {
                        const bg_asset_url =
                          type?.bg_asset_url && type?.bg_asset_url?.length > 0
                            ? type?.bg_asset_url
                            : [];
                        const slide_link_url =
                          type?.slide_link_url && type?.slide_link_url?.length > 0
                            ? type?.slide_link_url
                            : [];
                        return {
                          ...type,
                          bg_asset_url: bg_asset_url.map((asset, index) => ({
                            type_of_url: index.toString(),
                            url_link: asset?.url_link,
                          })),
                          slide_link_url: slide_link_url.map((link, index) => ({
                            type_of_url: index.toString(),
                            url_link: link?.url_link || "",
                          })),
                        };
                      }),
                    };
                  }),
                  initialSlideState,
                ],
              })),
            };

            updatedData.lessons.push({
              ...initialLessonState,
              slides: [initialSlideState],
            });

            setCurriculumValues(updatedData);
            setCurrCurriculumValues(updatedData);
            setEnCurriculumValues(structuredClone(updatedData));
          }
        },
      })
    );
  };

  useEffect(() => {
    if (curriculum_id !== undefined) {
      getCurriculumDetails();
    }
  }, []);

  useEffect(() => {
    dispatch(
      readLanguageAction({
        callback: getAllLanguageResponse,
      })
    );
  }, []);

  return {
    curriculumValues,
    currCurriculumValues,
    handleChange,
    addNewLesson,
    addNewSlide,
    currLessonIndex,
    currSlideIndex,
    setCurrLesson,
    currLesson,
    cancelLesson,
    saveLesson,
    setShowLessonBox,
    showLessonBox,
    setCurrLessonIndex,
    setIsLessonEdit,
    initialLessonState,
    showSlideBox,
    showCurriculumBox,
    initialSlideState,
    cancelSlideModal,
    showPreview,
    storyData,
    handleClosePreview,
    handleOpenPreview,
    setCurrSlideIndex,
    saveSlide,
    setIsSlideEdit,
    setCurrSlide,
    setShowSlideBox,
    setShowCurriculumBox,
    handleSlideBgChange,
    saveCurriculum,
    curriculumErrors,
    lessonErrors,
    slideErrors,
    showCurriculumErrors,
    showLessonErrors,
    showSlideErrors,
    setShowCurriculumErrors,
    setShowLessonErrors,
    setShowSlideErrors,
    setLayoutValues,
    setLayoutInfo,
    setAddedLayout,
    addedLayout,
    editTargetLayout,
    isLayoutEdit,
    layoutInfo,
    layoutValues,
    initialLayoutValues,
    handleVideoLayoutChange,
    handleVideoDateChange,
    handleLayoutUpdate,
    handleAddLayoutToArray,
    layoutArray,
    setIsLayoutEdit,
    setEditLayoutIndex,
    setEditTargetLayout,
    readLanguageData,
    setActiveTab,
    activeTab,
    setIsLanguageSave,
    languagemeta,
    updateStateWithTranslation,
    cancelCurriculum,
    isLessonEdit,
    isSlideEdit,
    validateSlideComplete,
    validateLessonComplete,
    validateCurriculumComplete,
    handlePublishCurriculum,
    handleDeleteSlide,
    handleDeleteLesson,
    handleSaveLanguage,
    activeLanguageDetails,
    setActiveLanguageDetails,
  };
};
