import React, { useState } from "react";
import TextInputField from "../InputFields/TextInputField";
import edit from "../../assets/icons/edit.svg";
import close from "../../assets/icons/close.svg";
import ConfirmToast from "../universal/ConfirmToast";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";
import Modal from "react-modal";

const AccordionCard = ({
  accordion,
  setIsAccordionEdit,
  setShowAccordionModal,
  setCurrAccordion,
  handleAccordionDelete,
}) => {
  const [accordionCollapse, setAccordionCollapse] = useState(true);

  const toggleCollapse = () => {
    setAccordionCollapse(!accordionCollapse);
  };

  return (
    <div className="flex flex-col gap-3 relative p-4 border bg-black bg-opacity-30 rounded-lg border-opacity-25 border-white">
      <div className="cursor-pointer" onClick={toggleCollapse}>
        <div className="flex justify-between">
          <p className="text-sm font-white font-medium text-white w-9/12">
            {accordion?.panel_title}
          </p>
          <div className="flex gap-2 justify-end items-center cursor-pointer">
            <img
              className="h-4"
              src={edit}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                setCurrAccordion(accordion);
                setIsAccordionEdit(true);
                setShowAccordionModal(true);
              }}
            />
            <img
              className="h-4 mr-4"
              src={close}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                const options = {
                  customUI: ({ onClose }) => {
                    return (
                      <ConfirmToast
                        title="Are you sure?"
                        message="You want to delete this accordion?"
                        onClose={() => onClose()}
                        onSuccess={() => {
                          handleAccordionDelete(accordion?.accorObjId);
                          onClose();
                        }}
                      />
                    );
                  },
                  closeOnEscape: true,
                  closeOnClickOutside: true,
                  keyCodeForClose: [8, 32],
                  overlayClassName: "!bg-gray-500 !bg-opacity-75",
                };
                confirmAlert(options);
              }}
            />
          </div>
        </div>
      </div>

      <div className={`${accordionCollapse ? "hidden" : ""}`}>
        <div className="pr-12  text-greyish text-[13px] font-medium relative">
          {accordion?.panel_description}
        </div>
      </div>
    </div>
  );
};

function CustomLangFields({
  onShow,
  data,
  onSaved,
  onCancel,
  currentState,
  activeTab,
  targetLanguage,
  fieldValues,
  onFieldChange,
  updateStateWithTranslation,
  setShowAccordionModal,
  showAccordionModal,
  isAccordionEdit,
  setIsAccordionEdit,
  handleAccordionInput,
  accordionInitialState,
  currAccordion,
  setCurrAccordion,
  handleAccordionSave,
  handleAccordionDelete,
}) {
  console.log("fieldValues", fieldValues);
  return (
    <div className="h-full w-full mt-4 flex flex-col">
      <div className="h-[44vh] overflow-auto">
        <div className="grid md:grid-cols-2 grid-cols-1 grid-rows-1 gap-x-4 gap-y-4 mx-1 w-full">
          {data?.map((field) => {
            return (
              <div key={field.label}>
                <TextInputField
                  name={field?.name}
                  labelText={field?.label}
                  placeholder={field?.placeholder}
                  value={fieldValues[field?.name]}
                  handleInputChange={onFieldChange}
                />
              </div>
            );
          })}
        </div>
        {activeTab === "Help and Support" && (
          <div className="flex flex-col">
            <div className="flex justify-end my-2">
              <button
                className="text-yellow-500 text-[10px] px-3 py-1 border border-yellow-500 rounded-md"
                onClick={() => {
                  setShowAccordionModal(true);
                  setIsAccordionEdit(false);
                }}
              >
                Add Accordion
              </button>
            </div>
            <ul className="flex flex-col gap-3">
              {fieldValues?.accordians?.map((accordion, index) => {
                return (
                  <AccordionCard
                    key={accordion?.accorObjId + "-" + index}
                    setIsAccordionEdit={setIsAccordionEdit}
                    setShowAccordionModal={setShowAccordionModal}
                    accordion={accordion}
                    setCurrAccordion={setCurrAccordion}
                    handleAccordionDelete={handleAccordionDelete}
                  />
                );
              })}
            </ul>
            <Modal
              isOpen={showAccordionModal}
              onRequestClose={() => {
                setShowAccordionModal((prev) => !prev);
              }}
              contentLabel="Custom Modal"
              className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
              ariaHideApp={false}
            >
              <div className="MODAL-BODY h-[60%] md:w-[40%] w-[90%] md:absolute rounded-2xl p-4 bg-blackishV2">
                <div className="w-full h-full flex flex-col">
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-white text-lg font-semibold">
                      {isAccordionEdit ? "Update" : "Save"} Accordion
                    </p>
                    <div className="flex">
                      <div className="flex space-x-2">
                        <img
                          className="cursor-pointer"
                          onClick={() => {
                            setShowAccordionModal((prev) => !prev);
                          }}
                          src={close}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="rounded-xl bg-blackish flex-1 overflow-y-auto p-4 ">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4 gap-y-4">
                      {" "}
                      <div className="col-span-2">
                        <label
                          htmlFor="panel_title"
                          className="text-[12px] text-greyish font-medium leading-4 flex -items-center gap-2"
                        >
                          Accordion Title *
                          <FaInfoCircle
                            data-tooltip-id="add_accordion"
                            data-tooltip-content="Enter the Accordion Title"
                          />
                        </label>
                        <input
                          name="panel_title"
                          type="text"
                          className={` placeholder:text-greyish bg-blackishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                          placeholder="Enter Accordion Title"
                          value={currAccordion?.panel_title}
                          onChange={handleAccordionInput}
                        />
                      </div>
                      <div className="md:col-span-2 col-span-1">
                        <label
                          htmlFor="panel_description"
                          className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                        >
                          Accordion Description *
                          <FaInfoCircle
                            data-tooltip-id="add_accordion"
                            data-tooltip-content="Enter the Accordion Description"
                          />
                        </label>
                        <textarea
                          rows={6}
                          className={`placeholder:text-greyish  bg-blackishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md`}
                          name="panel_description"
                          placeholder="Enter Accordion Description"
                          value={currAccordion?.panel_description}
                          onChange={handleAccordionInput}
                        />
                      </div>
                    </div>
                    <div className="py-1 w-full"></div>
                    <div className=" py-1 w-full">
                      <div className="flex justify-end gap-5">
                        <button
                          className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                          onClick={() => {
                            setShowAccordionModal((prev) => !prev);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                          onClick={() => {
                            handleAccordionSave();
                          }}
                        >
                          {isAccordionEdit ? "Update" : "Save"} Accordion
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <Tooltip id="add_accordion" className="border border-greyish border-opacity-20" />
              </div>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomLangFields;
