import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  readLanguageAction,
  translateLanguageAction,
} from "../../../store/Languages/LanguageAction";
import {
  layoutDashboardAction,
  readLayoutAction,
  saveLayoutAction,
  updateLayoutAction,
} from "../../../store/Layout/LayoutAction";
import Toastiy from "../../../components/universal/Toastiy";

export const AddLayoutHooks = ({ _id, setLoading }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("en");
  const [activeLanguageDetails, setActiveLanguageDetails] = useState({
    language_code: "en",
    language_name: "English",
  });
  const [readLanguageData, setReadLanguageData] = useState([]);
  const [isLanguageSave, setIsLanguageSave] = useState(true);
  const [languagemeta, setLanguagemeta] = useState();
  const [layoutStats, setLayoutStats] = useState(null);

  const initialLayoutValues = {
    _id: _id,
    layout_name: "",
    mobile_layout: "1",
    order: "",
    expiry: "",
    category: "0",
    // static: "0",
  };

  const [layoutValues, setLayoutValues] = useState(initialLayoutValues);

  const handleSaveLanguage = () => {
    // if (isLanguageSave) {
    //   const keysArray = Object.keys(videoUrlsInputs);
    //   dispatch(
    //     saveVideoLanguageAction({
    //       apiPayloadRequest: {
    //         _id: data?._id,
    //         language_code: activeTab,
    //         video_title: videoInputs?.video_title,
    //         video_description: videoInputs?.video_description,
    //       },
    //       callback: (res) => {
    //         if (res?.type === 1) {
    //           Toastiy("Video Language Saved Successfully", 1);
    //         }
    //       },
    //     })
    //   );
    //   keysArray.map((item, index) => {
    //     setTimeout(() => {
    //       dispatch(
    //         saveVideoUrlLanguageAction({
    //           apiPayloadRequest: {
    //             _id: data?._id,
    //             language_code: activeTab,
    //             type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
    //             url_link: videoUrlsInputs[item],
    //           },
    //         })
    //       );
    //     }, (index + 1) * 500);
    //   });
    //   keysArray.map((item, index) => {
    //     setTimeout(() => {
    //       dispatch(
    //         saveDownloadVideoUrlLanguageAction({
    //           apiPayloadRequest: {
    //             _id: data?._id,
    //             language_code: activeTab,
    //             type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
    //             url_link: UdownloadrlsInputs[item],
    //           },
    //         })
    //       );
    //     }, (index + 1) * 600);
    //   });
    // } else {
    //   const keysArray = Object.keys(videoUrlsInputs);
    //   if (
    //     videoInputs?.video_title !== currentLanguageInputs?.video_title ||
    //     videoInputs?.video_description !== currentLanguageInputs?.video_description
    //   ) {
    //     dispatch(
    //       updateVideoLanguageAction({
    //         apiPayloadRequest: {
    //           _id: data?._id,
    //           language_code: activeTab,
    //           video_title: videoInputs?.video_title,
    //           video_description: videoInputs?.video_description,
    //         },
    //         callback: (res) => {
    //           if (res?.type === 1) {
    //             Toastiy("Video Language Updated Successfully", 1);
    //           }
    //         },
    //       })
    //     );
    //   }
    //   keysArray.map((item) => {
    //     if (videoUrlsInputs[item] !== currentLanguageUrlCheck[item]) {
    //       dispatch(
    //         updateVideoUrlLanguageAction({
    //           apiPayloadRequest: {
    //             _id: data?._id,
    //             language_code: activeTab,
    //             type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
    //             url_link: videoUrlsInputs[item],
    //           },
    //         })
    //       );
    //     }
    //   });
    //   keysArray.map((item) => {
    //     if (UdownloadrlsInputs[item] !== currentLanguageDownloadUrlCheck[item]) {
    //       dispatch({
    //         apiPayloadRequest: {
    //           _id: data?._id,
    //           language_code: activeTab,
    //           type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
    //           url_link: UdownloadrlsInputs[item],
    //         },
    //       });
    //     }
    //   });
    // }
  };

  const combineStateKeys = () => {
    const values = [layoutValues?.layout_name];
    const combinedString = values.join(" @@@@ ");
    return combinedString;
  };

  const onTranslateSuccess = (data) => {
    const arrayOfValues = data.split(" @@@@ ");

    setLayoutValues((preState) => ({
      ...preState,
      layout_name: arrayOfValues[0],
    }));
  };

  const updateStateWithTranslation = () => {
    const combinedText = combineStateKeys();

    dispatch(
      translateLanguageAction({
        apiPayloadRequest: {
          targetcode: activeTab,
          translatetext: combinedText,
        },
        callback: (res) => onTranslateSuccess(res?.data),
      })
    );
  };

  const handleNewAddLayout = () => {
    if (layoutValues._id !== undefined) {
      setLoading(true);
      dispatch(
        updateLayoutAction({
          apiPayloadRequest: layoutValues,
          callback: (res) => {
            setLayoutValues((prev) => ({
              ...prev,
              _id: res?.data[0]?._id,
            }));
            Toastiy("Layout Updated Successfully!", res?.type);
            setLoading(false);
          },
        })
      );
    } else {
      setLoading(true);
      dispatch(
        saveLayoutAction({
          apiPayloadRequest: layoutValues,
          callback: (res) => {
            setLayoutValues((prev) => ({
              ...prev,
              _id: res?.data[0]?._id,
            }));
            Toastiy("Layout Added Successfully!", res?.type);
            setLoading(false);
          },
        })
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setLayoutValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getAllLanguageResponse = (response) => {
    if (response?.type === 1) {
      setReadLanguageData(response?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      readLanguageAction({
        callback: getAllLanguageResponse,
      })
    );
  }, []);

  useEffect(() => {
    if (_id) {
      setLoading(true);
      dispatch(
        readLayoutAction({
          apiPayloadRequest: { _id: _id },
          callback: (res) => {
            if (res?.type === 1) {
              const requiredData = res?.data?.filter((item) => item._id === _id)[0];
              console.log("requiredData", requiredData);
              setLayoutValues((prev) => ({
                ...prev,
                _id: requiredData._id,
                layout_name: requiredData?.layout_name,
                mobile_layout: requiredData?.mobile_layout,
                order: requiredData?.order,
                category: requiredData?.category,
                // static: requiredData?.static,
                // expiry: requiredData?.expiry,
              }));
              setLanguagemeta(requiredData?.languagemetas);
            }
            setLoading(false);
          },
        })
      );
      dispatch(
        layoutDashboardAction({
          apiPayloadRequest: { _id: _id },
          callback: (res) => {
            if (res?.type === 1) {
              setLayoutStats(res?.data);
            }
          },
        })
      );
    }
  }, []);

  return {
    activeTab,
    setActiveTab,
    readLanguageData,
    handleChange,
    layoutValues,
    setIsLanguageSave,
    handleNewAddLayout,
    languagemeta,
    updateStateWithTranslation,
    handleSaveLanguage,
    layoutStats,
    activeLanguageDetails,
    setActiveLanguageDetails,
  };
};
