import InputsOfText from "../../../InputFields/InputsOfText";

import { IoIosArrowDown } from "react-icons/io";
import { BsArrowLeft } from "react-icons/bs";

import logo from "../../../../assets/icons/logo.png";
import userImg from "../../../../assets/images/profilepic.png";
import Screenshotallmenu from "../../../../assets/images/Screenshotallmenunew.png";
import foreignLanguage from "../../../../assets/icons/foreignLanguage.png";
import { useState } from "react";

const AccordionBox = ({ accordion }) => {
  return (
    <li className="flex justify-between items-center w-full">
      <span>
        {accordion?.panel_title?.length > 22
          ? accordion?.panel_title?.substring(0, 22) + ".."
          : accordion?.panel_title}
      </span>
      <IoIosArrowDown className="text-yellowish" />
    </li>
  );
};

const HelpAndSupportPreview = (fieldValues) => {
  console.log("fieldValues", fieldValues);
  return (
    <div className="flex flex-col h-full relative bg-black ">
      <div className="absolute  bottom-0 left-0 right-0 z-10 ">
        <img src={Screenshotallmenu} alt="menu" />
      </div>

      <div className="flex flex-col small-bar   overflow-auto pb-14">
        <div className="flex flex-row gap-2 pt-5 px-3 items-center">
          <img className="w-7" src={logo} alt="company logo" />
          <div className="w-24 h-7 bg-slate-50 opacity-40 rounded-3xl"></div>
          <img className="w-7" src={foreignLanguage} alt="language support" />
          <img className="w-8" src={userImg} alt="user avatar" />
        </div>

        <div className="flex flex-col justify-center rounded-t-3xl items-center pt-5 mt-4">
          <div className="flex items-center border-b border-gray-700 mb-4">
            <BsArrowLeft className="text-slate-200 mr-8" />
            <InputsOfText
              value={fieldValues?.fieldValues?.help_n_support_lbl}
              inputStyle={"text-slate-200 w-40 font-bold text-[15px]"}
            />
          </div>
          <ul className="flex flex-col gap-3 text-white w-full text-sm px-6">
            {fieldValues?.fieldValues?.accordians?.map((accordion, index) => {
              return (
                <AccordionBox key={accordion?.accorObjId + "-" + index} accordion={accordion} />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HelpAndSupportPreview;
