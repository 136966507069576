import React, { useEffect, useState } from "react";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import OtpForm from "./OtpForm";
import EmailVerification from "./EmailVerification";
import { LoginPageHooks } from "./hooks/LoginHooks";
import { withAuthenticationRequired } from "@auth0/auth0-react";

const LoginPage = () => {
  const { currentForm, setCurrentForm, navigate } = LoginPageHooks();

  // useEffect(() => {
  //     const token = localStorage.getItem('token');
  //     if (token !== null) {
  //         navigate('/dashboard')
  //     }
  // }, [])

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="bg-image-class-content flex bg-slate-800 h-full w-full">
      <div className="h-[100vh] w-1/2"></div>
      <div className="flex justify-center items-center h-[100vh] w-1/2">
        {currentForm === "login" && (
          <LoginForm setCurrentForm={setCurrentForm} />
        )}
        {currentForm === "signup" && (
          <SignupForm setCurrentForm={setCurrentForm} />
        )}
        {currentForm === "validate" && (
          <EmailVerification setCurrentForm={setCurrentForm} />
        )}
        {currentForm === "forgotpassword" && (
          <ForgotPasswordForm setCurrentForm={setCurrentForm} />
        )}
        {currentForm === "otp" && <OtpForm setCurrentForm={setCurrentForm} />}
      </div>
    </div>
  );
};

export default withAuthenticationRequired(LoginPage);
