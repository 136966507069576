import React, { useEffect, useState } from "react";
import Select from "react-select";
import infoCircle from "../../assets/icons/infocircle.svg";
import searchIcon from "../../assets/icons/search.svg";
import like from "../../assets/icons/like.svg";
import share from "../../assets/icons/share.svg";
import reload from "../../assets/icons/reload.svg";
import CustomTabsV2 from "../universal/CustomTabsV2";

import CustomLineChart from "../charts/lineChart/CustomLineChart";
import SingleTab from "../universal/SingleTab";
import CustomCarousel from "../universal/CustomCarousel";
import { AddTwgHooks } from "../../screens/TWG/hooks/TwgHooks";
import DateTimePicker from "react-datetime-picker";
import {
  readLanguageAction,
  translateLanguageAction,
  uploadAssestsAction,
} from "../../store/Languages/LanguageAction";
import { useDispatch } from "react-redux";
import {
  readPromiseAction,
  savePromiseAction,
  savePromiseAudioAction,
  savePromiseAudioLanguageAction,
  savePromiseBgAction,
  savePromiseLanguageAction,
  savePromiseVideoAction,
  savePromiseVideoLanguageAction,
  updatePromiseAction,
  updatePromiseAudioAction,
  updatePromiseAudioLanguageAction,
  updatePromiseBgAction,
  updatePromiseLanguageAction,
  updatePromiseVideoAction,
  updatePromiseVideoLanguageAction,
} from "../../store/TWG/TWGAction";
import { savePromiseBg, updatePromiseBg } from "../../store/TWG/TWGAPI";
import Toastiy from "../universal/Toastiy";
import LanguageDrawer from "../universal/LanguageDrawer";
import UploadControls from "./UploadControls";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";

const AddTWGFields = ({ setLoading, _id }) => {
  // const {
  //     inputs,
  //     handleInputChange,
  //     handleFileInputChange,
  //     handleCategoryDescriptionChange,
  //     // handleSave,
  // } = AddTwgHooks();

  const dispatch = useDispatch();
  const [readLanguageData, setReadLanguageData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [activeTab, setActiveTab] = useState("en");

  const [userTab, setUserTab] = useState("Month");

  const initialStateValues = {
    mobileUploadUrl: "",
    webUploadUrl: "",
    tabletUploadUrl: "",
  };

  const initialPromiseValues = {
    title: "",
    date: "",
    hearWords: "",
    readWords: "",
    image: initialStateValues,
    audio: initialStateValues,
    video: initialStateValues,
  };

  const [promiseValues, setPromiseValues] = useState(initialPromiseValues);
  const [currentPromise, setCurrentPromise] = useState(initialPromiseValues);
  const [isLanguageSave, setIsLanguageSave] = useState();
  const [activeLanguageDetails, setActiveLanguageDetails] = useState({
    language_code: "en",
    language_name: "English",
  });

  const handleFileUploadImage = (event) => {
    const { name, files } = event.target;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: formData,
        callback: (res) => {
          setPromiseValues((prevInputs) => ({
            ...prevInputs,
            image: {
              ...prevInputs.image,
              [name]: res?.url,
            },
          }));
          setLoading(false);
        },
      })
    );
  };

  const handleFileUploadAudio = (event) => {
    const { name, files } = event.target;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: formData,
        callback: (res) => {
          setPromiseValues((prevInputs) => ({
            ...prevInputs,
            audio: {
              ...prevInputs.audio,
              [name]: res?.url,
            },
          }));
          setLoading(false);
        },
      })
    );
  };

  const handleFileUploadVideo = (event) => {
    const { name, files } = event.target;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: formData,
        callback: (res) => {
          setPromiseValues((prevInputs) => ({
            ...prevInputs,
            video: {
              ...prevInputs.video,
              [name]: res?.url,
            },
          }));
          setLoading(false);
        },
      })
    );
  };

  const handleChangeImage = (event) => {
    const { name, value } = event.target;

    setPromiseValues((prevInputs) => ({
      ...prevInputs,
      image: {
        ...prevInputs.image,
        [name]: value,
      },
    }));
  };

  const handleChangeAudio = (event) => {
    const { name, value } = event.target;

    setPromiseValues((prevInputs) => ({
      ...prevInputs,
      audio: {
        ...prevInputs.audio,
        [name]: value,
      },
    }));
  };

  const handleChangeVideo = (event) => {
    const { name, value } = event.target;

    setPromiseValues((prevInputs) => ({
      ...prevInputs,
      video: {
        ...prevInputs.video,
        [name]: value,
      },
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setPromiseValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (value) => {
    setPromiseValues((prev) => ({
      ...prev,
      date: value,
    }));
  };

  const handleSave = () => {
    if (_id !== undefined) {
      if (
        currentPromise?.title !== promiseValues.title ||
        currentPromise?.hearWords !== promiseValues.hearWords ||
        currentPromise?.readWords !== promiseValues.readWords ||
        currentPromise?.date !== promiseValues.date
      ) {
        dispatch(
          updatePromiseAction({
            apiPayloadRequest: {
              _id: _id,
              twg_title: promiseValues.title,
              hear_gods_word: promiseValues.hearWords,
              read_gods_word: promiseValues.readWords,
              promise_date: promiseValues.date,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Promise Update successful", 1);
              }
            },
          })
        );
      }
      const keysArray = Object.keys(initialStateValues);
      keysArray.map((item) => {
        if (currentPromise?.image[item] !== promiseValues.image[item]) {
          dispatch(
            updatePromiseBgAction({
              apiPayloadRequest: {
                _id: _id,
                twg_title: promiseValues.title,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.image[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                }
              },
            })
          );
        }

        if (currentPromise?.audio[item] !== promiseValues.audio[item]) {
          dispatch(
            updatePromiseAudioAction({
              apiPayloadRequest: {
                _id: _id,
                twg_title: promiseValues.title,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.audio[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                }
              },
            })
          );
        }

        if (currentPromise?.video[item] !== promiseValues.video[item]) {
          dispatch(
            updatePromiseVideoAction({
              apiPayloadRequest: {
                _id: _id,
                twg_title: promiseValues.title,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.video[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                }
              },
            })
          );
        }
      });
    } else {
      dispatch(
        savePromiseAction({
          apiPayloadRequest: {
            twg_title: promiseValues.title,
            hear_gods_word: promiseValues.hearWords,
            read_gods_word: promiseValues.readWords,
            promise_date: promiseValues.date,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const keysArray = Object.keys(initialStateValues);
              keysArray.map((item) => {
                dispatch(
                  savePromiseBgAction({
                    apiPayloadRequest: {
                      _id: res?.data[0]?._id,
                      twg_title: promiseValues.title,
                      type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                      url_link: promiseValues.image[item],
                    },
                  })
                );

                dispatch(
                  savePromiseAudioAction({
                    apiPayloadRequest: {
                      _id: res?.data[0]?._id,
                      twg_title: promiseValues.title,
                      type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                      url_link: promiseValues.audio[item],
                    },
                  })
                );

                dispatch(
                  savePromiseVideoAction({
                    apiPayloadRequest: {
                      _id: res?.data[0]?._id,
                      twg_title: promiseValues.title,
                      type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                      url_link: promiseValues.video[item],
                    },
                  })
                );
              });
            }
          },
        })
      );
    }
  };

  const [translatePromise, setTranslatePromise] = useState();

  useEffect(() => {
    if (_id !== undefined) {
      dispatch(
        readPromiseAction({
          apiPayloadRequest: {
            _id: _id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const data = res?.data[0];
              const languagemetas = data?.languagemetas ? data?.languagemetas : [];
              const images = res?.data[0].bg_Images;
              const audios = res?.data[0].audios;
              const videos = res?.data[0].videos;

              setTranslatePromise({
                title: data?.twg_title,
                hearWords: data?.hear_gods_word || "",
                readWords: data?.read_gods_word || "",
              });

              //   setPromiseValues({
              //     title: data?.twg_title,
              //     date: data?.promise_date || "",
              //     hearWords: data?.hear_gods_word || "",
              //     readWords: data?.read_gods_word || "",
              //     image: {
              //       mobileUploadUrl:
              //         images.filter((item) => item?.type_of_url === "0")[0]
              //           .url_link || "",
              //       webUploadUrl:
              //         images.filter((item) => item?.type_of_url === "1")[0]
              //           .url_link || "",
              //       tabletUploadUrl:
              //         images.filter((item) => item?.type_of_url === "2")[0]
              //           .url_link || "",
              //     },
              //     audio: {
              //       mobileUploadUrl:
              //         audios.filter((item) => item?.type_of_url === "0")[0]
              //           .url_link || "",
              //       webUploadUrl:
              //         audios.filter((item) => item?.type_of_url === "1")[0]
              //           .url_link || "",
              //       tabletUploadUrl:
              //         audios.filter((item) => item?.type_of_url === "2")[0]
              //           .url_link || "",
              //     },
              //     video: {
              //       mobileUploadUrl:
              //         videos.filter((item) => item?.type_of_url === "0")[0]
              //           .url_link || "",
              //       webUploadUrl:
              //         videos.filter((item) => item?.type_of_url === "1")[0]
              //           .url_link || "",
              //       tabletUploadUrl:
              //         videos.filter((item) => item?.type_of_url === "2")[0]
              //           .url_link || "",
              //     },
              //   });

              setLanguageData(languagemetas);

              setCurrentPromise({
                title: data?.twg_title,
                date: data?.promise_date || "",
                hearWords: data?.hear_gods_word || "",
                readWords: data?.read_gods_word || "",
                image: {
                  mobileUploadUrl:
                    images.filter((item) => item?.type_of_url === "0")[0].url_link || "",
                  webUploadUrl:
                    images.filter((item) => item?.type_of_url === "1")[0].url_link || "",
                  tabletUploadUrl:
                    images.filter((item) => item?.type_of_url === "2")[0].url_link || "",
                },
                audio: {
                  mobileUploadUrl:
                    audios.filter((item) => item?.type_of_url === "0")[0].url_link || "",
                  webUploadUrl:
                    audios.filter((item) => item?.type_of_url === "1")[0].url_link || "",
                  tabletUploadUrl:
                    audios.filter((item) => item?.type_of_url === "2")[0].url_link || "",
                },
                video: {
                  mobileUploadUrl:
                    videos.filter((item) => item?.type_of_url === "0")[0].url_link || "",
                  webUploadUrl:
                    videos.filter((item) => item?.type_of_url === "1")[0].url_link || "",
                  tabletUploadUrl:
                    videos.filter((item) => item?.type_of_url === "2")[0].url_link || "",
                },
              });
            }
          },
        })
      );
    }
  }, []);

  const combineStateKeys = () => {
    const values = Object.values(translatePromise);
    const combinedString = values.join(" @@@@ ");
    return combinedString;
  };

  const updateStateWithTranslation = () => {
    // const landCode = language.split(' - ');

    const combinedText = combineStateKeys();

    // if (landCode[1]?.length > 0) {
    dispatch(
      translateLanguageAction({
        apiPayloadRequest: {
          targetcode: activeTab,
          translatetext: combinedText,
        },
        callback: (res) => onTranslateSuccess(res?.data),
      })
    );
    // }
  };

  const onTranslateSuccess = (data) => {
    const arrayOfValues = data.split(" @@@@ ");
    const arrayOfKeys = Object.keys(translatePromise);

    for (let key in arrayOfKeys) {
      setTranslatePromise((preState) => ({
        ...preState,
        [arrayOfKeys[key]]: arrayOfValues[key],
      }));

      setPromiseValues((preState) => ({
        ...preState,
        [arrayOfKeys[key]]: arrayOfValues[key],
      }));
    }
  };

  const getAllLanguageResponse = (response) => {
    if (response?.type === 1) {
      setReadLanguageData(response?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      readLanguageAction({
        callback: getAllLanguageResponse,
      })
    );
  }, []);

  const [currentLanguagePromise, setCurrentLanguagePromise] = useState();

  useEffect(() => {
    if (activeTab === "en") {
      if (currentPromise) {
        setPromiseValues(currentPromise);
      }
    } else {
      const isPresent = languageData?.some((language) => language?.language_code === activeTab);
      if (isPresent) {
        const data = languageData?.filter((item) => item?.language_code === activeTab)[0];

        const audios = data?.audios;
        const videos = data?.videos;

        setPromiseValues((prev) => ({
          ...prev,
          title: data?.twg_title,
          hearWords: data?.hear_gods_word || "",
          readWords: data?.read_gods_word || "",
          audio: audios?.length
            ? {
                mobileUploadUrl:
                  audios.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                webUploadUrl: audios.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                tabletUploadUrl:
                  audios.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
              }
            : initialStateValues,
          video: videos?.length
            ? {
                mobileUploadUrl:
                  videos.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                webUploadUrl: videos.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                tabletUploadUrl:
                  videos.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
              }
            : initialStateValues,
        }));

        setCurrentLanguagePromise((prev) => ({
          ...prev,
          title: data?.twg_title,
          hearWords: data?.hear_gods_word || "",
          readWords: data?.read_gods_word || "",
          audio: audios?.length
            ? {
                mobileUploadUrl:
                  audios.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                webUploadUrl: audios.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                tabletUploadUrl:
                  audios.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
              }
            : initialStateValues,
          video: videos?.length
            ? {
                mobileUploadUrl:
                  videos.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                webUploadUrl: videos.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                tabletUploadUrl:
                  videos.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
              }
            : initialStateValues,
        }));
      } else {
        setPromiseValues({
          title: currentPromise?.title,
          date: currentPromise?.date,
          hearWords: currentPromise?.hearWords,
          readWords: currentPromise?.readWords,
          image: initialStateValues,
          audio: initialStateValues,
          video: initialStateValues,
        });
        setCurrentLanguagePromise({
          title: currentPromise?.title,
          date: currentPromise?.date,
          hearWords: currentPromise?.hearWords,
          readWords: currentPromise?.readWords,
          image: initialStateValues,
          audio: initialStateValues,
          video: initialStateValues,
        });
      }
    }
  }, [activeTab, currentPromise]);

  const handleLanguageSave = () => {
    if (isLanguageSave) {
      dispatch(
        savePromiseLanguageAction({
          apiPayloadRequest: {
            _id: _id,
            language_code: activeTab,
            twg_title: promiseValues?.title,
            hear_gods_word: promiseValues?.hearWords,
            read_gods_word: promiseValues?.readWords,
          },
        })
      );

      const keysArray = Object.keys(initialStateValues);
      const promiseAudio = promiseValues?.audio;
      const promiseVideo = promiseValues?.video;

      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            savePromiseAudioLanguageAction({
              apiPayloadRequest: {
                _id: _id,
                language_code: activeTab,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseAudio[item],
              },
            })
          );
        }, (index + 1) * 500);
      });

      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            savePromiseVideoLanguageAction({
              apiPayloadRequest: {
                _id: _id,
                language_code: activeTab,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseVideo[item],
              },
            })
          );
        }, (index + 1) * 600);
      });
    } else {
      const keysArray = Object.keys(initialStateValues);
      const promiseAudio = promiseValues?.audio;
      const promiseVideo = promiseValues?.video;

      const currentAudio = currentLanguagePromise?.audio;
      const currentVideo = currentLanguagePromise?.video;

      if (
        currentLanguagePromise?.title !== promiseValues?.title ||
        currentLanguagePromise?.hearWords !== promiseValues?.hearWords ||
        currentLanguagePromise?.readWords !== promiseValues?.readWords
      ) {
        dispatch(
          updatePromiseLanguageAction({
            apiPayloadRequest: {
              _id: _id,
              language_code: activeTab,
              twg_title: promiseValues?.title,
              hear_gods_word: promiseValues?.hearWords,
              read_gods_word: promiseValues?.readWords,
            },
          })
        );
      }

      keysArray.map((item) => {
        if (promiseAudio[item] !== currentAudio[item]) {
          dispatch(
            updatePromiseAudioLanguageAction({
              apiPayloadRequest: {
                _id: _id,
                language_code: activeTab,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseAudio[item],
              },
            })
          );
        }

        if (promiseVideo[item] !== currentVideo[item]) {
          dispatch(
            updatePromiseVideoLanguageAction({
              apiPayloadRequest: {
                _id: _id,
                language_code: activeTab,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseVideo[item],
              },
            })
          );
        }
      });
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-full w-full flex-1 rounded-2xl overflow-hidden">
      <div className="flex h-full flex-1">
        <LanguageDrawer
          languageData={readLanguageData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          languagemeta={languageData}
          setIsLanguageSave={setIsLanguageSave}
          setActiveLanguageDetails={setActiveLanguageDetails}
        />
        <div className="flex h-full flex-1">
          <div className="flex flex-col h-full w-full bg-greyishV2 bg-opacity-50 md:border-l md:border-r border-greyish border-opacity-30">
            <div className="flex justify-between gap-4 items-center h-[64px] px-4 border-b border-[#28292a]">
              <p className="text-white font-semibold text-lg flex whitespace-nowrap">
                {activeTab === "en"
                  ? "Add Promise"
                  : `Translate Promise to ${activeLanguageDetails?.language_name}`}
              </p>
              <div className="flex gap-4 items-center">
                {activeTab !== "en" && (
                  <p
                    className="flex items-center gap-1 text-greyish text-[11px] font-medium cursor-pointer whitespace-nowrap"
                    onClick={updateStateWithTranslation}
                  >
                    <img src={reload} alt="" /> Auto fill data with google translation
                  </p>
                )}
                {activeTab === "en" ? (
                  <div className="w-full flex items-end justify-end gap-4 mt-2">
                    <button
                      className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                      onClick={handleSave}
                    >
                      {_id ? "Update" : "Save"}
                    </button>
                  </div>
                ) : (
                  <div className="w-full flex items-end justify-end gap-4 mt-2">
                    <button
                      className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                      onClick={handleLanguageSave}
                    >
                      Save Language
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* TAB CONTENT  */}
            {activeTab !== "en" && !_id ? (
              <div className="flex items-center justify-center text-white gap-1 py-28">
                Promise translation in{" "}
                <span className="italic">{activeLanguageDetails?.language_name}</span> is available
                for saved promises
              </div>
            ) : (
              <div className="px-4 my-4">
                <div className="h-[68vh] overflow-y-auto overflow-x-hidden">
                  <div className="grid md:grid-cols-2 grid-cols-1 grid-rows-1 gap-x-4 gap-y-4 mx-1 w-full">
                    <div>
                      <label
                        htmlFor="title"
                        className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                      >
                        Title
                        <FaInfoCircle
                          data-tooltip-id="add_promise"
                          data-tooltip-content="Enter the Title"
                        />
                      </label>
                      <input
                        name="title"
                        placeholder="Enter Title"
                        type="text"
                        className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                        value={promiseValues.title}
                        onChange={handleChange}
                      />
                    </div>
                    {activeTab === "en" ? (
                      <div>
                        <label
                          htmlFor="date"
                          className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                        >
                          Select Date
                          <FaInfoCircle
                            data-tooltip-id="add_promise"
                            data-tooltip-content="Select the Date"
                          />
                        </label>
                        <DateTimePicker
                          name="date"
                          className={
                            "bg-blackish w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10"
                          }
                          value={promiseValues?.date}
                          onChange={handleDateChange}
                        />
                      </div>
                    ) : (
                      <></>
                    )}

                    {activeTab === "en" && (
                      <div className="md:col-span-2 col-span-1">
                        <p className="text-[12px] text-greyish font-medium leading-4 mb-3">
                          Promise of the Day Video
                        </p>
                        <div className="p-2 bg-blackish rounded-lg">
                          <UploadControls
                            tooltipTitle="Upload Promise Video"
                            className="my-2"
                            title="Upload Video"
                            subtitle="Upload Video"
                            handleFileUpload={handleFileUploadVideo}
                            handleChangeUrl={handleChangeVideo}
                            mobileUploadUrl={promiseValues?.video?.mobileUploadUrl}
                            tabletUploadUrl={promiseValues?.video?.tabletUploadUrl}
                            webUploadUrl={promiseValues?.video?.webUploadUrl}
                            accept="video"
                          />
                          <UploadControls
                            tooltipTitle="Upload Video Thumbnail"
                            className="my-2"
                            title="Upload Thumbnail"
                            subtitle="Upload Thumbnail"
                            handleFileUpload={handleFileUploadVideo}
                            handleChangeUrl={handleChangeVideo}
                            mobileUploadUrl={promiseValues?.thumbnail?.mobileUploadUrl}
                            tabletUploadUrl={promiseValues?.thumbnail?.tabletUploadUrl}
                            showWebUpload={false}
                            accept="image"
                          />
                        </div>
                      </div>
                    )}
                    <div className="md:col-span-2 col-span-1">
                      <p className="text-[12px] text-greyish font-medium leading-4 mb-3">
                        Promise of the Day Audio
                      </p>

                      <div className="p-2 bg-blackish rounded-lg">
                        <div className="md:col-span-2 col-span-1">
                          <label
                            htmlFor="hearWords"
                            className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                          >
                            Hear God's Word
                            <FaInfoCircle
                              data-tooltip-id="add_promise"
                              data-tooltip-content="Enter the text for God's Word"
                            />
                          </label>
                          <textarea
                            rows={6}
                            className={`bg-blackishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md`}
                            name="hearWords"
                            placeholder="Enter Here"
                            value={promiseValues.hearWords}
                            onChange={handleChange}
                          />
                        </div>
                        {activeTab === "en" && (
                          <UploadControls
                            tooltipTitle="Upload Audio"
                            title="Upload Audio"
                            subtitle="Upload Audio"
                            handleFileUpload={handleFileUploadAudio}
                            handleChangeUrl={handleChangeAudio}
                            mobileUploadUrl={promiseValues?.audio?.mobileUploadUrl}
                            tabletUploadUrl={promiseValues?.audio?.tabletUploadUrl}
                            webUploadUrl={promiseValues?.audio?.webUploadUrl}
                            accept="audio"
                          />
                        )}
                      </div>
                    </div>
                    <div className="md:col-span-2 col-span-1">
                      <label
                        htmlFor="readWords"
                        className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                      >
                        Promise of the Day Scripture
                        <FaInfoCircle
                          data-tooltip-id="add_promise"
                          data-tooltip-content="Enter Promise of the day Scripture"
                        />
                      </label>
                      <textarea
                        rows={6}
                        className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md`}
                        name="readWords"
                        placeholder="Enter Here"
                        value={promiseValues.readWords}
                        onChange={handleChange}
                      />
                    </div>

                    {activeTab === "en" && (
                      <UploadControls
                        tooltipTitle="Upload Image"
                        className="col-span-2"
                        title="Upload Image"
                        subtitle="Upload Image"
                        handleFileUpload={handleFileUploadImage}
                        handleChangeUrl={handleChangeImage}
                        mobileUploadUrl={promiseValues?.image?.mobileUploadUrl}
                        tabletUploadUrl={promiseValues?.image?.tabletUploadUrl}
                        webUploadUrl={promiseValues?.image?.webUploadUrl}
                        accept="image"
                      />
                    )}
                  </div>
                </div>
                <Tooltip id="add_promise" className="border border-greyish border-opacity-20" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="md:w-[33%] bg-[#1b1b1b]">
        <CustomTabsV2
          tabHeight={"h-[64px]"}
          tabsTitleArray={["Preview", "Stats"]}
          tabsContentObject={{
            Preview: (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                No Data Found...
              </div>
            ),
            Stats: !_id ? (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                No stats available for promise
              </div>
            ) : (
              <div className="p-4">
                <div className="p-1 flex justify-between items-center w-full">
                  <div className="flex items-center gap-8">
                    <p className="font-semibold text-white">Givings</p>
                    {/* <p className="text-gray-400">Hindi</p> */}
                  </div>
                  <div className="md:w-[15vw] w-full">
                    <div className="md:block hidden">
                      <CustomCarousel
                        rows={1}
                        cols={3}
                        gapX={0}
                        gapY={0}
                        // easier to pass this array like this rather than separating it
                        completeSliders={[
                          <SingleTab activeTab={userTab} tab={"Week"} />,
                          <SingleTab activeTab={userTab} tab={"Month"} />,
                          <SingleTab activeTab={userTab} tab={"Year"} />,
                        ]}
                        numberOfCardsShownInSingleSlide={3}
                        handleCardOnClick={(card) => {
                          setUserTab(card.props.tab);
                          switch (card.props.tab) {
                            case "Month":
                              // change data for trending tab
                              break;
                            // add other tabs here
                            default:
                              break;
                          }
                        }}
                        topNext={"hidden"}
                        topPrev={"hidden"}
                      />
                    </div>
                    <div className="md:hidden block">
                      <CustomCarousel
                        rows={1}
                        cols={2}
                        gapX={0}
                        gapY={0}
                        // easier to pass this array like this rather than separating it
                        completeSliders={[
                          <SingleTab activeTab={userTab} tab={"Week"} />,
                          <SingleTab activeTab={userTab} tab={"Month"} />,
                          <SingleTab activeTab={userTab} tab={"Year"} />,
                        ]}
                        numberOfCardsShownInSingleSlide={2}
                        handleCardOnClick={(card) => {
                          setUserTab(card.props.tab);
                          switch (card.props.tab) {
                            case "Month":
                              // change data for trending tab
                              break;
                            // add other tabs here
                            default:
                              break;
                          }
                        }}
                        topNext={"hidden"}
                        topPrev={"hidden"}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <CustomLineChart
                    customHeight="300"
                    customWidth="450"
                    data={[100, 300, 250, 350, 200, 280]}
                  />
                </div>
                <div className="p-2">
                  <p className="p-2 text-white text-opacity-50 text-sm">
                    Total No. of Videos Liked and Shared
                  </p>
                  <div className="p-2 flex justify-between">
                    <img className="h-6 w-6" src={like} alt="" />
                    <p className="text-white text-sm">38,891 Likes</p>
                  </div>
                  <div className="p-2 flex justify-between">
                    <img className="h-6 w-6" src={share} alt="" />
                    <p className="text-white text-sm">891 Shared</p>
                  </div>
                </div>
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default AddTWGFields;
