import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { acceptTestimony } from "../../../store/Testimony/TestimonyAPI";
import { readTestimonyAction, rejectTestimonyAction } from "../../../store/Testimony/TestimonyAction";

const TestimonialsHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

export default TestimonialsHooks;

export const AllTestimonialsHooks = () => {

  const dispatch = useDispatch();

  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  const [testimonyData, setTestimonyData] = useState([]);

  const onAccept = (id) => {
    dispatch(acceptTestimony({
      apiPayloadRequest: {
        _id: id
      }
    }))
  }

  const onReject = (id) => {
    dispatch(rejectTestimonyAction({
      apiPayloadRequest: {
        _id: id
      }
    }))
  }

  // useEffect(() => {
  //   dispatch(readTestimonyAction({
  //     callback: res => {
  //       if (res?.type === 1) {
  //         setTestimonyData(res?.data);
  //       }
  //     }
  //   }))
  // }, [])

  return { value, onChangeSearchBar };
};
