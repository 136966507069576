import React from "react";

export default function Input({ value, placeholder, inputClass, parentClass }) {
  return (
    <div className={`w-full px-5 ${parentClass ?? ""}`}>
      <input
        className={`w-full flex items-center text-xs bg-black outline-none border-yellowish text-slate-100 px-3 
              py-1.5 opacity-80 font-semibold border-[1.5px] rounded-xl ${inputClass ?? ""}`}
        placeholder={placeholder}
        value={value}
      ></input>
    </div>
  );
}
