import React from "react";
import { useNavigate } from "react-router-dom";

function HorizontalTile({
  avatarImage,
  headingText,
  headingColor,
  headingFontSize,
  subHeadingText,
  subHeadingColor,
  subHeadingFontSize,
  dateHeadingColor,
  dateHeadingFontSize,
  dateHeadingText,
  date,
  dateFontSize,
  dateColor,
  focusBgColor,
  hoverBgColor,
}) {
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          ":focus": {
            background: `${focusBgColor}`,
          },
          ":hover": {
            background: `${hoverBgColor}`,
          },
        }}
        className="bg-opacity-40 flex justify-between items-center p-4 rounded-md cursor-pointer"
      >
        <div onClick={() => navigate("/users/099129")}>
          <div
            // style={{
            //   backgroundColor: `${avatarBgColor}`,
            //   fontSize: `${avatarFontSize}`,
            //   color: `${avatarColor}`,
            // }}
            className="rounded-full h-[34px] w-[34px] font-bold  leading-4 flex items-center justify-center"
          >
            <img src={avatarImage} alt="" />
          </div>
        </div>
        <div className="flex-1 px-4" onClick={() => navigate("/users/099129")}>
          <p
            style={{ color: `${headingColor}`, fontSize: `${headingFontSize}` }}
            className="font-medium leading-4 mb-1"
          >
            {headingText}
          </p>
          <p
            style={{
              color: `${subHeadingColor}`,
              fontSize: `${subHeadingFontSize}`,
            }}
            className="leading-4 font-medium"
          >
            {subHeadingText}
          </p>
        </div>
        <div
          style={{
            color: `${dateColor}`,
          }}
          className="p-3 bg-blackish rounded-lg text-sm"
        >
          {date}
        </div>
      </div>
    </div>
  );
}

export default HorizontalTile;
