import Axios from "../../utils/axios";

//Get Country data
export const saveTWG = (payload) => {
    const URL = "/";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const getListTWG = (payload) => {
    const URL = "/";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const updateTWG = (payload) => {
    const URL = "/";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const savePromise = (payload) => {
    const URL = '/promise/save';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const savePromiseLanguage = (payload) => {
    const URL = '/promise/lang/save';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const updatePromise = (payload) => {
    const URL = '/promise/update';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const updatePromiseLanguage = (payload) => {
    const URL = '/promise/lang/update';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const deletePromise = (payload) => {
    const URL = '/promise/delete';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const readPromise = (payload) => {
    const URL = '/promise/read';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const savePromiseBg = (payload) => {
    const URL = '/promise/bg/save';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const updatePromiseBg = (payload) => {
    const URL = '/promise/bg/update';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const deletePromiseBg = (payload) => {
    const URL = '/promise/bg/delete';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const savePromiseAudio = (payload) => {
    const URL = '/promise/audio/save';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const savePromiseAudioLanguage = (payload) => {
    const URL = '/promise/lang/audio/save';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const updatePromiseAudio = (payload) => {
    const URL = '/promise/audio/update';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const updatePromiseAudioLanguage = (payload) => {
    const URL = '/promise/lang/audio/update';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const deletePromiseAudio = (payload) => {
    const URL = '/promise/audio/delete';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const savePromiseVideo = (payload) => {
    const URL = '/promise/video/save';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const savePromiseVideoLanguage = (payload) => {
    const URL = '/promise/lang/video/save';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const updatePromiseVideo = (payload) => {
    const URL = '/promise/video/update';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const updatePromiseVideoLanguage = (payload) => {
    const URL = '/promise/lang/video/update';
    return Axios.post(URL, payload).then((res) => res.data);
};

export const deletePromiseVideo = (payload) => {
    const URL = '/promise/video/delete';
    return Axios.post(URL, payload).then((res) => res.data);
};
