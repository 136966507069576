import HorizontalTile from "../../components/universal/HorizontalTile";
import avatar from "../../assets/icons/squareCard/story1.png";
import avatar2 from "../../assets/icons/StoryCircles/story1.png";

export const tabsTitleArray = ["Users", "Testimonies", "Givings"];
export const tabsContentObject = {
  Users: (
    <>
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
    </>
  ),
  Testimonies: (
    <>
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
    </>
  ),
};
export const twgHeaders = [
  {
    headerName: "Title",
    headerId: "twg_title",
  },
  {
    headerName: "Date",
    headerId: "promise_date",
  },
  {
    headerName: "Views",
    headerId: "views",
  },
  {
    headerName: "Actions",
    headerId: "actions",
  },
];

export const twgData = [
  {
    date: "20/07/2023",
    title: "Promise of the day",
    views: "124",
  },
  {
    date: "20/07/2023",
    title: "Promise of the day",
    views: "124",
  },
  {
    date: "20/07/2023",
    title: "Promise of the day",
    views: "124",
  },
  {
    date: "20/07/2023",
    title: "Promise of the day",
    views: "124",
  },
];
export const allCategoriesHeaders = [
  {
    headerName: "Title",
    headerId: "title",
  },
  {
    headerName: "Description",
    headerId: "description",
  },
  {
    headerName: "Last Modified Date",
    headerId: "date",
    date: true,
  },
  // {
  //   headerName: "Category Code",
  //   headerId: "categoryCode",
  // },
  {
    headerName: "Actions",
    headerId: "actions",
  },
];
export const allCategoriesData = [
  {
    title: "Book of John",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Parabies",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Jesus Speaks",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Terminator",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Book of John",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Parabies",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Jesus Speaks",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Terminator",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Book of John",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Parabies",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Jesus Speaks",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Terminator",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Book of John",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Parabies",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Jesus Speaks",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Terminator",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
];

// add languages const

export const langArray = [
  { value: "tamil", label: "Tamil" },
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
];
export const LoginInFields = [
  {
    label: "Login",
    placeholder: "Login Id",
  },
  {
    label: "Email or Phone",
    placeholder: "Email or Phone",
  },
  {
    label: "Password",
    placeholder: "Password",
  },
];
export const SignUpFields = [
  {
    label: "Sign Up",
    placeholder: "Sign Up",
  },
  {
    label: "Email or Phone",
    placeholder: "Email or Phone",
  },
];
export const allGivingsHeaders = [
  {
    headerName: "User Name",
    headerId: "userNameGivings",
  },
  {
    headerName: "Date & Time",
    headerId: "date",
  },
  {
    headerName: "Country",
    headerId: "country",
  },
  {
    headerName: "Amount",
    headerId: "amount",
  },
  {
    headerName: "Mode of Payment",
    headerId: "MOP",
  },
];
export const allGivingsData = [
  {
    userNameGivings: {
      imgUrl: avatar,
      id: "#99999",
    },
    date: "21/06/2023 09:29 AM",
    country: "Australia",
    amount: "20$",
    MOP: "Credit Card",
  },
  {
    userNameGivings: {
      imgUrl: avatar,
      id: "#99999",
    },
    date: "21/06/2023 09:29 AM",
    country: "Australia",
    amount: "20$",
    MOP: "Credit Card",
  },
  {
    userNameGivings: {
      imgUrl: avatar,
      id: "#99999",
    },
    date: "21/06/2023 09:29 AM",
    country: "Australia",
    amount: "20$",
    MOP: "Credit Card",
  },
  {
    userNameGivings: {
      imgUrl: avatar,
      id: "#99999",
    },
    date: "21/06/2023 09:29 AM",
    country: "Australia",
    amount: "20$",
    MOP: "Credit Card",
  },
  {
    userNameGivings: {
      imgUrl: avatar,
      id: "#99999",
    },
    date: "21/06/2023 09:29 AM",
    country: "Australia",
    amount: "20$",
    MOP: "Credit Card",
  },
];
