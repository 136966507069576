export const APP_LOADING = "APP_LOADING";
export const DASHBOARD_ACTION_GET_NEW_DATA = "DASHBOARD_ACTION_GET_NEW_DATA";
export const CATEGORIES_DASHBOARD_REQUEST = "CATEGORIES_DASHBOARD_REQUEST";
export const CURRICULUM_DASHBOARD_REQUEST = "CURRICULUM_DASHBOARD_REQUEST";

//Login and Sign Up
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const EMAIL_VERIFICATION_REQUEST = "EMAIL_VERIFICATION_REQUEST";
export const RESEND_VERIFICATION_REQUEST = "RESEND_VERIFICATION_REQUEST";
export const POST_GOOGLE_LOGIN_REQUEST = "POST_GOOGLE_LOGIN_REQUEST";

//Translate
export const TRANSLATE_TEXT_REQUEST = "TRANSLATE_TEXT_REQUEST";

//Upload Assests
export const UPLOAD_ASSEST_REQUEST = "UPLOAD_ASSEST_REQUEST";
export const UPLOAD_VIDEOS_REQUEST = "UPLOAD_VIDEOS_REQUEST";

//Languages
export const LANGUAGE_DASHBOARD_REQUEST = "LANGUAGE_DASHBOARD_REQUEST";
export const LAYOUT_DASHBOARD_REQUEST = "LAYOUT_DASHBOARD_REQUEST";
export const VIDEOS_DASHBOARD_REQUEST = "VIDEOS_DASHBOARD_REQUEST";
export const SAVE_LANGUAGES_REQUEST = "SAVE_LANGUAGES_REQUEST";
export const UPDATE_LANGUAGES_REQUEST = "UPDATE_LANGUAGES_REQUEST";
export const READ_LANGUAGES_REQUEST = "READ_LANGUAGES_REQUEST";
export const ACTIVE_OR_INACTIVE_LANGUAGES_REQUEST = "ACTIVE_OR_INACTIVE_LANGUAGES_REQUEST";

export const SAVE_LANGUAGE_LOGIN_META = "SAVE_LANGUAGE_LOGIN_META";
export const UPDATE_LANGUAGES_LOGIN_META = "UPDATE_LANGUAGES_LOGIN_META";
export const READ_LANGUAGES_LOGIN_META = "READ_LANGUAGES_LOGIN_META";
export const DELETE_LANGUAGES_LOGIN_META = "DELETE_LANGUAGES_LOGIN_META";

export const SAVE_LANGUAGES_SIGN_UP_REQUEST = "SAVE_LANGUAGES_SIGN_UP_REQUEST";
export const UPDATE_LANGUAGES_SIGN_UP_REQUEST = "UPDATE_LANGUAGES_SIGN_UP_REQUEST";
export const READ_LANGUAGES_SIGN_UP_REQUEST = "READ_LANGUAGES_SIGN_UP_REQUEST";
export const DELETE_LANGUAGES_SIGN_UP_REQUEST = "DELETE_LANGUAGES_SIGN_UP_REQUEST";

export const SAVE_LANGUAGES_FORGOT_PASSWORD_REQUEST = "SAVE_LANGUAGES_FORGOT_PASSWORD_REQUEST";
export const UPDATE_LANGUAGES_FORGOT_PASSWORD_REQUEST = "UPDATE_LANGUAGES_FORGOT_PASSWORD_REQUEST";
export const READ_LANGUAGES_FORGOT_PASSWORD_REQUEST = "READ_LANGUAGES_FORGOT_PASSWORD_REQUEST";
export const DELETE_LANGUAGES_FORGOT_PASSWORD_REQUEST = "DELETE_LANGUAGES_FORGOT_PASSWORD_REQUEST";

export const SAVE_LANGUAGES_HOME_DATA_REQUEST = "SAVE_LANGUAGES_HOME_DATA_REQUEST";
export const READ_LANGUAGES_HOME_DATA_REQUEST = "READ_LANGUAGES_HOME_DATA_REQUEST";
export const UPDATE_LANGUAGES_HOME_DATA_REQUEST = "UPDATE_LANGUAGES_HOME_DATA_REQUEST";
export const DELETE_LANGUAGES_HOME_DATA_REQUEST = "DELETE_LANGUAGES_HOME_DATA_REQUEST";

export const SAVE_LANGUAGES_PROMISES_DATA_REQUEST = "SAVE_LANGUAGES_PROMISES_DATA_REQUEST";
export const UPDATE_LANGUAGES_PROMISES_DATA_REQUEST = "UPDATE_LANGUAGES_PROMISES_DATA_REQUEST";

export const SAVE_LANGUAGE_CURRICULUM = "SAVE_LANGUAGE_CURRICULUM";
export const UPDATE_LANGUAGE_CURRICULUM = "UPDATE_LANGUAGE_CURRICULUM";
export const DELETE_LANGUAGE_CURRICULUM = "DELETE_LANGUAGE_CURRICULUM";
export const READ_LANGUAGE_CURRICULUM = "READ_LANGUAGE_CURRICULUM";

export const SAVE_LANGUAGE_FAVOURITES = "SAVE_LANGUAGE_FAVOURITES";
export const UPDATE_LANGUAGE_FAVOURITES = "UPDATE_LANGUAGE_FAVOURITES";
export const DELETE_LANGUAGE_FAVOURITES = "DELETE_LANGUAGE_FAVOURITES";
export const READ_LANGUAGE_FAVOURITES = "READ_LANGUAGE_FAVOURITES";

export const SAVE_LANGUAGE_TESTIMONIES_REQUEST = "SAVE_LANGUAGE_TESTIMONIES_REQUEST";
export const UPDATE_LANGUAGE_TESTIMONIES_REQUEST = "UPDATE_LANGUAGE_TESTIMONIES_REQUEST";
export const READ_LANGUAGE_TESTIMONIES_REQUEST = "READ_LANGUAGE_TESTIMONIES_REQUEST";
export const DELETE_LANGUAGE_TESTIMONIES_REQUEST = "DELETE_LANGUAGE_TESTIMONIES_REQUEST";

export const SAVE_LANGUAGES_GIVINGS_REQUEST = "SAVE_LANGUAGES_GIVINGS_REQUEST";
export const READ_LANGUAGES_GIVINGS_REQUEST = "READ_LANGUAGES_GIVINGS_REQUEST";
export const UPDATE_LANGUAGES_GIVINGS_REQUEST = "UPDATE_LANGUAGES_GIVINGS_REQUEST";
export const DELETE_LANGUAGES_GIVINGS_REQUEST = "DELETE_LANGUAGES_GIVINGS_REQUEST";

export const SAVE_LANGUAGES_MENU_REQUEST = "SAVE_LANGUAGES_MENU_REQUEST";
export const READ_LANGUAGES_MENU_REQUEST = "READ_LANGUAGES_MENU_REQUEST";
export const UPDATE_LANGUAGES_MENU_REQUEST = "UPDATE_LANGUAGES_MENU_REQUEST";
export const DELETE_LANGUAGES_MENU_REQUEST = "DELETE_LANGUAGES_MENU_REQUEST";

export const SAVE_LANGUAGES_MY_PROFILE_REQUEST = "SAVE_LANGUAGES_MY_PROFILE_REQUEST";
export const READ_LANGUAGES_MY_PROFILE_REQUEST = "READ_LANGUAGES_MY_PROFILE_REQUEST";
export const UPDATE_LANGUAGES_MY_PROFILE_REQUEST = "UPDATE_LANGUAGES_MY_PROFILE_REQUEST";
export const DELETE_LANGUAGES_MY_PROFILE_REQUEST = "DELETE_LANGUAGES_MY_PROFILE_REQUEST";

export const SAVE_LANGUAGES_MY_DOWNLOADS_REQUEST = "SAVE_LANGUAGES_MY_DOWNLOADS_REQUEST";
export const READ_LANGUAGES_MY_DOWNLOADS_REQUEST = "READ_LANGUAGES_MY_DOWNLOADS_REQUEST";
export const UPDATE_LANGUAGES_MY_DOWNLOADS_REQUEST = "UPDATE_LANGUAGES_MY_DOWNLOADS_REQUEST";
export const DELETE_LANGUAGES_MY_DOWNLOADS_REQUEST = "DELETE_LANGUAGES_MY_DOWNLOADS_REQUEST";

export const SAVE_LANGUAGES_MY_GIVINGS_REQUEST = "SAVE_LANGUAGES_MY_GIVINGS_REQUEST";
export const READ_LANGUAGES_MY_GIVINGS_REQUEST = "READ_LANGUAGES_MY_GIVINGS_REQUEST";
export const UPDATE_LANGUAGES_MY_GIVINGS_REQUEST = "UPDATE_LANGUAGES_MY_GIVINGS_REQUEST";
export const DELETE_LANGUAGES_MY_GIVINGS_REQUEST = "DELETE_LANGUAGES_MY_GIVINGS_REQUEST";

export const SAVE_LANGUAGES_HELP_AND_SUPPORT_REQUEST = "SAVE_LANGUAGES_HELP_AND_SUPPORT_REQUEST";
export const READ_LANGUAGES_HELP_AND_SUPPORT_REQUEST = "READ_LANGUAGES_HELP_AND_SUPPORT_REQUEST";
export const UPDATE_LANGUAGES_HELP_AND_SUPPORT_REQUEST =
  "UPDATE_LANGUAGES_HELP_AND_SUPPORT_REQUEST";
export const DELETE_LANGUAGES_HELP_AND_SUPPORT_REQUEST =
  "DELETE_LANGUAGES_HELP_AND_SUPPORT_REQUEST";

export const SAVE_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST =
  "SAVE_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST";
export const READ_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST =
  "READ_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST";
export const UPDATE_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST =
  "UPDATE_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST";
export const DELETE_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST =
  "DELETE_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST";

export const SAVE_LANGUAGES_ABOUT_TWG_REQUEST = "SAVE_LANGUAGES_ABOUT_TWG_REQUEST";
export const READ_LANGUAGES_ABOUT_TWG_REQUEST = "READ_LANGUAGES_ABOUT_TWG_REQUEST";
export const UPDATE_LANGUAGES_ABOUT_TWG_REQUEST = "UPDATE_LANGUAGES_ABOUT_TWG_REQUEST";
export const DELETE_LANGUAGES_ABOUT_TWG_REQUEST = "DELETE_LANGUAGES_ABOUT_TWG_REQUEST";

export const SAVE_CURRICULUM_REQUEST = "SAVE_CURRICULUM_REQUEST";
export const UPDATE_CURRICULUM_REQUEST = "UPDATE_CURRICULUM_REQUEST";
export const READ_CURRICULUM_REQUEST = "READ_CURRICULUM_REQUEST";
export const DELETE_CURRICULUM_REQUEST = "DELETE_CURRICULUM_REQUEST";

export const SAVE_BG_CURRICULUM_REQUEST = "SAVE_BG_CURRICULUM_REQUEST";
export const UPDATE_BG_CURRICULUM_REQUEST = "UPDATE_BG_CURRICULUM_REQUEST";
export const READ_BG_CURRICULUM_REQUEST = "READ_BG_CURRICULUM_REQUEST";
export const DELETE_BG_CURRICULUM_REQUEST = "DELETE_BG_CURRICULUM_REQUEST";

export const SAVE_CURRICULUM_LANGUAGE_REQUEST = "SAVE_CURRICULUM_LANGUAGE_REQUEST";
export const UPDATE_CURRICULUM_LANGUAGE_REQUEST = "UPDATE_CURRICULUM_LANGUAGE_REQUEST";
export const READ_CURRICULUM_LANGUAGE_REQUEST = "READ_CURRICULUM_LANGUAGE_REQUEST";
export const DELETE_CURRICULUM_LANGUAGE_REQUEST = "DELETE_CURRICULUM_LANGUAGE_REQUEST";

export const SAVE_BG_CURRICULUM_LANGUAGE_REQUEST = "SAVE_BG_CURRICULUM_LANGUAGE_REQUEST";
export const UPDATE_BG_CURRICULUM_LANGUAGE_REQUEST = "UPDATE_BG_CURRICULUM_LANGUAGE_REQUEST";
export const READ_BG_CURRICULUM_LANGUAGE_REQUEST = "READ_BG_CURRICULUM_LANGUAGE_REQUEST";
export const DELETE_BG_CURRICULUM_LANGUAGE_REQUEST = "DELETE_BG_CURRICULUM_LANGUAGE_REQUEST";

//User
export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const SAVE_USER_PROFILE_REQUEST = "SAVE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const SAVE_CURRICULUM_LESSON_REQUEST = "SAVE_CURRICULUM_LESSON_REQUEST";
export const UPDATE_CURRICULUM_LESSON_REQUEST = "UPDATE_CURRICULUM_LESSON_REQUEST";
export const READ_CURRICULUM_LESSON_REQUEST = "READ_CURRICULUM_LESSON_REQUEST";
export const DELETE_CURRICULUM_LESSON_REQUEST = "DELETE_CURRICULUM_LESSON_REQUEST";
export const GET_PICK_WHERE_LEFT_REQUEST = "GET_PICK_WHERE_LEFT_REQUEST";
export const GET_ACHIEVEMENTS = "GET_ACHIEVEMENTS";
export const READ_FAV_REQUEST = "READ_FAV_REQUEST";
export const UPDATE_USER_PROFILE_INFO = "UPDATE_USER_PROFILE_INFO";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";

export const SAVE_CURRICULUM_LANGUAGE_LESSON_REQUEST = "SAVE_CURRICULUM_LANGUAGE_LESSON_REQUEST";
export const UPDATE_CURRICULUM_LANGUAGE_LESSON_REQUEST =
  "UPDATE_CURRICULUM_LANGUAGE_LESSON_REQUEST";
export const READ_CURRICULUM_LANGUAGE_LESSON_REQUEST = "READ_CURRICULUM_LANGUAGE_LESSON_REQUEST";
export const DELETE_CURRICULUM_LANGUAGE_LESSON_REQUEST =
  "DELETE_CURRICULUM_LANGUAGE_LESSON_REQUEST";

export const SAVE_CURRICULUM_LESSON_THUMBNAIL_REQUEST = "SAVE_CURRICULUM_LESSON_THUMBNAIL_REQUEST";
export const UPDATE_CURRICULUM_LESSON_THUMBNAIL_REQUEST =
  "UPDATE_CURRICULUM_LESSON_THUMBNAIL_REQUEST";
export const READ_CURRICULUM_LESSON_THUMBNAIL_REQUEST = "READ_CURRICULUM_LESSON_THUMBNAIL_REQUEST";
export const DELETE_CURRICULUM_LESSON_THUMBNAIL_REQUEST =
  "DELETE_CURRICULUM_LESSON_THUMBNAIL_REQUEST";

export const SAVE_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST =
  "SAVE_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST";
export const UPDATE_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST =
  "UPDATE_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST";
export const READ_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST =
  "READ_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST";
export const DELETE_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST =
  "DELETE_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST";

export const SAVE_CURRICULUM_LESSON_SLIDE_REQUEST = "SAVE_CURRICULUM_LESSON_SLIDE_REQUEST";
export const UPDATE_CURRICULUM_LESSON_SLIDE_REQUEST = "UPDATE_CURRICULUM_LESSON_SLIDE_REQUEST";
export const READ_CURRICULUM_LESSON_SLIDE_REQUEST = "READ_CURRICULUM_LESSON_SLIDE_REQUEST";
export const DELETE_CURRICULUM_LESSON_SLIDE_REQUEST = "DELETE_CURRICULUM_LESSON_SLIDE_REQUEST";

export const SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST =
  "SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST";
export const UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST =
  "UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST";
export const READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST =
  "READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST";
export const DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST =
  "DELETE_CURRICULUM_LANGUAGE_THUMBNAIL_LESSON_REQUEST";

export const SAVE_CURRICULUM_LESSON_BG_SLIDE_REQUEST = "SAVE_CURRICULUM_LESSON_BG_SLIDE_REQUEST";
export const UPDATE_CURRICULUM_LESSON_BG_SLIDE_REQUEST =
  "UPDATE_CURRICULUM_LESSON_BG_SLIDE_REQUEST";
export const READ_CURRICULUM_LESSON_BG_SLIDE_REQUEST = "READ_CURRICULUM_LESSON_BG_SLIDE_REQUEST";
export const DELETE_CURRICULUM_LESSON_BG_SLIDE_REQUEST =
  "DELETE_CURRICULUM_THUMBNABG_IL_LESSON_REQUEST";

export const SAVE_CURRICULUM_LANGUAGE_LESSON_BG_SLIDE_REQUEST =
  "SAVE_CURRICULUM_LANGUAGE_LESSON_BG_SLIDE_REQUEST";
export const UPDATE_CURRICULUM_LANGUAGE_LESSON_BG_SLIDE_REQUEST =
  "UPDATE_CURRICULUM_LANGUAGE_LESSON_BG_SLIDE_REQUEST";
export const READ_CURRICULUM_LANGUAGE_LESSON_BG_SLIDE_REQUEST =
  "READ_CURRICULUM_LANGUAGE_LESSON_BG_SLIDE_REQUEST";
export const DELETE_CURRICULUM_LANGUAGE_LESSON_BG_SLIDE_REQUEST =
  "DELETE_CURRICULUM_LANGUAGE_THUMBNABG_IL_LESSON_REQUEST";

export const SAVE_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST =
  "SAVE_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST";
export const UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST =
  "UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST";
export const READ_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST =
  "READ_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST";
export const DELETE_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST =
  "DELETE_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST";

export const SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST =
  "SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST";
export const UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST =
  "UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST";
export const READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST =
  "READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST";
export const DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST =
  "DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST";

export const SAVE_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST =
  "SAVE_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST";
export const UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST =
  "UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST";
export const READ_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST =
  "READ_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST";
export const DELETE_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST =
  "DELETE_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST";

export const SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST =
  "SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST";
export const UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST =
  "UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST";
export const READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST =
  "READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST";
export const DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST =
  "DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST";

export const SAVE_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST =
  "SAVE_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST";
export const UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST =
  "UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST";
export const READ_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST =
  "READ_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST";
export const DELETE_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST =
  "DELETE_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST";

export const SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST =
  "SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST";
export const UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST =
  "UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST";
export const READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST =
  "READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST";
export const DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST =
  "DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST";

export const SAVE_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST =
  "SAVE_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST";
export const UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST =
  "UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST";
export const READ_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST =
  "READ_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST";
export const DELETE_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST =
  "DELETE_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST";

export const SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST =
  "SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST";
export const UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST =
  "UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST";
export const READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST =
  "READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST";
export const DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST =
  "DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST";

export const SAVE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST =
  "SAVE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST";
export const UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST =
  "UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST";
export const READ_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST =
  "READ_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST";
export const DELETE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST =
  "DELETE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST";

export const SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST =
  "SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST";
export const UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST =
  "UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST";
export const READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST =
  "READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST";
export const DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST =
  "DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST";

export const SAVE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST =
  "SAVE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST";
export const UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST =
  "UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST";
export const READ_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST =
  "READ_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST";
export const DELETE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST =
  "DELETE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST";
export const SAVE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST =
  "SAVE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST";
export const UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST =
  "UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST";
export const READ_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST =
  "READ_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST";
export const DELETE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST =
  "DELETE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST";

export const SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST =
  "SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST";
export const UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST =
  "UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST";
export const READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST =
  "READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST";
export const DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST =
  "DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST";
export const SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST =
  "SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST";
export const UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST =
  "UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST";
export const READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST =
  "READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST";
export const DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST =
  "DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST";

export const SAVE_VIDEOS_REQUEST = "SAVE_VIDEOS_REQUEST";
export const UPDATE_VIDEOS_REQUEST = "UPDATE_VIDEOS_REQUEST";
export const READ_VIDEOS_REQUEST = "READ_VIDEOS_REQUEST";
export const DELETE_VIDEOS_REQUEST = "DELETE_VIDEOS_REQUEST";

export const SAVE_VIDEOS_LANGUAGE_REQUEST = "SAVE_VIDEOS_LANGUAGE_REQUEST";
export const UPDATE_VIDEOS_LANGUAGE_REQUEST = "UPDATE_VIDEOS_LANGUAGE_REQUEST";
export const READ_VIDEOS_LANGUAGE_REQUEST = "READ_VIDEOS_LANGUAGE_REQUEST";
export const DELETE_VIDEOS_LANGUAGE_REQUEST = "DELETE_VIDEOS_LANGUAGE_REQUEST";

export const UPDATE_COMMON_URLS_REQUEST = "UPDATE_COMMON_URLS_REQUEST";
export const SAVE_VIDEOS_URLS_REQUEST = "SAVE_VIDEOS_URLS_REQUEST";
export const SAVE_COMMON_URLS_REQUEST = "SAVE_COMMON_URLS_REQUEST";
export const UPDATE_VIDEOS_URLS_REQUEST = "UPDATE_VIDEOS_URLS_REQUEST";
export const READ_VIDEOS_URLS_REQUEST = "READ_VIDEOS_URLS_REQUEST";
export const DELETE_VIDEOS_URLS_REQUEST = "DELETE_VIDEOS_URLS_REQUEST";
export const SAVE_VIDEO_LANGUAGE_REQUEST = "SAVE_VIDEO_LANGUAGE_REQUEST";
export const SAVE_CATEGORIES_LANGUAGE_REQUEST = "SAVE_CATEGORIES_LANGUAGE_REQUEST";
export const UPDATE_CATEGORIES_LANGUAGE_REQUEST = "UPDATE_CATEGORIES_LANGUAGE_REQUEST";
export const UPDATE_VIDEO_LANGUAGE_REQUEST = "UPDATE_VIDEO_LANGUAGE_REQUEST";
export const SAVE_VIDEO_URL_LANGUAGE_REQUEST = "SAVE_VIDEO_URL_LANGUAGE_REQUEST";
export const UPDATE_VIDEO_URL_LANGUAGE_REQUEST = "UPDATE_VIDEO_URL_LANGUAGE_REQUEST";
export const SAVE_VIDEO_DOWNLOAD_URL_LANGUAGE_REQUEST = "SAVE_VIDEO_DOWNLOAD_URL_LANGUAGE_REQUEST";
export const UPDATE_VIDEO_DOWNLOAD_URL_LANGUAGE_REQUEST =
  "UPDATE_VIDEO_DOWNLOAD_URL_LANGUAGE_REQUEST";

export const SAVE_VIDEOS_URLS_LANGUAGE_REQUEST = "SAVE_VIDEOS_URLS_LANGUAGE_REQUEST";
export const UPDATE_VIDEOS_URLS_LANGUAGE_REQUEST = "UPDATE_VIDEOS_URLS_LANGUAGE_REQUEST";
export const READ_VIDEOS_URLS_LANGUAGE_REQUEST = "READ_VIDEOS_URLS_LANGUAGE_REQUEST";
export const DELETE_VIDEOS_URLS_LANGUAGE_REQUEST = "DELETE_VIDEOS_URLS_LANGUAGE_REQUEST";

export const SAVE_VIDEOS_DOWNLOAD_URLS_REQUEST = "SAVE_VIDEOS_DOWNLOAD_URLS_REQUEST";
export const UPDATE_VIDEOS_DOWNLOAD_URLS_REQUEST = "UPDATE_VIDEOS_DOWNLOAD_URLS_REQUEST";
export const READ_VIDEOS_DOWNLOAD_URLS_REQUEST = "READ_VIDEOS_DOWNLOAD_URLS_REQUEST";
export const DELETE_VIDEOS_DOWNLOAD_URLS_REQUEST = "DELETE_VIDEOS_DOWNLOAD_URLS_REQUEST";

export const SAVE_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST =
  "SAVE_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST";
export const UPDATE_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST =
  "UPDATE_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST";
export const READ_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST =
  "READ_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST";
export const DELETE_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST =
  "DELETE_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST";

export const SAVE_VIDEOS_THUMBNAIL_URLS_REQUEST = "SAVE_VIDEOS_THUMBNAIL_URLS_REQUEST";
export const UPDATE_VIDEOS_THUMBNAIL_URLS_REQUEST = "UPDATE_VIDEOS_THUMBNAIL_URLS_REQUEST";
export const READ_VIDEOS_THUMBNAIL_URLS_REQUEST = "READ_VIDEOS_THUMBNAIL_URLS_REQUEST";
export const DELETE_VIDEOS_THUMBNAIL_URLS_REQUEST = "DELETE_VIDEOS_THUMBNAIL_URLS_REQUEST";

export const SAVE_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST =
  "SAVE_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST";
export const UPDATE_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST =
  "UPDATE_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST";
export const READ_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST =
  "READ_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST";
export const DELETE_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST =
  "DELETE_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST";

export const SAVE_VIDEOS_RELATED_REQUEST = "SAVE_VIDEOS_RELATED_REQUEST";
export const READ_VIDEOS_RELATED_REQUEST = "READ_VIDEOS_RELATED_REQUEST";
export const DELETE_VIDEOS_RELATED_REQUEST = "DELETE_VIDEOS_RELATED_REQUEST";

export const SAVE_CATEGORIES_REQUEST = "SAVE_CATEGORIES_REQUEST";
export const SAVE_COMMON_THUMBNAIL_CATEGORIES_REQUEST = "SAVE_COMMON_THUMBNAIL_CATEGORIES_REQUEST";
export const UPDATE_COMMON_THUMBNAIL_CATEGORIES_REQUEST =
  "UPDATE_COMMON_THUMBNAIL_CATEGORIES_REQUEST";
export const UPDATE_CATEGORIES_REQUEST = "UPDATE_CATEGORIES_REQUEST";
export const READ_CATEGORIES_REQUEST = "READ_CATEGORIES_REQUEST";
export const DELETE_CATEGORIES_REQUEST = "DELETE_CATEGORIES_REQUEST";
export const SAVE_CATEGORIES_ICON_REQUEST = "SAVE_CATEGORIES_ICON_REQUEST";
export const UPDATE_CATEGORIES_ICON_REQUEST = "UPDATE_CATEGORIES_ICON_REQUEST";
export const READ_CATEGORIES_ICON_REQUEST = "READ_CATEGORIES_ICON_REQUEST";
export const DELETE_CATEGORIES_ICON_REQUEST = "DELETE_CATEGORIES_ICON_REQUEST";
export const SAVE_CATEGORIES_RELATED_REQUEST = "SAVE_CATEGORIES_RELATED_REQUEST";
export const UPDATE_CATEGORIES_RELATED_REQUEST = "UPDATE_CATEGORIES_RELATED_REQUEST";
export const READ_CATEGORIES_RELATED_REQUEST = "READ_CATEGORIES_RELATED_REQUEST";
export const DELETE_CATEGORIES_RELATED_REQUEST = "DELETE_CATEGORIES_RELATED_REQUEST";

export const SAVE_LANGUAGE_CATEGORIES_REQUEST = "SAVE_LANGUAGE_CATEGORIES_REQUEST";
export const UPDATE_LANGUAGE_CATEGORIES_REQUEST = "UPDATE_LANGUAGE_CATEGORIES_REQUEST";
export const READ_LANGUAGE_CATEGORIES_REQUEST = "READ_LANGUAGE_CATEGORIES_REQUEST";
export const DELETE_LANGUAGE_CATEGORIES_REQUEST = "DELETE_LANGUAGE_CATEGORIES_REQUEST";

export const SAVE_LAYOUT_REQUEST = "SAVE_LAYOUT_REQUEST";
export const UPDATE_LAYOUT_REQUEST = "UPDATE_LAYOUT_REQUEST";
export const READ_LAYOUT_REQUEST = "READ_LAYOUT_REQUEST";
export const DELETE_LAYOUT_REQUEST = "DELETE_LAYOUT_REQUEST";

export const SAVE_CATEGORY_LAYOUT_REQUEST = "SAVE_CATEGORY_LAYOUT_REQUEST";
export const DELETE_CATEGORY_LAYOUT_REQUEST = "DELETE_CATEGORY_LAYOUT_REQUEST";
export const READ_CATEGORY_LAYOUT_REQUEST = "READ_CATEGORY_LAYOUT_REQUEST";

export const SAVE_CURRICULUM_LAYOUT_REQUEST = "SAVE_CURRICULUM_LAYOUT_REQUEST";
export const READ_CURRICULUM_LAYOUT_REQUEST = "READ_CURRICULUM_LAYOUT_REQUEST";
export const DELETE_CURRICULUM_LAYOUT_REQUEST = "DELETE_CURRICULUM_LAYOUT_REQUEST";
export const UPDATE_CURRICULUM_LAYOUT_REQUEST = "UPDATE_CURRICULUM_LAYOUT_REQUEST";

export const SAVE_VIDEO_LAYOUT_REQUEST = "SAVE_VIDEO_LAYOUT_REQUEST";
export const UPDATE_VIDEO_LAYOUT_REQUEST = "UPDATE_VIDEO_LAYOUT_REQUEST";
export const READ_VIDEO_LAYOUT_REQUEST = "READ_VIDEO_LAYOUT_REQUEST";
export const DELETE_VIDEO_LAYOUT_REQUEST = "DELETE_VIDEO_LAYOUT_REQUEST";

export const READ_TESTIMONY_CATEGORY_REQUEST = "READ_TESTIMONY_CATEGORY_REQUEST";
export const READ_TESTIMONY_REQUEST = "READ_TESTIMONY_REQUEST";
export const POST_ACCEPT_TESTIMONY_REQUEST = "POST_ACCEPT_TESTIMONY_REQUEST";
export const POST_REJECT_TESTIMONY_REQUEST = "POST_REJECT_TESTIMONY_REQUEST";
export const POST_TESTIMONY_OF_THE_DAY_REQUEST = "POST_TESTIMONY_OF_THE_DAY_REQUEST";

export const GET_GIVINGS_LIST_REQUEST = "GET_GIVINGS_LIST_REQUEST";
export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_TRANSACTIONS_REQEST = "GET_USER_TRANSACTIONS_REQEST";
export const POST_REFUND_REQUEST = "POST_REFUND_REQUEST";

export const GET_USERS_FOR_NOTIFICATIONS_REQUEST = "GET_USERS_FOR_NOTIFICATIONS_REQUEST";
export const SEND_NOTIFICATION_REQUEST = "SEND_NOTIFICATION_REQUEST";
export const GET_LIST_OF_NOTIFICATIONS_REQEST = "GET_LIST_OF_NOTIFICATIONS_REQEST";
export const DELETE_NOTIFICATION_REQUEST = "DELETE_NOTIFICATION_REQUEST";

export const GET_LIST_OF_FEEDBACK_REQEST = "GET_LIST_OF_FEEDBACK_REQEST";
export const GET_LIST_OF_PRAYER_REQEST = "GET_LIST_OF_PRAYER_REQEST";

export const SAVE_TWG_REQUEST = "SAVE_TWG_REQUEST";
export const GET_LIST_TWG_REQUEST = "GET_LIST_TWG_REQUEST";
export const UPDATE_TWG_REQUEST = "UPDATE_TWG_REQUEST";

export const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
export const SAVE_CMS_USER_REQUEST = "SAVE_CMS_USER_REQUEST";
export const GET_CMS_USER_REQUEST = "GET_CMS_USER_REQUEST";
export const UPDATE_CMS_USER_REQUEST = "UPDATE_CMS_USER_REQUEST";

export const SAVE_PROMISE_REQUEST = "SAVE_PROMISE_REQUEST";
export const SAVE_PROMISE_LANGUAGE_REQUEST = "SAVE_PROMISE_LANGUAGE_REQUEST";
export const UPDATE_PROMISE_REQUEST = "UPDATE_PROMISE_REQUEST";
export const UPDATE_PROMISE_LANGUAGE_REQUEST = "UPDATE_PROMISE_LANGUAGE_REQUEST";
export const DELETE_PROMISE_REQUEST = "DELETE_PROMISE_REQUEST";
export const READ_PROMISE_REQUEST = "READ_PROMISE_REQUEST";

export const SAVE_PROMISE_BG_REQUEST = "SAVE_PROMISE_BG_REQUEST";
export const UPDATE_PROMISE_BG_REQUEST = "UPDATE_PROMISE_BG_REQUEST";
export const DELETE_PROMISE_BG_REQUEST = "DELETE_PROMISE_BG_REQUEST";

export const SAVE_PROMISE_AUDIO_REQUEST = "SAVE_PROMISE_AUDIO_REQUEST";
export const SAVE_PROMISE_AUDIO_LANGUAGE_REQUEST = "SAVE_PROMISE_AUDIO_LANGUAGE_REQUEST";
export const UPDATE_PROMISE_AUDIO_REQUEST = "UPDATE_PROMISE_AUDIO_REQUEST";
export const UPDATE_PROMISE_AUDIO_LANGUAGE_REQUEST = "UPDATE_PROMISE_AUDIO_LANGUAGE_REQUEST";
export const DELETE_PROMISE_AUDIO_REQUEST = "DELETE_PROMISE_AUDIO_REQUEST";

export const SAVE_PROMISE_VIDEO_REQUEST = "SAVE_PROMISE_VIDEO_REQUEST";
export const SAVE_PROMISE_VIDEO_LANGUAGE_REQUEST = "SAVE_PROMISE_VIDEO_LANGUAGE_REQUEST";
export const UPDATE_PROMISE_VIDEO_REQUEST = "UPDATE_PROMISE_VIDEO_REQUEST";
export const UPDATE_PROMISE_VIDEO_LANGUAGE_REQUEST = "UPDATE_PROMISE_VIDEO_LANGUAGE_REQUEST";
export const DELETE_PROMISE_VIDEO_REQUEST = "DELETE_PROMISE_VIDEO_REQUEST";
