import React, { useEffect, useState } from "react";
import SearchDropdown from "../InputFields/SearchDropdown";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  readLayoutAction,
  saveLayoutAction,
  updateLayoutAction,
} from "../../store/Layout/LayoutAction";
import Toastiy from "./Toastiy";
import layout1 from "../../assets/images/layout1.jpeg";
import layout2 from "../../assets/images/layout2.jpeg";
import layout3 from "../../assets/images/layout3.jpeg";
import layout4 from "../../assets/images/layout4.jpeg";
import layout5 from "../../assets/images/layout5.jpeg";
import layout6 from "../../assets/images/layout6.jpeg";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const LayoutDropdown = ({
  category = false,
  setMyState,
  setLayoutInfo,
  addLayout = false,
  outerLayoutValues,
  addedLayout = false,
  setAddedLayout,
  newTarget,
  isLayoutEdit,
  outerTargetLayout = null,
  module = null,
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    _id: "",
    mobile_layout: "1",
    layout_name: "",
    order: "",
    category: "0",
    static: "0",
    // title: "",
    // subtitle: "",
    // expiry: "",
  };

  const [isUpdated, setIsUpdated] = useState(true);
  const [isAdd, setIsAdd] = useState(false);
  const [targetLayout, setTargetLayout] = useState("");
  const [layoutValues, setLayoutValues] = useState(initialValues);
  const [layoutData, setLayoutData] = useState([]);
  const [layouts, setLayouts] = useState([]);

  useEffect(() => {
    if (outerTargetLayout === "") {
      setTargetLayout("");
      setLayoutValues(initialValues);
    }
  }, [outerTargetLayout]);

  const handleDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));

    if (addLayout) {
      setMyState((prev) => ({
        ...prev,
        expiry: value,
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "mobile_layout") {
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));
      setMyState((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (addLayout) {
        setMyState((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };

  const handleNewAddLayout = () => {
    if (outerLayoutValues._id !== undefined) {
      dispatch(
        updateLayoutAction({
          apiPayloadRequest: outerLayoutValues,
          callback: (res) => {
            setMyState((prev) => ({
              ...prev,
              _id: res?.data[0]?._id,
            }));
            Toastiy("Layout Updated Successfully!", res?.type);
            setIsUpdated(true);
            setIsAdd(false);
          },
        })
      );
    } else {
      dispatch(
        saveLayoutAction({
          apiPayloadRequest: outerLayoutValues,
          callback: (res) => {
            setMyState((prev) => ({
              ...prev,
              _id: res?.data[0]?._id,
            }));
            Toastiy("Layout Added Successfully!", res?.type);
            setIsUpdated(true);
            setIsAdd(false);
          },
        })
      );
    }
  };

  const handleGetLayout = () => {
    dispatch(
      readLayoutAction({
        callback: (res) => {
          res?.data?.map((item) => {
            if (module !== "category" && item?.category === "1") {
              return null;
            }
            setLayoutData((prev) => [...prev, item]);
            setLayouts((prev) => [...prev, item?.layout_name]);
          });
        },
      })
    );
    setIsUpdated(false);
  };

  useEffect(() => {
    if (isUpdated) {
      handleGetLayout();
    }
  }, [isUpdated]);

  useEffect(() => {
    if (addedLayout) {
      setTargetLayout("");
      setAddedLayout(false);
      setLayoutValues(initialValues);
    }
  }, [addedLayout]);

  useEffect(() => {
    if (isLayoutEdit) {
      setTargetLayout(newTarget);
    }
  }, [isLayoutEdit]);

  useEffect(() => {
    if (layouts.includes(targetLayout)) {
      const requiredValues = layoutData.filter((item) => item.layout_name === targetLayout)[0];
      setIsAdd(true);
      setLayoutInfo(true);
      const selectedDate = requiredValues?.expiry ? new Date(requiredValues?.expiry) : "";
      const dateString = selectedDate ? selectedDate.toISOString().slice(0, 10) : "";
      setLayoutValues({
        layout_name: requiredValues?.layout_name,
        expiry: dateString,
        order: requiredValues?.order,
        mobile_layout: requiredValues?.mobile_layout,
      });
      if (category === true) {
        setMyState((prev) => ({
          ...prev,
          _id: requiredValues?._id,
          layout_name: requiredValues?.layout_name,
          mobile_layout: requiredValues?.mobile_layout,
        }));
      } else {
        setMyState((prev) => ({
          ...prev,
          _id: requiredValues?._id,
          layout_name: requiredValues?.layout_name,
        }));
      }
    } else {
      setIsAdd(false);
      setLayoutInfo(false);
    }
  }, [targetLayout]);

  return (
    <div className="w-full">
      {!addLayout && (
        <>
          <label htmlFor="relatedVideos" className="text-[12px] text-greyish font-medium">
            Layouts Name
          </label>
          <div className="flex w-full justify-between mb-2">
            <SearchDropdown
              width={"w-72"}
              id="layout"
              handleChange={(event) => setTargetLayout(event.target.value)}
              placeholder={"Select Layout"}
              data={layoutData}
              value={targetLayout}
              bgColor="greyishV2"
            />
            {layoutValues.mobile_layout === "1" && <img className="h-24" src={layout1} />}
            {layoutValues.mobile_layout === "2" && <img className="h-24" src={layout2} />}
            {layoutValues.mobile_layout === "3" && <img className="h-24" src={layout3} />}
            {layoutValues.mobile_layout === "4" && <img className="h-24" src={layout4} />}
            {layoutValues.mobile_layout === "5" && <img className="h-24" src={layout5} />}
            {layoutValues.mobile_layout === "6" && <img className="h-24" src={layout6} />}
          </div>
        </>
      )}
      {isAdd && category && !addLayout && (
        <div className="p-4 bg-black rounded-xl">
          <div className="flex gap-3">
            <div className="w-full">
              <label
                htmlFor="layout_name"
                className="text-[12px] text-greyish font-medium leading-4 "
              >
                Layout Name
              </label>
              <input
                type="text"
                className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                name="layout_name"
                placeholder="Enter Layout Name"
                value={layoutValues?.layout_name}
                onChange={handleChange}
              />
            </div>
            {/* <div className="w-full flex flex-col mt-2">
              <label htmlFor="expiry" className="text-[12px] text-greyish font-medium leading-4">
                Expiry
              </label>
              <DateTimePicker
                name="expiry"
                className={
                  "bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10"
                }
                value={layoutValues?.expiry}
                onChange={handleDateChange}
              />
            </div> */}
          </div>
          <div className="flex gap-3">
            <div className="w-full">
              <label htmlFor="order" className="text-[12px] text-greyish font-medium leading-4 ">
                Display Order
              </label>
              <input
                type="text"
                className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                name="order"
                placeholder="Enter Category Description"
                value={layoutValues?.order}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="mobile_layout"
                className="text-[12px] text-greyish font-medium leading-4 "
              >
                Mobile Layout
              </label>
              <select
                className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                name="mobile_layout"
                value={layoutValues?.mobile_layout}
                onChange={handleChange}
              >
                <option value="1">first layout</option>
                <option value="2">second layout</option>
                <option value="3">third layout</option>
                <option value="4">fourth layout</option>
                <option value="5">fifth layout</option>
                <option value="6">sixth layout</option>
              </select>
            </div>
          </div>
        </div>
      )}

      {addLayout && (
        <div className="flex h-full flex-1">
          <div className="flex h-full flex-1">
            <div className="p-4 rounded-xl flex flex-col justify-between gap-3 h-full">
              <div className="flex flex-col gap-3">
                <div className="flex gap-3">
                  <div className="w-full">
                    <label
                      htmlFor="layout_name"
                      className="text-[12px] text-greyish font-medium leading-4 "
                    >
                      Layout Name
                    </label>
                    <input
                      type="text"
                      className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                      name="layout_name"
                      placeholder="Enter Layout Name"
                      value={outerLayoutValues?.layout_name}
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="w-full flex flex-col mt-2">
                <label htmlFor="expiry" className="text-[12px] text-greyish font-medium leading-4">
                  Expiry
                </label>
                <DateTimePicker
                  name="expiry"
                  className={
                    "bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10"
                  }
                  value={outerLayoutValues?.expiry}
                  onChange={handleDateChange}
                />
              </div> */}
                </div>
                <div className="flex gap-3">
                  <div className="w-full">
                    <label
                      htmlFor="order"
                      className="text-[12px] text-greyish font-medium leading-4 "
                    >
                      Display Order
                    </label>
                    <input
                      type="text"
                      className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                      name="order"
                      placeholder="Enter Category Description"
                      value={outerLayoutValues?.order}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="mobile_layout"
                      className="text-[12px] text-greyish font-medium leading-4 "
                    >
                      Mobile Layout
                    </label>
                    <select
                      className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                      name="mobile_layout"
                      value={outerLayoutValues?.mobile_layout}
                      onChange={handleChange}
                    >
                      <option value="1">first layout</option>
                      <option value="2">second layout</option>
                      <option value="3">third layout</option>
                      <option value="4">fourth layout</option>
                      <option value="5">fifth layout</option>
                      <option value="6">sixth layout</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <div className="flex gap-3">
                  <button className="text-greyish bg-blackish font-medium text-[12px] rounded-xl py-2 px-6">
                    Cancel
                  </button>
                  <button
                    className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                    onClick={handleNewAddLayout}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LayoutDropdown;
