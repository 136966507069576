import React, { useState } from "react";
import Back from "../../assets/icons/Back.svg";
import ProfilePic from "../../assets/images/profilepic.png";
import { ProfileHooks } from "./Hooks/ProfileHooks";
import DateTimePicker from "react-datetime-picker";
import Profile from "./Profile";
import Navbar from "../universal/Navbar";
import TextInputField from "../InputFields/TextInputField";
import ImageUpload from "../universal/ImageUpload";
import DropdownField from "../InputFields/DropDown";
import SearchBar from "../universal/SearchBar";
import { UserProfileHooks } from "./Hooks/UserProfileHooks";

const UserProfileV2 = () => {
  const {
    value,
    editProfile,
    onChangeSearchBar,
    setEditProfile,
    profileValues,
    handleChange,
    imageUrl,
    setImageUrl,
    updateImageUrl,
    handleUpdateProfile,
    setProfileValues,
  } = UserProfileHooks();

  const [activeTab, setActiveTab] = useState("My Profile");

  const handleTab1 = () => {
    setActiveTab("My Profile");
  };

  console.log("profileValues", profileValues);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20 flex flex-col">
        <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
        <div className="MAIN-CONTENT flex-1 flex flex-col items-center h-full gap-4 w-full">
          {!editProfile ? (
            <div className="p-6 bg-[#1F1F1F] w-[50vw] rounded-3xl py-10 overflow-y-auto no-scrollbar">
              <div className="flex justify-between">
                <p className="text-white text-2xl font-semibold">
                  {profileValues.firstName} {profileValues.lastName}
                </p>
                <div className="flex items-center gap-5">
                  <button
                    className="py-3 px-6 rounded-xl text-sm font-semibold border border-gray-400 text-gray-400"
                    onClick={() => {
                      setEditProfile(true);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="py-3 px-6 rounded-xl text-sm font-semibold border border-yellow-400 text-yellow-400"
                    onClick={() => {
                      handleUpdateProfile();
                    }}
                  >
                    {editProfile ? " Update Profile" : "Confirm"}
                  </button>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center p-4 gap-3">
                <ImageUpload
                  placeholderImage={ProfilePic}
                  imageUrl={imageUrl}
                  setImageUrl={updateImageUrl}
                  isUpload={!editProfile}
                />
                <p className="text-xs text-white text-opacity-50"> Upload Picture</p>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <TextInputField
                  name={"firstName"}
                  labelText={"First Name"}
                  placeholder={"First Name"}
                  value={profileValues.firstName}
                  handleInputChange={handleChange}
                  disabled={editProfile}
                />
                <TextInputField
                  name={"lastName"}
                  labelText={"Last Name"}
                  placeholder={"Last Name"}
                  value={profileValues.lastName}
                  handleInputChange={handleChange}
                  disabled={editProfile}
                />
                <DropdownField
                  name={"gender"}
                  placeholder={"Select role"}
                  labelText={"Gender"}
                  isDisabled={editProfile}
                  defaultValue={"male"}
                  options={[
                    {
                      roleName: "Male",
                      value: "male",
                      shortCode: "male",
                    },
                    {
                      roleName: "Female",
                      value: "female",
                      shortCode: "female",
                    },
                  ]}
                  handleInputChange={handleChange}
                  value={profileValues.gender}
                />
                <div>
                  <p className="text-[12px] font-semibold text-greyish mb-1.5 pt-1">
                    Date of Birth
                  </p>
                  <div>
                    <DateTimePicker
                      disabled={editProfile}
                      className={"w-full"}
                      value={profileValues.dob}
                      onChange={(value) => {
                        setProfileValues((prev) => ({
                          ...prev,
                          dob: value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <TextInputField
                    name={"aboutMe"}
                    labelText={"About me"}
                    placeholder={"About me"}
                    value={profileValues.aboutMe}
                    handleInputChange={handleChange}
                    disabled={editProfile}
                  />
                </div>

                <TextInputField
                  name={"mobile"}
                  labelText={"Phone Number"}
                  placeholder={"Phone Number"}
                  value={profileValues.mobile}
                  handleInputChange={handleChange}
                  disabled={editProfile}
                />
                <TextInputField
                  name={"email"}
                  labelText={"Email ID"}
                  placeholder={"Email ID"}
                  value={profileValues.email}
                  handleInputChange={handleChange}
                  disabled={true}
                />
                <TextInputField
                  name={"country"}
                  labelText={"Country"}
                  placeholder={"Country"}
                  value={profileValues.country}
                  handleInputChange={handleChange}
                  disabled={editProfile}
                />
              </div>
            </div>
          ) : (
            <Profile setEditProfile={setEditProfile} profileValues={profileValues} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfileV2;
