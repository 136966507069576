import React, { useEffect, useState } from "react";
import Select from "react-select";
import infoCircle from "../../assets/icons/infocircle.svg";
import searchIcon from "../../assets/icons/search.svg";
import like from "../../assets/icons/like.svg";
import share from "../../assets/icons/share.svg";
import reload from "../../assets/icons/reload.svg";
import CustomTabsV2 from "../universal/CustomTabsV2";
import { AddNotificationsListHooks } from "../../screens/NotificationList/hooks/NotificationsListHooks";
import CustomLineChart from "../charts/lineChart/CustomLineChart";
import SingleTab from "../universal/SingleTab";
import CustomCarousel from "../universal/CustomCarousel";
import DateTimePicker from "react-datetime-picker";
import DropdownField from "../InputFields/DropDown";
import { readLanguageAction } from "../../store/Languages/LanguageAction";
import { useDispatch } from "react-redux";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";

const AddNotificationsFields = ({ id }) => {
  const {
    languages,
    activeTab,
    setActiveTab,
    inputs,
    handleInputChange,
    handleFileInputChange,
    notificationInputs,
    handleFileUpload,
    handleTime,
    handleChange,
    handleCategoryDescriptionChange,
    handleSave,
    selected,
    setSelected,
    updateStateWithTranslation,
  } = AddNotificationsListHooks(id);

  const dispatch = useDispatch();

  const [languageOptions, setLanguageOptions] = useState([]);
  const [userTab, setUserTab] = useState("Month");

  const [notificationTypeOptions, setNotificationTypeOptions] = useState([
    {
      roleName: "Text",
      value: "text",
      shortCode: "text",
    },
    {
      roleName: "Video",
      value: "Video",
      shortCode: "Video",
    },
    {
      roleName: "Image",
      value: "Image",
      shortCode: "Image",
    },
  ]);

  const [notificationType, setNotificationType] = useState("text");

  useEffect(() => {
    dispatch(
      readLanguageAction({
        callback: getAllLanguageResponse,
      })
    );
  }, []);

  const getAllLanguageResponse = (response) => {
    if (response?.type === 1) {
      setLanguageOptions([]);
      const data = response?.data;
      data.map((language) => {
        if (language?.status === 1) {
          setLanguageOptions((prev) => [
            ...prev,
            {
              roleName: language?.language_name,
              value: language?.language_code,
              shortCode: language?.language_code,
            },
          ]);
        }
      });
    }
  };

  return (
    <div className="grid md:grid-cols-12 grid-cols-1 grid-rows-1 h-full w-full flex-1 rounded-2xl overflow-hidden">
      <div className="flex flex-col h-full col-span-8 row-span-1 bg-greyishV2 bg-opacity-50 md:border-l md:border-r border-greyish border-opacity-30">
        <div className="flex justify-between gap-4 items-center h-[90px] px-4 border-b border-[#28292a] ">
          <p className="text-white font-semibold text-lg">Send Notifications</p>
          <div className="flex items-center gap-5">
            {" "}
            <div className="flex gap-5 items-center">
              {selected !== "en" && (
                <button
                  onClick={updateStateWithTranslation}
                  className="flex items-center gap-1 text-greyish text-[11px] font-medium whitespace-nowrap"
                >
                  <img src={reload} alt="" /> Auto fill data with google translation
                </button>
              )}
              <div className="w-[100px]">
                <DropdownField
                  value={selected}
                  options={languageOptions}
                  labelText={""}
                  width={"w-full"}
                  handleInputChange={(e) => setSelected(e.target.value)}
                />
              </div>
            </div>
            {id === undefined && (
              <div className="w-full flex items-end justify-end gap-4">
                <button
                  className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                  onClick={handleSave}
                >
                  Send Notification
                </button>
              </div>
            )}
          </div>
        </div>
        {/* TAB CONTENT  */}
        <div className="px-4 my-4">
          <div className="h-[68vh] overflow-y-auto overflow-x-hidden">
            <div className="flex flex-col px-5 gap-x-4 gap-y-4 mx-1 w-full pb-10">
              <DropdownField
                tooltipId="add_notification"
                tooltipText="Select the Notification Type"
                value={notificationType}
                options={notificationTypeOptions}
                labelText={"Select Notification Type"}
                width={"p-1"}
                handleInputChange={(e) => setNotificationType(e.target.value)}
              />
              <div>
                <label
                  htmlFor="notification_title"
                  className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                >
                  Notification Title
                  <FaInfoCircle
                    data-tooltip-id="add_notification"
                    data-tooltip-content="Enter the Notification Title"
                  />
                </label>
                <input
                  name="notification_title"
                  type="text"
                  className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                  value={notificationInputs.notification_title}
                  placeholder="Enter Notification Name"
                  onChange={handleChange}
                />
              </div>
              <div className="md:col-span-2 col-span-1">
                <label
                  htmlFor="notification_description"
                  className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                >
                  Notification Description
                  <FaInfoCircle
                    data-tooltip-id="add_notification"
                    data-tooltip-content="Enter the Notification Description"
                  />
                </label>
                <textarea
                  rows={6}
                  className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md`}
                  name="notification_description"
                  placeholder="Enter Notification Description"
                  value={notificationInputs.notification_description}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full flex flex-col">
                <label
                  htmlFor="notification_schedule_time"
                  className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                >
                  Schedule Time
                  <FaInfoCircle
                    data-tooltip-id="add_notification"
                    data-tooltip-content="Enter the Schedule Time"
                  />
                </label>
                <DateTimePicker
                  name="notification_schedule_time"
                  className={
                    "bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10"
                  }
                  value={notificationInputs?.notification_schedule_time}
                  onChange={handleTime}
                />
              </div>
              {notificationType !== "text" && (
                <div className="flex flex-row gap-3 items-center col-span-2">
                  <div className="w-1/2">
                    <label
                      htmlFor="notification_icon_url"
                      className="text-[12px] text-greyish font-medium leading-4 "
                    >
                      {`Upload ${notificationType}`}
                    </label>
                    <input
                      name="notification_icon_url"
                      className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileUpload(e, notificationType)}
                    />
                  </div>

                  <div className="w-1/2 md:col-span-2 col-span-1">
                    <label
                      htmlFor="notification_icon_url"
                      className="text-[12px] text-greyish font-medium leading-4 "
                    >
                      Upload URL
                    </label>
                    <input
                      name="notification_icon_url"
                      placeholder="Enter URL"
                      type="text"
                      className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                      value={notificationInputs.notification_icon_url}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <Tooltip id="add_notification" className="border border-greyish border-opacity-20" />
        </div>
      </div>
      <div className="col-span-4 row-span-1 bg-[#1b1b1b]">
        <CustomTabsV2
          tabHeight={"h-[64px]"}
          tabsTitleArray={["Preview", "Stats"]}
          tabsContentObject={{
            Preview: (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                No Data Found...
              </div>
            ),
            Stats: !id ? (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                No stats available for notification
              </div>
            ) : (
              <div className="p-4">
                <div className="p-1 flex justify-between items-center w-full">
                  <div className="flex items-center gap-8">
                    <p className="font-semibold text-white">Givings</p>
                    {/* <p className="text-gray-400">Hindi</p> */}
                  </div>
                  <div className="md:w-[15vw] w-full">
                    <div className="md:block hidden">
                      <CustomCarousel
                        rows={1}
                        cols={3}
                        gapX={0}
                        gapY={0}
                        // easier to pass this array like this rather than separating it
                        completeSliders={[
                          <SingleTab activeTab={userTab} tab={"Week"} />,
                          <SingleTab activeTab={userTab} tab={"Month"} />,
                          <SingleTab activeTab={userTab} tab={"Year"} />,
                        ]}
                        numberOfCardsShownInSingleSlide={3}
                        handleCardOnClick={(card) => {
                          setUserTab(card.props.tab);
                          switch (card.props.tab) {
                            case "Month":
                              // change data for trending tab
                              break;
                            // add other tabs here
                            default:
                              break;
                          }
                        }}
                        topNext={"hidden"}
                        topPrev={"hidden"}
                      />
                    </div>
                    <div className="md:hidden block">
                      <CustomCarousel
                        rows={1}
                        cols={2}
                        gapX={0}
                        gapY={0}
                        // easier to pass this array like this rather than separating it
                        completeSliders={[
                          <SingleTab activeTab={userTab} tab={"Week"} />,
                          <SingleTab activeTab={userTab} tab={"Month"} />,
                          <SingleTab activeTab={userTab} tab={"Year"} />,
                        ]}
                        numberOfCardsShownInSingleSlide={2}
                        handleCardOnClick={(card) => {
                          setUserTab(card.props.tab);
                          switch (card.props.tab) {
                            case "Month":
                              // change data for trending tab
                              break;
                            // add other tabs here
                            default:
                              break;
                          }
                        }}
                        topNext={"hidden"}
                        topPrev={"hidden"}
                      />
                    </div>
                  </div>
                </div>
                <div className="block">
                  <CustomLineChart
                    customHeight="300"
                    customWidth="450"
                    data={[100, 300, 250, 350, 200, 280]}
                  />
                </div>
                {/* <div className="p-2">
                <p className="p-2 text-white text-opacity-50 text-sm">Total No. of Videos Liked and Shared</p>
                <div className="p-2 flex justify-between">
                  <img className="h-6 w-6" src={like} alt="" />
                  <p className="text-white text-sm">38,891 Likes</p>
                </div>
                <div className="p-2 flex justify-between">
                  <img className="h-6 w-6" src={share} alt="" />
                  <p className="text-white text-sm">891 Shared</p>
                </div>
              </div> */}
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default AddNotificationsFields;
