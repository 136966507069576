import React, { useEffect, useState } from "react";
import { AddLanguagesHooks } from "./hooks/LanguagesHooks";
import SearchBar from "../../components/universal/SearchBar";
import CustomCarousel from "../../components/universal/CustomCarousel";
import reload from "../../assets/icons/reload.svg";

import {
  AchiementsFields,
  BookOfJohnFields,
  ForgotPasswordFields,
  HomePageFields,
  LoginInFields,
  SignUpFields,
  VideosLikedFields,
  langArray,
  LanguageCurriculumFields,
  LanguageFavouriteFields,
  LanguageGivingFields,
  LanguageMenuFields,
  LanguageHelpAndSupportFields,
  LanguageAboutTWGFields,
  LanguageMyGivingsFields,
  LanguageMyDownloadFields,
  LanguageMyProfileFields,
  PromiseFields,
} from "./Constants";
import SingleTab from "../../components/universal/SingleTab";
import DropdownField from "../../components/InputFields/DropDown";
import CustomLangFields from "../../components/languages/CustomLangFields";
import CustomTabsV2 from "../../components/universal/CustomTabsV2";
import langPreview1 from "../../assets/images/langPreview1.png";
import langPreview2 from "../../assets/images/langPreview2.png";
import langPreview3 from "../../assets/images/langPreview3.png";
import langPreview4 from "../../assets/images/langPreview4.png";
import langPreview5 from "../../assets/images/langPreview5.png";
import langPreview6 from "../../assets/images/langPreview6.png";
import langPreview7 from "../../assets/images/langPreview7.png";
import langPreview9 from "../../assets/images/langPreview9.png";
import langPreview8 from "../../assets/images/langPreview8.png";
import TextInputField from "../../components/InputFields/TextInputField";
import { LanguagePreview } from "../../components/languages/LanguagePreview/LanguagePreview";

import Navbar from "../../components/universal/Navbar";
import SearchDropdown from "../../components/InputFields/SearchDropdown";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";

function AddLanguage() {
  const location = useLocation();

  const {
    value,
    onChangeSearchBar,
    handleSubmit,
    activeTab,
    arrayOfValues,
    langPreview,
    setLangPreview,
    activeTabData,
    setActiveTab,
    handleFieldChange,
    setActiveTabData,
    languages,
    setTargetLanguage,
    targetLanguage,
    updateStateWithTranslation,
    currentState,
    handleLanguageSave,
    currentLanguageData,
    showAdd,
    languageStats,
    showAccordionModal,
    setShowAccordionModal,
    isAccordionEdit,
    setIsAccordionEdit,
    handleAccordionInput,
    accordionInitialState,
    currAccordion,
    setCurrAccordion,
    handleAccordionSave,
    handleAccordionDelete,
  } = AddLanguagesHooks(location?.state);

  console.log("targetLanguage", targetLanguage);
  console.log("currentState", currentState);
  console.log("currentLanguageData", currentLanguageData);
  console.log("languageStats", languageStats);
  console.log("activeTab", activeTab);

  const getTabsList = (iconRequired = true) => {
    const list = [];
    list.push(
      <SingleTab
        activeTab={activeTab}
        saved={currentLanguageData[0]?.login_meta}
        tab={"Log In"}
        iconRequired={iconRequired}
      />,
      <SingleTab
        activeTab={activeTab}
        saved={currentLanguageData[0]?.signup_meta}
        tab={"Sign Up"}
        iconRequired={iconRequired}
      />,
      // <SingleTab
      //   activeTab={activeTab}
      //   saved={currentLanguageData[0]?.forgotpassword_meta}
      //   tab={"Forgot Password"}
      //   iconRequired={true}
      // />,
      <SingleTab
        activeTab={activeTab}
        saved={currentLanguageData[0]?.home_meta}
        tab={"Home page"}
        iconRequired={iconRequired}
      />,
      <SingleTab
        activeTab={activeTab}
        saved={currentLanguageData[0]?.promises_meta}
        tab={"Promises"}
        iconRequired={iconRequired}
      />,
      <SingleTab
        activeTab={activeTab}
        saved={currentLanguageData[0]?.testimonies}
        tab={"Testimonies"}
        iconRequired={iconRequired}
      />,
      // <SingleTab activeTab={activeTab} tab={"Your achievements"} />,
      // <SingleTab activeTab={activeTab} tab={"Videos liked"} />,
      <SingleTab
        activeTab={activeTab}
        saved={currentLanguageData[0]?.curricullum_meta}
        tab={"Curriculum"}
        iconRequired={iconRequired}
      />,
      <SingleTab
        activeTab={activeTab}
        saved={currentLanguageData[0]?.my_favourites}
        tab={"Favourites"}
        iconRequired={iconRequired}
      />,
      <SingleTab
        activeTab={activeTab}
        saved={currentLanguageData[0]?.givings}
        tab={"Givings"}
        iconRequired={iconRequired}
      />,
      <SingleTab
        activeTab={activeTab}
        saved={currentLanguageData[0]?.menus}
        tab={"Menu"}
        iconRequired={iconRequired}
      />,
      <SingleTab
        activeTab={activeTab}
        saved={currentLanguageData[0]?.my_profile}
        tab={"My Profile"}
        iconRequired={iconRequired}
      />,
      <SingleTab
        activeTab={activeTab}
        saved={currentLanguageData[0]?.my_downloads}
        tab={"My Downloads"}
        iconRequired={iconRequired}
      />
      // <SingleTab
      //   activeTab={activeTab}
      //   saved={currentLanguageData[0]?.my_givings}
      //   tab={"My Givings"}
      //   iconRequired={iconRequired}
      // />,
    );
    if (currentLanguageData?.[0]?.language_code === "en") {
      list.push(
        <SingleTab
          activeTab={activeTab}
          saved={currentLanguageData[0]?.help_n_supports?.[0]}
          tab={"Help and Support"}
          iconRequired={iconRequired}
        />
      );
    }
    list.push(
      <SingleTab
        activeTab={activeTab}
        saved={currentLanguageData[0]?.about_twg}
        tab={"About TWG"}
        iconRequired={iconRequired}
      />
    );

    return list;
  };

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium">Languages</p>
              <Link to={"/languages/all"}>
                <button className=" text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                  Back
                </button>
              </Link>
            </div>
            <div className="grid md:grid-cols-3 grid-cols-1 grid-rows-1 h-full w-full rounded-2xl overflow-hidden">
              <div className="col-span-2 row-span-1 bg-blackishV2 p-4 px-6">
                <div className="flex gap-4 justify-between items-center">
                  <p className="text-white font-semibold text-lg whitespace-nowrap">Add Language</p>
                  {!showAdd && (
                    <div className="w-full flex items-center justify-end gap-4">
                      {currentLanguageData?.[0]?.language_code !== "en" && (
                        <p
                          onClick={() => {
                            updateStateWithTranslation(
                              targetLanguage,
                              currentState[activeTab]?.state,
                              currentState[activeTab]?.setState
                            );
                          }}
                          className="flex items-center gap-1 text-greyish text-[11px] font-medium cursor-pointer"
                        >
                          <span>
                            {" "}
                            <img src={reload} alt="" />{" "}
                          </span>
                          Auto fill data with google translation
                        </p>
                      )}
                      <button
                        className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                        onClick={handleSubmit}
                      >
                        Update
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex gap-4 my-4 md:flex-row flex-col">
                  <SearchDropdown
                    width={"w-72"}
                    id={"language"}
                    handleChange={(event) => setTargetLanguage(event.target.value)}
                    placeholder={"Select A Language"}
                    data={languages}
                    value={targetLanguage}
                  />
                  {showAdd && (
                    <div className="mt-2">
                      <button
                        className="text-yellowish flex justify-center items-center gap-2 bg-blackish font-medium text-[10px] border border-yellowish rounded-lg py-[10px] px-4"
                        onClick={handleLanguageSave}
                      >
                        <AiOutlinePlus /> <p>Add Language</p>
                      </button>
                    </div>
                  )}
                </div>
                {!showAdd && (
                  <>
                    {" "}
                    <div className="w-full h-[1px] border-greyishV2 border mb-2"></div>
                    <div className="DESKTOP-CAROUSEL md:block hidden">
                      <CustomCarousel
                        gapX={12}
                        gapY={12}
                        rows={1}
                        cols={5}
                        // easier to pass this array like this rather than separating it
                        completeSliders={getTabsList()}
                        numberOfCardsShownInSingleSlide={5}
                        handleCardOnClick={(card) => {
                          setActiveTab(card.props.tab);
                          switch (card.props.tab) {
                            case "Log In":
                              // change data here
                              setActiveTabData(LoginInFields);
                              setLangPreview(langPreview1);
                              break;
                            case "Sign Up":
                              setActiveTabData(SignUpFields);
                              setLangPreview(langPreview2);
                              break;
                            case "Forgot Password":
                              setActiveTabData(ForgotPasswordFields);
                              setLangPreview(langPreview3);
                              break;
                            case "Home page":
                              // change data here
                              setActiveTabData(HomePageFields);
                              setLangPreview(langPreview7);
                              break;
                            case "Promises":
                              // change data here
                              setActiveTabData(PromiseFields);
                              setLangPreview(langPreview7);
                              break;
                            case "Testimonies":
                              setActiveTabData(BookOfJohnFields);
                              setLangPreview(langPreview4);
                              break;
                            // case "Your achievements":
                            //   setActiveTabData(AchiementsFields);
                            //   setLangPreview(langPreview5);
                            //   break;
                            // case "Videos liked":
                            //   setActiveTabData(VideosLikedFields);
                            //   setLangPreview(langPreview6);
                            //   break;
                            case "Curriculum":
                              setActiveTabData(LanguageCurriculumFields);
                              setLangPreview(langPreview8);
                              break;
                            case "Favourites":
                              setActiveTabData(LanguageFavouriteFields);
                              setLangPreview(langPreview9);
                              break;

                            case "Givings":
                              setActiveTabData(LanguageGivingFields);
                              setLangPreview(langPreview8);
                              break;
                            case "Menu":
                              setActiveTabData(LanguageMenuFields);
                              setLangPreview(langPreview8);
                              break;
                            case "My Profile":
                              setActiveTabData(LanguageMyProfileFields);
                              setLangPreview(langPreview8);
                              break;
                            case "My Downloads":
                              setActiveTabData(LanguageMyDownloadFields);
                              setLangPreview(langPreview8);
                              break;
                            case "My Givings":
                              setActiveTabData(LanguageMyGivingsFields);
                              setLangPreview(langPreview8);
                              break;
                            case "Help and Support":
                              setActiveTabData(LanguageHelpAndSupportFields);
                              setLangPreview(langPreview8);
                              break;
                            case "About TWG":
                              setActiveTabData(LanguageAboutTWGFields);
                              setLangPreview(langPreview8);
                              break;
                            // add other tabs here
                            default:
                              // can set it up to data not found as well
                              setActiveTabData(LoginInFields);
                              break;
                          }
                        }}
                        topNext={"top-3"}
                        topPrev={"top-9"}
                      />
                    </div>
                    <div className="MOBILE-CAROUSEL md:hidden block">
                      <CustomCarousel
                        gapX={12}
                        rows={1}
                        cols={2}
                        gapY={0}
                        // easier to pass this array like this rather than separating it
                        completeSliders={getTabsList(false)}
                        numberOfCardsShownInSingleSlide={2}
                        handleCardOnClick={(card) => {
                          setActiveTab(card.props.tab);
                          switch (card.props.tab) {
                            case "Log In":
                              // change data here
                              setActiveTabData(LoginInFields);
                              setLangPreview(langPreview1);
                              break;
                            case "Sign Up":
                              setActiveTabData(SignUpFields);
                              setLangPreview(langPreview2);
                              break;
                            case "Forgot Password":
                              setActiveTabData(ForgotPasswordFields);
                              setLangPreview(langPreview2);
                              break;
                            case "Home page":
                              // change data here
                              setActiveTabData(HomePageFields);
                              setLangPreview(langPreview2);
                              break;
                            case "Promises":
                              // change data here
                              setActiveTabData(PromiseFields);
                              setLangPreview(langPreview7);
                              break;
                            case "Testimonies":
                              setActiveTabData(BookOfJohnFields);
                              setLangPreview(langPreview2);
                              break;
                            // case "Your achievements":
                            //   setActiveTabData(AchiementsFields);
                            //   setLangPreview(langPreview2);
                            //   break;
                            // case "Videos liked":
                            //   setActiveTabData(VideosLikedFields);
                            //   setLangPreview(langPreview2);
                            //   break;
                            case "Curriculum":
                              setActiveTabData(LanguageCurriculumFields);
                              setLangPreview(langPreview2);
                              break;
                            case "Favourites":
                              setActiveTabData(LanguageFavouriteFields);
                              setLangPreview(langPreview2);
                              break;

                            case "Givings":
                              setActiveTabData(LanguageGivingFields);
                              setLangPreview(langPreview2);
                              break;
                            case "Menu":
                              setActiveTabData(LanguageMenuFields);
                              setLangPreview(langPreview2);
                              break;
                            case "My Profile":
                              setActiveTabData(LanguageMyProfileFields);
                              setLangPreview(langPreview2);
                              break;
                            case "My Downloads":
                              setActiveTabData(LanguageMyDownloadFields);
                              setLangPreview(langPreview2);
                              break;
                            case "My Givings":
                              setActiveTabData(LanguageMyGivingsFields);
                              setLangPreview(langPreview2);
                              break;
                            case "Help and Support":
                              setActiveTabData(LanguageHelpAndSupportFields);
                              setLangPreview(langPreview2);
                              break;
                            case "About TWG":
                              setActiveTabData(LanguageAboutTWGFields);
                              setLangPreview(langPreview2);
                              break;
                            // add other tabs here
                            default:
                              // can set it up to data not found as well
                              setActiveTabData(LoginInFields);
                              break;
                          }
                        }}
                        topNext={"top-3"}
                        topPrev={"top-9"}
                      />
                    </div>
                    <div className="FIELDS h-fit">
                      <CustomLangFields
                        data={activeTabData}
                        fieldValues={
                          arrayOfValues?.filter((item) => item.title === activeTab)[0]?.values || {}
                        }
                        updateStateWithTranslation={updateStateWithTranslation}
                        targetLanguage={targetLanguage}
                        onFieldChange={handleFieldChange}
                        currentState={currentState}
                        activeTab={activeTab}
                        onCancel={() => {
                          console.log("Cancelled");
                        }}
                        onSaved={() => handleSubmit()}
                        onShow={!showAdd}
                        showAccordionModal={showAccordionModal}
                        setShowAccordionModal={setShowAccordionModal}
                        isAccordionEdit={isAccordionEdit}
                        setIsAccordionEdit={setIsAccordionEdit}
                        handleAccordionInput={handleAccordionInput}
                        accordionInitialState={accordionInitialState}
                        currAccordion={currAccordion}
                        setCurrAccordion={setCurrAccordion}
                        handleAccordionSave={handleAccordionSave}
                        handleAccordionDelete={handleAccordionDelete}
                      />
                    </div>
                  </>
                )}
              </div>
              {!showAdd && (
                <div className="col-span-1 row-span-1 bg-[#1B1B1B]">
                  <CustomTabsV2
                    tabHeight={"h-[48px]"}
                    tabsTitleArray={["Preview", "Stats"]}
                    tabsContentObject={{
                      Preview: (
                        // <img
                        //   className="md:h-[74vh] h-[32vh] w-full object-contain"
                        //   src={langPreview}
                        //   alt="preview"
                        // />
                        <LanguagePreview
                          activeTab={activeTab}
                          fieldValues={
                            arrayOfValues.find((item) => item.title === activeTab)?.values || []
                          }
                        />
                      ),
                      Stats: (
                        <div className="mt-8 flex items-center justify-center w-full text-white">
                          {languageStats ? (
                            <div className="w-full px-10">
                              {languageStats?.map((d) => {
                                if (d?.dash === "Users count by language") {
                                  return (
                                    <p className="text-start">
                                      Users count by language :{" "}
                                      <span className="text-yellowish">
                                        {d?.data?.[0]?.count || 0}
                                      </span>
                                    </p>
                                  );
                                } else if (d?.dash === "Language Counts by active or inactive") {
                                  return (
                                    <>
                                      {d?.data?.map((item) => {
                                        return (
                                          <p>
                                            {item?._id === 1 ? "Active" : "Inactive"} Languages :{" "}
                                            <span className="text-yellowish">
                                              {item?.count || 0}
                                            </span>
                                          </p>
                                        );
                                      })}
                                    </>
                                  );
                                }
                              })}
                            </div>
                          ) : (
                            "Stats are not available for this language"
                          )}
                        </div>
                      ),
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddLanguage;
