import { useEffect, useState } from "react";
import {
  deleteCategoriesAction,
  deleteCategoriesRelatedAction,
  readCategoriesAction,
  readLanguageAction,
  readVideosAction,
  readVideosrelatedAction,
  saveCategoriesAction,
  saveCategoriesIconAction,
  saveCategoriesLanguageAction,
  saveCategoriesRelatedAction,
  saveCommonThumbnailCategoriesAction,
  saveVideosrelatedAction,
  translateLanguageAction,
  updateCategoriesAction,
  updateCategoriesIconAction,
  updateCategoriesLanguageAction,
  updateCommonThumbnailCategoriesAction,
  uploadAssestsAction,
} from "../../../store/Languages/LanguageAction";
import { useDispatch } from "react-redux";
import Toastiy from "../../../components/universal/Toastiy";
import { useNavigate } from "react-router";
import {
  deleteCategoryLayoutAction,
  readCategoryLayoutAction,
  saveCategoryLayoutAction,
  updateVideoLayoutAction,
} from "../../../store/Layout/LayoutAction";

export const CategoriesHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

export const AllCategoriesHooks = () => {
  const [value, setValue] = useState("");

  const [allCategoriesData, setAllCategoriesData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isUpdated, setIsUpdated] = useState(true);

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (isUpdated === true) {
      dispatch(
        readCategoriesAction({
          callback: getAllCategoriesResponse,
        })
      );

      setIsUpdated(false);
    }
  }, [isUpdated]);

  const getAllCategoriesResponse = (Data) => {
    setAllCategoriesData(Data?.data);
  };

  const handleEyeClick = (row) => {
    navigate("/categories/add", { state: { _id: row._id } });
    // setAllCategoriesData((prevData) => ({
    //   ...prevData,
    //   category_name: row?.category_name,
    //   category_description: row?.category_description,
    //   mobile_layout: row?.mobile_layout,
    // }));
  };

  const handleDelete = (row, index) => {
    dispatch(
      deleteCategoriesAction({
        apiPayloadRequest: { _id: row?._id },
        callback: (res) => getCategoriesDeletedResponse(res, "Categories Deleted Successfully"),
      })
    );

    const getCategoriesDeletedResponse = (data, messsage) => {
      if (data?.type === 1) {
        Toastiy(messsage, data?.type);
        setIsUpdated(true);
      } else {
        Toastiy("Issue performing the operation, contact admin!!", data?.type);
      }
    };
  };

  return {
    value,
    onChangeSearchBar,
    allCategoriesData,
    handleDelete,
    handleEyeClick,
  };
};

export const AddCategoriesHooks = (data, options, setLoading) => {
  // search logic here
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [languagemeta, setLanguagemeta] = useState([]);
  const [isLanguageSave, setIsLanguageSave] = useState(true);
  const [activeLanguageDetails, setActiveLanguageDetails] = useState({
    language_code: "en",
    language_name: "English",
  });

  const [relatedVideos, setRelatedVideos] = useState([]);
  const [activeTab, setActiveTab] = useState("en");

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [translateCategories, setTranslateCategories] = useState([]);

  // const [options, setOptions] = useState([]);
  const [layoutInfo, setLayoutInfo] = useState(false);
  const [addedLayout, setAddedLayout] = useState(false);
  const [layoutArray, setLayoutArray] = useState([]);
  const [isLayoutEdit, setIsLayoutEdit] = useState(false);
  const [editLayoutIndex, setEditLayoutIndex] = useState(null);
  const [editTargetLayout, setEditTargetLayout] = useState("");

  const initialLayoutValues = {
    _id: "",
    mobile_layout: "1",
    layout_name: "",
    order: "",
    // title: "",
    // subtitle: "",
    // expiry: "",
  };

  const [layoutValues, setLayoutValues] = useState(initialLayoutValues);

  const initialCategory = {
    category_name: "",
    category_description: "",
    mobile_layout: layoutValues.mobile_layout,
    mobileUploadUrl: "",
    tabletUploadUrl: "",
    webUploadUrl: "",
    commonMobileUploadUrl: "",
    commonTabletUploadUrl: "",
    commonWebUploadUrl: "",
  };

  const [inputs, setInputs] = useState("");
  const [categoryInputs, setCateoryInputs] = useState(initialCategory);
  const [categoryErrors, setCategoryErrors] = useState({
    category_name: false,
    category_description: false,
    video_urls: false,
    selectedOptions: false,
  });

  const [currentInputs, setCurrentInputs] = useState({});

  const languages = ["english", "hindi", "tamil"]; // List of languages
  // Set the first language as active

  const handleDropdownChange = (selectedValues) => {
    if (data?._id !== "" && data?._id !== undefined) {
      if (selectedValues?.length > selectedOptions?.length) {
        const requiredValue = selectedValues.filter(
          (item1) => !selectedOptions.some((item2) => item1.value === item2.value)
        );

        dispatch(
          saveCategoriesRelatedAction({
            apiPayloadRequest: {
              _id: data?._id,
              videoObjId: requiredValue[0]?.value,
            },
            callback: (res) => {
              if (res?.type === 1) {
                setSelectedOptions(selectedValues);
              }
            },
          })
        );
      } else {
        const requiredValue = selectedOptions.filter(
          (item1) => !selectedValues.some((item2) => item1.value === item2.value)
        );

        console.log(requiredValue, "njkefwjknwejkfnwkjefn");

        dispatch(
          deleteCategoriesRelatedAction({
            apiPayloadRequest: {
              _id: data?._id,
              videoObjId: requiredValue[0]?.value,
            },
            callback: (res) => {
              if (res?.type === 1) {
                setSelectedOptions((prev) =>
                  prev.filter((item) => item.value !== requiredValue[0]?.value)
                );
              }
            },
          })
        );
      }
    } else {
      setSelectedOptions(selectedValues);
    }
    setCategoryErrors({ ...categoryErrors, selectedOptions: false });
  };

  const handleDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));
  };

  const handleCategoryLayoutChange = (event) => {
    const { name, value } = event.target;
    setLayoutValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  const handleUploadInput = (event) => {
    const { name, files } = event.target;

    setLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: formData,
        callback: (res) => {
          setCateoryInputs((prevInputs) => ({
            ...prevInputs,
            [name]: res?.url,
          }));
          setCategoryErrors({ ...categoryErrors, video_urls: false });
          setLoading(false);
        },
      })
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCateoryInputs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setCategoryErrors({ ...categoryErrors, [name]: false, video_urls: false });
    // switch (name) {
    //   case 'mobile_layout':
    //     setLayoutValue(value)
    // }
  };

  const handleVideoDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));
  };

  const initialUrls = {
    mobileUploadUrl: "",
    webUploadUrl: "",
    tabletUploadUrl: "",
  };

  const initialCommonUrls = {
    commonMobileUploadUrl: "",
    commonWebUploadUrl: "",
    commonTabletUploadUrl: "",
  };

  //Layout handles here

  const handleAddLayoutToArray = () => {
    if (data?._id !== undefined && data?._id !== "") {
      dispatch(
        saveCategoryLayoutAction({
          apiPayloadRequest: {
            _id: layoutValues?._id,
            categoryObjId: data?._id,
            order: layoutValues?.order,
            // expiry: layoutValues?.expiry,
            // title: layoutValues?.title,
            // subtitle: layoutValues?.subtitle,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Layout Added Successfully!", 1);
              setLayoutArray((prev) => [...prev, layoutValues]);
              setLayoutValues(initialLayoutValues);
              setAddedLayout(true);
            }
          },
        })
      );
    } else {
      setLayoutArray((prev) => [...prev, layoutValues]);
      setLayoutValues(initialLayoutValues);
      setAddedLayout(true);
    }
  };

  //Change to Category API
  const handleLayoutUpdate = () => {
    if (data?._id !== undefined && data?._id !== "") {
      dispatch(
        updateVideoLayoutAction({
          apiPayloadRequest: {
            _id: layoutArray._id,
            categoryObjId: data?._id,
            order: layoutArray._id,
            // title: layoutArray._id,
            // subtitle: layoutArray._id,
            // expiry: layoutArray._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setLayoutValues(initialLayoutValues);
              setIsLayoutEdit(false);
              Toastiy("Layout Updated Successfully!", 1);
            }
          },
        })
      );
    } else {
      setLayoutValues(initialLayoutValues);
      setIsLayoutEdit(false);
    }
  };

  const handleDeleteLayoutFromArray = (id) => {
    if (data?._id !== undefined && data?._id !== "") {
      dispatch(
        deleteCategoryLayoutAction({
          apiPayloadRequest: {
            _id: id,
            categoryObjId: data?._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const updatedArray = layoutArray.filter((item) => item._id !== id);
              setLayoutArray(updatedArray);
            }
          },
        })
      );
    } else {
      const updatedArray = layoutArray.filter((item) => item._id !== id);
      setLayoutArray(updatedArray);
    }
  };

  const handleVideoLayoutChange = (event) => {
    if (isLayoutEdit) {
      const { name, value } = event.target;
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));

      setLayoutArray((prev) => {
        const updatedArray = [...prev];

        updatedArray[editLayoutIndex] = {
          ...updatedArray[editLayoutIndex],
          [name]: value,
        };
      });
    } else {
      const { name, value } = event.target;
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSave = () => {
    if (data?._id !== "" && data?._id !== undefined) {
      if (
        categoryInputs?.category_name !== currentInputs?.category_name ||
        categoryInputs?.category_description !== currentInputs?.category_description
      ) {
        setLoading(true);
        dispatch(
          updateCategoriesAction({
            apiPayloadRequest: {
              _id: data?._id,
              category_name: categoryInputs?.category_name,
              category_description: categoryInputs?.category_description,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Categories Updated Sucessfully", 1);
                navigate("/categories/all");
              } else {
                Toastiy("Categories Update Failed", 2);
              }
              setLoading(false);
            },
          })
        );
      }

      const keysArray = Object.keys(initialUrls);
      keysArray.map((item) => {
        if (categoryInputs[item] !== currentInputs[item]) {
          setLoading(true);
          dispatch(
            updateCategoriesIconAction({
              apiPayloadRequest: {
                _id: data?._id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: categoryInputs[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Categories Icon Sucessfully", 1);
                } else {
                  Toastiy("Categories Icon Failed", 2);
                }
                setLoading(false);
              },
            })
          );
        }
      });

      const commonKeysArray = Object.keys(initialCommonUrls);
      commonKeysArray.map((item) => {
        if (categoryInputs[item] !== currentInputs[item]) {
          setLoading(true);
          dispatch(
            updateCommonThumbnailCategoriesAction({
              apiPayloadRequest: {
                _id: data?._id,
                type_of_url:
                  item === "commonMobileUploadUrl" ? 0 : item === "commonWebUploadUrl" ? 1 : 2,
                url_link: categoryInputs[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Categories Common Thumbnail Updated Sucessfully", 1);
                  navigate("/categories/all");
                } else {
                  Toastiy("Categories Common Thumbnail Update Failed", 2);
                }
                setLoading(false);
              },
            })
          );
        }
      });
    } else {
      // validating fields
      if (!categoryInputs?.category_name)
        setCategoryErrors({ ...categoryErrors, category_name: true });
      else if (!categoryInputs?.category_description)
        setCategoryErrors({ ...categoryErrors, category_description: true });
      else if (
        !categoryInputs?.mobileUploadUrl ||
        !categoryInputs?.tabletUploadUrl ||
        !categoryInputs?.webUploadUrl
      )
        setCategoryErrors({ ...categoryErrors, video_urls: true });
      else if (!selectedOptions || selectedOptions?.length === 0)
        setCategoryErrors({ ...categoryErrors, selectedOptions: true });
      else {
        setLoading(true);
        dispatch(
          saveCategoriesAction({
            apiPayloadRequest: categoryInputs,
            callback: getSaveCategoriesResponse,
          })
        );
      }
    }
  };

  const getUpdateCategoriesResponse = (data) => {
    if (data?.type === 1) {
    } else {
      Toastiy("Issue performing the operation, contact admin!!", data?.type);
    }
  };

  const getSaveCategoriesResponse = (data) => {
    if (data?.type === 1) {
      Toastiy("Categories Saved Sucessfully", data?.type);
      setLoading(false);
      selectedOptions.map((item) => {
        dispatch(
          saveCategoriesRelatedAction({
            apiPayloadRequest: {
              _id: data?.data[0]?._id,
              videoObjId: item?.value,
            },
          })
        );
      });
      const keysArray = Object.keys(initialUrls);
      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveCategoriesIconAction({
              apiPayloadRequest: {
                _id: data?.data[0]?._id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: categoryInputs[item],
              },
            })
          );
        }, (index + 1) * 500);
      });

      const commonKeysArray = Object.keys(initialCommonUrls);
      commonKeysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveCommonThumbnailCategoriesAction({
              apiPayloadRequest: {
                _id: data?.data[0]?._id,
                type_of_url:
                  item === "commonMobileUploadUrl" ? 0 : item === "commonWebUploadUrl" ? 1 : 2,
                url_link: categoryInputs[item],
              },
            })
          );
        }, (index + 1) * 600);
      });

      layoutArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveCategoryLayoutAction({
              apiPayloadRequest: {
                _id: item?._id,
                categoryObjId: data?.data[0]?._id,
                order: item?.order,
                // expiry: item?.expiry,
                // title: item?.title || "",
                // subtitle: item?.subtitle || "",
              },
            })
          );
        }, 700 * (index + 1));
      });
      navigate("/categories/all");
    } else {
      setLoading(false);
      Toastiy("Issue performing the operation, contact admin!!", data?.type);
    }
  };

  useEffect(() => {
    if (data?._id !== "" && data?._id !== undefined) {
      dispatch(
        readCategoriesAction({
          apiPayloadRequest: {
            _id: data?._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const categoryData = res?.data[0];
              const iconData = categoryData?.icon_images;
              const gnerictumbnailurls = categoryData?.gnerictumbnailurls;
              const isValidRelatedVideos = categoryData?.relatedvideos?.find(
                (v) => v?.videoObjId?.length > 0
              );
              const relatedvideosData = isValidRelatedVideos ? categoryData?.relatedvideos : [];

              setLanguagemeta(categoryData?.languagemetas);

              setTranslateCategories({
                category_name: categoryData?.category_name || "",
                category_description: categoryData?.category_description || "",
              });

              // commonMobileUploadUrl: "",
              // commonTabletUploadUrl: "",
              // commonWebUploadUrl: "",

              setCateoryInputs({
                category_name: categoryData?.category_name || "",
                category_description: categoryData?.category_description || "",
                mobile_layout: categoryData?.mobile_layout || "",
                mobileUploadUrl:
                  iconData?.filter((item) => item?.type_of_url === "0")[0].url_link || "",
                tabletUploadUrl:
                  iconData?.filter((item) => item?.type_of_url === "1")[0].url_link || "",
                webUploadUrl:
                  iconData?.filter((item) => item?.type_of_url === "2")[0].url_link || "",
                commonMobileUploadUrl:
                  gnerictumbnailurls?.filter((item) => item?.type_of_url === "0")[0].url_link || "",
                commonTabletUploadUrl:
                  gnerictumbnailurls?.filter((item) => item?.type_of_url === "1")[0].url_link || "",
                commonWebUploadUrl:
                  gnerictumbnailurls?.filter((item) => item?.type_of_url === "2")[0].url_link || "",
              });

              setCurrentInputs({
                category_name: categoryData?.category_name || "",
                category_description: categoryData?.category_description || "",
                mobile_layout: categoryData?.mobile_layout || "",
                mobileUploadUrl:
                  iconData?.filter((item) => item?.type_of_url === "0")[0].url_link || "",
                tabletUploadUrl:
                  iconData?.filter((item) => item?.type_of_url === "1")[0].url_link || "",
                webUploadUrl:
                  iconData?.filter((item) => item?.type_of_url === "2")[0].url_link || "",
              });

              relatedvideosData?.map((item) => {
                setSelectedOptions((prev) => [
                  ...prev,
                  {
                    value: item?.videoObjId[0]?._id || "",
                    label: item?.videoObjId[0]?.video_title || "",
                    image:
                      item?.videoObjId[0]?.tumbnailurls.filter(
                        (thumbnail) => thumbnail.type_of_url === "0"
                      )[0].url_link || "",
                  },
                ]);
              });

              dispatch(
                readCategoryLayoutAction({
                  apiPayloadRequest: {
                    categoryObjId: data?._id,
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      res?.data.forEach((layout) => {
                        const requiredData = layout?.categories?.filter(
                          (item) => item.categoryObjId === data?._id
                        );

                        const requiredId = layout._id;
                        const requiredMobileLayout = layout.mobile_layout;
                        const requiredName = layout.layout_name;

                        requiredData.map((item) => {
                          setLayoutArray((prev) => [
                            ...prev,
                            {
                              _id: requiredId,
                              mobile_layout: requiredMobileLayout,
                              layout_name: requiredName,
                              order: item.order,
                              // title: item.title,
                              // subtitle: item.subtitle,
                              // expiry: item.expiry,
                            },
                          ]);
                        });
                      });
                    }
                  },
                })
              );

              // setRelatedVideos(data?.relatedvideos)
            }
          },
        })
      );
      // setCateoryInputs(data?.categoryInputs)
      // setRelatedVideos(data?.relatedvideos)
    }
  }, []);

  const combineStateKeys = () => {
    const values = Object.values(translateCategories);
    const combinedString = values.join(" @@@@ ");
    return combinedString;
  };

  const updateStateWithTranslation = () => {
    const combinedText = combineStateKeys();
    dispatch(
      translateLanguageAction({
        apiPayloadRequest: {
          targetcode: activeTab,
          translatetext: combinedText,
        },
        callback: (res) => onTranslateSuccess(res?.data),
      })
    );
  };

  const onTranslateSuccess = (data) => {
    console.log(translateCategories, "myState");
    const arrayOfValues = data.split(" @@@@ ");
    const arrayOfKeys = Object.keys(translateCategories);

    for (let key in arrayOfKeys) {
      setTranslateCategories((preState) => ({
        ...preState,
        [arrayOfKeys[key]]: arrayOfValues[key],
      }));

      setCateoryInputs((preState) => ({
        ...preState,
        [arrayOfKeys[key]]: arrayOfValues[key],
      }));
    }
  };

  const [currentLanguageInputs, setCurrentLanguageInputs] = useState();

  useEffect(() => {
    if (activeTab === "en") {
      if (currentInputs) {
        setCateoryInputs(currentInputs);
      }
    } else {
      const isPresent = languagemeta?.some((language) => language?.language_code === activeTab);
      if (isPresent) {
        const data = languagemeta?.filter((item) => item?.language_code === activeTab)[0];

        setCateoryInputs((prev) => ({
          ...prev,
          category_name: data?.category_name || "",
          category_description: data?.category_description || "",
        }));

        setCurrentLanguageInputs((prev) => ({
          ...prev,
          category_name: data?.category_name || "",
          category_description: data?.category_description || "",
        }));
      } else {
        setCateoryInputs(currentInputs);
        setCurrentLanguageInputs(currentInputs);
      }
    }
  }, [activeTab]);

  const handleSaveLanguage = () => {
    if (isLanguageSave) {
      dispatch(
        saveCategoriesLanguageAction({
          apiPayloadRequest: {
            _id: data?._id,
            language_code: activeTab,
            category_name: categoryInputs?.category_name,
            category_description: categoryInputs?.category_description,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Categories Language Saved Successfully", 1);
            }
          },
        })
      );
    } else {
      if (
        categoryInputs?.category_name !== currentLanguageInputs?.category_name ||
        categoryInputs?.category_description !== currentLanguageInputs?.category_description
      ) {
        dispatch(
          updateCategoriesLanguageAction({
            apiPayloadRequest: {
              _id: data?._id,
              language_code: activeTab,
              category_name: categoryInputs?.category_name,
              category_description: categoryInputs?.category_description,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Categories Language Updated Successfully", 1);
              }
            },
          })
        );
      }
    }
  };

  return {
    value,
    onChangeSearchBar,
    languages,
    activeTab,
    setActiveTab,
    inputs,
    handleInputChange,
    //handleFileInputChange,
    // handleRelatedVideosChange,
    //handleCategoryDescriptionChange,
    handleSave,
    categoryInputs,
    categoryErrors,
    layoutValues,
    initialLayoutValues,
    selectedOptions,
    //options,
    handleDropdownChange,
    setLayoutValues,
    handleCategoryLayoutChange,
    layoutInfo,
    setLayoutInfo,
    handleUploadInput,
    handleDateChange,

    //Layout
    addedLayout,
    setAddedLayout,
    setLayoutArray,
    setIsLayoutEdit,
    setEditLayoutIndex,
    setEditTargetLayout,
    isLayoutEdit,
    editLayoutIndex,
    editTargetLayout,
    handleDeleteLayoutFromArray,
    handleLayoutUpdate,
    handleVideoLayoutChange,
    handleAddLayoutToArray,
    handleVideoDateChange,
    layoutArray,
    setIsLanguageSave,
    languagemeta,
    updateStateWithTranslation,
    handleSaveLanguage,
    activeLanguageDetails,
    setActiveLanguageDetails,
  };
};

export const GetRelatedVideosHooks = () => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);

  const getRelatedVideos = (response) => {
    if (response?.type === 1) {
      response?.data?.map((item) =>
        setOptions((prevData) => [
          ...prevData,
          {
            value: item?._id,
            label: item?.video_title,
            image: item?.tumbnailurls?.length > 0 ? item?.tumbnailurls[0]?.url_link : "",
          },
        ])
      );
    } else {
      Toastiy("Failed To Get Related Videos Data");
    }
  };

  const dispatchReadVideos = () => {
    if (options?.length === 0) {
      dispatch(readVideosAction({ callback: getRelatedVideos }));
    }
  };

  useEffect(() => {
    dispatchReadVideos();
  }, []);

  return {
    options,
  };
};
