import HorizontalTile from "../../components/universal/HorizontalTile";
import avatar from "../../assets/icons/squareCard/story1.png";
import avatar1 from "../../assets/images/avatar1.png";
import avatar22 from "../../assets/images/avatar2.png";
import avatar3 from "../../assets/images/avatar3.png";
import avatar4 from "../../assets/images/avatar4.png";
import avatar2 from "../../assets/icons/StoryCircles/story1.png";

export const tabsTitleArray = ["Users", "Testimonies", "Givings"];
export const tabsContentObject = {
  Users: (
    <>
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
    </>
  ),
  Testimonies: (
    <>
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
    </>
  ),
};
export const givingsHeaders = [
  {
    headerName: "User Name",
    headerId: "userName",
  },
  {
    headerName: "Country",
    headerId: "country",
  },
  {
    headerName: "Views",
    headerId: "views",
  },
];

export const givingsData = [
  {
    userName: "Aravind",
    country: "India",
    views: "128",
  },
  {
    userName: "John suresh",
    country: "India",
    views: "124",
  },
  {
    userName: "George",
    country: "India",
    views: "124",
  },
  {
    userName: "Stephen Gill",
    country: "India",
    views: "124",
  },
  {
    userName: "Amanda Mendis",
    country: "India",
    views: "124",
  },
  {
    userName: "Maxwell",
    country: "India",
    views: "124",
  },
];
export const allGivingsHeaders = [
  {
    headerName: "User Name",
    headerId: "payment_gateway_reff",
  },
  {
    headerName: "Date & Time",
    headerId: "giving_date",
  },
  {
    headerName: "Country",
    headerId: "country",
  },
  {
    headerName: "Amount",
    headerId: "amount",
  },
  {
    headerName: "Mode of Payment",
    headerId: "mode_of_payment",
  },
];
export const allGivingsData = [
  {
    userNameGivings: {
      imgUrl: avatar,
      id: "#88009",
    },
    date: "21/06/2023 09:29 AM",
    country: "Australia",
    amount: "20$",
    MOP: "Credit Card",
  },
  {
    userNameGivings: {
      imgUrl: avatar1,
      id: "#88008",
    },
    date: "21/06/2023 09:29 AM",
    country: "Australia",
    amount: "20$",
    MOP: "UPI",
  },
  {
    userNameGivings: {
      imgUrl: avatar22,
      id: "#88002",
    },
    date: "21/06/2023 09:29 AM",
    country: "Australia",
    amount: "20$",
    MOP: "Credit Card",
  },
  {
    userNameGivings: {
      imgUrl: avatar3,
      id: "#99999",
    },
    date: "21/06/2023 09:29 AM",
    country: "Australia",
    amount: "20$",
    MOP: "Credit Card",
  },
  {
    userNameGivings: {
      imgUrl: avatar4,
      id: "#99999",
    },
    date: "21/06/2023 09:29 AM",
    country: "Australia",
    amount: "20$",
    MOP: "UPI",
  },
];

// add languages const

export const langArray = [
  { value: "tamil", label: "Tamil" },
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
];
export const LoginInFields = [
  {
    label: "Login",
    placeholder: "Login Id",
  },
  {
    label: "Email or Phone",
    placeholder: "Email or Phone",
  },
  {
    label: "Password",
    placeholder: "Password",
  },
];
export const SignUpFields = [
  {
    label: "Sign Up",
    placeholder: "Sign Up",
  },
  {
    label: "Email or Phone",
    placeholder: "Email or Phone",
  },
];
