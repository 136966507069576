import React from "react";
import storyIcon from "../../assets/images/story.png";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AddCurriculumHooks } from "../../screens/Curriculum/hooks/CurriculumHooks";
function StoryButtons() {
  const { handleStoryClick, activeStory, storyData } = AddCurriculumHooks();
  return (
    <div className="flex flex-wrap gap-2">
      {storyData.map((data, index) => (
        <div
          key={index}
          className={`h-10 w-10 rounded relative ${
            activeStory === index ? "active-story" : ""
          }`}
          onClick={() => handleStoryClick(index)}
        >
          <img
            src={storyIcon}
            className="w-full h-full object-contain"
            alt=""
          />
          {activeStory === index && (
            <div className="h-full w-full absolute rounded top-0 left-0 bg-black bg-opacity-60 flex items-center justify-center">
              <AiOutlineCheckCircle className="text-yellowish" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default StoryButtons;
