import React, { useState } from "react";

function CustomTabsV2({ tabsTitleArray, tabsContentObject, tabHeight, tabsClassName = "" }) {
  const [activeTab, setActiveTab] = useState(tabsTitleArray[0]);

  const handleTabSuper = (tabName) => {
    setActiveTab(tabName);
  };

  const currentKey = Object.keys(tabsContentObject).filter((key) => key === activeTab)[0];

  return (
    <>
      <div className={`tab-list overflow-x-auto flex gap-2 w-full justify-around ${tabsClassName}`}>
        {tabsTitleArray.map((tab, i) => (
          <div
            onClick={() => handleTabSuper(tab)}
            key={i + 100}
            className={`${
              activeTab === tab ? "text-white border-b border-yellowish" : "text-greyish"
            } flex-1 px-4 flex whitespace-nowrap justify-center items-center ${tabHeight} focus:border-yellowish focus:text-white focus:bg-opacity-10 hover:border-yellowish hover:border-b cursor-pointer font-semibold text-[14px] leading-3 hover:text-white`}
          >
            {tab}
          </div>
        ))}
      </div>

      <div className="outlet h-full">
        <div className="h-full ">
          <div className="h-full overflow-y-auto  w-full">{tabsContentObject[currentKey]}</div>
        </div>
      </div>
    </>
  );
}

export default CustomTabsV2;
