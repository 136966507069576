import * as actionTypes from "../ActionTypes";

export const saveTWGAction = (payload) => {
    return {
        type: actionTypes.SAVE_TWG_REQUEST,
        payload: payload,
    };
};

export const getListTWGAction = (payload) => {
    return {
        type: actionTypes.GET_LIST_TWG_REQUEST,
        payload: payload,
    };
};

export const updateTWGAction = (payload) => {
    return {
        type: actionTypes.UPDATE_TWG_REQUEST,
        payload: payload,
    };
};

export const savePromiseAction = (payload) => ({
    type: actionTypes.SAVE_PROMISE_REQUEST,
    payload: payload,
});

export const savePromiseLanguageAction = (payload) => ({
    type: actionTypes.SAVE_PROMISE_LANGUAGE_REQUEST,
    payload: payload,
});

export const updatePromiseAction = (payload) => ({
    type: actionTypes.UPDATE_PROMISE_REQUEST,
    payload: payload,
});

export const updatePromiseLanguageAction = (payload) => ({
    type: actionTypes.UPDATE_PROMISE_LANGUAGE_REQUEST,
    payload: payload,
});

export const deletePromiseAction = (payload) => ({
    type: actionTypes.DELETE_PROMISE_REQUEST,
    payload: payload,
});

export const readPromiseAction = (payload) => ({
    type: actionTypes.READ_PROMISE_REQUEST,
    payload: payload,
});

export const savePromiseBgAction = (payload) => ({
    type: actionTypes.SAVE_PROMISE_BG_REQUEST,
    payload: payload,
});

export const updatePromiseBgAction = (payload) => ({
    type: actionTypes.UPDATE_PROMISE_BG_REQUEST,
    payload: payload,
});

export const deletePromiseBgAction = (payload) => ({
    type: actionTypes.DELETE_PROMISE_BG_REQUEST,
    payload: payload,
});

export const savePromiseAudioAction = (payload) => ({
    type: actionTypes.SAVE_PROMISE_AUDIO_REQUEST,
    payload: payload,
});

export const savePromiseAudioLanguageAction = (payload) => ({
    type: actionTypes.SAVE_PROMISE_AUDIO_LANGUAGE_REQUEST,
    payload: payload,
});

export const updatePromiseAudioAction = (payload) => ({
    type: actionTypes.UPDATE_PROMISE_AUDIO_REQUEST,
    payload: payload,
});

export const updatePromiseAudioLanguageAction = (payload) => ({
    type: actionTypes.UPDATE_PROMISE_AUDIO_LANGUAGE_REQUEST,
    payload: payload,
});

export const deletePromiseAudioAction = (payload) => ({
    type: actionTypes.DELETE_PROMISE_AUDIO_REQUEST,
    payload: payload,
});

export const savePromiseVideoAction = (payload) => ({
    type: actionTypes.SAVE_PROMISE_VIDEO_REQUEST,
    payload: payload,
});

export const savePromiseVideoLanguageAction = (payload) => ({
    type: actionTypes.SAVE_PROMISE_VIDEO_LANGUAGE_REQUEST,
    payload: payload,
});

export const updatePromiseVideoAction = (payload) => ({
    type: actionTypes.UPDATE_PROMISE_VIDEO_REQUEST,
    payload: payload,
});

export const updatePromiseVideoLanguageAction = (payload) => ({
    type: actionTypes.UPDATE_PROMISE_VIDEO_LANGUAGE_REQUEST,
    payload: payload,
});

export const deletePromiseVideoAction = (payload) => ({
    type: actionTypes.DELETE_PROMISE_VIDEO_REQUEST,
    payload: payload,
});