import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteVideosAction,
  deleteVideosrelatedAction,
  readLanguageAction,
  readVideosAction,
  saveCommonsUrlsAction,
  saveDownloadVideoUrlLanguageAction,
  saveVideoLanguageAction,
  saveVideoUrlLanguageAction,
  saveVideosAction,
  saveVideosDownloadUrlsAction,
  saveVideosThumbNailUrlsAction,
  saveVideosUrlsAction,
  saveVideosrelatedAction,
  translateLanguageAction,
  updateCommonsUrlsAction,
  updateDownloadVideoUrlLanguageAction,
  updateVideoLanguageAction,
  updateVideoUrlLanguageAction,
  updateVideosAction,
  updateVideosDownloadUrlsAction,
  updateVideosThumbNailUrlsAction,
  updateVideosUrlsAction,
  uploadAssestsAction,
  uploadVideosAction,
} from "../../../store/Languages/LanguageAction";
import {} from "q";
import Toastiy from "../../../components/universal/Toastiy";
import { useNavigate } from "react-router-dom";
import {
  deleteVideoLayoutAction,
  readLayoutAction,
  readVideoLayoutAction,
  saveVideoLayoutAction,
  updateVideoLayoutAction,
} from "../../../store/Layout/LayoutAction";

const VideosHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

export default VideosHooks;

export const AllVideosHooks = () => {
  const [value, setValue] = useState("");
  const [allVideosData, setAllVideosData] = useState([]);
  const dispatch = useDispatch();

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    dispatch(
      readVideosAction({
        callback: getAllVideosResponse,
      })
    );
  }, []);

  const getAllVideosResponse = (Data) => {
    if (Data?.type === 1) {
      setAllVideosData(Data?.data);
    }
  };

  const handleDelete = (row, index) => {
    dispatch(
      deleteVideosAction({
        apiPayloadRequest: { _id: row?._id },
        callback: (res) => getVideoDeletedResponse(res, "Video Deleted Successfully"),
      })
    );
  };

  const getVideoDeletedResponse = (data, message) => {
    if (data?.type === 1) {
      Toastiy(message, data?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", data?.type);
    }
  };
  return { value, onChangeSearchBar, allVideosData, handleDelete };
};

export const AddVideosHooks = (setLoading, data) => {
  // search logic here
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const [activeTab, setActiveTab] = useState("en");
  const [activeLanguageDetails, setActiveLanguageDetails] = useState({
    language_code: "en",
    language_name: "English",
  });
  const [allLanguageData, setAllLanguageData] = useState([]);
  const [readLanguageData, setReadLanguageData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [relatedVideos, setRelatedVideos] = useState([]);
  const dispatch = useDispatch();

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };
  // add category logic here

  const initialLayoutValues = {
    _id: "",
    mobile_layout: "1",
    layout_name: "",
    order: "",
    // title: "",
    // subtitle: "",
    // expiry: "",
  };

  const initialVideoValues = {
    video_title: "",
    video_description: "",
    _id: "",
  };

  const [layoutValues, setLayoutValues] = useState(initialLayoutValues);
  const [addedLayout, setAddedLayout] = useState(false);

  const initialStateValues = {
    mobileUploadUrl: "",
    webUploadUrl: "",
    tabletUploadUrl: "",
  };

  const languages = [
    "English",
    "hindi",
    "tamil",
    "telugu",
    "Malayalam",
    "Spanish",
    "Marathi",
    "Urdu",
    "Oriya",
    "kanada",
    "Bengali",
  ]; // List of languages

  console.log(activeTab, "activeTab");
  const [downloadValues, setDownloadValues] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const [thumbNailValues, setThumbNailValues] = useState("");
  const [thumbNailUrl, setThumbNailUrl] = useState("");
  const [inputs, setInputs] = useState("");
  const [videoInputs, setVideoInputs] = useState(initialVideoValues);
  const [videoErrors, setVideoErrors] = useState({
    video_title: false,
    video_description: false,
    video_urls: false,
    video_download_urls: false,
    video_thumbnails_urls: false,
    selectedOptions: false,
  });

  const [currentInputs, setCurrentInputs] = useState();
  const [languagemeta, setLanguagemeta] = useState();

  const [videoUrlsInputs, setVideoUrlsInputs] = useState(initialStateValues);
  const [UdownloadrlsInputs, setDownloadUrlsInputs] = useState(initialStateValues);
  const [tumbNailUrlsInputs, setTumbNailUrlsInputs] = useState(initialStateValues);
  const [commonVideoUrlsInputs, setCommonUrlsInputs] = useState(initialStateValues);

  const [videoUrlsInputsCheck, setVideoUrlsInputsCheck] = useState();
  const [UdownloadrlsInputsCheck, setDownloadUrlsInputsCheck] = useState();
  const [tumbNailUrlsInputsCheck, setTumbNailUrlsInputsCheck] = useState();
  const [commonUrlsInputsCheck, setCommonUrlsInputsCheck] = useState();
  const [layoutInfo, setLayoutInfo] = useState(false);
  const [layoutArray, setLayoutArray] = useState([]);
  const [isLayoutEdit, setIsLayoutEdit] = useState(false);
  const [editLayoutIndex, setEditLayoutIndex] = useState(null);
  const [editTargetLayout, setEditTargetLayout] = useState("");

  const navigate = useNavigate();

  const combineStateKeys = () => {
    const values = Object.values(videoInputs);
    const combinedString = values.join(" @@@@ ");
    return combinedString;
  };

  const updateStateWithTranslation = () => {
    const combinedText = combineStateKeys();

    dispatch(
      translateLanguageAction({
        apiPayloadRequest: {
          targetcode: activeTab,
          translatetext: combinedText,
        },
        callback: (res) => onTranslateSuccess(res?.data),
      })
    );
  };

  const onTranslateSuccess = (data) => {
    console.log(videoInputs, "myState");
    const arrayOfValues = data.split(" @@@@ ");
    const arrayOfKeys = Object.keys(videoInputs);

    for (let key in arrayOfKeys) {
      setVideoInputs((preState) => ({
        ...preState,
        [arrayOfKeys[key]]: arrayOfValues[key],
      }));
    }
  };

  const getRelatedVideos = (response) => {
    if (response?.type === 1) {
      response?.data?.map((item) =>
        setOptions((prevData) => [
          ...prevData,
          {
            value: item?._id || "",
            label: item?.video_title || "",
            image: item?.tumbnailurls?.length > 0 ? item?.tumbnailurls[0]?.url_link : "",
          },
        ])
      );
      console.log(response, "response");
    } else {
      Toastiy("Failed To Get Related Videos Data");
    }
  };

  const handleAddLayoutToArray = () => {
    if (videoInputs?._id !== undefined && videoInputs?._id !== "") {
      dispatch(
        saveVideoLayoutAction({
          apiPayloadRequest: {
            _id: layoutValues?._id,
            videoObjId: videoInputs?._id,
            order: layoutValues?.order,
            // expiry: layoutValues?.expiry,
            // title: layoutValues?.title,
            // subtitle: layoutValues?.subtitle,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Layout Added Successfully!", 1);
              setLayoutArray((prev) => [...prev, layoutValues]);
              setLayoutValues(initialLayoutValues);
              setAddedLayout(true);
            }
          },
        })
      );
    } else {
      setLayoutArray((prev) => [...prev, layoutValues]);
      setLayoutValues(initialLayoutValues);
      setAddedLayout(true);
    }
  };

  const handleDeleteLayoutFromArray = (id) => {
    if (videoInputs?._id !== undefined && videoInputs?._id !== "") {
      dispatch(
        deleteVideoLayoutAction({
          apiPayloadRequest: {
            _id: id,
            videoObjId: videoInputs?._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const updatedArray = layoutArray.filter((item) => item._id !== id);
              setLayoutArray(updatedArray);
            }
          },
        })
      );
    } else {
      const updatedArray = layoutArray.filter((item) => item._id !== id);
      setLayoutArray(updatedArray);
    }
  };

  const handleLayoutUpdate = () => {
    if (videoInputs?._id !== undefined && videoInputs?._id !== "") {
      dispatch(
        updateVideoLayoutAction({
          apiPayloadRequest: {
            _id: layoutArray._id,
            videoObjId: videoInputs?._id,
            order: layoutArray._id,
            // title: layoutArray._id,
            // subtitle: layoutArray._id,
            // expiry: layoutArray._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setLayoutValues(initialLayoutValues);
              setIsLayoutEdit(false);
              Toastiy("Layout Updated Successfully!", 1);
            }
          },
        })
      );
    } else {
      setLayoutValues(initialLayoutValues);
      setIsLayoutEdit(false);
    }
  };

  const handleDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));
  };

  const handleVideoLayoutChange = (event) => {
    if (isLayoutEdit) {
      const { name, value } = event.target;
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));

      setLayoutArray((prev) => {
        const updatedArray = [...prev];

        updatedArray[editLayoutIndex] = {
          ...updatedArray[editLayoutIndex],
          [name]: value,
        };
      });
    } else {
      const { name, value } = event.target;
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleVideoDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));
  };

  const handleDropdownChange = (selectedValues) => {
    if (data?._id !== "" && data?._id !== undefined) {
      if (selectedValues?.length > selectedOptions?.length) {
        const requiredValue = selectedValues?.filter(
          (item1) => !selectedOptions.some((item2) => item1?.value === item2?.value)
        );

        dispatch(
          saveVideosrelatedAction({
            apiPayloadRequest: {
              _id: data?._id,
              videoObjId: requiredValue[0]?.value,
            },
            callback: (res) => {
              if (res?.type === 1) {
                setSelectedOptions(selectedValues);
              }
            },
          })
        );
      } else {
        const requiredValue = selectedOptions.filter(
          (item1) => !selectedValues.some((item2) => item1.value === item2.value)
        );

        dispatch(
          deleteVideosrelatedAction({
            apiPayloadRequest: {
              _id: data?._id,
              videoObjId: requiredValue[0]?.value,
            },
            callback: (res) => {
              if (res?.type === 1) {
                setSelectedOptions((prev) =>
                  prev.filter((item) => item.value !== requiredValue[0]?.value)
                );
              }
            },
          })
        );
      }
      setVideoErrors({ ...videoErrors, selectedOptions: false });
    } else {
      setVideoErrors({ ...videoErrors, selectedOptions: false });
      setSelectedOptions(selectedValues);
    }
  };

  const getAllLanguageResponse = (response) => {
    if (response?.type === 1) {
      setReadLanguageData(response?.data);
      setLoading(false);
    }
  };

  const handleInputChange = (event, setState) => {
    const { name, value } = event.target;
    setState((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setVideoErrors({
      ...videoErrors,
      video_urls: false,
      video_download_urls: false,
      video_thumbnails_urls: false,
    });
  };

  const handleDownloadInputChange = (event) => {
    const { name, value } = event.target;
    setDownloadUrlsInputs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setVideoErrors({ ...videoErrors, video_download_urls: false });
  };

  const handleTumbNailInputChange = (event) => {
    const { name, value } = event.target;
    setTumbNailUrlsInputs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setVideoErrors({ ...videoErrors, video_thumbnails_urls: false });
  };

  const handleCommonTumbNailInputChange = (event) => {
    const { name, value } = event.target;
    setCommonUrlsInputs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setVideoErrors({ ...videoErrors, video_thumbnails_urls: false });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVideoInputs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setVideoErrors({ ...videoErrors, [name]: false });
  };

  const handleFileInputChange = (setState, keyName, file, type) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    if (type === "videos") {
      dispatch(
        uploadVideosAction({
          apiPayloadRequest: formData,
          callback: (res) => {
            setState((prevInputs) => ({
              ...prevInputs,
              [keyName]: res?.url,
            }));
            setVideoErrors({ ...videoErrors, video_urls: false });
            setLoading(false);
          },
        })
      );
    } else {
      dispatch(
        uploadAssestsAction({
          apiPayloadRequest: formData,
          callback: (res) => {
            setState((prevInputs) => ({
              ...prevInputs,
              [keyName]: res?.url,
            }));
            setVideoErrors({ ...videoErrors, video_urls: false });
            setLoading(false);
          },
        })
      );
    }
  };

  const handleFileDownloadInputChange = (language, field, file, value) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: formData,
        callback: (res) => {
          setDownloadUrl(res?.url);
          setDownloadUrlsInputs((prevInputs) => ({
            ...prevInputs,
            [field]: res?.url,
          }));
          setVideoErrors({ ...videoErrors, video_download_urls: false });
        },
      })
    );
    setDownloadValues(value);
  };

  const handleFiletumbNailInputChange = (language, field, file, value) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: formData,
        callback: (res) => {
          setThumbNailUrl(res?.url);
          setTumbNailUrlsInputs((prevInputs) => ({
            ...prevInputs,
            [field]: res?.url,
          }));
          setVideoErrors({ ...videoErrors, video_thumbnails_urls: false });
        },
      })
    );
    setThumbNailValues(value);
  };

  const handleRelatedVideosChange = (selectedOptions) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [activeTab]: {
        ...prevInputs[activeTab],
        relatedVideos: selectedOptions.map((option) => option.value),
      },
    }));
    setVideoErrors({ ...videoErrors, selectedOptions: false });
  };

  const handleVideoDescriptionChange = (e) => {
    handleInputChange(activeTab, "videoDescription", e.target.value);
  };

  const videoData =
    videoUrlsInputs.mobileUploadUrl !== ""
      ? videoUrlsInputs.mobileUploadUrl
      : videoUrlsInputs.webUploadUrl !== ""
      ? videoUrlsInputs.webUploadUrl
      : videoUrlsInputs.tabletUploadUrl;

  const downloadData =
    UdownloadrlsInputs.mobileUploadUrl !== ""
      ? UdownloadrlsInputs.mobileUploadUrl
      : UdownloadrlsInputs.webUploadUrl !== ""
      ? UdownloadrlsInputs.webUploadUrl
      : UdownloadrlsInputs.tabletUploadUrl;

  const tumbNailData =
    tumbNailUrlsInputs.mobileUploadUrl !== ""
      ? tumbNailUrlsInputs.mobileUploadUrl
      : tumbNailUrlsInputs.webUploadUrl !== ""
      ? tumbNailUrlsInputs.webUploadUrl
      : tumbNailUrlsInputs.tabletUploadUrl;

  const handleSave = () => {
    if (videoInputs?._id === undefined || videoInputs?._id === "") {
      // validating fields
      if (!videoInputs?.video_title) setVideoErrors({ ...videoErrors, video_title: true });
      else if (!videoInputs?.video_description)
        setVideoErrors({ ...videoErrors, video_description: true });
      else if (
        !videoUrlsInputs?.mobileUploadUrl ||
        !videoUrlsInputs?.tabletUploadUrl ||
        !videoUrlsInputs?.webUploadUrl
      )
        setVideoErrors({ ...videoErrors, video_urls: true });
      else if (
        !UdownloadrlsInputs?.mobileUploadUrl ||
        !UdownloadrlsInputs?.tabletUploadUrl ||
        !UdownloadrlsInputs?.webUploadUrl
      )
        setVideoErrors({ ...videoErrors, video_download_urls: true });
      else if (
        !tumbNailUrlsInputs?.mobileUploadUrl ||
        !tumbNailUrlsInputs?.tabletUploadUrl ||
        !tumbNailUrlsInputs?.webUploadUrl
      )
        setVideoErrors({ ...videoErrors, video_thumbnails_urls: true });
      else if (!selectedOptions || selectedOptions?.length === 0)
        setVideoErrors({ ...videoErrors, selectedOptions: true });
      else {
        setLoading(true);
        dispatch(
          saveVideosAction({
            apiPayloadRequest: videoInputs,
            callback: getSaveVideosResponse,
          })
        );
      }
    } else {
      const id = videoInputs?._id;
      const keysArray = Object.keys(videoUrlsInputs);

      if (
        currentInputs?.video_title !== videoInputs?.video_title ||
        currentInputs?.video_description !== videoInputs?.video_description
      ) {
        setLoading(true);
        dispatch(
          updateVideosAction({
            apiPayloadRequest: videoInputs,
            callback: (response) => {
              if (response?.type === 1) {
                console.log(response);
                setTimeout(() => {
                  setLoading(false);
                  Toastiy("Video Updated Successfully!", 1);
                  navigate("/videos/all");
                }, 1000);
              } else {
                Toastiy("Video Update Failed");
              }
              setLoading(false);
            },
          })
        );
      }

      // if (videoUrlsInputsCheck === '') {
      //   keysArray.map((item, index) => {
      //     setTimeout(() => {
      //       dispatch(saveVideosUrlsAction({
      //         apiPayloadRequest: {
      //           _id: id,
      //           type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
      //           url_link: videoUrlsInputs[item]
      //         },
      //         callback: (res) => {
      //           if (res?.type !== 1) {
      //             Toastiy("Failed to Video URLS")
      //           }
      //         }
      //       }))
      //     }, 500 * (index + 1))
      //   })
      // } else {
      keysArray.map((item, index) => {
        setTimeout(() => {
          if (videoUrlsInputs[item] !== videoUrlsInputsCheck[item]) {
            dispatch(
              updateVideosUrlsAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                  url_link: videoUrlsInputs[item],
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to Video URLS");
                  }
                },
              })
            );
          }
        }, 500 * (index + 1));
      });
      // }

      // if (UdownloadrlsInputsCheck === '') {
      //   keysArray.map((item, index) => {
      //     setTimeout(() => {
      //       dispatch(saveVideosDownloadUrlsAction({
      //         apiPayloadRequest: {
      //           _id: id,
      //           type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
      //           url_link: UdownloadrlsInputs[item]
      //         },
      //         callback: (res) => {
      //           if (res?.type !== 1) {
      //             Toastiy("Failed to Video Download URLS")
      //           }
      //         }
      //       }))
      //     }, 1000 * (index + 1))
      //   })
      // } else {
      keysArray.map((item, index) => {
        setTimeout(() => {
          if (UdownloadrlsInputs[item] !== UdownloadrlsInputsCheck[item]) {
            dispatch(
              updateVideosDownloadUrlsAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                  url_link: UdownloadrlsInputs[item],
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to Video Download URLS");
                  }
                },
              })
            );
          }
        }, 1000 * (index + 1));
      });

      keysArray.map((item, index) => {
        setTimeout(() => {
          if (tumbNailUrlsInputsCheck[item] !== tumbNailUrlsInputs[item]) {
            dispatch(
              updateVideosThumbNailUrlsAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                  url_link: tumbNailUrlsInputs[item],
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to Tumbnail URLS");
                  } else {
                    Toastiy("Video Updated Successfully!", 1);
                  }
                },
              })
            );
          }

          if (commonUrlsInputsCheck[item] !== commonVideoUrlsInputs[item]) {
            dispatch(
              updateCommonsUrlsAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                  url_link: commonVideoUrlsInputs[item],
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to Tumbnail URLS");
                  } else {
                    Toastiy("Video Updated Successfully!", 1);
                  }
                },
              })
            );
          }
        }, 1500 * (index + 1));
      });
      // }
    }
  };

  const getSaveVideosResponse = (response) => {
    const leng = response?.data?.length - 1;
    const id = response?.data[leng]?._id;
    const keysArray = Object.keys(videoUrlsInputs);

    if (response.type === 1) {
      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveVideosUrlsAction({
              apiPayloadRequest: {
                _id: id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: videoUrlsInputs[item],
              },
              callback: (res) => {
                if (res?.type !== 1) {
                  Toastiy("Failed to Video URLS");
                }
              },
            })
          );
        }, 500 * (index + 1));
      });

      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveVideosDownloadUrlsAction({
              apiPayloadRequest: {
                _id: id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: UdownloadrlsInputs[item],
              },
              callback: (res) => {
                if (res?.type !== 1) {
                  Toastiy("Failed to Video Download URLS");
                }
              },
            })
          );
        }, 1000 * (index + 1));
      });

      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveVideosThumbNailUrlsAction({
              apiPayloadRequest: {
                _id: id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: tumbNailUrlsInputs[item],
              },
              callback: (res) => {
                if (res?.type !== 1) {
                  Toastiy("Failed to Thumbnail URLS");
                } else {
                  // Toastiy("Video Saved Successfully!", 1);
                }
              },
            })
          );
        }, 500 * (index + 1));
      });

      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveCommonsUrlsAction({
              apiPayloadRequest: {
                _id: id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: commonVideoUrlsInputs[item],
              },
              callback: (res) => {
                if (res?.type !== 1) {
                  Toastiy("Failed to Thumbnail URLS");
                } else {
                  // Toastiy("Video Saved Successfully!", 1);
                }
              },
            })
          );
        }, 600 * (index + 1));
      });

      selectedOptions.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveVideosrelatedAction({
              apiPayloadRequest: {
                _id: id,
                videoObjId: item?.value,
              },
            })
          );
        }, 600 * (index + 1));
      });

      layoutArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveVideoLayoutAction({
              apiPayloadRequest: {
                _id: item?._id,
                videoObjId: id,
                order: item?.order,
                // expiry: item?.expiry,
                // title: item?.title,
                // subtitle: item?.subtitle,
              },
              callback: (res) => {
                console.log(res);
              },
            })
          );
        }, 700 * (index + 1));
      });
      setTimeout(() => {
        setLoading(false);
        Toastiy("Video Updated Successfully!", 1);
        navigate("/videos/all");
      }, 3000);
    } else {
      Toastiy("Failed To Save Video");
    }
    setLoading(false);
  };

  // mobileUploadUrl: '',
  // webUploadUrl: '',
  // tabletUploadUrl: '', saveVideosThumbNailUrlsAction

  const getSaveVideosResponse1 = (data, id, message) => {
    if (data?.type === 1) {
      Toastiy(message, data?.type);
      dispatch(
        saveVideosDownloadUrlsAction({
          apiPayloadRequest: {
            _id: id,
            type_of_url: downloadValues,
            url_link: downloadData,
          },
          callback: (res) =>
            getSaveVideosResponse2(res, id, "Video Download Url Saved Sucessfully."),
        })
      );
    } else {
      Toastiy("Issue performing the operation, contact admin!!", data?.type);
    }
  };

  const getSaveVideosResponse2 = (data, id, message) => {
    if (data?.type === 1) {
      Toastiy(message, data?.type);
      dispatch(
        saveVideosThumbNailUrlsAction({
          apiPayloadRequest: {
            _id: id,
            type_of_url: thumbNailValues,
            url_link: tumbNailData,
          },
          callback: (res) => getSaveVideosResponse3(res, "Video Tumbnail Url Saved Sucessfully."),
        })
      );
    } else {
      Toastiy("Issue performing the operation, contact admin!!", data?.type);
    }
  };

  const getSaveVideosResponse3 = (data, message) => {
    if (data?.type === 1) {
      Toastiy(message, data?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", data?.type);
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      readLanguageAction({
        callback: getAllLanguageResponse,
      })
    );
  }, []);

  useEffect(() => {
    if (data?._id !== "" && data?._id !== undefined) {
      // setTimeout(() => {
      //   dispatch(readVideosAction({ callback: getRelatedVideos }));
      // }, 2000);

      dispatch(
        readVideosAction({
          apiPayloadRequest: {
            _id: data?._id,
          },
          callback: (res) => {
            const data = res?.data[0];
            // setVideoInputs({
            //   _id: data?._id,
            //   video_title: data?.video_title || "",
            //   video_description: data?.video_description || "",
            // });

            setCurrentInputs({
              _id: data?._id,
              video_title: data?.video_title || "",
              video_description: data?.video_description || "",
              totallikes: data?.totallikes || "",
            });

            setLanguagemeta(data?.languagemetas);
            // setVideoUrlsInputs({
            //   mobileUploadUrl:
            //     data?.urls?.filter((item) => item.type_of_url === "0")[0]
            //       .url_link || "",
            //   webUploadUrl:
            //     data?.urls?.filter((item) => item.type_of_url === "1")[0]
            //       .url_link || "",
            //   tabletUploadUrl:
            //     data?.urls?.filter((item) => item.type_of_url === "2")[0]
            //       .url_link || "",
            // });

            setVideoUrlsInputsCheck({
              mobileUploadUrl:
                data?.urls?.filter((item) => item.type_of_url === "0")[0].url_link || "",
              webUploadUrl:
                data?.urls?.filter((item) => item.type_of_url === "1")[0].url_link || "",
              tabletUploadUrl:
                data?.urls?.filter((item) => item.type_of_url === "2")[0].url_link || "",
            });

            // setDownloadUrlsInputs({
            //   mobileUploadUrl:
            //     data?.downloadurls?.filter(
            //       (item) => item.type_of_url === "0"
            //     )[0].url_link || "",
            //   webUploadUrl:
            //     data?.downloadurls?.filter(
            //       (item) => item.type_of_url === "1"
            //     )[0].url_link || "",
            //   tabletUploadUrl:
            //     data?.downloadurls?.filter(
            //       (item) => item.type_of_url === "2"
            //     )[0].url_link || "",
            // });

            setDownloadUrlsInputsCheck({
              mobileUploadUrl:
                data?.downloadurls?.filter((item) => item.type_of_url === "0")[0].url_link || "",
              webUploadUrl:
                data?.downloadurls?.filter((item) => item.type_of_url === "1")[0].url_link || "",
              tabletUploadUrl:
                data?.downloadurls?.filter((item) => item.type_of_url === "2")[0].url_link || "",
            });

            setTumbNailUrlsInputs({
              mobileUploadUrl:
                data?.tumbnailurls?.filter((item) => item.type_of_url === "0")[0].url_link || "",
              webUploadUrl:
                data?.tumbnailurls?.filter((item) => item.type_of_url === "1")[0].url_link || "",
              tabletUploadUrl:
                data?.tumbnailurls?.filter((item) => item.type_of_url === "2")[0].url_link || "",
            });

            setCommonUrlsInputs({
              mobileUploadUrl:
                data?.gnerictumbnailurls?.filter((item) => item.type_of_url === "0")[0].url_link ||
                "",
              webUploadUrl:
                data?.gnerictumbnailurls?.filter((item) => item.type_of_url === "1")[0].url_link ||
                "",
              tabletUploadUrl:
                data?.gnerictumbnailurls?.filter((item) => item.type_of_url === "2")[0].url_link ||
                "",
            });

            setTumbNailUrlsInputsCheck({
              mobileUploadUrl:
                data?.tumbnailurls?.filter((item) => item.type_of_url === "0")[0].url_link || "",
              webUploadUrl:
                data?.tumbnailurls?.filter((item) => item.type_of_url === "1")[0].url_link || "",
              tabletUploadUrl:
                data?.tumbnailurls?.filter((item) => item.type_of_url === "2")[0].url_link || "",
            });

            setCommonUrlsInputsCheck({
              mobileUploadUrl:
                data?.gnerictumbnailurls?.filter((item) => item.type_of_url === "0")[0].url_link ||
                "",
              webUploadUrl:
                data?.gnerictumbnailurls?.filter((item) => item.type_of_url === "1")[0].url_link ||
                "",
              tabletUploadUrl:
                data?.gnerictumbnailurls?.filter((item) => item.type_of_url === "2")[0].url_link ||
                "",
            });

            const isValidRelatedVideos = data?.relatedvideos?.find(
              (v) => v?.videoObjId?.length > 0
            );
            const relatedVideos = isValidRelatedVideos ? data?.relatedvideos : [];
            relatedVideos.map((item) => {
              setSelectedOptions((prev) => [
                ...prev,
                {
                  value: item?.videoObjId?._id,
                  label: item?.videoObjId?.video_title,
                  image: item?.videoObjId?.tumbnailurls[0].url_link,
                },
              ]);
            });
          },
        })
      );

      dispatch(
        readVideoLayoutAction({
          apiPayloadRequest: {
            videoObjId: data?._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              console.log("res?.data", res?.data);
              res?.data.forEach((layout) => {
                const requiredData = layout.videos.filter((item) => item.videoObjId === data?._id);

                const requiredId = layout._id;
                const requiredMobileLayout = layout.mobile_layout;
                const requiredName = layout.layout_name;

                requiredData.map((item) => {
                  setLayoutArray((prev) => [
                    ...prev,
                    {
                      _id: requiredId,
                      mobile_layout: requiredMobileLayout,
                      layout_name: requiredName,
                      order: item.order,
                      // title: item.title,
                      // subtitle: item.subtitle,
                      // expiry: item.expiry,
                    },
                  ]);
                });
              });
            }
          },
        })
      );
    } else {
      dispatch(readVideosAction({ callback: getRelatedVideos }));
    }
  }, []);

  const [isLanguageSave, setIsLanguageSave] = useState(true);
  const [currentLanguageInputs, setCurrentLanguageInputs] = useState();
  const [currentLanguageUrlCheck, setCurrentLanguageUrlCheck] = useState();
  const [currentLanguageDownloadUrlCheck, setCurrentLanguageDownloadUrlCheck] = useState();

  useEffect(() => {
    if (activeTab === "en") {
      if (currentInputs) {
        setVideoInputs(currentInputs);
      }
      if (videoUrlsInputsCheck) {
        setVideoUrlsInputs(videoUrlsInputsCheck);
      }
      if (UdownloadrlsInputsCheck) {
        setDownloadUrlsInputs(UdownloadrlsInputsCheck);
      }
    } else {
      const isPresent = languagemeta?.some((language) => language?.language_code === activeTab);
      if (isPresent) {
        const data = languagemeta?.filter((item) => item?.language_code === activeTab)[0];

        setVideoInputs((prev) => ({
          ...prev,
          video_title: data?.video_title || "",
          video_description: data?.video_description || "",
        }));
        setCurrentLanguageInputs((prev) => ({
          ...prev,
          video_title: data?.video_title || "",
          video_description: data?.video_description || "",
        }));

        if (data?.urls?.length > 0) {
          setVideoUrlsInputs({
            mobileUploadUrl:
              data?.urls?.filter((item) => item.type_of_url === "0")[0].url_link || "",
            webUploadUrl: data?.urls?.filter((item) => item.type_of_url === "1")[0].url_link || "",
            tabletUploadUrl:
              data?.urls?.filter((item) => item.type_of_url === "2")[0].url_link || "",
          });
          setCurrentLanguageUrlCheck({
            mobileUploadUrl:
              data?.urls?.filter((item) => item.type_of_url === "0")[0].url_link || "",
            webUploadUrl: data?.urls?.filter((item) => item.type_of_url === "1")[0].url_link || "",
            tabletUploadUrl:
              data?.urls?.filter((item) => item.type_of_url === "2")[0].url_link || "",
          });
        } else {
          setVideoUrlsInputs(initialStateValues);
          setCurrentLanguageUrlCheck(initialStateValues);
        }
        if (data?.downloadurls?.length > 0) {
          setDownloadUrlsInputs({
            mobileUploadUrl:
              data?.downloadurls?.filter((item) => item.type_of_url === "0")[0].url_link || "",
            webUploadUrl:
              data?.downloadurls?.filter((item) => item.type_of_url === "1")[0].url_link || "",
            tabletUploadUrl:
              data?.downloadurls?.filter((item) => item.type_of_url === "2")[0].url_link || "",
          });
          setCurrentLanguageDownloadUrlCheck({
            mobileUploadUrl:
              data?.downloadurls?.filter((item) => item.type_of_url === "0")[0].url_link || "",
            webUploadUrl:
              data?.downloadurls?.filter((item) => item.type_of_url === "1")[0].url_link || "",
            tabletUploadUrl:
              data?.downloadurls?.filter((item) => item.type_of_url === "2")[0].url_link || "",
          });
        } else {
          setDownloadUrlsInputs(initialStateValues);
          setCurrentLanguageDownloadUrlCheck(initialStateValues);
        }
      } else {
        setVideoInputs(currentInputs);
        setCurrentLanguageInputs(currentInputs);
        setVideoUrlsInputs(initialStateValues);
        setCurrentLanguageUrlCheck(initialStateValues);
        setDownloadUrlsInputs(initialStateValues);
        setCurrentLanguageDownloadUrlCheck(initialStateValues);
      }
    }
  }, [activeTab, videoUrlsInputsCheck, UdownloadrlsInputsCheck]);

  const handleSaveLanguage = () => {
    if (isLanguageSave) {
      const keysArray = Object.keys(videoUrlsInputs);

      dispatch(
        saveVideoLanguageAction({
          apiPayloadRequest: {
            _id: data?._id,
            language_code: activeTab,
            video_title: videoInputs?.video_title,
            video_description: videoInputs?.video_description,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Video Language Saved Successfully", 1);
            }
          },
        })
      );

      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveVideoUrlLanguageAction({
              apiPayloadRequest: {
                _id: data?._id,
                language_code: activeTab,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: videoUrlsInputs[item],
              },
            })
          );
        }, (index + 1) * 500);
      });

      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveDownloadVideoUrlLanguageAction({
              apiPayloadRequest: {
                _id: data?._id,
                language_code: activeTab,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: UdownloadrlsInputs[item],
              },
            })
          );
        }, (index + 1) * 600);
      });
    } else {
      const keysArray = Object.keys(videoUrlsInputs);

      if (
        videoInputs?.video_title !== currentLanguageInputs?.video_title ||
        videoInputs?.video_description !== currentLanguageInputs?.video_description
      ) {
        dispatch(
          updateVideoLanguageAction({
            apiPayloadRequest: {
              _id: data?._id,
              language_code: activeTab,
              video_title: videoInputs?.video_title,
              video_description: videoInputs?.video_description,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Video Language Updated Successfully", 1);
              }
            },
          })
        );
      }

      keysArray.map((item) => {
        if (videoUrlsInputs[item] !== currentLanguageUrlCheck[item]) {
          dispatch(
            updateVideoUrlLanguageAction({
              apiPayloadRequest: {
                _id: data?._id,
                language_code: activeTab,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: videoUrlsInputs[item],
              },
            })
          );
        }
      });

      keysArray.map((item) => {
        if (UdownloadrlsInputs[item] !== currentLanguageDownloadUrlCheck[item]) {
          dispatch({
            apiPayloadRequest: {
              _id: data?._id,
              language_code: activeTab,
              type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
              url_link: UdownloadrlsInputs[item],
            },
          });
        }
      });
    }
  };

  return {
    value,
    onChangeSearchBar,
    languages,
    activeTab,
    setActiveTab,
    activeLanguageDetails,
    setActiveLanguageDetails,
    inputs,
    handleInputChange,
    handleFileInputChange,
    handleRelatedVideosChange,
    handleVideoDescriptionChange,
    handleSave,
    readLanguageData,
    videoInputs,
    videoErrors,
    setVideoUrlsInputs,
    setDownloadUrlsInputs,
    setTumbNailUrlsInputs,
    videoUrlsInputs,
    handleFileDownloadInputChange,
    handleFiletumbNailInputChange,
    tumbNailUrlsInputs,
    UdownloadrlsInputs,
    handleChange,
    handleTumbNailInputChange,
    handleDownloadInputChange,
    selectedOptions,
    handleDropdownChange,
    setLayoutValues,
    handleVideoLayoutChange,
    layoutValues,
    initialLayoutValues,
    setLayoutInfo,
    layoutInfo,
    handleDateChange,
    handleAddLayoutToArray,
    layoutArray,
    setAddedLayout,
    addedLayout,
    handleVideoDateChange,
    options,
    handleDeleteLayoutFromArray,
    setIsLayoutEdit,
    setEditLayoutIndex,
    isLayoutEdit,
    handleLayoutUpdate,
    setEditTargetLayout,
    editTargetLayout,
    updateStateWithTranslation,
    handleCommonTumbNailInputChange,
    setCommonUrlsInputs,
    commonVideoUrlsInputs,
    languagemeta,
    setIsLanguageSave,
    handleSaveLanguage,
  };
};
