function SquareCardTestimonials({
  heading,
  icon,
  user,
  views,
  handleOnClick,
  iconStyle,
}) {
  return (
    <div
      onClick={() => handleOnClick()}
      className="cursor-pointer md:w-[224px] md:h-[184px] rounded-2xl border border-greyishV2 border-opacity-50 flex flex-col gap-3 bg-blackishV2 md:p-4 p-2"
    >
      <p className="text-sm text-greyish">{heading}</p>
      <div className="h-fit w-full relative">
        {icon ? (
          <img
            className={`${iconStyle} object-cover md:w-[179px] w-[108px] h-[108px]  rounded-xl`}
            src={icon}
            alt=""
          />
        ) : (
          <div
            className={`${iconStyle} object-cover md:w-[179px] w-[108px] h-[108px] bg-black bg-opacity-30  rounded-xl`}
          ></div>
        )}
        <p className="text-white text-sm font-medium absolute bottom-12 left-2">
          {user}
        </p>
        <p className="text-yellowish text-base font-bold absolute bottom-4 left-2">
          {views} <span className="text-white text-sm">Views</span>{" "}
        </p>
      </div>
    </div>
  );
}

export default SquareCardTestimonials;
