import React, { useEffect, useState } from "react";
import Select from "react-select";
import infoCircle from "../../assets/icons/infocircle.svg";
import searchIcon from "../../assets/icons/search.svg";
import reload from "../../assets/icons/reload.svg";
import CustomTabsV2 from "../universal/CustomTabsV2";
import {
  AddCategoriesHooks,
  CategoriesHooks,
  GetRelatedVideosHooks,
} from "../../screens/Categories/hooks/CategoriesHooks";
import layout1 from "../../assets/images/layout1.jpeg";
import layout2 from "../../assets/images/layout2.jpeg";
import layout3 from "../../assets/images/layout3.jpeg";
import layout4 from "../../assets/images/layout4.jpeg";
import layout5 from "../../assets/images/layout5.jpeg";
import layout6 from "../../assets/images/layout6.jpeg";
import Navbar from "../universal/Navbar";
import DropdownWithTags from "../universal/DropdownWithTags";
import SearchBar from "../universal/SearchBar";
import TextInputField from "../InputFields/TextInputField";
import LayoutDropdown from "../universal/LayoutDropdown";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import moment from "moment";
import edit from "../../assets/icons/edit.svg";
import close from "../../assets/icons/close.svg";
import { useDispatch } from "react-redux";
import { readLanguageAction } from "../../store/Languages/LanguageAction";
import ReactModal from "react-modal";
import { ImCross } from "react-icons/im";
import LanguageDrawer from "../universal/LanguageDrawer";
import UploadControls from "../TWG/UploadControls";
import { FaInfoCircle } from "react-icons/fa";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

const AddCategoryFields = ({ data, setLoading }) => {
  const { options } = GetRelatedVideosHooks();

  const {
    languages,
    activeTab,
    setActiveTab,
    inputs,
    handleInputChange,
    handleSave,
    categoryInputs,
    categoryErrors,
    layoutValues,
    initialLayoutValues,
    selectedOptions,
    //options,
    handleDropdownChange,
    setLayoutValues,
    handleCategoryLayoutChange,
    layoutInfo,
    setLayoutInfo,
    handleUploadInput,
    handleDateChange,
    addedLayout,
    setAddedLayout,
    isLayoutEdit,
    editLayoutIndex,
    editTargetLayout,
    layoutArray,
    handleDeleteLayoutFromArray,
    setIsLayoutEdit,
    setEditLayoutIndex,
    setEditTargetLayout,
    handleVideoLayoutChange,
    handleVideoDateChange,
    handleLayoutUpdate,
    handleAddLayoutToArray,
    setIsLanguageSave,
    languagemeta,
    updateStateWithTranslation,
    handleSaveLanguage,
    activeLanguageDetails,
    setActiveLanguageDetails,
  } = AddCategoriesHooks(data, options, setLoading);

  console.log("categoryInputs", categoryInputs);
  console.log("data", data);

  const dispatch = useDispatch();
  const [readLanguageData, setReadLanguageData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  const getAllLanguageResponse = (response) => {
    if (response?.type === 1) {
      const sortData = [...response?.data].sort((a, b) => {
        if (a.status !== undefined && b.status === undefined) return -1;
        if (a.status === undefined && b.status !== undefined) return 1;
        if (b.status !== undefined && a.status !== undefined) return b.status - a.status;
        if (a.status === undefined && b.status === undefined) return 0;
        if (a.status === undefined) return 1;
        if (b.status === undefined) return -1;

        return 0;
      });
      setReadLanguageData(sortData);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      readLanguageAction({
        callback: getAllLanguageResponse,
      })
    );
  }, []);

  return (
    <div className="flex flex-col md:flex-row h-full w-full flex-1 rounded-2xl overflow-hidden">
      <div className="flex min-h-full flex-1">
        <LanguageDrawer
          languageData={readLanguageData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          languagemeta={languagemeta}
          setIsLanguageSave={setIsLanguageSave}
          setActiveLanguageDetails={setActiveLanguageDetails}
        />
        <div className="flex h-full flex-1">
          <div className="flex flex-col h-full w-full bg-greyishV2 bg-opacity-50 md:border-l md:border-r border-greyish border-opacity-30 ">
            <div className="flex gap-4 justify-between items-center h-[64px] px-4 border-b border-[#28292a]">
              <p className="text-white font-semibold text-lg whitespace-nowrap">
                {activeTab === "en"
                  ? "Add Category"
                  : `Translate Category to ${activeLanguageDetails?.language_name}`}{" "}
              </p>
              <div className="flex items-center gap-5">
                {activeTab !== "en" && data?._id && (
                  <p
                    className="flex items-center gap-1 text-greyish text-[11px] font-medium cursor-pointer whitespace-nowrap"
                    onClick={updateStateWithTranslation}
                  >
                    <img src={reload} alt="" /> Auto fill data with google translation
                  </p>
                )}
                {activeTab === "en" ? (
                  <div className="w-full flex items-end justify-end gap-4">
                    <button
                      className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                      onClick={handleSave}
                    >
                      {data?._id ? "Update" : "Save"}
                    </button>
                  </div>
                ) : data?._id ? (
                  <div className="w-full flex items-end justify-end gap-4">
                    <button
                      className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                      onClick={handleSaveLanguage}
                    >
                      Save Language
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            {/* TAB CONTENT  */}

            {activeTab !== "en" && !data?._id ? (
              <div className="flex items-center justify-center text-white gap-1 py-28">
                Category translation in{" "}
                <span className="italic">{activeLanguageDetails?.language_name}</span> is available
                for saved categories
              </div>
            ) : (
              <div className=" px-5 py-2">
                <div className=" overflow-hidden">
                  <div className="grid md:grid-cols-2 grid-cols-1 grid-rows-1 gap-x-4 gap-y-4 mx-1 w-full">
                    <div className="md:col-span-2 col-span-1">
                      <label
                        htmlFor="categoryName"
                        className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                      >
                        Category Name *
                        <FaInfoCircle
                          data-tooltip-id="add_category"
                          data-tooltip-content="Enter the Category Name"
                        />
                      </label>
                      <input
                        name="category_name"
                        type="text"
                        className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                        value={categoryInputs?.category_name}
                        placeholder="Enter Category Name"
                        onChange={handleInputChange}
                      />
                      {categoryErrors?.category_name && (
                        <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2">
                          Category Name Is Required Field
                        </p>
                      )}
                    </div>
                    <div className="md:col-span-2 col-span-1">
                      <label
                        htmlFor="category_description"
                        className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                      >
                        Category Description *
                        <FaInfoCircle
                          data-tooltip-id="add_category"
                          data-tooltip-content="Enter the Category Description"
                        />
                      </label>
                      <textarea
                        rows={6}
                        className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md`}
                        name="category_description"
                        placeholder="Enter Category Description"
                        value={categoryInputs?.category_description}
                        onChange={handleInputChange}
                      />
                      {categoryErrors?.category_description && (
                        <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2">
                          Category Description Is Required Field
                        </p>
                      )}
                    </div>
                  </div>
                  {activeTab === "en" && (
                    <>
                      <div className="flex flex-col p-2 mt-2">
                        <label
                          htmlFor="relatedVideos"
                          className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                        >
                          Related Videos *
                          <FaInfoCircle
                            data-tooltip-id="add_category"
                            data-tooltip-content="Select Related Videos"
                          />
                        </label>
                        <DropdownWithTags
                          selectedOptions={selectedOptions}
                          options={options}
                          handleChange={handleDropdownChange}
                        />
                        {categoryErrors?.selectedOptions && (
                          <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2">
                            Related Videos Is Required Field
                          </p>
                        )}
                      </div>

                      <UploadControls
                        tooltipTitle="Upload Thumbnails"
                        className="my-2"
                        title="Thumbnails *"
                        subtitle="Upload Image"
                        handleFileUpload={handleUploadInput}
                        handleChangeUrl={handleInputChange}
                        mobileUploadUrl={categoryInputs?.mobileUploadUrl}
                        handleMobilePreview={() => {
                          setIsImage(true);
                          setShowModal(true);
                          setCurrentUrl(categoryInputs?.mobileUploadUrl);
                        }}
                        tabletUploadUrl={categoryInputs?.tabletUploadUrl}
                        handleTabletPreview={() => {
                          setIsImage(true);
                          setShowModal(true);
                          setCurrentUrl(categoryInputs?.tabletUploadUrl);
                        }}
                        webUploadUrl={categoryInputs?.webUploadUrl}
                        handleWebPreview={() => {
                          setIsImage(true);
                          setShowModal(true);
                          setCurrentUrl(categoryInputs?.webUploadUrl);
                        }}
                        isFieldRequired={categoryErrors?.video_urls}
                        requiredMessage="Thumbnail URL's Is Required Field"
                      />
                      <UploadControls
                        tooltipTitle="Upload Common Thumbnails"
                        className="my-2"
                        title="Common Thumbnails *"
                        subtitle="Upload Image"
                        handleFileUpload={handleUploadInput}
                        handleChangeUrl={handleInputChange}
                        mobileUploadUrl={categoryInputs?.commonMobileUploadUrl}
                        mobileUploadName="commonMobileUploadUrl"
                        handleMobilePreview={() => {
                          setIsImage(true);
                          setShowModal(true);
                          setCurrentUrl(categoryInputs?.commonMobileUploadUrl);
                        }}
                        tabletUploadUrl={categoryInputs?.commonTabletUploadUrl}
                        tabletUploadName="commonTabletUploadUrl"
                        handleTabletPreview={() => {
                          setIsImage(true);
                          setShowModal(true);
                          setCurrentUrl(categoryInputs?.commonTabletUploadUrl);
                        }}
                        webUploadUrl={categoryInputs?.commonWebUploadUrl}
                        webUploadName="commonWebUploadUrl"
                        handleWebPreview={() => {
                          setIsImage(true);
                          setShowModal(true);
                          setCurrentUrl(categoryInputs?.commonWebUploadUrl);
                        }}
                        isFieldRequired={categoryErrors?.video_urls}
                        requiredMessage="Thumbnail URL's Is Required Field"
                      />
                    </>
                  )}
                </div>
                <Tooltip id="add_category" className="border border-greyish border-opacity-20" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="md:w-[33%] bg-[#1b1b1b]">
        <CustomTabsV2
          tabHeight={"h-[64px]"}
          tabsTitleArray={["Preview", "Stats", "Layouts"]}
          tabsContentObject={{
            Preview: (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                No Data Found...
              </div>
            ),
            Stats: (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                {data?._id ? "No Data Found..." : "No stats available for category"}
              </div>
            ),
            Layouts: !data?._id ? (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                Save this category to assign the layout
              </div>
            ) : (
              <>
                <div className="mt-4 p-1 ml-2">
                  <label htmlFor="relatedVideos" className="text-[12px] text-greyish font-medium">
                    Layouts
                  </label>
                  <div className="bg-blackish py-4 px-3 mt-3 rounded-lg">
                    <LayoutDropdown
                      setMyState={setLayoutValues}
                      setLayoutInfo={setLayoutInfo}
                      setAddedLayout={setAddedLayout}
                      addedLayout={addedLayout}
                      newTarget={editTargetLayout}
                      isLayoutEdit={isLayoutEdit}
                      outerTargetLayout={layoutValues?.layout_name}
                      module="category"
                    />

                    {layoutInfo && (
                      <>
                        {/* <div className="flex gap-3">
                          <div className="w-full">
                            <label
                              htmlFor="title"
                              className="text-[12px] text-greyish font-medium leading-4 "
                            >
                              Layout Title
                            </label>
                            <input
                              type="text"
                              className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                              name="title"
                              placeholder="Enter title"
                              value={layoutValues?.title}
                              onChange={handleVideoLayoutChange}
                            />
                          </div>
                          <div className="w-full">
                            <label
                              htmlFor="subtitle"
                              className="text-[12px] text-greyish font-medium leading-4 "
                            >
                              Layout Subtitle
                            </label>
                            <input
                              type="text"
                              className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                              name="subtitle"
                              placeholder="Enter subtitle "
                              value={layoutValues?.subtitle}
                              onChange={handleVideoLayoutChange}
                            />
                          </div>
                        </div> */}
                        <div className="flex gap-3">
                          {/* <div className="w-full flex flex-col mt-2">
                            <label
                              htmlFor="expiry"
                              className="text-[12px] text-greyish font-medium leading-4"
                            >
                              Expiry
                            </label>
                            <DateTimePicker
                              name="expiry"
                              className={
                                "bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10"
                              }
                              value={layoutValues?.expiry}
                              onChange={handleVideoDateChange}
                            />
                          </div> */}
                          <div className="w-full">
                            <label
                              htmlFor="order"
                              className="text-[12px] text-greyish font-medium leading-4 "
                            >
                              Display Order
                            </label>
                            <input
                              type="text"
                              className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                              name="order"
                              placeholder="Enter order"
                              value={layoutValues?.order}
                              onChange={handleVideoLayoutChange}
                            />
                          </div>
                        </div>
                        <div className="flex justify-end mt-4">
                          <div className="flex gap-3">
                            {!isLayoutEdit && (
                              <button
                                className="text-greyish bg-blackishV2 font-medium text-[12px] rounded-xl py-2 px-6"
                                onClick={() => {
                                  setLayoutValues(initialLayoutValues);
                                  setLayoutInfo(false);
                                }}
                              >
                                Cancel
                              </button>
                            )}
                            {isLayoutEdit ? (
                              <button
                                className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                                onClick={handleLayoutUpdate}
                              >
                                Confirm
                              </button>
                            ) : (
                              <button
                                className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                                onClick={handleAddLayoutToArray}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {layoutArray?.length > 0 &&
                  layoutArray?.map((item, index) => (
                    <div className="w-full px-4 mt-4">
                      <div className="w-full p-2 px-4 bg-black bg-opacity-10 rounded-lg border border-opacity-25 border-white">
                        <div className="flex flex-col items-center justify-center w-full text-white">
                          <div className="flex justify-between w-full">
                            <p className="text-sm font-white font-medium text-white">
                              {item?.layout_name}
                            </p>
                            <div className="flex gap-2 justify-end items-center cursor-pointer">
                              <img
                                className="h-4"
                                src={edit}
                                alt=""
                                onClick={() => {
                                  setIsLayoutEdit(true);
                                  setEditLayoutIndex(index);
                                  setLayoutValues(item);
                                  setEditTargetLayout(item?.layout_name);
                                }}
                              />
                              <img
                                className="h-4"
                                src={close}
                                alt=""
                                onClick={() => {
                                  handleDeleteLayoutFromArray(item?._id);
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex justify-between w-full mt-5 bg-blackishV2 p-1 px-2">
                            <p className="text-[10px] font-white text-white">Order</p>
                            {/* <p className="text-[10px] font-white text-white">Title</p> */}
                            {/* <p className="text-[10px] font-white  text-white">Subtitle</p> */}
                            {/* <p className="text-[10px] font-white text-white">Expiry</p> */}
                          </div>
                          <div className="flex w-full mt-2 p-1 px-2">
                            <p className="flex w-full text-xs font-white font-bold text-white">
                              {item?.order}
                            </p>
                            {/* <p className="flex w-full  text-xs font-white font-bold text-white">
                              {item?.title}
                            </p>
                            <p className="flex w-full justify-center text-xs font-white font-bold text-white">
                              {item?.subtitle}
                            </p>
                            <p className="flex w-full justify-end text-end  text-xs font-white font-bold text-white">
                              {moment(item?.expiry).format("DD-MM-YYYY")}
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            ),
          }}
        />
      </div>
      <ReactModal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <div className="flex justify-center h-full w-full relative">
          <div
            className="absolute top-0 right-0 cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            <ImCross />
          </div>
          {isImage ? (
            <img className="h-full" src={currentUrl} />
          ) : (
            <video className="h-full" controls autoPlay>
              <source src={currentUrl} />
            </video>
          )}
        </div>
      </ReactModal>
    </div>
  );
};

export default AddCategoryFields;
