"use client";
import React, { useState } from "react";
import eye from "../../assets/icons/eye.svg";
import edit from "../../assets/icons/edit.svg";
import ToggleButton from "./ToggleButton";
import { Link } from "react-router-dom";
import redCross from "../../assets/icons/redCross.svg";
import greenTick from "../../assets/icons/greenTick.svg";
import close from "../../assets/icons/close.svg";
import Cross from "../../assets/icons/Cross.svg";
import { useNavigate } from "react-router-dom";
import ToggleStar from "./ToggleStar";
import RatingStars from "./RatingStars";
function CustomTable({
  activeTab,
  headers,
  data,
  width,
  heightDesktop,
  heightMobile,
  reverseTable,
  nthChildWidth,
  nthChildColor,
  nthChildStyle,
  handleToggleClick,
  handleEyeClick,
  handleGreenTick,
  handleRedCross,
  handleEditClick,
  openGivingHandler,
  testimonialTable,
  notificationTable,
  handleDeleteClick,
  layoutTable,
  showStar = false,
  searchFor = "Data",
  customTabs = null,
}) {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const filteredData = () => {
    if (search.length === 0) {
      return data;
    } else {
      const newData = data?.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        )
      );

      return newData;
    }
  };

  const updatedData = filteredData();

  return (
    <div className="overflow-auto custom-table">
      <div className="flex justify-between w-full">
        <div className="text-white p-2 px-3 w-1/4 border border-white border-opacity-40 rounded-md mt-2">
          <input
            type="text"
            placeholder={"Search " + searchFor}
            className="bg-transparent rounded-md w-full focus:outline-none"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {customTabs}
      </div>
      <div className="md:w-full w-[92vw]">
        <div className="TABLE flex flex-col mt-4 overflow-auto relative w-full remove-scrollbar">
          {/* Header */}
          <div
            className={` HEADER gap-x-4 z-10 ${nthChildWidth} flex items-center text-[12px] font-medium justify-between w-fit min-w-full sticky top-0  ${
              reverseTable
                ? "text-greyish bg-blackish py-4 px-4"
                : " text-greyish bg-blackishV2 pb-4"
            } `}
          >
            {headers.map((header, index) => {
              return (
                <div className={`${width}`} key={header.headerId}>
                  {header.headerName}
                </div>
              );
            })}
          </div>
          {/* Rows */}

          <div
            className={`ROWS  ${heightDesktop ? heightDesktop : "md:h-[42vh]"} ${
              heightMobile ? heightMobile : ""
            } `}
          >
            {/* new refined code  */}

            {updatedData ? (
              <>
                {updatedData?.map((row, index) => {
                  return (
                    <>
                      {layoutTable ? (
                        <div
                          key={index}
                          className={`ROW gap-x-4  ${nthChildWidth} ${nthChildColor} ${nthChildStyle}  flex items-center text-white text-sm font-medium py-4 justify-between w-fit min-w-full ${
                            reverseTable ? "px-4 bg-blackishV2 mb-2 rounded-lg" : ""
                          } `}
                        >
                          {headers.map((header, index) => {
                            switch (header.headerId) {
                              case "actions":
                                return (
                                  <div
                                    className={`cursor-pointer text-white  flex items-center text-[13px] ${width}`}
                                    key={header.headerId}
                                  >
                                    {testimonialTable === true ? (
                                      row.selected === true ? (
                                        <div className="flex gap-2 items-center">
                                          <img
                                            className="cursor-pointer"
                                            src={eye}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row);
                                            }}
                                          />
                                        </div>
                                      ) : activeTab === "Approved" ? (
                                        <div className="flex gap-2 items-center">
                                          <img
                                            className="cursor-pointer"
                                            src={eye}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row);
                                            }}
                                          />
                                          <img
                                            className="cursor-pointer"
                                            src={redCross}
                                            alt="eye"
                                            onClick={() => {
                                              handleRedCross(row);
                                            }}
                                          />
                                          {showStar && (
                                            <ToggleStar
                                              handleToggleClick={(isActive) => {
                                                handleToggleClick(row, isActive);
                                              }}
                                              status={row?.status || row?.active ? 1 : 0}
                                            />
                                          )}
                                        </div>
                                      ) : activeTab === "Rejected" ? (
                                        <div className="flex gap-2 items-center">
                                          <img
                                            className="cursor-pointer"
                                            src={eye}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row);
                                            }}
                                          />
                                          <img
                                            className="cursor-pointer"
                                            src={greenTick}
                                            alt="eye"
                                            onClick={() => {
                                              handleGreenTick(row);
                                            }}
                                          />
                                          {showStar && (
                                            <ToggleStar
                                              handleToggleClick={(isActive) => {
                                                handleToggleClick(row, isActive);
                                              }}
                                              status={row?.status || row?.active ? 1 : 0}
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <div className="flex gap-2 items-center">
                                          <img
                                            className="cursor-pointer"
                                            src={eye}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row);
                                            }}
                                          />
                                          <img
                                            className="cursor-pointer"
                                            src={greenTick}
                                            alt="eye"
                                            onClick={() => {
                                              handleGreenTick(row);
                                            }}
                                          />
                                          <img
                                            className="cursor-pointer"
                                            src={redCross}
                                            alt="eye"
                                            onClick={() => {
                                              handleRedCross(row);
                                            }}
                                          />
                                          {showStar && (
                                            <ToggleStar
                                              handleToggleClick={(isActive) => {
                                                handleToggleClick(row, isActive);
                                              }}
                                              status={row?.status || row?.active ? 1 : 0}
                                            />
                                          )}
                                        </div>
                                      )
                                    ) : notificationTable === true ? (
                                      <div className="flex gap-2 items-center">
                                        {handleEyeClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={eye}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row);
                                            }}
                                          />
                                        )}
                                        {handleEditClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={edit}
                                            alt="edit"
                                            onClick={() => {
                                              handleEditClick(row);
                                            }}
                                          />
                                        )}
                                        {handleDeleteClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={close}
                                            alt="delete"
                                            onClick={() => {
                                              handleDeleteClick(row);
                                            }}
                                          />
                                        )}
                                      </div>
                                    ) : layoutTable === true ? (
                                      <div className="flex gap-2 items-center">
                                        {handleEyeClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={eye}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row);
                                            }}
                                          />
                                        )}
                                        {handleEditClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={edit}
                                            alt="edit"
                                            onClick={() => {
                                              handleEditClick(row);
                                            }}
                                          />
                                        )}
                                        {row.static !== "1" && handleDeleteClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={close}
                                            alt="delete"
                                            onClick={() => {
                                              handleDeleteClick(row);
                                            }}
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <div className="flex gap-2 items-center">
                                        <ToggleButton
                                          handleToggleClick={(isActive) => {
                                            handleToggleClick(row, isActive);
                                          }}
                                          status={row?.status || row?.active ? 1 : 0}
                                        />
                                        {handleEyeClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={eye}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row);
                                            }}
                                          />
                                        )}
                                        {handleEditClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={edit}
                                            alt="edit"
                                            onClick={() => {
                                              handleEditClick(row);
                                            }}
                                          />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              case "status":
                                return (
                                  <div
                                    className={` ${
                                      row[header.headerId] === 1
                                        ? "bg-[#23E33E] text-[#23E33E]"
                                        : "bg-[#FF544D] text-[#FF544D] "
                                    }  cursor-pointer p-2 flex items-center justify-center bg-opacity-10   text-[13px] rounded-lg ${width}`}
                                    key={header.headerId}
                                  >
                                    {row[header.headerId] === 1 ? "Active" : "In-Active"}
                                  </div>
                                );
                              case "publish":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces`}
                                    key={header.headerId}
                                  >
                                    {row[header.headerId] === "true" ? "Yes" : "No"}
                                    {header.headerId}
                                  </div>
                                );
                              case "active":
                                return (
                                  <div
                                    className={` ${
                                      row[header.headerId] === 1
                                        ? "bg-[#23E33E] text-[#23E33E]"
                                        : "bg-[#FF544D] text-[#FF544D] "
                                    }  cursor-pointer p-2 flex items-center justify-center bg-opacity-10   text-[13px] rounded-lg ${width}`}
                                    key={header.headerId}
                                  >
                                    {row[header.headerId] === 1 ? "Active" : "In-Active"}
                                  </div>
                                );
                              case "userName":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces`}
                                    key={header.headerId}
                                    onClick={() => navigate("/users/099129")}
                                  >
                                    {header.image ? (
                                      <div className="flex items-center gap-4">
                                        <div className="h-8 w-8 rounded-full overflow-hidden">
                                          {row[header?.headerId]?.imgUrl && (
                                            <img
                                              className="h-full w-full object-contain"
                                              src={row[header?.headerId]?.imgUrl}
                                              alt=""
                                            />
                                          )}
                                        </div>
                                        {row[header.headerId].name}
                                      </div>
                                    ) : (
                                      <div>{row[header.headerId]}</div>
                                    )}
                                  </div>
                                );
                              case "language_name":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                    key={header.headerId}
                                    onClick={() => handleEyeClick(row)}
                                  >
                                    <div>{row[header.headerId]}</div>
                                  </div>
                                );
                              case "layout_name":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                    key={header.headerId}
                                    onClick={() => handleEyeClick(row)}
                                  >
                                    <div>{row[header.headerId]}</div>
                                  </div>
                                );
                              case "category_name":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                    key={header.headerId}
                                    onClick={() => handleEyeClick(row)}
                                  >
                                    <div>{row[header.headerId]}</div>
                                  </div>
                                );
                              case "firstName":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                    key={header.headerId}
                                    onClick={() => handleEyeClick(row)}
                                  >
                                    <div>{row[header.headerId]}</div>
                                  </div>
                                );
                              case "video_title":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                    key={header.headerId}
                                    onClick={() => handleEyeClick(row)}
                                  >
                                    <div>{row[header.headerId]}</div>
                                  </div>
                                );
                              case "curriculum_name":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                    key={header.headerId}
                                    onClick={() => handleEyeClick(row)}
                                  >
                                    <div>{row[header.headerId]}</div>
                                  </div>
                                );
                              case "twg_title":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                    key={header.headerId}
                                    onClick={() => handleEyeClick(row)}
                                  >
                                    <div>{row[header.headerId]}</div>
                                  </div>
                                );
                              case "userId":
                                return (
                                  <Link
                                    key={index}
                                    to={"/users/099129"}
                                    // to={`/users/${row[header.headerId].substring(
                                    //   1
                                    // )}`}
                                  >
                                    <div
                                      className={`${width} whitespace-break-spaces ${
                                        header.hyperLink ? "underline" : ""
                                      } `}
                                      key={header.headerId}
                                    >
                                      {row[header.headerId]}
                                    </div>
                                  </Link>
                                );
                              case "_id":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces ${
                                      header.hyperLink ? "underline" : ""
                                    } `}
                                    key={header.headerId}
                                  >
                                    {row[header.headerId]
                                      ? row[header.headerId]
                                      : "Language Not Assigned"}
                                  </div>
                                );
                              case "daysToExpiry":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces ${
                                      header.hyperLink ? "underline" : ""
                                    } `}
                                    key={header.headerId}
                                  >
                                    {Math.round(row[header.headerId])}
                                  </div>
                                );
                              case "payment_gateway_reff":
                                return (
                                  <div
                                    key={row[header.headerId]}
                                    onClick={() => openGivingHandler(row)}
                                    className="flex items-center gap-4 underline cursor-pointer"
                                  >
                                    <div className="h-8 w-8 rounded-full overflow-hidden">
                                      {row[header.headerId]?.imgUrl && (
                                        <img
                                          className="h-full w-full object-contain"
                                          src={row[header.headerId]?.imgUrl}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    {row[header.headerId]}
                                  </div>
                                );
                              case "notificationTitle":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces`}
                                    key={header.headerId}
                                    onClick={() => navigate("/users/099129")}
                                  >
                                    <div className="flex items-center gap-4">
                                      <div className="h-8 w-8 rounded-full overflow-hidden">
                                        <img
                                          className="h-full w-full object-contain"
                                          src={row[header.headerId].imgUrl}
                                          alt=""
                                        />
                                      </div>
                                      {row[header.headerId].title}
                                    </div>
                                  </div>
                                );
                              default:
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces`}
                                    key={header.headerId}
                                  >
                                    {row[header.headerId]}
                                  </div>
                                );
                            }
                          })}
                        </div>
                      ) : (
                        <div
                          key={index}
                          className={`ROW gap-x-4  ${nthChildWidth} ${nthChildColor} ${nthChildStyle}  flex items-center text-white text-sm font-medium py-4 justify-between w-fit min-w-full ${
                            reverseTable ? "px-4 bg-blackishV2 mb-2 rounded-lg" : ""
                          } `}
                        >
                          {headers.map((header, index) => {
                            switch (header.headerId) {
                              case "actions":
                                return (
                                  <div
                                    className={`cursor-pointer text-white  flex items-center text-[13px] ${width}`}
                                    key={header.headerId}
                                  >
                                    {testimonialTable === true ? (
                                      row.selected === true ? (
                                        <div className="flex gap-2 items-center">
                                          <img
                                            className="cursor-pointer"
                                            src={eye}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row);
                                            }}
                                          />
                                        </div>
                                      ) : activeTab === "Approved" ? (
                                        <div className="flex gap-2 items-center">
                                          <img
                                            className="cursor-pointer"
                                            src={eye}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row);
                                            }}
                                          />
                                          <img
                                            className="cursor-pointer"
                                            src={redCross}
                                            alt="eye"
                                            onClick={() => {
                                              handleRedCross(row);
                                            }}
                                          />
                                          {showStar && (
                                            <ToggleStar
                                              handleToggleClick={(isActive) => {
                                                handleToggleClick(row, isActive);
                                              }}
                                              status={row?.status || row?.active ? 1 : 0}
                                            />
                                          )}
                                        </div>
                                      ) : activeTab === "Rejected" ? (
                                        <div className="flex gap-2 items-center">
                                          <img
                                            className="cursor-pointer"
                                            src={eye}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row);
                                            }}
                                          />
                                          <img
                                            className="cursor-pointer"
                                            src={greenTick}
                                            alt="eye"
                                            onClick={() => {
                                              handleGreenTick(row);
                                            }}
                                          />
                                          {showStar && (
                                            <ToggleStar
                                              handleToggleClick={(isActive) => {
                                                handleToggleClick(row, isActive);
                                              }}
                                              status={row?.status || row?.active ? 1 : 0}
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <div className="flex gap-2 items-center">
                                          <img
                                            className="cursor-pointer"
                                            src={eye}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row);
                                            }}
                                          />
                                          <img
                                            className="cursor-pointer"
                                            src={greenTick}
                                            alt="eye"
                                            onClick={() => {
                                              handleGreenTick(row);
                                            }}
                                          />
                                          <img
                                            className="cursor-pointer"
                                            src={redCross}
                                            alt="eye"
                                            onClick={() => {
                                              handleRedCross(row);
                                            }}
                                          />
                                          {showStar && (
                                            <ToggleStar
                                              handleToggleClick={(isActive) => {
                                                handleToggleClick(row, isActive);
                                              }}
                                              status={row?.status || row?.active ? 1 : 0}
                                            />
                                          )}
                                        </div>
                                      )
                                    ) : notificationTable === true ? (
                                      <div className="flex gap-2 items-center">
                                        {handleEyeClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={eye}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row);
                                            }}
                                          />
                                        )}
                                        {handleEditClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={edit}
                                            alt="edit"
                                            onClick={() => {
                                              handleEditClick(row);
                                            }}
                                          />
                                        )}
                                        {handleDeleteClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={close}
                                            alt="delete"
                                            onClick={() => {
                                              handleDeleteClick(row);
                                            }}
                                          />
                                        )}
                                      </div>
                                    ) : layoutTable === true ? (
                                      <div className="flex gap-2 items-center">
                                        {handleEyeClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={eye}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row);
                                            }}
                                          />
                                        )}
                                        {handleEditClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={edit}
                                            alt="edit"
                                            onClick={() => {
                                              handleEditClick(row);
                                            }}
                                          />
                                        )}
                                        {row.static !== "1" && handleDeleteClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={close}
                                            alt="delete"
                                            onClick={() => {
                                              handleDeleteClick(row);
                                            }}
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <div className="flex gap-2 items-center">
                                        <ToggleButton
                                          handleToggleClick={(isActive) => {
                                            handleToggleClick(row, isActive);
                                          }}
                                          status={row?.status || row?.active ? 1 : 0}
                                        />
                                        {handleEyeClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={eye}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row);
                                            }}
                                          />
                                        )}
                                        {handleEditClick && (
                                          <img
                                            className="cursor-pointer"
                                            src={edit}
                                            alt="edit"
                                            onClick={() => {
                                              handleEditClick(row);
                                            }}
                                          />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              case "publish":
                                return (
                                  <div
                                    className={`${width} text-center whitespace-break-spaces`}
                                    key={header.headerId}
                                  >
                                    {row[header.headerId] === "true" ? "Yes" : "No"}
                                  </div>
                                );
                              case "status":
                                return (
                                  <div
                                    className={` ${
                                      row[header.headerId] === 1
                                        ? "bg-[#23E33E] text-[#23E33E]"
                                        : "bg-[#FF544D] text-[#FF544D] "
                                    }  cursor-pointer p-2 flex items-center justify-center bg-opacity-10   text-[13px] rounded-lg ${width}`}
                                    key={header.headerId}
                                  >
                                    {row[header.headerId] === 1 ? "Active" : "In-Active"}
                                  </div>
                                );
                              case "rating":
                                return <RatingStars rating={parseFloat(row[header.headerId])} />;
                              case "userDetails":
                                return (
                                  <div className="flex items-center gap-3">
                                    <div
                                      style={{
                                        backgroundImage: `url(${row?.user[0]?.profilePicUrl})`,
                                      }}
                                      className="h-10 w-10 bg-black rounded-full bg-cover bg-no-repeat"
                                    ></div>
                                    <p className="text-white font-bold">
                                      {row?.user[0]?.firstName}
                                    </p>
                                  </div>
                                );
                              case "active":
                                return (
                                  <div
                                    className={` ${
                                      row[header.headerId] === 1
                                        ? "bg-[#23E33E] text-[#23E33E]"
                                        : "bg-[#FF544D] text-[#FF544D] "
                                    }  cursor-pointer p-2 flex items-center justify-center bg-opacity-10   text-[13px] rounded-lg ${width}`}
                                    key={header.headerId}
                                  >
                                    {row[header.headerId] === 1 ? "Active" : "In-Active"}
                                  </div>
                                );
                              case "userName":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces`}
                                    key={header.headerId}
                                    onClick={() => navigate("/users/099129")}
                                  >
                                    {header.image ? (
                                      <div className="flex items-center gap-4">
                                        <div className="h-8 w-8 rounded-full overflow-hidden">
                                          {row[header?.headerId]?.imgUrl && (
                                            <img
                                              className="h-full w-full object-contain"
                                              src={row[header?.headerId]?.imgUrl}
                                              alt=""
                                            />
                                          )}
                                        </div>
                                        {row[header.headerId].name}
                                      </div>
                                    ) : (
                                      <div>{row[header.headerId]}</div>
                                    )}
                                  </div>
                                );
                              case "language_name":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                    key={header.headerId}
                                    onClick={() => handleEyeClick(row)}
                                  >
                                    <div>{row[header.headerId]}</div>
                                  </div>
                                );
                              case "layout_name":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                    key={header.headerId}
                                    onClick={() => handleEyeClick(row)}
                                  >
                                    <div>{row[header.headerId]}</div>
                                  </div>
                                );
                              case "category_name":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                    key={header.headerId}
                                    onClick={() => handleEyeClick(row)}
                                  >
                                    <div>{row[header.headerId]}</div>
                                  </div>
                                );
                              case "firstName":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                    key={header.headerId}
                                    onClick={() => handleEyeClick(row)}
                                  >
                                    <div>{row[header.headerId]}</div>
                                  </div>
                                );
                              case "video_title":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                    key={header.headerId}
                                    onClick={() => handleEyeClick(row)}
                                  >
                                    <div>{row[header.headerId]}</div>
                                  </div>
                                );
                              case "curriculum_name":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                    key={header.headerId}
                                    onClick={() => handleEyeClick(row)}
                                  >
                                    <div>{row[header.headerId]}</div>
                                  </div>
                                );
                              case "twg_title":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                    key={header.headerId}
                                    onClick={() => handleEyeClick(row)}
                                  >
                                    <div>{row[header.headerId]}</div>
                                  </div>
                                );
                              case "userId":
                                return (
                                  <Link
                                    key={index}
                                    to={"/users/099129"}
                                    // to={`/users/${row[header.headerId].substring(
                                    //   1
                                    // )}`}
                                  >
                                    <div
                                      className={`${width} whitespace-break-spaces ${
                                        header.hyperLink ? "underline" : ""
                                      } `}
                                      key={header.headerId}
                                    >
                                      {row[header.headerId]}
                                    </div>
                                  </Link>
                                );
                              case "_id":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces ${
                                      header.hyperLink ? "underline" : ""
                                    } `}
                                    key={header.headerId}
                                  >
                                    {row[header.headerId]
                                      ? row[header.headerId]
                                      : "Language Not Assigned"}
                                  </div>
                                );
                              case "daysToExpiry":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces ${
                                      header.hyperLink ? "underline" : ""
                                    } `}
                                    key={header.headerId}
                                  >
                                    {Math.round(row[header.headerId])}
                                  </div>
                                );
                              case "payment_gateway_reff":
                                return (
                                  <div
                                    key={row[header.headerId]}
                                    onClick={() => openGivingHandler(row)}
                                    className="flex items-center gap-4 underline cursor-pointer"
                                  >
                                    <div className="h-8 w-8 rounded-full overflow-hidden">
                                      {row[header.headerId]?.imgUrl && (
                                        <img
                                          className="h-full w-full object-contain"
                                          src={row[header.headerId]?.imgUrl}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    {row[header.headerId]}
                                  </div>
                                );
                              case "notificationTitle":
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces`}
                                    key={header.headerId}
                                    onClick={() => navigate("/users/099129")}
                                  >
                                    <div className="flex items-center gap-4">
                                      <div className="h-8 w-8 rounded-full overflow-hidden">
                                        <img
                                          className="h-full w-full object-contain"
                                          src={row[header.headerId].imgUrl}
                                          alt=""
                                        />
                                      </div>
                                      {row[header.headerId].title}
                                    </div>
                                  </div>
                                );
                              default:
                                return (
                                  <div
                                    className={`${width} whitespace-break-spaces`}
                                    key={header.headerId}
                                  >
                                    {row[header.headerId]?.length > 35
                                      ? row[header.headerId]?.substring(0, 35) + "..."
                                      : row[header.headerId]}
                                  </div>
                                );
                            }
                          })}
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            ) : (
              <div className="flex justify-center items-center h-full w-full">
                <p className="font-bold text-sm text-greyish ">Loading...</p>
              </div>
            )}

            {updatedData?.length === 0 && (
              <div className="flex justify-center items-center h-full w-full">
                <p className="font-bold text-sm text-greyish ">No Data Found</p>
              </div>
            )}
            {/* ends here  */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomTable;
