import logo from '../../../../assets/icons/logo.png'
import userImg from '../../../../assets/images/profilepic.png'
import Screenshot3 from '../../../../assets/images/Screenshot3.png'
import Screenshotprof1 from '../../../../assets/images/Screenshotprof1.png'
import Screenshotprof2 from '../../../../assets/images/Screenshotprof2.png'
import Screenshotprof3 from '../../../../assets/images/Screenshotprof3.png'
import Screenshotallmenu from '../../../../assets/images/Screenshotallmenu.png'
import InputsOfText from '../../../InputFields/InputsOfText'
import { AddLanguagesHooks } from '../../../../screens/Languages/hooks/LanguagesHooks'

const MyProfilePreview = (fieldValues) => {
    console.log(fieldValues, 'teti')
    const { setShowData, showData } = AddLanguagesHooks();

    return (
        <div className='flex flex-col h-full relative bg-zinc-900'>

            <div className='bg-zinc-800 absolute  bottom-0 left-0 right-0   rounded-t-xl  z-10 '>
                <img src={Screenshotallmenu} />
            </div>
            <div className='flex flex-col small-bar  overflow-auto px-2 pb-14'>

                <div className='flex flex-row gap-2 pt-8 px-3 items-center'>
                    <img className='w-10 ' src={logo} />
                    <div className='w-24 h-7 bg-slate-50 opacity-40 rounded-3xl'></div>
                    <img className='w-8 ' src={logo} />
                    <img className='w-8 ' src={userImg} />
                </div>

                <img src={Screenshotprof1} />

                <div className='flex flex-col justify-center items-center pt-5'>
                    <div className='flex flex-col gap-3'>
                        <InputsOfText
                            value={fieldValues?.fieldValues?.about_me_lbl}
                            inputStyle={'text-slate-200 w-40 font-bold text-[12px]'}
                        />

                        <p className='text-white text-[10px]'>The commissioning of the Twelve Apostles during the ministry of Jesus is described in the Synoptic Gospels. After his resurrection, Jesus sent eleven of them</p>
                    </div>

                    <div className='flex flex-col gap-3 pt-4'> <InputsOfText
                        value={fieldValues?.fieldValues?.recently_watched_videos_lbl}
                        inputStyle={'text-slate-200 w-40 font-bold text-[12px]'}
                    />
                        <img src={Screenshotprof3} /></div>

                    <div className='flex flex-col gap-3'><InputsOfText
                        value={fieldValues?.fieldValues?.testimonials_lbl}
                        inputStyle={'text-slate-200 w-40 font-bold text-[12px]'}
                    />
                        <img src={Screenshot3} /></div>


                    <div className='flex flex-col gap-3'><InputsOfText
                        value={fieldValues?.fieldValues?.curriculum_lbl}
                        inputStyle={'text-slate-200 w-40 font-bold text-[12px]'}
                    />
                        <img src={Screenshotprof3} /></div>


                    <div className='flex flex-col gap-3 pt-4'><InputsOfText
                        value={fieldValues?.fieldValues?.certificates_lbl}
                        inputStyle={'text-slate-200 w-40 font-bold text-[12px]'}
                    />
                        <img src={Screenshotprof2} /></div>


                </div>
            </div>
        </div>
    )
}

export default MyProfilePreview
