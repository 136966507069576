import React, { Component } from "react";

import Stories from "react-insta-stories";

function StoryPreview({ storyData, onClose }) {
  const imageUrls = storyData.map((data) => data);

  return (
    <Stories
      keyboardNavigation
      defaultInterval={3000}
      stories={imageUrls}
      onAllStoriesEnd={onClose}
      height={"500px"}
      width={"350px"}
      storyContainerStyles={{
        background: "black",
        height: "100%",
        width: "100%",
        borderRadius: 10
      }}
      storyStyles={{
        width: 350,
        height: 500,
      }}
      // storyInnerContainerStyles={{
      //   background: 'pink'
      // }}
    />
  );
}

export default StoryPreview;
