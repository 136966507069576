import Axios from "../../utils/axios";

//Get Country data
export const getGivingsList = (payload) => {
    const URL = "/giving/list";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const getUserProfile = (payload) => {
    const URL = "/user/profileInfo";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const getUserTransactions = (payload) => {
    const URL = "/giving/transaction";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};

export const postRefund = (payload) => {
    const URL = "/giving/updateTransaction";
    return Axios.post(URL, payload).then((res) => {
        return res.data;
    });
};
