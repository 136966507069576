import React from 'react'
import { LoginFormHooks } from './hooks/LoginHooks'
import TextInputField from '../../components/InputFields/TextInputField';

const EmailVerification = ({ setCurrentForm }) => {

    const {
        otpValue,
        handleValidateSubmit,
        handleValidateChange,

    } = LoginFormHooks(setCurrentForm);

    return (
        <form onSubmit={handleValidateSubmit} className='py-10 px-12 bg-black bg-opacity-50 rounded-3xl lg:w-[400px]'>
            <p className='mb-10 text-4xl font-bold text-white'>Validate</p>
            <div className='flex flex-col gap-6'>
                <TextInputField
                    htmlFor={"emailotp"}
                    name={"emailotp"}
                    labelText={"Email OTP"}
                    placeholder={"Enter Your Email OTP"}
                    handleInputChange={handleValidateChange}
                    value={otpValue.validate}
                    validateFunction={() => true}
                />
            </div>
            <div className='mt-5 flex flex-col gap-[12px]'>
                <button
                    className='p-3 rounded-xl text-[15px] font-semibold bg-yellow-400'
                    type='submit'
                >
                    Validate
                </button>
                <button
                    className='p-3 rounded-xl text-[15px] font-semibold border border-yellow-400 text-yellow-400'
                    onClick={()=>setCurrentForm("login")}
                >
                    Cancel
                </button>

            </div>
        </form>
    )
}

export default EmailVerification
