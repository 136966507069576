import reload from "../../assets/icons/reload.svg";
import CustomTabsV2 from "../../components/universal/CustomTabsV2";
import layout1 from "../../assets/images/layout1new.jpg";
import layout2 from "../../assets/images/layout2new.jpg";
import layout3 from "../../assets/images/layout3new.jpg";
import layout4 from "../../assets/images/layout4new.jpg";
import layout5 from "../../assets/images/layout5new.jpg";
import layout6 from "../../assets/images/layout6new.jpg";
import LanguageDrawer from "../../components/universal/LanguageDrawer";
import { AddLayoutHooks } from "./Hooks/AddLayoutHooks";
import ActiveToggleButton from "../../components/InputFields/ActiveToggleButton";
import { FaInfoCircle } from "react-icons/fa";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

const AddLayoutFields = ({ _id, setLoading }) => {
  const {
    activeTab,
    setActiveTab,
    layoutValues,
    handleChange,
    readLanguageData,
    setIsLanguageSave,
    handleNewAddLayout,
    languagemeta,
    handleSaveLanguage,
    updateStateWithTranslation,
    layoutStats,
    activeLanguageDetails,
    setActiveLanguageDetails,
  } = AddLayoutHooks({
    _id,
    setLoading,
  });

  return (
    <div className="flex flex-col md:flex-row h-full w-full flex-1 rounded-2xl overflow-hidden bg-blackishV2">
      <div className="flex h-full flex-1">
        <LanguageDrawer
          languageData={readLanguageData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          languagemeta={languagemeta}
          setIsLanguageSave={setIsLanguageSave}
          setActiveLanguageDetails={setActiveLanguageDetails}
        />
        <div className="flex h-full flex-1 ">
          <div className="flex flex-col h-full flex-1 bg-greyishV2 bg-opacity-50 md:border-l md:border-r border-greyish border-opacity-30 overflow-auto">
            <div className="flex gap-4 items-center justify-between h-[64px] px-4 border-b border-[#28292a]">
              <p className="text-white font-semibold text-lg">
                {activeTab === "en"
                  ? "Add Layout"
                  : `Translate Layout to ${activeLanguageDetails?.language_name}`}{" "}
              </p>
              <div className="flex items-center gap-5">
                {activeTab !== "en" && (
                  <p
                    className="flex items-center gap-1 text-greyish text-[11px] font-medium cursor-pointer whitespace-nowrap"
                    onClick={updateStateWithTranslation}
                  >
                    <img src={reload} alt="" /> Auto fill data with google translation
                  </p>
                )}
                {activeTab === "en" ? (
                  <button
                    className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                    onClick={handleNewAddLayout}
                  >
                    {_id ? "Update" : "Save"}
                  </button>
                ) : (
                  <div className="w-full flex items-end justify-end gap-4">
                    <button
                      className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                      onClick={handleSaveLanguage}
                    >
                      Save Language
                    </button>
                  </div>
                )}
              </div>
            </div>
            {activeTab !== "en" && !_id ? (
              <div className="flex items-center justify-center text-white gap-1 py-28">
                Layout translation in{" "}
                <span className="italic">{activeLanguageDetails?.language_name}</span> is available
                for saved layouts
              </div>
            ) : (
              <div className="p-4 flex flex-col justify-between gap-3 h-full">
                <div className="flex flex-col gap-3">
                  <div className="flex gap-3">
                    <div className="w-full">
                      <label
                        htmlFor="layout_name"
                        className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-1"
                      >
                        Layout Name
                        <FaInfoCircle
                          data-tooltip-id="add_layout"
                          data-tooltip-content="Enter the Layout Name"
                        />
                      </label>

                      <input
                        type="text"
                        className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                        name="layout_name"
                        placeholder="Enter Layout Name"
                        value={layoutValues?.layout_name}
                        onChange={handleChange}
                      />
                    </div>
                    {/* <div className="w-full flex flex-col mt-2">
                <label htmlFor="expiry" className="text-[12px] text-greyish font-medium leading-4">
                  Expiry
                </label>
                <DateTimePicker
                  name="expiry"
                  className={
                    "bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10"
                  }
                  value={outerLayoutValues?.expiry}
                  onChange={handleDateChange}
                />
              </div> */}
                  </div>
                  {activeTab === "en" && (
                    <>
                      <div className="flex gap-3">
                        <div className="w-full">
                          <label
                            htmlFor="order"
                            className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-1"
                          >
                            Display Order
                            <FaInfoCircle
                              data-tooltip-id="add_layout"
                              data-tooltip-content="Enter the Display Order"
                            />
                          </label>
                          <input
                            type="number"
                            className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10`}
                            name="order"
                            placeholder="Enter Display Order"
                            value={layoutValues?.order}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor="mobile_layout"
                            className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-1"
                          >
                            Mobile Layout
                            <FaInfoCircle
                              data-tooltip-id="add_layout"
                              data-tooltip-content="Select the Mobile Layout"
                            />
                          </label>
                          <select
                            className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                            name="mobile_layout"
                            value={layoutValues?.mobile_layout}
                            onChange={handleChange}
                          >
                            <option value="1">first layout</option>
                            <option value="2">second layout</option>
                            <option value="3">third layout</option>
                            <option value="4">fourth layout</option>
                            <option value="5">fifth layout</option>
                            <option value="6">sixth layout</option>
                          </select>
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <div className="w-full flex gap-5 items-center">
                          <label
                            htmlFor="layout_name"
                            className="text-[12px] text-greyish font-medium flex items-center gap-1"
                          >
                            Is Category
                            <FaInfoCircle
                              data-tooltip-id="add_layout"
                              data-tooltip-content="Check if the layout is for category"
                            />
                          </label>
                          <ActiveToggleButton
                            currentState={layoutValues?.category === "1" ? true : false}
                            setState={(value) => {
                              handleChange({
                                target: { name: "category", value: value ? "1" : "0" },
                              });
                            }}
                            color={"#EFBC51"}
                            className="drop-shadow-standard mt-2"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}

            <Tooltip id="add_layout" className="border border-greyish border-opacity-20" />
          </div>
        </div>
      </div>
      <div className="w-[33%] bg-[#1b1b1b]">
        <CustomTabsV2
          tabHeight={"h-[64px]"}
          tabsTitleArray={["Preview", "Stats"]}
          tabsContentObject={{
            Preview: (
              <img
                className="md:h-[74vh] h-[32vh] w-full object-contain"
                src={
                  layoutValues?.mobile_layout === "1"
                    ? layout1
                    : layoutValues?.mobile_layout === "2"
                    ? layout2
                    : layoutValues?.mobile_layout === "3"
                    ? layout3
                    : layoutValues?.mobile_layout === "4"
                    ? layout4
                    : layoutValues?.mobile_layout === "5"
                    ? layout5
                    : layoutValues?.mobile_layout === "6" && layout6
                }
                alt="preview"
              />
            ),
            Stats: (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                {layoutStats ? (
                  <div className="w-full px-10">
                    {layoutStats?.map((d) => {
                      if (d?.dash === "Active Layouts count") {
                        return (
                          <p className="text-start capitalize">
                            Active Layouts :{" "}
                            <span className="text-yellowish">
                              {d?.data?.[0]?.activeLayouts || 0}
                            </span>
                          </p>
                        );
                      } else if (d?.dash === "In Active Layouts count") {
                        return (
                          <p className="text-start capitalize">
                            In Active Layouts :{" "}
                            <span className="text-yellowish">
                              {d?.data?.[0]?.activeLayouts || 0}
                            </span>
                          </p>
                        );
                      } else {
                        return (
                          <p className="text-start capitalize">
                            {d?.dash} : <span className="text-yellowish">{d?.data?.length}</span>
                          </p>
                        );
                      }
                    })}
                  </div>
                ) : (
                  "Stats are not available for this language"
                )}
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default AddLayoutFields;
