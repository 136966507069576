import HorizontalTile from "../../components/universal/HorizontalTile";
import avatar from "../../assets/icons/squareCard/story1.png";
import avatar2 from "../../assets/icons/StoryCircles/story1.png";
import { AddCategoryFields } from "./AddCategory";
import { CategoriesHooks } from "./hooks/CategoriesHooks";

export const tabsTitleArray = ["Users", "Testimonies", "Givings"];
export const tabsContentObject = {
  Users: (
    <>
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
    </>
  ),
  Testimonies: (
    <>
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
    </>
  ),
};
export const categoryHeaders = [
  {
    headerName: "Category Name",
    headerId: "categoryName",
  },
  {
    headerName: "Description",
    headerId: "description",
  },
  {
    headerName: "Views",
    headerId: "views",
  },
];

export const categoryData = [
  {
    categoryName: "Healing",
    description: "We become what we think ab...",
    views: "128",
  },
  {
    categoryName: "Rededicating",
    description: "We become what we think ab...",
    views: "222",
  },
  {
    categoryName: "Miraculous",
    description: "We become what we think ab...",
    views: "133",
  },
  {
    categoryName: "Finacial Breakthrough",
    description: "We become what we think ab...",
    views: "128",
  },
  {
    categoryName: "Prayers",
    description: "We become what we think ab...",
    views: "332",
  },
  {
    categoryName: "Believe in God",
    description: "We become what we think ab...",
    views: "221",
  },
  {
    categoryName: "Prayers",
    description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est, tenetur.",
    views: "189",
  },
];
export const allCategoriesHeaders = [
  {
    headerName: "Title",
    headerId: "category_name",
  },
  {
    headerName: "Description",
    headerId: "category_description",
  },
  {
    headerName: "Last Modified Date",
    headerId: "lastUpdatedDate",
    date: true,
  },
  {
    headerName: "Actions",
    headerId: "actions",
  },
];
export const allCategoriesData = [
  {
    title: "Book of John",
    description: "We become what we think ab...",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Parabies",
    description: "We become what we think ab...",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Jesus Speaks",
    description: "We become what we think ab...",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  {
    title: "Terminator",
    description: "We become what we think ab...",
    date: "12/03/2023",
    categoryCode: "TWG-0012",
  },
  // {
  //   title: "Book of John",
  //   description:
  //     "We become what we think ab...",
  //   date: "12/03/2023",
  //   categoryCode: "TWG-0012",
  // },
  // {
  //   title: "Parabies",
  //   description:
  //     "We become what we think ab...",
  //   date: "12/03/2023",
  //   categoryCode: "TWG-0012",
  // },
  // {
  //   title: "Jesus Speaks",
  //   description:
  //     "We become what we think ab...",
  //   date: "12/03/2023",
  //   categoryCode: "TWG-0012",
  // },
  // {
  //   title: "Terminator",
  //   description:
  //     "We become what we think ab...",
  //   date: "12/03/2023",
  //   categoryCode: "TWG-0012",
  // },
  // {
  //   title: "Book of John",
  //   description:
  //     "We become what we think ab...",
  //   date: "12/03/2023",
  //   categoryCode: "TWG-0012",
  // },
  // {
  //   title: "Parabies",
  //   description:
  //     "We become what we think ab...",
  //   date: "12/03/2023",
  //   categoryCode: "TWG-0012",
  // },
  // {
  //   title: "Jesus Speaks",
  //   description:
  //     "We become what we think ab...",
  //   date: "12/03/2023",
  //   categoryCode: "TWG-0012",
  // },
  // {
  //   title: "Terminator",
  //   description:
  //     "We become what we think ab...",
  //   date: "12/03/2023",
  //   categoryCode: "TWG-0012",
  // },
  // {
  //   title: "Book of John",
  //   description:
  //     "We become what we think ab...",
  //   date: "12/03/2023",
  //   categoryCode: "TWG-0012",
  // },
  // {
  //   title: "Parabies",
  //   description:
  //     "We become what we think ab...",
  //   date: "12/03/2023",
  //   categoryCode: "TWG-0012",
  // },
  // {
  //   title: "Jesus Speaks",
  //   description:
  //     "We become what we think ab...",
  //   date: "12/03/2023",
  //   categoryCode: "TWG-0012",
  // },
  // {
  //   title: "Terminator",
  //   description:
  //     "We become what we think ab...",
  //   date: "12/03/2023",
  //   categoryCode: "TWG-0012",
  // },
];

// add category const
export const addCategoryLangList = ["English", "Hindi", "Tamil", "Telegu"];

export const langArray = [
  { value: "tamil", label: "Tamil" },
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
];
export const LoginInFields = [
  {
    label: "Login",
    placeholder: "Login Id",
  },
  {
    label: "Email or Phone",
    placeholder: "Email or Phone",
  },
  {
    label: "Password",
    placeholder: "Password",
  },
];
export const SignUpFields = [
  {
    label: "Sign Up",
    placeholder: "Sign Up",
  },
  {
    label: "Email or Phone",
    placeholder: "Email or Phone",
  },
];

// forms add categories
export const initialStateOfForms = [
  {
    English: {
      categoryName: "",
      categoryCode: "",
    },
  },
  {
    Hindi: {
      categoryName: "",
      categoryCode: "",
    },
  },
];
