// MyStatefulEditor.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import RichTextEditor from 'react-rte';
import { color } from "d3";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const MyStatefulEditor = ({
  labelText,
  labelColor = "white",
  errorMessage = "",
  placeholder = "",
  isFieldRequired = false,
  htmlFor,
  name,
  value,
  handleInputChange,
  isSubmitted = false,
  // validationFunctionName,
  margin,
  bgColor = "",
}) => {
  //   const [innerValue, setInnerValue] = useState(RichTextEditor.createValueFromString(value, "html"));
  const [innerValue, setInnerValue] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(value || "")?.contentBlocks)
    )
  );
  //   const [innerValue, setInnerValue] = useState(EditorState.createEmpty());

  const [ifInvalid, setIfInvalid] = useState(false);

  // useEffect(() => {
  //     if (innerValue?.length > 0 && validationFunctionName(innerValue) === true) {
  //         setIfInvalid(false);
  //     }
  // }, [validationFunctionName, innerValue]);

  const handleChange = (newValue) => {
    setInnerValue(newValue); // Update the value state
    if (handleInputChange) {
      // Pass the correct parameters to the parent's handleInputChange function
      handleInputChange({
        target: {
          name: name,
          value: draftToHtml(convertToRaw(innerValue.getCurrentContent())), // Assuming you want to pass the content as HTML
        },
      });
    }
  };

  //   const toolbarConfig = {
  //     // Optionally specify the groups to display (displayed in the order listed).
  //     display: [
  //       "INLINE_STYLE_BUTTONS",
  //       "BLOCK_TYPE_BUTTONS",
  //       "LINK_BUTTONS",
  //       "BLOCK_TYPE_DROPDOWN",
  //       "HISTORY_BUTTONS",
  //     ],
  //     INLINE_STYLE_BUTTONS: [
  //       { label: "Bold", style: "BOLD", className: "custom-css-class" },
  //       { label: "Italic", style: "ITALIC" },
  //       { label: "Underline", style: "UNDERLINE" },
  //     ],
  //     BLOCK_TYPE_DROPDOWN: [
  //       { label: "Normal", style: "unstyled" },
  //       { label: "Heading Large", style: "header-one" },
  //       { label: "Heading Medium", style: "header-two" },
  //       { label: "Heading Small", style: "header-three" },
  //     ],
  //     BLOCK_TYPE_BUTTONS: [
  //       { label: "UL", style: "unordered-list-item" },
  //       { label: "OL", style: "ordered-list-item" },
  //     ],
  //   };

  return (
    <div className="relative w-full ">
      <label
        htmlFor={htmlFor}
        style={{
          color: `${
            isSubmitted &&
            // !validationFunctionName(innerValue) &&
            isFieldRequired
              ? "border-red-500"
              : labelColor
          }`,
        }}
        className="text-[12px] text-gray-600 pb-4 font-medium leading-4 "
      >
        {labelText}
        {isFieldRequired ? <span className="text-red-500">*</span> : ""}
      </label>
      {/* <RichTextEditor
            className={bgColor === `black` ? `!bg-black` : `!bg-white`}
            id={name}
            name={name}
            value={innerValue}
            onChange={handleChange}
            placeholder={placeholder}
            style={{ marginBottom: `${margin}` }}
            toolbarConfig={toolbarConfig}
        /> */}
      <Editor
        placeholder={placeholder}
        editorState={innerValue}
        toolbarClassName="toolbarClassName text-black"
        wrapperClassName="wrapperClassName border mt-3 p-3"
        editorClassName="editorClassName"
        onEditorStateChange={handleChange}
      />

      {isSubmitted &&
        //  !validationFunctionName(innerValue) &&
        isFieldRequired && (
          <p className="text-red-500  relative text-[13px] left-0 top-[-10px]">{errorMessage}</p>
        )}
      {ifInvalid && (
        <p className="text-red-500  relative text-[13px] left-0 top-[-10px] ">{errorMessage}</p>
      )}
    </div>
  );
};

MyStatefulEditor.propTypes = {
  labelText: PropTypes.string,
  labelColor: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  isFieldRequired: PropTypes.bool,
  htmlFor: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool,
  // validationFunctionName: PropTypes.string,
  margin: PropTypes.string,
};

MyStatefulEditor.defaultProps = {
  isSubmitted: false,
  margin: "10px", // You can set a default value for margin if not provided
};

export default MyStatefulEditor;
