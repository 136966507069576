import logo from "../../../../assets/icons/logo.png";
import userImg from "../../../../assets/images/profilepic.png";
import Screenshotallmenu from "../../../../assets/images/Screenshotallmenu.png";
import Screenshotgiv from "../../../../assets/images/Screenshotgiv.png";
import InputsOfText from "../../../InputFields/InputsOfText";
import { AddLanguagesHooks } from "../../../../screens/Languages/hooks/LanguagesHooks";
import SignInButton from "../../../Buttons/SignInButton";

const GivingsPreview = (fieldValues) => {
  console.log(fieldValues, "teti112");
  const { setShowData, showData } = AddLanguagesHooks();

  return (
    <div className="flex flex-col h-full relative bg-zinc-900 ">
      <div className="bg-zinc-800 absolute  bottom-0 left-0 right-0   rounded-t-xl  z-10 ">
        <img src={Screenshotallmenu} />
      </div>

      <div className="flex flex-col small-bar  overflow-auto pb-14">
        <div className="flex flex-row gap-2 pt-8 px-3 items-center">
          <img className="w-10 " src={logo} />
          <div className="w-24 h-7 bg-slate-50 opacity-40 rounded-3xl"></div>
          <img className="w-8 " src={logo} />
          <img className="w-8 " src={userImg} />
        </div>

        <div className="flex flex-col">
          <div className="flex flex-col justify-between px-5 pt-10 gap-4">
            <InputsOfText
              value={fieldValues?.fieldValues?.give_lbl}
              inputStyle={"text-slate-200 w-16 font-bold text-[14px]"}
            />

            <img src={Screenshotgiv} />

            <p className="text-white text-[10px]">{fieldValues?.fieldValues?.give_desc_lbl}</p>

            <SignInButton Label={fieldValues?.fieldValues?.give_btn} style={"bg-yellow-300 "} />

            <SignInButton
              Label={fieldValues?.fieldValues?.give_now_btn}
              style={"bg-yellow-300 py-10 "}
            />

            <SignInButton
              Label={fieldValues?.fieldValues?.setup_recurring_btn}
              style={"bg-yellow-300 py-10"}
            />

            <SignInButton
              Label={fieldValues?.fieldValues?.your_past_giving_btn}
              style={"bg-yellow-300 py-10 "}
            />

            <div className="flex justify-center">
              <InputsOfText
                value={fieldValues?.fieldValues?.need_help_btn}
                inputStyle={"text-slate-200 w-24 font-bold text-[14px]"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GivingsPreview;
