import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AboutTWGInitialState,
  CurriculumInitialState,
  FavouritesInitialState,
  GivingsInitialState,
  HelpAndSupportAccordianInitialState,
  HelpAndSupportInitialState,
  LoginInFields,
  LoginInitialState,
  MenuInitialState,
  MyDownloadInitialState,
  MyGivingsInitialState,
  MyProfileInitialState,
  achievementInitialState,
  forgotPasswordInitialState,
  homeInitialState,
  johnInitialState,
  promisesInitialState,
  signUpInitialField,
  videoLikedInitialState,
} from "../Constants";
import langPreview1 from "../../../assets/images/langPreview1.png";
import {
  readLanguageAction,
  translateLanguageAction,
  ReadLanguageSignUpAction,
  deleteLanguageSignUpAction,
  readLanguageAboutTWGAction,
  readLanguageCurriculumAction,
  readLanguageFavouritesAction,
  readLanguageForgotPasswordAction,
  readLanguageGivingsAction,
  readLanguageHelpAndSupportAccordianAction,
  readLanguageHelpAndSupportAction,
  readLanguageHomeDataAction,
  readLanguageMenuAction,
  readLanguageMyDownloadsAction,
  readLanguageMyGivingsAction,
  readLanguageMyProfileAction,
  readLanguageTestimoniesAction,
  saveLanguageAboutTWGAction,
  saveLanguageCurriculumAction,
  saveLanguageFavouritesAction,
  saveLanguageForgotPasswordAction,
  saveLanguageGivingsAction,
  saveLanguageHelpAndSupportAccordianAction,
  saveLanguageHelpAndSupportAction,
  saveLanguageHomeDataAction,
  saveLanguageLoginAction,
  saveLanguageMenuAction,
  saveLanguageMyDownloadsAction,
  saveLanguageMyGivingsAction,
  saveLanguageMyProfileAction,
  saveLanguageSignUpAction,
  saveLanguageTestimoniesAction,
  updateLanguageAboutTWGAction,
  updateLanguageCurriculumAction,
  updateLanguageFavouritesAction,
  updateLanguageForgotPasswordAction,
  updateLanguageGivingsAction,
  updateLanguageHelpAndSupportAccordianAction,
  updateLanguageHelpAndSupportAction,
  updateLanguageHomeDataAction,
  updateLanguageMenuAction,
  updateLanguageMyDownloadsAction,
  updateLanguageMyGivingsAction,
  updateLanguageMyProfileAction,
  updateLanguageSignUpAction,
  updateLanguageTestimoniesAction,
  saveLanguageAction,
  activeOrInactiveLanguageAction,
  updateLanguageLoginAction,
  updateLanguagePromisesDataAction,
  saveLanguagePromisesDataAction,
  languageDashboardAction,
  deleteLanguageHelpAndSupportAccordianAction,
} from "../../../store/Languages/LanguageAction";
import Toastiy from "../../../components/universal/Toastiy";
import { tr } from "translate-google/languages";

const { languages } = require("google-cloud-translation-languages");

const LanguagesHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

export default LanguagesHooks;

export const AllLanguagesHooks = () => {
  const [value, setValue] = useState("");
  const [allLanguageData, setAllLanguageData] = useState([]);
  const [isUpdated, setIsUpdated] = useState(true);

  const dispatch = useDispatch();
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  const handleActiveInActiveToggle = (id, status) => {
    dispatch(
      activeOrInactiveLanguageAction({
        apiPayloadRequest: { _id: id, status: status },
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("Language Status Successfully Changed", res?.type);
            setIsUpdated(true);
          } else {
            Toastiy("Language Status Successfully Changed", res?.type);
          }
        },
      })
    );
  };

  useEffect(() => {
    if (isUpdated === true) {
      dispatch(
        readLanguageAction({
          callback: getAllLanguageResponse,
        })
      );
      setIsUpdated(false);
    }
  }, [isUpdated]);

  const getAllLanguageResponse = (response) => {
    if (response?.type === 1) {
      setAllLanguageData(response?.data);
    }
  };

  return {
    value,
    onChangeSearchBar,
    allLanguageData,
    handleActiveInActiveToggle,
  };
};

export const AddLanguagesHooks = (data) => {
  const [showAccordionModal, setShowAccordionModal] = useState(false);
  const [isAccordionEdit, setIsAccordionEdit] = useState(false);
  const [currentLanguageData, setCurrentLanguageData] = useState([]);
  const [targetLanguage, setTargetLanguage] = useState("English - en");
  const [value, setValue] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  const LoginInitialState = {
    loginId_lbl: "Log In",
    email_phone_lbl: "Email or Phone",
    password_lbl: "Password",
    forgot_Password_lbl: "Forgot Password",
    continue_btn: "Continue",
    continue_with_google_btn: "Continue With Google",
    continue_with_facebook_btn: "Continue With Facebook",
    continue_with_apple_btn: "Continue With Apple",
  };

  const signUpInitialField = {
    signup_lbl: "Sign Up",
    phone_lbl: "Phone",
    emailid_lbl: "Email ID",
    password_lbl: "Password",
    repeatePassword_lbl: "Repeat Password",
    termsandcond_lbl: "I agree to the Terms & Conditions and the Privacy Policy",
    createAccount_btn: "Create Account",
  };

  const forgotPasswordInitialState = {
    forgotpassword_lbl: "Email id",
    emailorphone_lbl: "Email or Phone",
    resetPassword_btn: "Reset Password",
    cancel_btn: "Cancel",
  };

  const homeInitialState = {
    welcome_lbl: "Welcome",
    todaywithgod_lbl: "Today With God",
    pick_where_you_are_left_lbl: "Pickup where you Left!",
    top10_lbl: "Top 10",
    seeall_btn: "see All",
    who_is_jesus_lbl: "Who is jesus?",
    parables_lbl: "Parables",
    jesus_speaks_lbl: "Jesus Speaks?",
    promises_lbl: "Promises",
    testimony_of_the_day_lbl: "Testimony of the day",
    more_videos_lbl: "more videos",
    support_us_lbl: "Support Us",
    support_btn: "support",
    need_prayer_lbl: "Need Prayer?",
  };

  const accordionInitialState = {
    panel_title: "",
    panel_description: "",
  };

  const [currAccordion, setCurrAccordion] = useState(accordionInitialState);
  const [activeTab, setActiveTab] = useState("Log In");
  const [activeTabData, setActiveTabData] = useState(LoginInFields);
  const [activeTabsList, setActiveTabsList] = useState({});
  const [langPreview, setLangPreview] = useState(langPreview1);
  const [loginFieldValues, setloginFieldValues] = useState(LoginInitialState);

  const [signUpfieldValues, setSignUpfieldValues] = useState(signUpInitialField);

  const [forgotPasswordValues, setForgotPasswordValues] = useState(forgotPasswordInitialState);

  const [homePageValues, setHomePageValues] = useState(homeInitialState);

  const [promisesValues, setPromisesValues] = useState(promisesInitialState);

  const [testimonies, setTestimonies] = useState(johnInitialState);

  const [achievementValues, setAchievementValues] = useState(achievementInitialState);

  const [videoLikedValues, setVideoLikedValues] = useState(videoLikedInitialState);

  const [curriculumValues, setCurriculumValues] = useState(CurriculumInitialState);

  const [favouriteValues, setFavouriteValues] = useState(FavouritesInitialState);

  const [givingsValues, setGivingsValues] = useState(GivingsInitialState);

  const [menuValues, setMenuValues] = useState(MenuInitialState);

  const [myProfileValues, setMyProfileValues] = useState(MyProfileInitialState);

  const [myDownloadsValues, setMyDownloadsValues] = useState(MyDownloadInitialState);

  const [myGivingsValues, setMyGivingsValues] = useState(MyGivingsInitialState);

  const [helpAndSupportValues, setHelpAndSupportValues] = useState(HelpAndSupportInitialState);

  const [aboutTWGValues, setAboutTWGValues] = useState(AboutTWGInitialState);

  const [showData, setShowData] = useState(false);

  const [showAdd, setShowAdd] = useState(false);

  const [languageStats, setLanguageStats] = useState(null);

  // Remove This an use "arrayOfValues"
  const [currentState, setCurrentState] = useState({
    "Log In": {
      state: loginFieldValues,
      setState: setloginFieldValues,
    },
    "Sign Up": {
      state: signUpfieldValues,
      setState: setSignUpfieldValues,
    },
    "Forgot Password": {
      state: forgotPasswordValues,
      setState: setForgotPasswordValues,
    },
    "Home page": {
      state: homePageValues,
      setState: setHomePageValues,
    },
    Promises: {
      state: promisesValues,
      setState: setPromisesValues,
    },
    Curriculum: {
      state: curriculumValues,
      setState: setCurriculumValues,
    },
    Favourites: {
      state: favouriteValues,
      setState: setFavouriteValues,
    },
    Givings: {
      state: givingsValues,
      setState: setGivingsValues,
    },
    Menu: {
      state: menuValues,
      setState: setMenuValues,
    },
    "My Profile": {
      state: myProfileValues,
      setState: setMyProfileValues,
    },
    "My Downloads": {
      state: myDownloadsValues,
      setState: setMyDownloadsValues,
    },
    Testimonies: {
      state: testimonies,
      setState: setTestimonies,
    },
    "My Givings": {
      state: myGivingsValues,
      setState: setMyGivingsValues,
    },
    "Help and Support": {
      state: helpAndSupportValues,
      setState: setHelpAndSupportValues,
    },
    "About TWG": {
      state: aboutTWGValues,
      setState: setAboutTWGValues,
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data?.targetLanguage) {
      setTargetLanguage(data?.targetLanguage);
    }
  }, []);

  useEffect(() => {
    const languageCode = targetLanguage.split(" - ");
    if (
      languages?.filter(
        (item) => item.name === languageCode[0] && item.language === languageCode[1]
      )?.length !== 0
    ) {
      readAllLanguages(languageCode[1]);
      dispatch(
        languageDashboardAction({
          apiPayloadRequest: { language_code: languageCode[1] },
          callback: (res) => {
            if (res?.type === 1) {
              setLanguageStats(res?.data);
            } else {
              setLanguageStats(null);
            }
          },
        })
      );
    }
  }, [targetLanguage]);

  useEffect(() => {
    if (isUpdated === true) {
      const languageCode = targetLanguage.split(" - ");
      if (
        languages?.filter(
          (item) => item.name === languageCode[0] && item.language === languageCode[1]
        )?.length !== 0
      ) {
        readAllLanguages(languageCode[1]);
        setIsUpdated(false);
      }
    }
  }, [isUpdated]);

  const handleAccordionInput = (event) => {
    const { value, name } = event.target;
    setCurrAccordion((prev) => ({ ...prev, [name]: value }));
  };

  const readAllLanguages = (code) => {
    dispatch(
      readLanguageAction({
        apiPayloadRequest: { language_code: code },
        callback: (res) => {
          if (res?.type === 1)
            if (res?.data?.length) {
              setCurrentLanguageData(res?.data);
            } else {
              setShowAdd(true);
              setCurrentLanguageData([]);
              setloginFieldValues(LoginInitialState);
              setSignUpfieldValues(signUpInitialField);
              setForgotPasswordValues(forgotPasswordInitialState);
              setHomePageValues(homeInitialState);
              setPromisesValues(promisesInitialState);
              setTestimonies(johnInitialState);
              // setAchievementValues(achievementInitialState);
              // setVideoLikedValues(videoLikedInitialState);
              setCurriculumValues(CurriculumInitialState);
              setFavouriteValues(FavouritesInitialState);
              setGivingsValues(GivingsInitialState);
              setMenuValues(MenuInitialState);
              setMyProfileValues(MyProfileInitialState);
              setMyDownloadsValues(MyDownloadInitialState);
              setMyGivingsValues(MyGivingsInitialState);
              setHelpAndSupportValues(HelpAndSupportInitialState);
              setAboutTWGValues(AboutTWGInitialState);
            }
        },
      })
    );
  };

  useEffect(() => {
    if (currentLanguageData?.length !== 0) {
      if (currentLanguageData[0]?.login_meta) {
        setloginFieldValues(currentLanguageData[0]?.login_meta);
      } else {
        setloginFieldValues(LoginInitialState);
      }
      if (currentLanguageData[0]?.signup_meta) {
        setSignUpfieldValues(currentLanguageData[0]?.signup_meta);
      } else {
        setSignUpfieldValues(signUpInitialField);
      }
      if (currentLanguageData[0]?.forgotpassword_meta) {
        setForgotPasswordValues(currentLanguageData[0]?.forgotpassword_meta);
      } else {
        setForgotPasswordValues(forgotPasswordInitialState);
      }
      if (currentLanguageData[0]?.home_meta) {
        setHomePageValues(currentLanguageData[0]?.home_meta);
      } else {
        setHomePageValues(homeInitialState);
      }
      if (currentLanguageData[0]?.promises_meta) {
        setPromisesValues(currentLanguageData[0]?.promises_meta);
      } else {
        setPromisesValues(promisesInitialState);
      }
      if (currentLanguageData[0]?.testimonies) {
        setTestimonies(currentLanguageData[0]?.testimonies);
      } else {
        setTestimonies(johnInitialState);
      }
      if (currentLanguageData[0]?.curricullum_meta) {
        setCurriculumValues(currentLanguageData[0]?.curricullum_meta);
      } else {
        setCurriculumValues(CurriculumInitialState);
      }
      if (currentLanguageData[0]?.my_favourites) {
        setFavouriteValues(currentLanguageData[0]?.my_favourites);
      } else {
        setFavouriteValues(FavouritesInitialState);
      }
      if (currentLanguageData[0]?.givings) {
        setGivingsValues(currentLanguageData[0]?.givings);
      } else {
        setGivingsValues(GivingsInitialState);
      }
      if (currentLanguageData[0]?.menus) {
        setMenuValues(currentLanguageData[0]?.menus);
      } else {
        setMenuValues(MenuInitialState);
      }
      if (currentLanguageData[0]?.my_profile) {
        setMyProfileValues(currentLanguageData[0]?.my_profile);
      } else {
        setMyProfileValues(MyProfileInitialState);
      }
      if (currentLanguageData[0]?.my_downloads) {
        setMyDownloadsValues(currentLanguageData[0]?.my_downloads);
      } else {
        setMyDownloadsValues(MyDownloadInitialState);
      }
      if (currentLanguageData[0]?.my_givings) {
        setMyGivingsValues(currentLanguageData[0]?.my_givings);
      } else {
        setMyGivingsValues(MyGivingsInitialState);
      }
      if (currentLanguageData[0]?.help_n_supports?.[0]) {
        setHelpAndSupportValues(currentLanguageData[0]?.help_n_supports?.[0]);
      } else {
        setHelpAndSupportValues(HelpAndSupportInitialState);
      }
      if (currentLanguageData[0]?.about_twg) {
        setAboutTWGValues(currentLanguageData[0]?.about_twg);
      } else {
        setAboutTWGValues(AboutTWGInitialState);
      }
    }
  }, [currentLanguageData]);

  const arrayOfValues = [
    {
      title: "Log In",
      values: loginFieldValues,
      setState: setloginFieldValues,
    },
    {
      title: "Sign Up",
      values: signUpfieldValues,
      setState: setSignUpfieldValues,
    },
    {
      title: "Forgot Password",
      values: forgotPasswordValues,
      setState: setForgotPasswordValues,
    },
    {
      title: "Home page",
      values: homePageValues,
      setState: setHomePageValues,
    },
    {
      title: "Promises",
      values: promisesValues,
      setState: setPromisesValues,
    },
    {
      title: "Testimonies",
      values: testimonies,
      setState: setTestimonies,
    },
    {
      title: "Your achievements",
      values: achievementValues,
      setState: setAchievementValues,
    },
    {
      title: "Videos liked",
      values: videoLikedValues,
      setState: setVideoLikedValues,
    },
    {
      title: "Curriculum",
      values: curriculumValues,
      setState: setCurriculumValues,
    },
    {
      title: "Favourites",
      values: favouriteValues,
      setState: setFavouriteValues,
    },
    {
      title: "Givings",
      values: givingsValues,
      setState: setGivingsValues,
    },
    {
      title: "Menu",
      values: menuValues,
      setState: setMenuValues,
    },
    {
      title: "My Profile",
      values: myProfileValues,
      setState: setMyProfileValues,
    },
    {
      title: "My Downloads",
      values: myDownloadsValues,
      setState: setMyDownloadsValues,
    },
    {
      title: "My Givings",
      values: myGivingsValues,
      setState: setMyGivingsValues,
    },
    {
      title: "Help and Support",
      values: helpAndSupportValues,
      setState: setHelpAndSupportValues,
    },
    {
      title: "About TWG",
      values: aboutTWGValues,
      setState: setAboutTWGValues,
    },
  ];

  const combineStateKeys = (state) => {
    const values = Object.values(state);
    const combinedString = values.join(" @@@@ ");
    return combinedString;
  };

  const updateStateWithTranslation = (language, myState, setState) => {
    const landCode = language.split(" - ");

    const combinedText = combineStateKeys(myState);

    if (landCode[1]?.length > 0) {
      dispatch(
        translateLanguageAction({
          apiPayloadRequest: {
            targetcode: landCode[1],
            translatetext: combinedText,
          },
          callback: (res) => onTranslateSuccess(res?.data, myState, setState),
        })
      );
    }
  };

  const onTranslateSuccess = (data, myState, setState) => {
    console.log(myState, "myState");
    const arrayOfValues = data.split(" @@@@ ");
    const arrayOfKeys = Object.keys(myState);

    for (let key in arrayOfKeys) {
      setState((preState) => ({
        ...preState,
        [arrayOfKeys[key]]: arrayOfValues[key],
      }));
    }
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    if (activeTab === "Log In") {
      setloginFieldValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Sign Up") {
      console.log(name, value, "FNEKMFEK");
      setSignUpfieldValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Forgot Password") {
      setForgotPasswordValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Home page") {
      setHomePageValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Promises") {
      setPromisesValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Testimonies") {
      setTestimonies((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Your achievements") {
      setAchievementValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Videos liked") {
      setVideoLikedValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Curriculum") {
      setCurriculumValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Favourites") {
      setFavouriteValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Givings") {
      setGivingsValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Menu") {
      setMenuValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "My Profile") {
      setMyProfileValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "My Downloads") {
      setMyDownloadsValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "My Givings") {
      setMyGivingsValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Help and Support") {
      setHelpAndSupportValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "About TWG") {
      setAboutTWGValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    if (activeTab === "Log In") {
      if (currentLanguageData[0]?.login_meta) {
        dispatch(
          updateLanguageLoginAction({
            apiPayloadRequest: {
              ...loginFieldValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
        console.log("Update", activeTab);
      } else {
        dispatch(
          saveLanguageLoginAction({
            apiPayloadRequest: {
              ...loginFieldValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Sign Up") {
      if (currentLanguageData[0]?.signup_meta) {
        dispatch(
          updateLanguageSignUpAction({
            apiPayloadRequest: {
              ...signUpfieldValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageSignUpAction({
            apiPayloadRequest: {
              ...signUpfieldValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Forgot Password") {
      if (currentLanguageData[0]?.forgotpassword_meta) {
        dispatch(
          updateLanguageForgotPasswordAction({
            apiPayloadRequest: {
              ...forgotPasswordValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageForgotPasswordAction({
            apiPayloadRequest: {
              ...forgotPasswordValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Home page") {
      if (currentLanguageData[0]?.home_meta) {
        dispatch(
          updateLanguageHomeDataAction({
            apiPayloadRequest: {
              ...homePageValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageHomeDataAction({
            apiPayloadRequest: {
              ...homePageValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Promises") {
      if (currentLanguageData[0]?.promises_meta) {
        dispatch(
          updateLanguagePromisesDataAction({
            apiPayloadRequest: {
              ...promisesValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguagePromisesDataAction({
            apiPayloadRequest: {
              ...promisesValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Testimonies") {
      if (currentLanguageData[0]?.testimonies) {
        dispatch(
          updateLanguageTestimoniesAction({
            apiPayloadRequest: {
              ...testimonies,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageTestimoniesAction({
            apiPayloadRequest: {
              ...testimonies,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Favourites") {
      if (currentLanguageData[0]?.my_favourites) {
        dispatch(
          updateLanguageFavouritesAction({
            apiPayloadRequest: {
              ...favouriteValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageFavouritesAction({
            apiPayloadRequest: {
              ...favouriteValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Curriculum") {
      if (currentLanguageData[0]?.curricullum_meta) {
        dispatch(
          updateLanguageCurriculumAction({
            apiPayloadRequest: {
              ...curriculumValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageCurriculumAction({
            apiPayloadRequest: {
              ...curriculumValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Givings") {
      if (currentLanguageData[0]?.givings) {
        dispatch(
          updateLanguageGivingsAction({
            apiPayloadRequest: {
              ...givingsValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageGivingsAction({
            apiPayloadRequest: {
              ...givingsValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Menu") {
      if (currentLanguageData[0]?.menus) {
        dispatch(
          updateLanguageMenuAction({
            apiPayloadRequest: {
              ...menuValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageMenuAction({
            apiPayloadRequest: {
              ...menuValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "My Profile") {
      if (currentLanguageData[0]?.my_profile) {
        dispatch(
          updateLanguageMyProfileAction({
            apiPayloadRequest: {
              ...myProfileValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageMyProfileAction({
            apiPayloadRequest: {
              ...myProfileValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "My Downloads") {
      if (currentLanguageData[0]?.my_downloads) {
        dispatch(
          updateLanguageMyDownloadsAction({
            apiPayloadRequest: {
              ...myDownloadsValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageMyDownloadsAction({
            apiPayloadRequest: {
              ...myDownloadsValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "My Givings") {
      if (currentLanguageData[0]?.my_givings) {
        dispatch(
          updateLanguageMyGivingsAction({
            apiPayloadRequest: {
              ...myGivingsValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageMyGivingsAction({
            apiPayloadRequest: {
              ...myGivingsValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Help and Support") {
      if (currentLanguageData[0]?.help_n_supports?.[0]) {
        dispatch(
          updateLanguageHelpAndSupportAction({
            apiPayloadRequest: {
              ...helpAndSupportValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageHelpAndSupportAction({
            apiPayloadRequest: {
              ...helpAndSupportValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "About TWG") {
      if (currentLanguageData[0]?.about_twg) {
        dispatch(
          updateLanguageAboutTWGAction({
            apiPayloadRequest: {
              ...aboutTWGValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageAboutTWGAction({
            apiPayloadRequest: {
              ...aboutTWGValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    }
  };

  const handleAccordionSave = () => {
    if (!currentLanguageData[0]?.help_n_supports?.[0]) {
      dispatch(
        updateLanguageHelpAndSupportAction({
          apiPayloadRequest: {
            ...helpAndSupportValues,
            _id: currentLanguageData[0]?._id,
          },
          callback: (res) => {
            languagePreviewResponse(res, activeTab + " Updated Successfully!");
            dispatch(
              saveLanguageHelpAndSupportAccordianAction({
                apiPayloadRequest: {
                  ...currAccordion,
                  helpsuppObjId: helpAndSupportValues?.helpsuppObjId,
                  _id: currentLanguageData[0]?._id,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    setShowAccordionModal(false);
                    setHelpAndSupportValues((prev) => ({
                      ...prev,
                      accordians: [
                        ...prev?.accordians,
                        { ...currAccordion, accorObjId: res?.data?.accorObjId },
                      ],
                    }));
                    Toastiy("Accordion Added Successfully!", 1);
                  }
                },
              })
            );
          },
        })
      );
    } else {
      if (!currAccordion?.accorObjId) {
        dispatch(
          saveLanguageHelpAndSupportAccordianAction({
            apiPayloadRequest: {
              ...currAccordion,
              helpsuppObjId: helpAndSupportValues?.helpsuppObjId,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => {
              if (res?.type === 1) {
                setShowAccordionModal(false);
                setHelpAndSupportValues((prev) => ({
                  ...prev,
                  accordians: [
                    ...prev?.accordians,
                    { ...currAccordion, accorObjId: res?.data?.accorObjId },
                  ],
                }));
                Toastiy("Accordion Added Successfully!", 1);
              }
            },
          })
        );
      } else {
        dispatch(
          updateLanguageHelpAndSupportAccordianAction({
            apiPayloadRequest: {
              ...currAccordion,
              helpsuppObjId: helpAndSupportValues?.helpsuppObjId,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => {
              if (res?.type === 1) {
                setShowAccordionModal(false);
                setHelpAndSupportValues((prev) => ({
                  ...prev,
                  accordians: prev?.accordians?.map((accordion) =>
                    accordion?.accorObjId === currAccordion?.accorObjId ? currAccordion : accordion
                  ),
                }));
                Toastiy("Accordion Updated Successfully!", 1);
              }
            },
          })
        );
      }
    }
  };

  const handleAccordionDelete = (accorObjId) => {
    dispatch(
      deleteLanguageHelpAndSupportAccordianAction({
        apiPayloadRequest: {
          _id: currentLanguageData[0]?._id,
          helpsuppObjId: helpAndSupportValues?.helpsuppObjId,
          accorObjId: accorObjId,
        },
        callback: (res) => {
          if (res?.type === 1) {
            setHelpAndSupportValues((prev) => ({
              ...prev,
              accordians: prev?.accordians?.filter(
                (accordion) => accordion?.accorObjId !== currAccordion?.accorObjId
              ),
            }));
            Toastiy("Accordion Deleted Successfully!", 1);
          }
        },
      })
    );
  };

  const handleLanguageSave = () => {
    const languageCode = targetLanguage.split(" - ");
    dispatch(
      saveLanguageAction({
        apiPayloadRequest: {
          language_code: languageCode[1],
          language_name: languageCode[0],
        },
        callback: (res) => {
          if (res?.type === 1) {
            onLanguageSaveSuccess(languageCode[1], res?.type);
          }
        },
      })
    );
  };

  const onLanguageSaveSuccess = (code, type) => {
    readAllLanguages(code);
    Toastiy("Language Added Successfully!", type);
    setShowAdd(false);
  };

  const handleDeleteLanguage = (item) => {
    dispatch(
      deleteLanguageSignUpAction({
        apiPayloadRequest: { _id: "give the id over here" },
        callback: deleteSuccessResponse,
      })
    );
  };

  const languagePreviewResponse = (res, message) => {
    // console.log(data, "Preview Response")
    if (res?.type === 1) {
      setIsUpdated(true);
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };

  console.log(isUpdated, "isUpdated");

  const deleteSuccessResponse = (data) => {};

  const saveOrUpdateLanguageResponse = (res, message) => {
    if (res?.type === 1) {
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };

  const saveOrUpdateForgotPassResponse = (res, message) => {
    if (res?.type === 1) {
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };

  const saveOrUpdateLanguageHomeDataResponse = (res, message) => {
    if (res?.type === 1) {
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };

  const saveOrUpdateLanguageFavouritesDataResponse = (res, message) => {
    if (res?.type === 1) {
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };
  const saveOrUpdateLanguageCurriculumResponse = (res, message) => {
    if (res?.type === 1) {
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };
  const saveOrUpdateLanguageGivingsResponse = (res, message) => {
    if (res?.type === 1) {
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };
  const saveOrUpdateLanguageMenuResponse = (res, message) => {
    if (res?.type === 1) {
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };
  const saveOrUpdateLanguageMyProfileResponse = (res, message) => {
    if (res?.type === 1) {
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };
  const saveOrUpdateLanguageMyDownloadsResponse = (res, message) => {
    if (res?.type === 1) {
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };
  const saveOrUpdateLanguageMyGivingsResponse = (res, message) => {
    if (res?.type === 1) {
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };
  const saveOrUpdateLanguageHelpAndSupportAccordResponse = (res, message) => {
    if (res?.type === 1) {
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };
  const saveOrUpdateLanguageHelpAndSupportResponse = (res, message) => {
    if (res?.type === 1) {
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };
  const saveOrUpdateLanguageAboutTWGResponse = (res, message) => {
    if (res?.type === 1) {
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };
  const saveOrUpdateLanguageTestimoniesResponse = (res, message) => {
    if (res?.type === 1) {
      Toastiy(message, res?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };

  const getLangOrUpdateuageSignUpResponse = (data) => {};

  const getLanguageCurriculumResponse = (data) => {};

  const getLanguageomeDataResponse = (data) => {};

  const getLanguageFavouritesResponse = (data) => {};

  const getLanguageTestimoniesResponse = (data) => {};

  const getLanguageGivingsResponse = (data) => {};

  const getLanguageMenuResponse = (data) => {};
  const getLanguageMyProfileResponse = (data) => {};
  const getLanguagemyDownloadsResponse = (data) => {};
  const getLanguageMyGivingsResponse = (data) => {};
  const getLanguageHelpAndSupportResponse = (data) => {};
  const getLanguageHelpAndSupportAccordianResponse = (data) => {};

  const getLanguageAboutTWGResponse = (data) => {};

  const getLanguageForgotPassResponse = (data) => {};

  return {
    value,
    onChangeSearchBar,
    handleSubmit,
    activeTab,
    arrayOfValues,
    langPreview,
    setShowData,
    showData,
    handleDeleteLanguage,
    setLangPreview,
    activeTabData,
    setActiveTab,
    handleFieldChange,
    setActiveTabData,
    languages,
    setTargetLanguage,
    targetLanguage,
    updateStateWithTranslation,
    setCurrentState,
    currentState,
    handleLanguageSave,
    currentLanguageData,
    showAdd,
    languageStats,
    showAccordionModal,
    setShowAccordionModal,
    isAccordionEdit,
    setIsAccordionEdit,
    currAccordion,
    setCurrAccordion,
    accordionInitialState,
    handleAccordionInput,
    handleAccordionSave,
    handleAccordionDelete,
  };
};
