import React, { useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import { MdCancel } from "react-icons/md";

import Like from "../../assets/icons/hoverfav.svg";
import { PiPlayCircleFill } from "react-icons/pi";

const CommonCarousel = ({
  _id = "",
  showLikes,
  imageType,
  data,
  perViewImages,
  showProgress,
  title,
  showTitle = true,
  seeAll = true,
  showItemTitle = false,
  linkTo = "",
  dynamiclinkTo = false,
  seeAllLink,
  canDelete = false,
  seeAllValue = "See All",
  Backto = "Home",
  isPromise = false,
  promiseClickHandler = () => {},
  handleDelete = () => {},
}) => {
  const audioRefs = useRef([]);
  const navigate = useNavigate();
  const totalCount = data?.length;
  const numberOfLists = Math.ceil(totalCount / perViewImages);
  const mywidth = 100 / perViewImages;
  const widthOfImage = mywidth - (0.2 + perViewImages / 10);

  const [playState, setPlayingState] = useState([]);

  const handleSeeAll = (title, data) => {
    navigate("/seeAll", {
      state: { title: title, data: data, Backto: Backto },
    });
  };

  const hanndlePlaying = (index) => {
    if (playState.length > 0) {
      const update = [...playState];
      update[index].playing = !update[index].playing;

      if (update[index].playing) {
        audioRefs.current[index].current.play();
      } else {
        audioRefs.current[index].current.pause();
      }

      setPlayingState(update);
    }
  };

  const handleNavigate = (item, index) => {
    console.log("item", item);
    const itemType = dynamiclinkTo ? item?.type : linkTo;
    if ((itemType === "videos" || itemType === "curriculum") && !dynamiclinkTo) {
      navigate("/video-player", {
        state: {
          url: item?.url,
          title: item?.title,
          _id: item?._id,
          category: itemType,
          image: item?.image,
          download: true,
        },
      });
    } else if (itemType === "video" || itemType === "videos") {
      navigate(`/videoInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (itemType === "category") {
      navigate(`/categoryInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (itemType === "testimonial") {
      navigate(`/testimonialInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (itemType === "curriculum") {
      navigate(`/testimonialInfo/${item._id}`, { state: { _id: item?._id } });
    } else {
      navigate(`/curriculum-details/${_id}`, {
        state: { _id: item?._id },
      });
    }
  };

  const handlePickWhereLeftNavigate = (item, index) => {
    if (item?.type === "video") {
      navigate(`/videoInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (item?.type === "category") {
      navigate(`/categoryInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (item?.type === "curriculum") {
      navigate(`/curriculum-details/${item._id}`, {
        state: { _id: item?._id },
      });
    } else if (item?.type === "testimonial") {
      navigate(`/testimonialInfo/${item._id}`, { state: { _id: item?._id } });
    } else {
      navigate(`/curriculum-details/${_id}`, {
        state: { _id: item?._id },
      });
    }
  };

  // useEffect(() => {
  //   setPlayingState([]);
  //   if (isPromise) {
  //     data?.map((item) => {
  //       setPlayingState((prev) => [
  //         ...prev,
  //         {
  //           playing: false,
  //         },
  //       ]);
  //     });

  //     audioRefs.current = Array(data.length)
  //       .fill()
  //       .map((_, i) => audioRefs.current[i] || createRef());
  //   }
  // }, [isPromise, data.length]);

  return (
    <div className={"mt-4"}>
      <div className={`pr-3 items-center flex ${showTitle ? "justify-between" : "justify-end"}`}>
        {showTitle && <p className=" pt-6 pb-2 text-lg font-semibold text-white">{title}</p>}
        {seeAll && (
          <button
            className="text-xs text-yellow-500 cursor-pointer"
            onClick={() => handleSeeAll(title, data)}
          >
            {seeAllValue}
          </button>
        )}
      </div>

      <Carousel
        className="p-1"
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        autoPlay={false}
        infiniteLoop
        swipeable={true}
      >
        {data?.slice(0, numberOfLists).map((_, index) => (
          <div
            key={index}
            className={showItemTitle ? "gap-[1%] pb-8 flex w-[100%]" : "gap-[1%] flex w-[100%]"}
          >
            {data?.slice(index * perViewImages, (index + 1) * perViewImages).map((item, i) => (
              <div
                className={`relative cursor-pointer bg-no-repeat bg-cover bg-top rounded-lg ${
                  imageType === "landscape" ? "aspect-video" : "aspect-[12/16]"
                }`}
                onClick={() => {
                  if (promiseClickHandler) promiseClickHandler(item);
                  showProgress
                    ? handlePickWhereLeftNavigate(item, i)
                    : isPromise
                    ? hanndlePlaying(i)
                    : handleNavigate(item, i);
                }}
                style={{
                  backgroundImage: `url('${item?.image}')`,
                  width: widthOfImage + "%",
                }}
              >
                {isPromise && (
                  <button className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    {/* {playState[i]?.playing ? (
                        <PiPauseCircleFill color="#EFBC51" size={55} />
                      ) : ( */}
                    <PiPlayCircleFill color="#EFBC51" size={55} />
                    {/* )} */}
                    <audio src={item?.audio} ref={audioRefs?.current[i]} />
                  </button>
                )}
                {showItemTitle && (
                  <p className="absolute text-white text-opacity-70 text-sm bottom-[-30px] overflow-hidden overflow-ellipsis text-start text-nowrap w-[95%]">
                    {item?.title}
                  </p>
                )}
                {canDelete && (
                  <div
                    className="text-red-600 flex justify-center items-center text-opacity-60 absolute top-2 right-2 rounded-full bg-white hover:text-opacity-100 transition-all duration-500 ease-in-out z-50"
                    onClick={() => handleDelete(item.category)}
                  >
                    <MdCancel size={25} onClick={() => handleDelete(item.category)} />
                  </div>
                )}
                {showProgress && (
                  <div className="absolute text-sm bottom-0 rounded-b-lg flex flex-col justify-end items-center h-[60%] w-[100%] bg-gradient-to-b from-[#0000] to-[#000] pb-4">
                    <p className="text-sm text-white font-semibold text-center w-[90%]">
                      {item?.title?.length < 15
                        ? item?.title
                        : item?.title?.substring(0, 15) + ".."}
                    </p>
                    <p className=" text-[12px] text-white opacity-50 text-center">
                      Completed {item?.percentage} %
                    </p>
                    <div className="w-full mt-3 px-5">
                      <div className="h-[3px] w-full bg-gray-500 rounded">
                        {item?.percentage !== "0" ? (
                          <div
                            className={`h-[3px] w-[${
                              parseInt(item?.percentage) > 5 ? item?.percentage : "5"
                            }%] bg-yellow-500 rounded`}
                          ></div>
                        ) : (
                          <div className={`h-[3px] `}></div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {showLikes && <img className="absolute h-5 top-3 left-[42%]" src={Like} />}
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CommonCarousel;
