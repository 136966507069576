import React from "react";
import { ImStack, ImStarEmpty, ImStarFull, ImStarHalf } from "react-icons/im";

const RatingStars = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;

  console.log(hasHalfStar, "hasHalfStar");

  // Array to store stars
  const stars = [];
  let i = 0;
  // Add full stars
  for (; i < fullStars; i++) {
    stars.push(<ImStarFull key={i} color="#EFBC51" />);
  }
  if (hasHalfStar) {
    stars.push(<ImStarHalf key={fullStars} color="#EFBC51" />);
    i++;
  }

  for (; i < 5; i++) {
    stars.push(<ImStarEmpty key={i} color="#EFBC51" />);
  }

  return <div className="flex gap-2">{stars}</div>;
};

export default RatingStars;
