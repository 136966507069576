import { useState } from "react";

const TwgHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

export default TwgHooks;

export const AllTwgHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

// export const AddTwgHooks = () => {
//   const [value, setValue] = useState("");
//   const onChangeSearchBar = (e) => {
//     setValue(e.target.value);
//   };

//   return { value, onChangeSearchBar };
// };

export const AddTwgHooks = () => {
  // search logic here
  const [value, setValue] = useState("");

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };
  // add category logic here

  const languages = [
    "english",
    "hindi",
    "tamil",
    "telugu",
    "Malayalam",
    "Spanish",
    "Marathi",
    "Urdu",
    "Oriya",
    "kanada",
    "Bengali",
  ]; // List of languages
  // Set the first language as active
  
  const [inputs, setInputs] = useState(
    languages.reduce((acc, lang) => {
      acc[lang] = {
        categoryName: "",
        categoryCode: "",
        categoryIcon: null,
        mobileLayout: "horizontal",
        categoryDescription: "",
        sendTo: "vertical",
      };
      return acc;
    }, {})
  );

  const handleInputChange = (language, field, value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [language]: {
        ...prevInputs[language],
        [field]: value,
      },
    }));
  };

  const handleFileInputChange = (language, field, file) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [language]: {
        ...prevInputs[language],
        [field]: file,
      },
    }));
  };

  const handleCategoryDescriptionChange = (e) => {
    // handleInputChange(activeTab, "categoryDescription", e.target.value);
  };

  const handleSave = () => {
    // Implement your save logic here
    console.log("Inputs saved:", inputs);
  };

  return {
    value,
    onChangeSearchBar,
    languages,
    inputs,
    handleInputChange,
    handleFileInputChange,
    handleCategoryDescriptionChange,
    handleSave,
  };
};
