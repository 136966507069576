import SignInButton from "../../../Buttons/SignInButton";
import logo from "../../../../assets/icons/logo.png";
import userImg from "../../../../assets/images/profilepic.png";
import foreignLanguage from "../../../../assets/icons/foreignLanguage.png";
import Screenshothome1 from "../../../../assets/images/Screenshothome1new.png";
import Screenshothome2 from "../../../../assets/images/Screenshothome2new.png";
import Screenshothome3 from "../../../../assets/images/Screenshothome3new.png";
import Screenshothome4 from "../../../../assets/images/Screenshothome4.png";
import Screenshothome5 from "../../../../assets/images/Screenshothome5.png";
import Screenshothome6 from "../../../../assets/images/Screenshothome6.png";
import Screenshothome7 from "../../../../assets/images/Screenshothome7.png";
import Screenshothome8 from "../../../../assets/images/Screenshothome8.png";
import Screenshothome9 from "../../../../assets/images/Screenshothome9.png";
import Screenshothome10 from "../../../../assets/images/Screenshothome10.png";
import Screenshothome11 from "../../../../assets/images/Screenshothome11.png";
import Screenshotallmenu from "../../../../assets/images/Screenshotallmenunew.png";

import testimonials from "../../../../assets/images/testimonials.png";
import InputsOfText from "../../../InputFields/InputsOfText";
import { AddLanguagesHooks } from "../../../../screens/Languages/hooks/LanguagesHooks";

const Homepreview = (fieldValues) => {
  const { setShowData, showData } = AddLanguagesHooks();

  return (
    <div className="flex flex-col h-full relative bg-black ">
      <div className="bg-zinc-800 absolute  bottom-0 left-0 right-0   rounded-t-xl  z-10 ">
        <img src={Screenshotallmenu} />
      </div>

      <div className="flex flex-col small-bar  overflow-auto pb-14 px-1">
        <div className="flex flex-row gap-2 pt-5 px-3 items-center">
          <img className="w-7" src={logo} alt="company logo" />
          <div className="w-24 h-7 bg-slate-50 opacity-40 rounded-3xl"></div>
          <img className="w-7" src={foreignLanguage} alt="language support" />
          <img className="w-8" src={userImg} alt="user avatar" />
        </div>

        <div className="flex flex-row text-center gap-2 justify-center w-full font-semibold opacity-70 py-3">
          <InputsOfText
            value={fieldValues?.fieldValues?.welcome_lbl}
            inputStyle={
              !showData
                ? "text-yellowish w-14 text-[12px] cursor-pointer"
                : "text-slate-200 w-14 text-[12px] cursor-pointer"
            }
            onClick={() => setShowData(false)}
          />
          <InputsOfText
            value={fieldValues?.fieldValues?.todaywithgod_lbl}
            inputStyle={
              showData
                ? "text-yellowish w-24 text-[12px] cursor-pointer"
                : "text-slate-200 w-24 text-[12px] cursor-pointer"
            }
            onClick={() => setShowData(true)}
          />
        </div>

        {!showData ? (
          <div>
            <div className="flex justify-center h-56 py-3 ">
              <img src={Screenshothome1} className="object-cover" alt="section1" />
            </div>

            <div className="flex flex-col">
              <div className="flex fex-row justify-between px-3">
                <InputsOfText
                  value={fieldValues?.fieldValues?.top10_lbl}
                  inputStyle={"text-slate-200 w-10 font-bold text-[12px]"}
                />
                <InputsOfText
                  value={fieldValues?.fieldValues?.seeall_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                />
              </div>

              <div className="flex justify-center h-56 py-3 ">
                <img src={Screenshothome2} className="object-cover" alt="section2" />
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex fex-row justify-between px-3">
                <InputsOfText
                  value={fieldValues?.fieldValues?.pick_where_you_are_left_lbl}
                  inputStyle={"text-slate-200 w-full font-bold text-[12px]"}
                />
                <InputsOfText
                  value={fieldValues?.fieldValues?.seeall_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                />
              </div>

              <div className="flex justify-center h-56 py-3 ">
                <img src={Screenshothome3} className="object-cover" alt="section3" />
              </div>
            </div>

            <div className="bg-gradient-to-b from-zinc-900 to-black mr-1 mb-1 rounded-tr-3xl flex flex-col justify-center gap-4 ">
              <div className="flex flex-row justify-between pt-6 px-2">
                <InputsOfText
                  value={fieldValues?.fieldValues?.who_is_jesus_lbl}
                  inputStyle={"text-slate-200 w-24 font-semibold text-[14px]"}
                />
                <InputsOfText
                  value={fieldValues?.fieldValues?.seeall_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                />
              </div>
              <div className="flex justify-center">
                <img className=" pt-2" src={Screenshothome4} />
              </div>

              <div className="flex flex-row gap-3 px-3">
                <div>
                  <img className="h-24 w-24" src={testimonials} />
                  <p className="text-[8px] text-white opacity-70 p-1">Lesson 1</p>
                </div>
                <div>
                  <img className="h-24 w-24" src={testimonials} />
                  <p className="text-[8px] text-white opacity-70 p-1">Lesson 2</p>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-b from-zinc-900 to-black mr-1 mb-1 rounded-tr-3xl flex flex-col justify-center gap-4 ">
              <div className="flex flex-row justify-between pt-6 px-2">
                <InputsOfText
                  value={fieldValues?.fieldValues?.parables_lbl}
                  inputStyle={"text-slate-200 w-24 font-semibold text-[14px]"}
                />
                <InputsOfText
                  value={fieldValues?.fieldValues?.seeall_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                />
              </div>
              <div className="flex justify-center">
                <img className=" pt-2" src={Screenshothome5} />
              </div>

              <div className="flex flex-row gap-3 px-3">
                <div>
                  <img className="h-24 w-24" src={testimonials} />
                  <p className="text-[8px] text-white opacity-70 p-1">Lesson 1</p>
                </div>
                <div>
                  <img className="h-24 w-24" src={testimonials} />
                  <p className="text-[8px] text-white opacity-70 p-1">Lesson 2</p>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-b from-zinc-900 to-black mr-1 mb-1 rounded-tr-3xl flex flex-col justify-center gap-4 ">
              <div className="flex flex-row justify-between pt-6 px-2">
                <InputsOfText
                  value={fieldValues?.fieldValues?.jesus_speaks_lbl}
                  inputStyle={"text-slate-200 w-24 font-semibold text-[14px]"}
                />
                <InputsOfText
                  value={fieldValues?.fieldValues?.seeall_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                />
              </div>
              <div className="flex justify-center">
                <img className=" pt-2" src={Screenshothome6} />
              </div>

              <div className="flex flex-row gap-3 px-3">
                <div>
                  <img className="h-24 w-24" src={testimonials} />
                  <p className="text-[8px] text-white opacity-70 p-1">Lesson 1</p>
                </div>
                <div>
                  <img className="h-24 w-24" src={testimonials} />
                  <p className="text-[8px] text-white opacity-70 p-1">Lesson 2</p>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-b from-zinc-900 to-black mr-1 mb-1 rounded-tr-3xl flex flex-col justify-center gap-4 ">
              <div className="flex flex-row justify-between pt-6 px-2">
                <InputsOfText
                  value={fieldValues?.fieldValues?.promises_lbl}
                  inputStyle={"text-slate-200 w-24 font-semibold text-[14px]"}
                />
                <InputsOfText
                  value={fieldValues?.fieldValues?.seeall_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                />
              </div>
              <div className="flex justify-center">
                <img className=" pt-2" src={Screenshothome7} />
              </div>

              <div className="flex flex-row gap-3 px-3">
                <div>
                  <img className="h-24 w-24" src={testimonials} />
                  <p className="text-[8px] text-white opacity-70 p-1">Promise 1</p>
                </div>
                <div>
                  <img className="h-24 w-24" src={testimonials} />
                  <p className="text-[8px] text-white opacity-70 p-1">Promise 2</p>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-b from-zinc-900 to-black mr-1 mb-1 rounded-tr-3xl flex flex-col justify-center gap-4 ">
              <div className="flex flex-row justify-between pt-6 px-2">
                <InputsOfText
                  value={fieldValues?.fieldValues?.testimony_of_the_day_lbl}
                  inputStyle={"text-slate-200 w-36 font-semibold text-[14px]"}
                />
                {/* <InputsOfText
                  value={fieldValues?.fieldValues?.seeall_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                /> */}
              </div>
              <div className="flex justify-center">
                <img className="pt-2" src={Screenshothome8} />
              </div>

              <div className="flex flex-row justify-between pt-6 px-2">
                <InputsOfText
                  value={fieldValues?.fieldValues?.more_videos_lbl}
                  inputStyle={"text-slate-200 w-36 font-semibold text-[14px]"}
                />
                <InputsOfText
                  value={fieldValues?.fieldValues?.seeall_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                />
              </div>
              <div className="flex justify-center gap-2">
                <img className=" pt-2" src={Screenshothome9} />
              </div>

              <div className="flex flex-col pt-6 px-2 gap-3">
                <InputsOfText
                  value={fieldValues?.fieldValues?.support_us_lbl}
                  inputStyle={"text-slate-200 w-36 font-semibold text-[14px]"}
                />
                <SignInButton
                  Label={fieldValues?.fieldValues?.support_btn}
                  style={"bg-yellow-300 "}
                />
                <div className="flex justify-center">
                  <img src={Screenshothome10} className="pt-2 object-cover" alt="section10" />
                </div>
              </div>

              <div className="flex flex-col pt-6 px-2 gap-3">
                <InputsOfText
                  value={fieldValues?.fieldValues?.need_prayer_lbl}
                  inputStyle={"text-slate-200 w-36 font-semibold text-[14px]"}
                />
                <div className="flex justify-center">
                  <img src={Screenshothome11} className="pt-2 object-cover" alt="section 11" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col p-1 pl-2">
            <InputsOfText
              value="Promise of the day"
              inputStyle={"text-slate-200 w-36 font-semibold text-[14px]"}
            />
            <div className="flex justify-center">
              <img className="w-52 h-80 pt-3" src={testimonials} />
            </div>

            <div className="bg-gradient-to-b from-zinc-900 to-black mr-1 mb-1 rounded-tr-3xl flex flex-col justify-center gap-4 ">
              <div className="flex flex-row justify-between pt-6 px-2">
                <InputsOfText
                  value="Here God's word"
                  inputStyle={"text-slate-200 w-32 font-semibold text-[14px]"}
                />
              </div>
              <div className="flex justify-center">
                <img className="w-52 h-36 pt-2" src={testimonials} />
              </div>

              <p className="text-yellowish text-[14px] font-semibold p-1 -mb-4">Here God's word</p>

              <div className="flex flex-row w-full items-center">
                <div className="w-1/2 p-2 pt-0">
                  <p className="text-white text-[10px] font-semibold opacity-60">
                    Gospel of Matthew is the first book of the New Testament of the Bible and one of
                    the three synoptic Gospels. It tells how Israel's Messiah.
                  </p>
                </div>
                <div className="w-1/2 px-3">
                  <img className="h-24" src={testimonials} />
                </div>
              </div>
            </div>

            <div className="flex flex-col pt-6 px-2 gap-3">
              <InputsOfText
                value="Read God's word"
                inputStyle={"text-slate-200 w-36 font-semibold text-[14px]"}
              />
              <div className="flex justify-center">
                <img className="w-52 h-36 pt-2" src={testimonials} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Homepreview;
