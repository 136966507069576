import React from "react";
import infoCircle from "../../assets/icons/infocircle.svg";

function SingleTab({ tabClassName, activeTab, tab, saved, iconRequired = false }) {
  return (
    <div className="h-12 relative flex justify-between items-center w-full">
      <div
        className={`${
          activeTab === tab ? "text-blackish bg-yellowish" : "text-greyish"
        } mr-10  w-full rounded-md flex justify-center items-center h-[30px] focus:bg-yellowish focus:text-blackish focus:bg-opacity-10 hover:bg-yellowish cursor-pointer font-semibold text-[12px] leading-3 hover:text-blackish px-2 ${
          tabClassName ?? ""
        }`}
      >
        {tab}
      </div>
      {iconRequired ? (
        !saved ? (
          <div className="h-2 w-2 absolute right-2 rounded-full bg-red-500"></div>
        ) : (
          <div className="h-2 w-2 absolute right-2 rounded-full bg-green-500"></div>
        )
      ) : (
        <></>
      )}
    </div>
  );
}

export default SingleTab;
